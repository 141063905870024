import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas, Stack, Card } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil, faProjectDiagram, faHandshake } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";
import { FilePond } from "react-filepond";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const SettingUploadLeads = () => {
    const { t } = useTranslation();
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [LeadsData, setLeadsData] = useState([]);
    const [isAddLead, setisAddLead] = useState(false);
    const [leadsLength, setLeadsLength] = useState(0);
    const [selectedMode, setSelectedMode] = useState(null);
    const [leadSource, setLeadSource] = useState([]);
    const [team, setTeam] = useState([]);
    const [pipeline, setPipeline] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);

    const [importLeadsCount, setImportLeadsCount] = useState(0);
    const [importLeadsData, setImportLeadsData] = useState([]);
    const [importLeadsRecord, setImportLeadsRecord] = useState([]);
    const leadsRef = useRef();


    // Validation Schema
    const validationSchemaLeadRoute = yup.object().shape({
        lead_routing_title: yup.string().required(t("UploadLeads_title_is_required")),
        lead_source: yup.string().required(t("UploadLeads_source_is_required")),
        lead_team: yup.string().required(t("UploadLeads_team_is_required")),
        lead_pipeline: yup.string().required(t("UploadLeads_pipeline_is_required")),
    });

    const validationSchemaDirectAssign = yup.object().shape({
        direct_assign_title: yup.string().required(t("UploadLeads_title_is_required")),
        direct_assign_lead_source: yup.string().required(t("UploadLeads_source_is_required")),
        direct_assign_team: yup.string().required(t("UploadLeads_team_is_required")),
        direct_assign_pipeline: yup.string().required(t("UploadLeads_pipeline_is_required")),
        direct_assign_leads_per_day: yup.string().required(t("UploadLeads_leads_per_day_is_required")),
    })

    const getValidationSchema = (selectedMode) => {
        if (selectedMode === 'lead_route') {
            return validationSchemaLeadRoute;
        } else if (selectedMode === 'direct_assign_rotated') {
            return validationSchemaDirectAssign;
        }
        return null;
    };

    // GET FUNCTION --------------------------------------------------------
    const getUploadLeadsList = async () => {
        try {
            const res = await axios.get(`ws/ws_data_upload.php`, {
                params: {
                    ctoken: session.company_token,
                    task: "listDataUpload",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                const sortedRecords = data.record.sort((a, b) => {
                    // Convert date strings to Date objects
                    const dateA = new Date(a.data_upload_date_time_create);
                    const dateB = new Date(b.data_upload_date_time_create);
                    
                    // Compare dates
                    return dateB - dateA;
                });
    
                setLeadsLength(sortedRecords.length);
                setLeadsData(sortedRecords);

                // setLeadsLength(data.record.length);
                // setLeadsData(data.record)
                setLoading(false);
            } else {
                setLeadsLength(0);
                setLeadsData([])
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.data.data_upload_date_time_create;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("D-MMM-YYYY").toUpperCase();
        }
    };

    const setOutcome = (params) => {
        const { data } = params;

        const completed = data.TOTAL_COMPLETED || "0";
        const notCompleted = data.TOTAL_DATA - completed || "0";
        const duplicated = data.TOTAL_DUPLICATE || "0";

        const outcomeString = `$ ${completed} completed | ${notCompleted} not completed | ${duplicated} duplicated`;
        return outcomeString;
    }

    const setSource = (params) => {
        const { data } = params;

        return (
            <div>
                <img src={`${session.hostUrl}/${session.hostUrlType}/assets/source_cat/default.jpg`} className="rounded-circle me-2" width={30} height={30} />
                {data.ds_title}
            </div>
        );
    }

    const handleActive = async (params) => {
        var active = params.pause == '0' ? '1' : '0';

        try {
            const res = await axios.get("ws/ws_data_upload.php", {
                params: {
                    task: 'pauseDataUpload',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    pause: active,
                    insertion_job_id: params.insertion_job_id
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setLeadsData((data) =>
                    data.map((prevData) =>
                        prevData.insertion_job_id === params.insertion_job_id ? { ...prevData, pause: active } : prevData
                    )
                );

                Swal.fire({
                    icon: 'success',
                    title: t("UploadLeads_success"),
                    text: t("UploadLeads_leads_status_updated"),
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t("UploadLeads_failed"),
                    text: t("UploadLeads_please_try_again_or_contact_your_it_support")
                });
            }
        } catch {

        }
    }

    const getLeadSource = async () => {
        try {
            const res = await axios.get("ws/ws_source.php", {
                params: {
                    company: session.company_id,
                    task: '2'
                }
            })

            const data = res.data;

            if (data.status === 0) {
                const transformedData = data.record.map(source => ({
                    label: source.ds_title,
                    value: source.ds_token
                }));

                setLeadSource(transformedData);
            } else {
                setLeadSource([]);
            }
        } catch {
            console.error("Some error happened when fetching lead source")
        }
    }

    const getTeam = async () => {
        try {
            const res = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: session.company_token,
                    task: 'getUserTags',
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                const transformedData = data.record.map(team => ({
                    label: team.user_tag_title,
                    value: team.tag_token
                }));

                setTeam(transformedData);
            } else {
                setTeam([]);
            }
        } catch {
            console.error("Some error happened when fetching team!")
        }
    }

    const getPipeline = async () => {
        try {
            const res = await axios.get("ws/ws_setting.php", {
                params: {
                    company: session.company_id,
                    task: 'getAllPipeline'
                }
            })

            const data = res.data;

            if (data.status === 0) {
                const transformedData = data.record.map(pipe => ({
                    label: pipe.pipeline_title,
                    value: pipe.pipeline_id
                }));

                setPipeline(transformedData);
            } else {
                setPipeline([]);
            }
        } catch {
            console.error("Some error happened when fetching pipeline!")
        }
    }


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const containerStyle2 = useMemo(() => ({ width: "100%", height: "50vh" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        {
            headerName: t("UploadLeads_date"),
            field: "data_upload_date_time_create",
            width: 150,
            cellRenderer: setDateFormat,
        },
        {
            headerName: t("UploadLeads_title"),
            field: "data_upload_title",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("UploadLeads_outcome"),
            field: "TOTAL_COMPLETED",
            width: 350,
            cellRenderer: setOutcome
        },
        {
            headerName: t("UploadLeads_source"),
            field: "ds_title",
            width: 250,
            cellRenderer: setSource
        },
        { headerName: t("UploadLeads_team_user"), field: "user_tag_title", width: 250 },
        { headerName: t("UploadLeads_pipeline"), field: "pipeline_title", width: 250 },
        {
            headerName: t("UploadLeads_action"),
            field: "pause",
            resizable: true,
            pinned: "right",
            width: 120,
            headerClass: "center",
            cellClass: "center",
            cellRenderer: (params) => {
                if (params.data) {
                    const isDisabled = Number(params.data.completed) === 1;
                    const isPause = Number(params.data.pause) === 0;

                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <Form.Check
                                type="switch"
                                className="d-flex align-items-center justify-content-center"
                                checked={isPause}
                                disabled={isDisabled}
                                onChange={() => handleActive(params.data)}
                            />
                        </div>
                    );
                }
            }
        },
    ];

    const importCurationColumn = [
        {
            headerName: t("UploadLeads_no"),
            field: "",
            width: 100,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            cellRenderer: (params) => {
                if (params.data) {
                    return params.rowIndex + 1;
                } else {
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            },
        },
        {
            headerName: t("UploadLeads_name"),
            field: "name",
        },
        {
            headerName: t("UploadLeads_email"),
            field: "email",
        },
        {
            headerName: t("UploadLeads_phone"),
            field: "phone",
        },
    ];

    const curationRowId = useCallback(function (params) {
        return params.data.name.toString();
    }, []);

    const curationSelect = useCallback(() => {
        setImportLeadsRecord(leadsRef.current.api.getSelectedRows());
    }, []);


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            if (currentStep === 2) {
                setCurrentStep(currentStep + 1);
            }
    
            if (currentStep === 3 && values.preview_val === 1) {
                var recordReader = new FileReader();
                recordReader.onload = () => {
                    var recordFileData = recordReader.result;
                    
                    // Read the workbook from the file data
                    var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
                    
                    // Dynamically get the first sheet name (instead of hardcoding "Upload Leads")
                    var sheetNames = recordWorkBook.SheetNames;
                    var firstSheetName = sheetNames[0]; // This will always select the first sheet
                    
                    // Access the first sheet using the dynamic sheet name
                    var recordWorksheet = recordWorkBook.Sheets[firstSheetName];
                    var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorksheet);
                    setImportLeadsCount(recordRow.length);
    
                    var headerRows = [];
    
                    if (recordWorksheet) {
                        var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
                        for (var R = range.s.r; R <= range.e.r; ++R) {
                            var row = [];
                            for (var C = range.s.c; C <= range.e.c; ++C) {
                                var cellAddress = XLSX.utils.encode_cell({
                                    r: R,
                                    c: C,
                                });
                                var cell = recordWorksheet[cellAddress];
                                var cellValue = cell ? cell.v : "";
                                row.push(cellValue);
                            }
                            headerRows.push(row);
                        }
                    }
    
                    if (!recordWorksheet) {
                        Swal.fire({
                            icon: "warning",
                            html: t("UploadLeads_you_are_uploading_wrong_templates"),
                        });
                        return;
                    }
    
                    var expectedColumns = ["Name", "Email", "Phone"];
                    var currentColumns = headerRows[0];
    
                    if (currentColumns) {
                        var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
                        if (missingColumns.length > 0) {
                            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
                            for (var i = 0; i < missingColumns.length; i++) {
                                alertMessage += `<li>${missingColumns[i]}</li>\n`;
                            }
                            alertMessage += `</ul></div>`;
                            Swal.fire({
                                icon: "warning",
                                html: alertMessage,
                            });
                            return;
                        }
                    }
    
                    if (!recordRow.length) {
                        Swal.fire({
                            icon: "warning",
                            html: t("UploadLeads_there_is_no_data_inside_the_sheet"),
                        });
                        return;
                    }
    
                    for (let i = 0; i < recordRow.length; i++) {
                        recordRow[i].index = i + 1;
                    }
    
                    const bulkData = recordRow.map(({ "Name": name = "", "Email": email = "", "Phone": phone = "", index: index }) => ({
                        name,
                        email,
                        phone,
                        index,
                    }));
    
                    setImportLeadsData(bulkData);
                };
                recordReader.readAsArrayBuffer(values.file[0]);
            }
    
        } catch (error) {
            console.error(error);
        }
    };
    

    // const handleSubmitForm = async (values) => {
    //     try {
    //         if (currentStep === 2) {
    //             setCurrentStep(currentStep + 1);
    //         }

    //         if (currentStep === 3 && values.preview_val === 1) {
    //             var recordReader = new FileReader();
    //             recordReader.onload = () => {
    //                 var recordFileData = recordReader.result;
    //                 var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
    //                 var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Upload Leads"]);
    //                 var recordData = recordRow;
    //                 setImportLeadsCount(recordRow.length);

    //                 var headerRows = [];
    //                 var recordWorksheet = recordWorkBook.Sheets["Upload Leads"];

    //                 if (recordWorksheet) {
    //                     var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
    //                     for (var R = range.s.r; R <= range.e.r; ++R) {
    //                         var row = [];
    //                         for (var C = range.s.c; C <= range.e.c; ++C) {
    //                             var cellAddress = XLSX.utils.encode_cell({
    //                                 r: R,
    //                                 c: C,
    //                             });
    //                             var cell = recordWorksheet[cellAddress];
    //                             var cellValue = cell ? cell.v : "";
    //                             row.push(cellValue);
    //                         }
    //                         headerRows.push(row);
    //                     }
    //                 }

    //                 if (!recordWorksheet) {
    //                     Swal.fire({
    //                         icon: "warning",
    //                         html: t("UploadLeads_you_are_uploading_wrong_templates"),
    //                     });
    //                     return;
    //                 }

    //                 var expectedColumns = ["Name", "Email", "Phone"];
    //                 var currentColumns = headerRows[0];

    //                 if (currentColumns) {
    //                     var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
    //                     if (missingColumns.length > 0) {
    //                         var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
    //                         for (var i = 0; i < missingColumns.length; i++) {
    //                             alertMessage += `<li>${missingColumns[i]}</li>\n`;
    //                         }
    //                         alertMessage += `</ul></div>`;
    //                         Swal.fire({
    //                             icon: "warning",
    //                             html: alertMessage,
    //                         });
    //                         return;
    //                     }
    //                 }

    //                 if (!recordRow.length) {
    //                     Swal.fire({
    //                         icon: "warning",
    //                         html: t("UploadLeads_there_is_no_data_inside_the_sheet"),
    //                     });
    //                     return;
    //                 }

    //                 for (let i = 0; i < recordData.length; i++) {
    //                     recordData[i].index = i + 1;
    //                 }

    //                 const bulkData = recordData.map(({ "Name": name = "", "Email": email = "", "Phone": phone = "", index: index }) => ({
    //                     name,
    //                     email,
    //                     phone,
    //                     index,
    //                 }));

    //                 setImportLeadsData(bulkData);
    //             };
    //             recordReader.readAsArrayBuffer(values.file[0]);
    //         }

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const sendUploadLeads = async (values) => {
        try {
            let mode = "";
            let ignoreCheckingDuplicate = "";

            if (selectedMode == 'lead_route') {
                mode = 'lead_route';
            } else {
                mode = 'direct_assign_rotated';
            }

            if (importLeadsRecord.length === 0) {

                Swal.fire({
                    icon: "warning",
                    text: t("UploadLeads_no_record_selected"),
                });
                return;
            }

            if (values && values.direct_assign_duplicate_check && mode == 'direct_assign_rotated') {
                ignoreCheckingDuplicate = values.direct_assign_duplicate_check ? 1 : 0;
            }

            var name = importLeadsRecord.map((item) => item.name ? item.name : "").filter(item => item !== "");
            var email = importLeadsRecord.map((item) => item.email ? item.email : "").filter(item => item !== "");
            var phone = importLeadsRecord.map((item) => item.phone ? item.phone : "").filter(item => item !== "");

            var nameVal = name.join("|");
            var emailVal = email.join("|");
            var phoneVal = phone.join("|");

            const formData = new FormData();
            formData.append("task", "addDataUploadDealsV2");
            formData.append("utoken", session.user_ac_token);
            formData.append("ctoken", session.company_token);
            formData.append("title", values.lead_routing_title || values.direct_assign_title);
            formData.append("ds_token", values.lead_source || values.direct_assign_lead_source);
            formData.append("user_tag_token", values.lead_team || values.direct_assign_team);
            formData.append("pipe_id", values.lead_pipeline || values.direct_assign_pipeline);
            formData.append("name", nameVal);
            formData.append("email", emailVal);
            formData.append("contact", phoneVal);
            formData.append("direct_assign_rotated_total_per_day", values.direct_assign_leads_per_day);
            formData.append("ignore_checking_duplicate", ignoreCheckingDuplicate || '');
            formData.append("type", mode);

            const res = await axios.post('ws/ws_data_upload.php', formData);
            const data = res.data;

            if (data.status === 0) {
                setisAddLead(false);

                Swal.fire({
                    icon: "success",
                    title: t("UploadLeads_success"),
                    text: t("UploadLeads_new_leads_imported_successfully"),
                    timer: 1500,
                });

                getUploadLeadsList();
                setCurrentStep(1);
                setSelectedMode(null);
            }
        } catch (error) {
            console.error("Error during import:", error);
        } finally {
            setLoading(false);
        }
    };

    // SELECT MODE --------------------------------------------------

    const handleSelectMode = (mode) => {
        setSelectedMode(mode);
        setCurrentStep(2);
    };

    const handleBackToSelectMode = (step, mode) => {
        if (step === 3) {
            setCurrentStep(step - 1);
            setSelectedMode(mode);
        } else if (step === 2) {
            setSelectedMode(null);
            setCurrentStep(1);
        }
    };


    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setLoading(true);
            getUploadLeadsList();
        }
    }, []);

    const toggleAddLeadsModal = () => {
        setisAddLead(prevState => !prevState);

        getLeadSource();
        getTeam();
        getPipeline();
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                        <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddLeadsModal}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("UploadLeads_new_import")}
                        </button>
                        <div className="px-2 ms-auto">
                            {t("UploadLeads_result")} {leadsLength === 0 ? t("UploadLeads_no_records_found") :
                                leadsLength === 1 ? t("UploadLeads_1_record_found") :
                                    t("UploadLeads_record_many", { count: leadsLength })}
                        </div>
                    </Stack>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : LeadsData} rowHeight={70} pagination={true} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddLead} onHide={toggleAddLeadsModal} size="lg">
                        <Formik
                            initialValues={{
                                lead_routing_title: "",
                                lead_source: "",
                                lead_team: "",
                                lead_pipeline: "",
                                direct_assign_title: "",
                                direct_assign_lead_source: "",
                                direct_assign_team: "",
                                direct_assign_pipeline: "",
                                direct_assign_leads_per_day: "",
                                direct_assign_duplicate_check: true,
                                file: [],
                                submit_disabled: true,
                                preview_val: "",
                                submit_confirm: ""
                            }}
                            validationSchema={getValidationSchema(selectedMode)}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                isValid,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger fw-bold">{t("UploadLeads_import_new_lead")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {currentStep === 1 && !selectedMode && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Modal.Title as={"h3"} className="text-center">{t("UploadLeads_please_choose_bulk_import_mode")}</Modal.Title>
                                                </Form.Group>

                                                <Row>
                                                    <Col xxl={6} className="mb-3">
                                                        <Card className={`shadow-sm cursor-pointer ${selectedMode === 'lead_route' ? 'selected' : ''}`} onClick={() => handleSelectMode('lead_route')}>
                                                            <Card.Body>
                                                                <FontAwesomeIcon icon={faProjectDiagram} className="me-2" style={{ width: '100%', height: '100px' }} />
                                                                <Card.Title as={"h5"} className="text-center">{t("UploadLeads_lead_routing")}</Card.Title>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>

                                                    <Col xxl={6} className="mb-3">
                                                        <Card className={`shadow-sm cursor-pointer ${selectedMode === 'direct_assign_rotated' ? 'selected' : ''}`} onClick={() => handleSelectMode('direct_assign_rotated')}>
                                                            <Card.Body>
                                                                <FontAwesomeIcon icon={faHandshake} className="me-2" style={{ width: '100%', height: '100px' }} />
                                                                <Card.Title as={"h5"} className="text-center">{t("UploadLeads_direct_assign")}</Card.Title>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                        {/* Conditionally render form controls based on selectedMode */}
                                        {selectedMode && (
                                            <>
                                                {currentStep === 2 && selectedMode && (
                                                    <Form.Group className="mb-3">
                                                        <Modal.Title className="fw-bold" as={"h5"} style={{ borderBottom: '1px solid lightgrey' }}>{t("UploadLeads_fill_in_the_information")}</Modal.Title>
                                                    </Form.Group>
                                                )}

                                                {/* Lead Routing */}
                                                {currentStep === 2 && selectedMode === 'lead_route' && (
                                                    <>
                                                        <Row className="mb-3" style={{ height: '70px' }}>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_title")}</Form.Label>
                                                                    <Form.Control
                                                                        placeholder={t("UploadLeads_enter_a_title")}
                                                                        type="text"
                                                                        name="lead_routing_title"
                                                                        value={values.lead_routing_title}
                                                                        onChange={handleChange}
                                                                        isInvalid={touched.lead_routing_title && !!errors.lead_routing_title}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.lead_routing_title}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_lead_source")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_source")}
                                                                        name="lead_source"
                                                                        options={leadSource}
                                                                        valueDefault={leadSource.find(option => option.value === values.lead_source)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('lead_source', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.lead_source && touched.lead_source}
                                                                    />
                                                                    {errors.lead_source && touched.lead_source && <div className="op-error-message">{errors.lead_source}</div>}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-3" style={{ height: '70px' }}>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_team")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_any_team")}
                                                                        name="lead_team"
                                                                        options={team}
                                                                        valueDefault={team.find(option => option.value === values.lead_team)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('lead_team', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.lead_team && touched.lead_team}
                                                                    />
                                                                    {errors.lead_team && touched.lead_team && <div className="op-error-message">{errors.lead_team}</div>}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_pipeline")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_pipeline")}
                                                                        name="lead_pipeline"
                                                                        options={pipeline}
                                                                        valueDefault={pipeline.find(option => option.value === values.lead_pipeline)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('lead_pipeline', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.lead_pipeline && touched.lead_pipeline}
                                                                    />
                                                                    {errors.lead_pipeline && touched.lead_pipeline && <div className="op-error-message">{errors.lead_pipeline}</div>}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}

                                                {/* Direct Assign */}
                                                {currentStep === 2 && selectedMode === 'direct_assign_rotated' && (
                                                    <>
                                                        <Row className="mb-3" style={{ height: '70px' }}>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_title")}</Form.Label>
                                                                    <Form.Control
                                                                        placeholder={t("UploadLeads_enter_a_title")}
                                                                        type="text"
                                                                        name="direct_assign_title"
                                                                        value={values.direct_assign_title}
                                                                        onChange={handleChange}
                                                                        isInvalid={touched.direct_assign_title && !!errors.direct_assign_title}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.direct_assign_title}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_lead_source")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_source")}
                                                                        name="direct_assign_lead_source"
                                                                        options={leadSource}
                                                                        valueDefault={leadSource.find(option => option.value === values.direct_assign_lead_source)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('direct_assign_lead_source', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.direct_assign_lead_source && touched.direct_assign_lead_source}
                                                                    />
                                                                    {errors.direct_assign_lead_source && touched.direct_assign_lead_source && <div className="op-error-message">{errors.direct_assign_lead_source}</div>}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-3" style={{ height: '70px' }}>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_team")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_any_team")}
                                                                        name="direct_assign_team"
                                                                        options={team}
                                                                        valueDefault={team.find(option => option.value === values.direct_assign_team)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('direct_assign_team', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.direct_assign_team && touched.direct_assign_team}
                                                                    />
                                                                    {errors.direct_assign_team && touched.direct_assign_team && <div className="op-error-message">{errors.direct_assign_team}</div>}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_pipeline")}</Form.Label>
                                                                    <FormSelect
                                                                        placeholder={t("UploadLeads_select_pipeline")}
                                                                        name="direct_assign_pipeline"
                                                                        options={pipeline}
                                                                        valueDefault={pipeline.find(option => option.value === values.direct_assign_pipeline)}
                                                                        width="100%"
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue('direct_assign_pipeline', selectedOption ? selectedOption.value : '');
                                                                        }}
                                                                        isClearable
                                                                        isInvalid={errors.direct_assign_pipeline && touched.direct_assign_pipeline}
                                                                    />
                                                                    {errors.direct_assign_pipeline && touched.direct_assign_pipeline && <div className="op-error-message">{errors.direct_assign_pipeline}</div>}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-3" style={{ height: '70px' }}>
                                                            <Col xs={12} md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>{t("UploadLeads_total_leads_per_day")}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="direct_assign_leads_per_day"
                                                                        value={values.direct_assign_leads_per_day}
                                                                        onChange={handleChange}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        isInvalid={touched.direct_assign_leads_per_day && !!errors.direct_assign_leads_per_day}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.direct_assign_leads_per_day}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={12} md={6} className="d-flex align-items-center">
                                                                <Form.Group className="d-flex">
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label="Ignore Checking Duplicate"
                                                                        name="direct_assign_duplicate_check"
                                                                        checked={values.direct_assign_duplicate_check}
                                                                        onChange={(e) => setFieldValue('direct_assign_duplicate_check', e.target.checked)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}

                                                {currentStep === 3 && (
                                                    <>
                                                        <Row>
                                                            <Col xxl={12}>
                                                                <Form.Group>
                                                                    <FilePond
                                                                        allowMultiple={false}
                                                                        maxFiles={1}
                                                                        name="file"
                                                                        credits={false}
                                                                        acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                                                        instantUpload={false}
                                                                        onupdatefiles={(fileItems) => {
                                                                            setFieldValue(
                                                                                "file",
                                                                                fileItems.map((fileItem) => fileItem.file)
                                                                            );
                                                                        }}
                                                                        onremovefile={() => {
                                                                            setFieldValue("file", []);
                                                                            setFieldValue("status_show", false);
                                                                            setFieldValue("status", "");
                                                                            setFieldValue("submit_disabled", true);
                                                                            setImportLeadsCount(0);
                                                                            setImportLeadsData([]);
                                                                            setImportLeadsRecord([]);
                                                                            setTimeout(() => {
                                                                                setFieldValue("status_show", true);
                                                                            }, 1);
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xxl={12}>
                                                                <Form.Text className="text-muted d-flex justify-content-center" style={{ marginBottom: '20px' }}>
                                                                    {t("UploadLeads_notes")}
                                                                </Form.Text>

                                                                <Form.Group className="d-flex justify-content-center">
                                                                    <Button
                                                                        type="submit"
                                                                        variant=""
                                                                        className="op-primary-color text-light"
                                                                        disabled={values.file.length === 0}
                                                                        // onClick={() => setFieldValue("submit_disabled", false)}
                                                                        onClick={() => {
                                                                            setFieldValue("preview_val", 1);
                                                                            setFieldValue("submit_disabled", false);
                                                                        }}
                                                                    >
                                                                        {t("UploadLeads_preview")}
                                                                    </Button>
                                                                    <Button type="button" variant="success" className="ms-2" onClick={() => window.open("https://app.outperformhq.io/cloud_staging/assets/outperform_lead_import_template.xlsx", "_blank")}>
                                                                        {t("UploadLeads_template")}
                                                                    </Button>
                                                                </Form.Group>
                                                            </Col>

                                                            {importLeadsCount > 0 && (
                                                                <Col xxl={12}>
                                                                    <p className="mb-2">{importLeadsCount === 0 ? "No record" : importLeadsCount === 1 ? "1 record" : `${importLeadsCount} records`}</p>
                                                                    <div style={containerStyle2}>
                                                                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                                            <AgGridReact
                                                                                ref={leadsRef}
                                                                                rowSelection="multiple"
                                                                                rowData={importLeadsData}
                                                                                columnDefs={importCurationColumn}
                                                                                getRowId={curationRowId}
                                                                                onSelectionChanged={curationSelect}
                                                                                pagination={true}
                                                                                paginationPageSize={100}
                                                                                paginationPageSizeSelector={false}
                                                                                suppressRowClickSelection={true}
                                                                                animateRows={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {selectedMode && (
                                            <div className="d-flex justify-content-between w-100">
                                                {currentStep > 1 && (
                                                    <button type="button" className="btn btn-secondary" onClick={() => handleBackToSelectMode(currentStep, selectedMode)}>
                                                        {t("UploadLeads_back")}
                                                    </button>
                                                )}

                                                {/* Next Button For lead_route and direct_assign_rotated */}
                                                {currentStep < 3 && selectedMode === 'lead_route' && (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={
                                                            !isValid ||
                                                            (!values.lead_routing_title ||
                                                                !values.lead_source ||
                                                                !values.lead_team ||
                                                                !values.lead_pipeline)
                                                        }
                                                    >
                                                        {t("UploadLeads_next")}
                                                    </button>
                                                )}

                                                {currentStep < 3 && selectedMode === 'direct_assign_rotated' && (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={!values.direct_assign_title ||
                                                            !values.direct_assign_lead_source ||
                                                            !values.direct_assign_leads_per_day ||
                                                            !values.direct_assign_team ||
                                                            !values.direct_assign_pipeline
                                                        }
                                                    >
                                                        {t("UploadLeads_next")}
                                                    </button>
                                                )}
                                                {currentStep === 3 && (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={values.submit_disabled}
                                                        onClick={() => {
                                                            setFieldValue("submit_confirm", 1);
                                                            sendUploadLeads(values)
                                                        }}
                                                    >
                                                        {t("UploadLeads_submit")}
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            )}
        </Container>
    );
}

export default SettingUploadLeads;
