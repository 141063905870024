import { useState, useRef, useEffect, useMemo } from "react";
import { Nav, Stack, Badge, Form, Button, Modal, ButtonGroup, ToggleButton, Row, Col, Container } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import * as formik from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import { FormSelect } from "../includes/FormCustom";


const MySwal = withReactContent(Swal);

const LoginReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [loginRecord, setLoginRecord] = useState([]);
    const [gridKey, setGridKey] = useState(0);
    const [reportDataSource, setReportDataSource] = useState(null);
    const [dateRange, setdateRange] = useState({
        ds: moment().subtract(7, 'days').format('YYYY-MM-DD'), // 7 days ago
        de: moment().format('YYYY-MM-DD'), // today
    });
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);


    // Pagination state
    const [startRow, setStartRow] = useState(0);  // Track startRow for pagination
    const [hasMoreData, setHasMoreData] = useState(true);  // Control API calls for more data

    // GET FUNCTION --------------------------------------------------------
    const getLoginRecord = (startRow = 0) => {
        try {
            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_login_report.php`, {
                    params: {
                        task: "getLoginReport",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        ds: dateRange.ds,
                        de: dateRange.de,
                        user: selectedUser.value || "",
                        startRow: startRow,
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 1 || data.record.length < 30) {
                        setHasMoreData(false);
                    }

                    if (Array.isArray(data.record)) {
                        setLoginRecord(prevRecords => [...prevRecords, ...data.record]);
                    } else {
                        console.error("data.record is not an array:", data.record);
                        setLoginRecord(prevRecords => [...prevRecords]);
                    }

                    setLoading(false);
                });
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const getUser = async () => {
        try {
            const res = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: session.company_token,
                    task: "4a",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            var userOptions = data.record.map((rec) => ({
                label: rec.user_name,
                value: rec.user_id
            }))

            userOptions.unshift({ value: "", label: "All User" });

            setUserOptions(userOptions);

        } catch {

        }
    }


    const containerStyle = useMemo(() => ({ width: "100%", height: "85vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "Date", field: "log_date_time", width: 300 },
        { headerName: "User", field: "user_name", width: 300 },
        { headerName: "Mode", field: "mode", width: 300 },
        { headerName: "Platform", field: "platform", width: 300 },
    ];

    // Function to handle pagination event
    const onPaginationChanged = () => {
        if (gridRef.current.api) {
            const currentPage = gridRef.current.api.paginationGetCurrentPage();
            const totalPages = gridRef.current.api.paginationGetTotalPages();

            if (loginRecord.length >= 30 && currentPage + 1 >= totalPages && hasMoreData) {
                const nextStartRow = startRow + 30;
                setStartRow(nextStartRow); // Increment startRow
                getLoginRecord(nextStartRow); // Fetch next 30 records
            }
        }
    };

    // ONCHANGE FUNCTION -----------------------------------------

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const handleFilterButtonClick = () => {
        setLoading(true);

        setTimeout(() => {
            setLoginRecord([]);
            getLoginRecord();
        }, 3000);
    };

    const handleDownload = () => {
        var params = {
            fileName: "login-report.csv",
            processCellCallback: function (params) {
                if (params.column.colId === "ds_title") {
                    return params.node.data.ds_title;
                } else {
                    var res = params.value;
                    if (res !== undefined && res !== null) {
                        res = String(res).replace(/<br>/g, "\r\n")
                            .replace(/<li>/g, "\r\n")
                            .replace(/<[^>]+>/g, "")
                            .replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setLoading(true);

            setTimeout(() => {
                getLoginRecord();
                getUser();
            }, 3000);
        }
    }, []);

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="d-flex">
                    <div style={{ width: "350px", backgroundColor: "white", height: "100vh" }}>
                        <div className="flex-column mx-2 mt-3">
                            <Form.Group>
                                <Form.Label className="mb-2">Filters:</Form.Label>
                                <Form.Group>
                                    <Form.Control
                                        type="date"
                                        name="ds"
                                        value={dateRange.ds}  // Use the already formatted value from state
                                        onChange={(e) => {
                                            onchangeDateRange("ds", e.target.value); // No need for extra moment() formatting
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3">
                                    <Form.Control
                                        type="date"
                                        name="de"
                                        value={dateRange.de}  // Use the already formatted value from state
                                        onChange={(e) => {
                                            onchangeDateRange("de", e.target.value); // No need for extra moment() formatting
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <FormSelect
                                        options={userOptions}
                                        valueDefault={userOptions.find(option => option.value == selectedUser.value)}
                                        value={selectedUser}
                                        onChange={(selectedOption) => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder="Select User"
                                        shadow={true}
                                        width="100%"
                                        isSearchable
                                    />
                                </Form.Group>

                                <Button className="mt-3" variant="primary" onClick={handleFilterButtonClick} style={{ width: '100%' }}>
                                    View Report
                                </Button>

                                <Button className="mt-3" variant="secondary" onClick={handleDownload} style={{ width: '100%' }}>
                                    Download
                                </Button>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="p-4" style={{ height: '100%', width: '100%' }}>
                        <Row>
                            <Col xxl={12}>
                                <div style={containerStyle}>
                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            columnDefs={gridColumn}
                                            rowData={loginRecord}
                                            rowHeight={100}
                                            pagination={true}
                                            paginationPageSize={30} // Show 30 records per page
                                            onPaginationChanged={onPaginationChanged} // Handle pagination
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default LoginReport;
