import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Container, Modal, Form, Stack, InputGroup, Row, Col, Card } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import Loader from "../../includes/Loader";
import "../../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import FormSelect from "../../includes/FormSelect";
import { useTranslation } from "react-i18next";
import FormCustomField from "../../includes/FormCustomField";
import moment from "moment";

const EditPersonGl = ({ prospect_id, pipeline_id, closeModal, onSuccess, dealID, location }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const [prosDetail, setProsDetail] = useState([]);
    const [prosData, setProsData] = useState({});
    const [cfProsData, setCfProsData] = useState([]);

    const [open, setOpen] = useState(false);

    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [corporateOpt, setCorporateOpt] = useState([]);
    const [idTypeOptState, setIdTypeOptState]= useState([]);

    const [isDisableFd, setIsDisableFd] = useState(false);
    const [isDupModal, setIsDupModal] = useState(false);
    const [dupModalData, setDupModalData] = useState({});

    // const [isExistIDNo, setIsExistIDNo] = useState(false);
    // const [existIDNoData, setIDNoData] = useState({});

    const prefixList = [
        { label: "60", value: "60" },
        { label: "61", value: "61" },
        { label: "65", value: "65" },
        { label: "1", value: "1" },
        { label: "27", value: "27" },
        { label: "33", value: "33" },
        { label: "41", value: "41" },
        { label: "44", value: "44" },
        { label: "49", value: "49" },
        { label: "62", value: "62" },
        { label: "63", value: "63" },
        { label: "66", value: "66" },
        { label: "81", value: "81" },
        { label: "82", value: "82" },
        { label: "84", value: "84" },
        { label: "86", value: "86" },
        { label: "90", value: "90" },
        { label: "91", value: "91" },
        { label: "92", value: "92" },
        { label: "94", value: "94" },
        { label: "353", value: "353" },
        { label: "357", value: "357" },
        { label: "673", value: "673" },
        { label: "850", value: "850" },
        { label: "852", value: "852" },
        { label: "853", value: "853" },
        { label: "880", value: "880" },
        { label: "886", value: "886" },
        { label: "966", value: "966" },
        { label: "971", value: "971" },
        { label: "973", value: "973" },
        { label: "974", value: "974" },
        { label: "996", value: "996" }
      ];
    
    const getProspectDetails = () => {
        axios.get("ws/ws_prospect.php",{
            params: {
                task: "getProspectDetails",
                prospect: prospect_id,
                pipe: pipeline_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0 && data.record.custom_field_data) {

                setProsDetail(data.record);

                const template = ["5000","5001","5002","5003","5004","2850","5005","5006","5007","5008","5009","5010","5011","5012","5013","5014","5015","5016","5017"];

                const sortByObject = template
                .reduce((obj, item, index) => {
                    return {
                    ...obj,
                    [item]: index,
                    };
                }, {});

                data.record.custom_field_data.sort((a,b) => sortByObject[a.cf_id] - sortByObject[b.cf_id]);

                var compulsoryCFIDs = ["5007", "5008", "2681", "5009", "5001", "5014", "5013", "5000", "5010", "5015", "5002", "5005", "5011"];
                var corporate = "";
                var idType = "";
                var idTypeOpt = "";
                var corporateOpt = "";
                var idNumber = '';
                var DOB = '';

                data.record.custom_field_data.map((rec, index) => {
                    if (compulsoryCFIDs.includes(rec.cf_id)) {
                      rec.cf_label = rec.cf_label + " *";
                    }

                    if (rec.cf_id == 5000) {
                        corporate = rec.fd_value;
                        corporateOpt = rec.cf_value;
                    }

                    if (rec.cf_id == 5001) {
                        idType = rec.fd_value;
                        idTypeOpt = rec.cf_value;
                    }

                    if (rec.cf_id == 5002) {
                        idNumber = rec.fd_value;
                    }

                    if (rec.cf_id == 5005) {
                        DOB = rec.fd_value;
                    }

                    if (Number(rec.cf_id) === 5011) {
                        rec.fd_value = rec.fd_value === 'True' ? 'Yes' : rec.fd_value === 'False' ? 'No' : rec.fd_value;
                    }
                });

                var optionsArr = JSON.parse(idTypeOpt);
                
                var idTypeOptNew =  Object.values(optionsArr).map(rec => {
                    return {label: rec, value: rec};
                });

                var idTypeOptUpdated = idTypeOptNew.map(obj => {

                    let corporate;

                    if (obj.value == "NRIC No." || obj.value == "Passport") {
                        corporate = 'No';
                    }else{
                        corporate = 'Yes';
                    }

                    return {...obj, corporate};
                });

                var option2Arr = JSON.parse(corporateOpt);
                var newOpt2 = Object.values(option2Arr).map(rec => {
                    return {label: rec, value: rec};
                });

                setCorporateOpt(newOpt2);
                setIdTypeOptState(idTypeOptUpdated);

                var cf_id_val_na = ["5008","5007","5015","5014","5013"];
                var cf_id_val_other = ["5009","5010"];

                setProsData({
                    pros_name: data.record.prospect_name.toUpperCase(),
                    contact1_pref: data.record.contact1_prefix,
                    contact1: data.record.contact1,
                    contact2_pref: data.record.contact2_prefix,
                    contact2: data.record.contact2,
                    contact3_pref: data.record.contact3_prefix,
                    contact3: data.record.contact3,
                    contact4_pref: data.record.contact4_prefix,
                    contact4: data.record.contact4,
                    email1: data.record.email1,
                    email2: data.record.email2,
                    address1: data.record.address_line1.toUpperCase(),
                    address2: data.record.address_line2.toUpperCase(),
                    address3: data.record.address_line3.toUpperCase(),
                    postcode: data.record.postal_code,
                    country: data.record.country_id,
                    state: data.record.state_id,
                    city: data.record.city_id,
                    cf_corporate_no_1: cf_id_val_na,
                    cf_corporate_no_2: cf_id_val_other,
                    cf_corporate: corporate,
                    cf_idType: idType,
                    cf_id_num: idNumber,
                    cf_dob: DOB,
                    cf_allow: corporate == 'Yes' ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"],
                    cf_allow2: idType == "NRIC No." ? ["5000","5001","5002","5003","5004","5005","5006"] : idType == "Passport" ? ["5000","5001","5002","5004","5006"] : (idType == "Reg No." || idType == "Others") ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"],
                    cf_person: data.record.custom_field_data.map(rec => ({
                        cf_id: rec.cf_id,
                        cf_labels: rec.cf_label,
                        name: rec.cf_id,
                        fd_values: rec.fd_value,
                        cf_values: rec.cf_value
                    }))
                });

                getDebtorID(data.record);
                setCfProsData(data.record.custom_field_data);
                setTimeout(() => {
                    setOpen(true);
                    setIsLoading(false);
                }, 500);
            }else{
                setProsData({
                    pros_name: ""
                });
                closeModal(false);
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Failed to open edit prospect"
                });
            }
        });
    }

    const getCountry = () => {
        axios
          .get("ws/ws_listplace.php", {
            params: {
              task: "listCountry",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            const data = response.data;
            const options = data.record.map((record) => ({
              label: record.country_title,
              value: record.country_id,
            }));
            setCountryData(options);
          });
    };
    
    const getState = () => {
        axios
          .get("ws/ws_listplace.php", {
            params: {
              task: "listState",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            const data = response.data;
            const options = data.record.map((record) => ({
              label: record.state_title,
              value: record.state_id,
              country: record.country_id,
            }));
            setStateData(options);
          });
    };
    
    const getCity = () => {
        axios
          .get("ws/ws_listplace.php", {
            params: {
              task: "listCityGL",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            const data = response.data;
            const options = data.record.map((record) => ({
              label: record.city_title,
              value: record.city_id,
              state: record.state_id,
            }));
            setCityData(options);
          });
    };

    // const getPurchaseLot = () => {
        
    //     axios.get("ext/gamuda/api_getPurchaseLots.php", {
    //         params: {
    //             prospect_id: prospect_id,
    //             utoken: session.user_ac_token,
    //             ctoken: session.company_token
    //         }
    //     })
    //     .then(res => {
    //         let data = res.data;

    //         if (data.record && data.record.length > 0) {
    //             var status = data.record.map(rec => {
    //                 return rec.status;
    //             });

    //             if (status.includes('Signed') || status.includes('Booked') || status.includes('Cancelled')) {
    //                 setIsDisableFd(true);
    //             }else{
    //                 setIsDisableFd(false);
    //             }
    //             setIsLoading(false);
    //         }else{
    //             setIsDisableFd(false);
    //             setIsLoading(false);
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error);
    //         setIsDisableFd(false);
    //         setIsLoading(false);
    //     });
    // }

    const getDebtorID = (prosDetail) => {
        axios.get("ext/gamuda/api_tasks.php", {
            params: {
                task: 'getDebtorIDbyProspect',
                prospect_id: prosDetail.hq_prospect_id && Number(prosDetail.hq_prospect_id) !== 0 ? prosDetail.hq_prospect_id : prosDetail.prospect_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                if (Number(data.debtorID) !== 0) {
                    setIsDisableFd(true);
                }else{
                    setIsDisableFd(false);
                }
            }else{
                setIsDisableFd(false);
            }
        })
        .catch(error => {
            console.log(error);
            setIsDisableFd(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getProspectDetails();
            // getPurchaseLot();
            // getDebtorID();
            getCountry();
            getState();
            getCity();
        }
    },[]);

    const replaceProspect = (id) => {
        axios.get("ws/ws_deal.php", {
            params: {
            task: "changeDealProspect",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            dealid: dealID,
            prospectid: id,
            },
        })
        .then((response) => {
            var data = response.data;
            if (data.status === 1) {
                Swal.fire({
                    icon: "error",
                    text: "The prospect chosen has not been changed",
                })
                .then(result => {
                    setIsDupModal(false);
                    setIsLoading(false);
                });
            } else {
                Swal.fire({
                    icon: "success",
                    text: "Prospect updated successfully! Changes have been applied.",
                    timer: 1500,
                })
                .then(result => {
                    onSuccess();
                    setIsDupModal(false);
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            console.log(error);
            setIsDupModal(false);
            setIsLoading(false);
        });
    }

    const handleUseExisting = (record) => {
        // deal_id=941536&hq_prospect_id=1297152&mode=&contact1=
        axios.get("ext/gamuda/api_tasks.php", {
            params: {
                task: 'changeDealProspect_gl',
                deal_id: dealID,
                hq_prospect_id: record.hq_prospect_id,
                mode: 'changeContact',
                contact1: record.contactNum,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    text: "Prospect updated successfully! Changes have been applied.",
                    timer: 1500,
                })
                .then(result => {
                    onSuccess();
                    setIsDupModal(false);
                    setIsLoading(false);
                });
            }else{
                Swal.fire({
                    icon: "error",
                    text: "The prospect chosen cannot be changed",
                })
                .then(result => {
                    setIsDupModal(false);
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            console.log(error);
            setIsDupModal(false);
            setIsLoading(false);
        });
    }
    
    const editSchema = yup.object().shape({
        pros_name: yup.string().required("This field is required"),
        contact1_pref: yup.string().required('This field is required'),
        contact1: yup.number("Must be a number type").typeError('Must be a number type').required('This field is required'),
        contact2: yup.number("Must be a number type").typeError('Must be a number type'),
        contact3: yup.number("Must be a number type").typeError('Must be a number type'),
        contact4: yup.number("Must be a number type").typeError('Must be a number type'),
        email1: yup.string().email('must be valid email').required('This field is required'),
        email2: yup.string().email('must be valid email'),
        address1: yup.string().required('This field is required'),
        address2: yup.string().required('This field is required'),
        postcode: yup.string().required('This field is required').min(2),
        city: yup.number().min(1,'This field is required').required('This field is required'),
        state: yup.number().min(1,'This field is required').required('This field is required'),
        country: yup.number().min(1,'This field is required').required('This field is required'),
        cf_corporate: yup.string().required('This field is required'),
        cf_idType: yup.string().required('This field is required'),
        cf_id_num: yup.string().required('This field is required').when('cf_idType', ([cf_idType], schema) => {
            if (cf_idType == 'NRIC No.')
                return yup.number('Number only').typeError('Must be a number type').required('This field is required');
            return schema;
        })
    });

    const sendEdit = (values) => {
        var allowAge = 1;
        var oriID = '';

        var allowContact1 = 1;
        var allowContact2 = 1;
        var allowContact3 = 1;
        var allowContact4 = 1;

        prosDetail.custom_field_data.map(rec => {
            if (rec.cf_id == 5002) {
                oriID = rec.fd_value;
            }
        });

        values.cf_person.map(data => {
            if (values.cf_idType == "NRIC No.") {
                if (data.cf_id == '5005') {
                    var ic = values.cf_id_num.replaceAll('-','');
                    var icDOB = ic.substring(0,6);

                    var year = icDOB.substring(0,2);
                    var icFullYear = year.charAt(0) == '0' ? "20"+year : "19"+year;
                    var month = icDOB.substring(2,4);
                    var day = icDOB.substring(4,6);


                    var dob = icFullYear+"-"+month+"-"+day;
                    data.fd_values = dob;
                }

                if (data.cf_id == '5006') {
                    var icYear = values.cf_id_num.substring(0,2);
                    var icFullYear = icYear.charAt(0) == '0' ? "20"+icYear : "19"+icYear;
                    var todayYear = new Date().getFullYear();

                    var age = parseInt(todayYear) - parseInt(icFullYear);
                    data.fd_values = age;

                    // if (age < 18) {
                    //     allowAge = 0;

                    //     Swal.fire({
                    //         icon: "error",
                    //         title: "Failed",
                    //         text: "Buyer's age need to be above 18 years old"
                    //     });
                    // }
                }
            }

            if (values.cf_idType == "Passport") {
                if (data.cf_id == '5006') {
                    var dobYear = new Date(values.cf_dob).getFullYear();
                    var todayYear = new Date().getFullYear();

                    var age = parseInt(todayYear) - parseInt(dobYear);
                    data.fd_values = age;

                    // if (age < 18) {
                    //     allowAge = 0;

                    //     Swal.fire({
                    //         icon: "error",
                    //         title: "Failed",
                    //         text: "Buyer's age need to be above 18 years old"
                    //     });
                    // }
                    
                }
            }
        });

        if (values.cf_idType == 'Passport' && !values.cf_dob) {
            Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Please insert D.O.B"
            });
        }

        if (Number(values.contact1_pref) === 60 && values.contact1) {
            if (Number(values.contact1[0]) !== 0) {
                allowContact1 = 0;
            }
        }

        if (Number(values.contact2_pref) === 60 && values.contact2) {
            if (Number(values.contact2[0]) !== 0) {
                allowContact2 = 0;
            }
        }

        if (Number(values.contact3_pref) === 60 && values.contact3) {
            if (Number(values.contact3[0]) !== 0) {
                allowContact3 = 0;
            }
        }

        if (Number(values.contact4_pref) === 60 && values.contact4) {
            if (Number(values.contact4[0]) !== 0) {
                allowContact4 = 0;
            }
        }

        if (allowContact1 === 0 || allowContact2 === 0 || allowContact3 === 0 || allowContact4 === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please insert valid contact number for prefix 60, ie: 0123456789'
            });
        }

        if (allowAge == 1 && allowContact1 === 1 && allowContact2 === 1 && allowContact3 === 1 && allowContact4 === 1) {
            handleCheckID(values, oriID);
        }
        
    }

    const handleCheckID = (values, oriID) => {
        setIsLoading(true);

        var prospectID;

        if (prosDetail.hq_prospect_id && prosDetail.hq_prospect_id != '0') {
            prospectID = prosDetail.hq_prospect_id;
        }else{
            prospectID = prosDetail.prospect_id;
        }

        if (values.cf_id_num == oriID) {
            handleTriggerUpdateApi(values);
        }else{

            axios.get('ext/gamuda/api_check_prospect.php',{
                params:{
                    prospect_id: prospectID,
                    ic_no: values.cf_id_num,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;
    
                if (data.status == 0) {
                    handleTriggerUpdateApi(values);
                }else{
                    // setIDNoData(data);
                    // setIsExistIDNo(true);
                    if (session.hostUrlType === 'cloud_staging' && location === 'dealDetail') {
                        data['contactNum'] = values.contact1;
                        data['cf_id_num'] = values.cf_id_num;
                        setDupModalData(data);
                        setIsDupModal(true);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: data.message
                        })
                        .then(result => {
                            setIsLoading(false);
                        });
                    }
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                })
                .then(result => {
                    setIsLoading(false);
                });
            });

        }

    }

    const handleTriggerUpdateApi = (values) => {

        var hq_prospect_id;

        if (prosDetail.hq_prospect_id && prosDetail.hq_prospect_id != '0') {
            hq_prospect_id = prosDetail.hq_prospect_id;
        }else{
            hq_prospect_id = prosDetail.prospect_id;
        }
        
        axios.get('ws/ws_edit_prospect_details.php',{
            params:{
                task: "updateProfile",
                prospect_id: prosDetail.prospect_id,
                hq_prospect_id: hq_prospect_id,
                prospect_name: values.pros_name,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {

                handleTriggerUpdateApi2(values);
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: data.message
                })
                .then(result => {
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });

    }

    const handleTriggerUpdateApi2 = (values) => {

        var prospectID;

        if (prosDetail.hq_prospect_id && prosDetail.hq_prospect_id != '0') {
            prospectID = prosDetail.hq_prospect_id;
        }else{
            prospectID = prosDetail.prospect_id;
        }

        var record = [];
        var cf_id = [];
        var fd_value = [];
        var cf_label = [];

        values.cf_person.map((rec) => {
            record.push(prospectID);
            cf_id.push(rec.cf_id);
            fd_value.push(rec.fd_values);
            cf_label.push(rec.cf_labels);
        })

        axios.get('ws/ws_custom_field.php',{
            params:{
                task: "updateOrAddCF",
                record: record.toString(),
                audithistory_prospectID: prospect_id,
                hq_prospect_id: prospectID,
                cf_label: cf_label.toString(),
                cf: cf_id.toString(),
                value: fd_value.toString(),
                referrer: "deal-detail-gl",
                user_id: session.user_id,
                company_id: session.company_id,
                secret_key: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {

                handleTriggerUpdateApi3(values)
                
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: data.message
                })
                .then(result => {
                    onSuccess();
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                onSuccess();
            });
        });
    }

    const handleTriggerUpdateApi3 = (values) => {

        axios.get('ws/ws_edit_prospect_details.php',{
            params: {
                task: "updateContacts",
                prospect_id: prosDetail.prospect_id,
                contact1_prefix: values.contact1_pref,
                contact1: values.contact1,
                contact1_old: prosDetail.contact1,
                contact2_prefix: values.contact2_pref,
                contact2: values.contact2,
                contact3_prefix: values.contact3_pref,
                contact3: values.contact3,
                contact4_prefix: values.contact4_pref,
                contact4: values.contact4,
                email1: values.email1,
                email2: values.email2,
                address_type: prosDetail.address_type,
                address1: values.address1,
                address2: values.address2,
                address3: values.address3,
                state_id: values.state,
                city_id: values.city,
                postcode: values.postcode,
                country_id: values.country,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                handleTriggerGllRRApi();
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: data.message
                })
                .then(result => {
                    onSuccess();
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                onSuccess();
            });
        });

    }

    const handleTriggerGllRRApi = () => {

        axios.get('ext/gamuda/api_rr_prospectivebuyerupdate.php',{
            params:{
                prospectID: prosDetail.prospect_id,
            }
        })
        .then(res => {
            let data = res.data;

            if (data) {
                
                axios.get('ext/gamudaLifestyle/gllapi_update_prospect_profile',{
                    params:{
                        Outperform_ID: prosDetail.prospect_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Successfully updated",
                        timer: 1500
                    })
                    .then(result => {
                        onSuccess();
                    });

                    // if (data) {
                        
                    // }

                });

            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })
        });

    }

    const handleCloseModal = () => {
        setIsDupModal(false);
        setIsLoading(false);
    }

    return(
        <>
            <Modal show={open} onHide={closeModal} size="xl">
                <Formik
                    validationSchema={editSchema}
                    onSubmit={sendEdit}
                    initialValues={prosData}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <Modal.Header closeButton>
                                <Modal.Title as={"h6"}>Edit Prospect Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-0">
                                
                                {
                                    isLoading ? (
                                        <div style={{overflow: "auto", height: "30vh", width: "100%"}} className="my-4 px-4">
                                            <Loader/>
                                        </div>
                                    ) :
                                    (
                                        <div style={{overflow: "auto", height: "70vh", width: "100%"}} className="py-0 px-4">
                                            <Row>
                                                <Col className="py-4">

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Name *</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.pros_name}
                                                            disabled={isDisableFd}
                                                            onChange={handleChange('pros_name')}
                                                        />
                                                        {errors.pros_name && touched.pros_name && <div className="op-error-message">{errors.pros_name}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Corporate *</Form.Label>
                                                        <FormSelect
                                                            options={corporateOpt}
                                                            valueDefault={corporateOpt.filter(option => option.value == values.cf_person[0].fd_values)}
                                                            isInvalid={errors.cf_corporate && touched.cf_corporate}
                                                            isDisabled={isDisableFd}
                                                            onChange={
                                                                (info) => {
                                                                    cfProsData.map(
                                                                        (record, index) => {
                                                                        const updatedCustomFields = [...values.cf_person];
                                                                        if (record.ctf_title == "Select") {
                                                                            if (record.cf_id == '5000') {
                                                                                updatedCustomFields[index].fd_values = info.value;
                                                                                setFieldValue('cf_corporate', info.value);
                                                                                setFieldValue('cf_allow', info.value == 'Yes' ? ["5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"]:"");
                                                                            }

                                                                            if (record.cf_id == '5001') {
                                                                                updatedCustomFields[index].fd_values = null;
                                                                            }

                                                                            if (record.cf_id == '5004') {
                                                                                if (info.value == "No") {
                                                                                    updatedCustomFields[index].fd_values = "";
                                                                                }else{
                                                                                    updatedCustomFields[index].fd_values = record.fd_value;
                                                                                }
                                                                            }

                                                                            if (record.cf_id == '5011') {
                                                                                if (info.value == "Yes") {
                                                                                    updatedCustomFields[index].fd_values = "No";
                                                                                }else{
                                                                                    updatedCustomFields[index].fd_values = record.fd_value;
                                                                                }
                                                                            }

                                                                            if (values.cf_corporate_no_1.includes(record.cf_id)) {
                                                                                if (info.value == 'Yes') {
                                                                                    updatedCustomFields[index].fd_values = "Not Applicable";
                                                                                }else{
                                                                                    updatedCustomFields[index].fd_values = record.fd_value;
                                                                                }
                                                                            }

                                                                            if (values.cf_corporate_no_2.includes(record.cf_id)) {
                                                                                if (info.value == 'Yes') {
                                                                                    updatedCustomFields[index].fd_values = "OTHERS";
                                                                                }else{
                                                                                    updatedCustomFields[index].fd_values = record.fd_value;
                                                                                }
                                                                            }
                                                                        }

                                                                        if (record.ctf_title == 'Date') {
                                                                            if (record.cf_id == '5005') {
                                                                                if (info.value == "Yes") {
                                                                                    var newDate = new Date();
                                                                                    updatedCustomFields[index].fd_values = moment(newDate).format("YYYY-MM-DD");
                                                                                    setFieldValue('cf_dob', moment(newDate).format("YYYY-MM-DD"));
                                                                                }else{
                                                                                    updatedCustomFields[index].fd_values = record.fd_value;
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        />
                                                        {errors.cf_corporate && touched.cf_corporate && <div className="op-error-message">{errors.cf_corporate}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Identification Type *</Form.Label>
                                                        <FormSelect
                                                            options={
                                                                values.cf_person[0].fd_values == 'Yes' ? idTypeOptState.filter(option => option.corporate == values.cf_person[0].fd_values) :
                                                                values.cf_person[0].fd_values == 'No' ? idTypeOptState.filter(option => option.corporate == values.cf_person[0].fd_values) :
                                                                idTypeOptState
                                                            }
                                                            value={values.cf_person[1].fd_values ? idTypeOptState.filter(option => option.value == values.cf_person[1].fd_values) : ""}
                                                            isInvalid={errors.cf_idType && touched.cf_idType}
                                                            isDisabled={isDisableFd}
                                                            onChange={
                                                                (info) => {
                                                                    cfProsData.map(
                                                                        (record, index) => {
                                                                        const updatedCustomFields = [...values.cf_person];
                                                                        if (record.ctf_title == "Select") {
                                                                            if (record.cf_id == '5001') {
                                                                                updatedCustomFields[index].fd_values = info.value;
                                                                                setFieldValue('cf_idType', info.value);
                                                                                setFieldValue('cf_allow2', info.value == "NRIC No." ? ["5000","5001","5002","5003","5004","5005","5006"] : info.value == "Passport" ? ["5000","5001","5002","5004","5006"] : (info.value == "Reg No." || info.value == "Others") ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"]);
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        />
                                                        {errors.cf_idType && touched.cf_idType && <div className="op-error-message">{errors.cf_idType}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Identification No 1 *</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.cf_person[2].fd_values ? values.cf_person[2].fd_values : ""}
                                                            isInvalid={errors.cf_id_num && touched.cf_id_num}
                                                            maxLength={values.cf_idType == 'NRIC No.' ? 12 : ""}
                                                            disabled={isDisableFd}
                                                            onChange={
                                                                (info) => {
                                                                    cfProsData.map((record, index) => {
                                                                        const updatedCustomFields = [...values.cf_person];
                                                                        if (record.cf_id == '5002') {
                                                                            updatedCustomFields[index].fd_values = info.target.value;
                                                                            setFieldValue('cf_id_num', info.target.value);
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        />
                                                        {errors.cf_id_num && touched.cf_id_num && <div className="op-error-message">{errors.cf_id_num}</div>}
                                                    </Form.Group>
                                                    {
                                                        cfProsData.map((record, index) => (
                                                            !values.cf_allow.includes(record.cf_id) && !values.cf_allow2.includes(record.cf_id) && 

                                                            <FormCustomField
                                                                key={record.cf_id}
                                                                id={record.cf_id}
                                                                label={record.cf_label}
                                                                options={record.cf_value}
                                                                type={record.ctf_title}
                                                                name={record.cf_id}
                                                                placeholder=""
                                                                valueDefault={values.cf_person[index].fd_values ? values.cf_person[index].fd_values : ""}
                                                                onChange={
                                                                    (info) => {
                                                                        const updatedCustomFields = [...values.cf_person];
                                                                        if (record.ctf_title == 'Select') {
                                                                            updatedCustomFields[index].fd_values = info.value;
                                                                        }else if(record.ctf_title == 'Date'){
                                                                            if (record.cf_id == '5005') {
                                                                                setFieldValue('cf_dob', info.target.value);
                                                                            }
                                                                            updatedCustomFields[index].fd_values = info.target.value;
                                                                        }else{
                                                                            updatedCustomFields[index].fd_values = info.target.value;
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                        ))
                                                    }

                                                </Col>
                                                <Col className="border-start py-4">

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Mobile Number 1 *</Form.Label>
                                                        <Row>
                                                            <Col xs={3}>
                                                                <FormSelect
                                                                    placeholder="prefix"
                                                                    options={prefixList}
                                                                    valueDefault={prefixList.filter(opt => opt.value == values.contact1_pref)}
                                                                    isInvalid={errors.contact1_pref && touched.contact1_pref}
                                                                    onChange={(e) => setFieldValue('contact1_pref', e.value)}
                                                                />
                                                                {errors.contact1_pref && touched.contact1_pref && <div className="op-error-message">{errors.contact1_pref}</div>}
                                                            </Col>
                                                            <Col xs={9}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={values.contact1}
                                                                    isInvalid={errors.contact1 && touched.contact1}
                                                                    onChange={handleChange('contact1')}
                                                                />
                                                                {errors.contact1 && touched.contact1 && <div className="op-error-message">{errors.contact1}</div>}
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Mobile Number 2</Form.Label>
                                                        <Row>
                                                            <Col xs={3}>
                                                                <FormSelect
                                                                    placeholder="prefix"
                                                                    options={prefixList}
                                                                    valueDefault={prefixList.filter(opt => opt.value == values.contact2_pref)}
                                                                    onChange={(e) => setFieldValue('contact2_pref', e.value)}
                                                                />
                                                            </Col>
                                                            <Col xs={9}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={values.contact2}
                                                                    isInvalid={errors.contact2 && touched.contact2}
                                                                    onChange={handleChange('contact2')}
                                                                />
                                                                {errors.contact2 && touched.contact2 && <div className="op-error-message">{errors.contact2}</div>}
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Contact Number 1</Form.Label>
                                                        <Row>
                                                            <Col xs={3}>
                                                                <FormSelect
                                                                    placeholder="prefix"
                                                                    options={prefixList}
                                                                    valueDefault={prefixList.filter(opt => opt.value == values.contact3_pref)}
                                                                    onChange={(e) => setFieldValue('contact3_pref', e.value)}
                                                                />
                                                            </Col>
                                                            <Col xs={9}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={values.contact3}
                                                                    isInvalid={errors.contact3 && touched.contact3}
                                                                    onChange={handleChange('contact3')}
                                                                />
                                                                {errors.contact3 && touched.contact3 && <div className="op-error-message">{errors.contact3}</div>}
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Contact Number 2</Form.Label>
                                                        <Row>
                                                            <Col xs={3}>
                                                                <FormSelect
                                                                    placeholder="prefix"
                                                                    options={prefixList}
                                                                    valueDefault={prefixList.filter(opt => opt.value == values.contact4_pref)}
                                                                    onChange={(e) => setFieldValue('contact4_pref', e.value)}
                                                                />
                                                            </Col>
                                                            <Col xs={9}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={values.contact4}
                                                                    isInvalid={errors.contact4 && touched.contact4}
                                                                    onChange={handleChange('contact4')}
                                                                />
                                                                {errors.contact4 && touched.contact4 && <div className="op-error-message">{errors.contact4}</div>}
                                                            </Col>
                                                        </Row>    
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Email 1 *</Form.Label>
                                                        <Form.Control
                                                            type="text" 
                                                            value={values.email1}
                                                            isInvalid={errors.email1 && touched.contact1}
                                                            onChange={handleChange('email1')}
                                                        />
                                                        {errors.email1 && touched.contact1 &&  <div className="op-error-message">{errors.email1}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Email 2</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.email2}
                                                            isInvalid={errors.email2 && touched.email2}
                                                            onChange={handleChange('email2')}
                                                        />
                                                        {errors.email2 && touched.email2 && <div className="op-error-message">{errors.email2}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address Line 1 *</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.address1}
                                                            isInvalid={errors.address1 && touched.address1}
                                                            onChange={handleChange('address1')}
                                                        />
                                                        {errors.address1 && touched.address1 && <div className="op-error-message">{errors.address1}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address Line 2 *</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.address2}
                                                            isInvalid={errors.address2 && touched.address2}
                                                            onChange={handleChange('address2')}
                                                        />
                                                        {errors.address2 && touched.address2 && <div className="op-error-message">{errors.address2}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Address Line 3</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.address3}
                                                            onChange={handleChange('address3')}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Postcode *</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.postcode}
                                                            isInvalid={errors.postcode && touched.postcode}
                                                            onChange={handleChange('postcode')}
                                                        />
                                                        {errors.postcode && touched.postcode && <div className="op-error-message">{errors.postcode}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Country *</Form.Label>
                                                        <FormSelect
                                                            placeholder="Choose Country"
                                                            options={countryData}
                                                            valueDefault={countryData.filter(option=> Number(option.value) == Number(values.country))}
                                                            isInvalid={errors.country && touched.country}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e)=>{
                                                                handleChange("country")(e ? e.value : "0");
                                                                handleChange("state")("0");
                                                                handleChange("city")("0");
                                                            }}
                                                        />
                                                        {errors.country && touched.country && <div className="op-error-message">{errors.country}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>State *</Form.Label>
                                                        <FormSelect
                                                            placeholder="Choose State"
                                                            options={Number(values.country) > 0 ? stateData.filter(option=> Number(option.country) == Number(values.country)) : stateData}
                                                            valueDefault={stateData.filter(option=> Number(option.value) == Number(values.state))}
                                                            isInvalid={errors.state && touched.state}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e)=>{
                                                                handleChange("state")(e ? e.value:"0");
                                                                handleChange("city")("0");
                                                            }}
                                                        />
                                                        {errors.state && touched.state && <div className="op-error-message">{errors.state}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>City *</Form.Label>
                                                        <FormSelect
                                                            placeholder="Choose City"
                                                            options={Number(values.state) > 0 ? cityData.filter(option=> Number(option.state) == Number(values.state)) : cityData}
                                                            valueDefault={cityData.filter(option=> Number(option.value) == Number(values.city))}
                                                            isInvalid={errors.city && touched.city}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e)=>{
                                                                handleChange("city")(e ? e.value:"0");
                                                            }}
                                                        />
                                                        {errors.city && touched.city && <div className="op-error-message">{errors.city}</div>}
                                                    </Form.Group>

                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                                
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light" disabled={isLoading}>Submit</button>
                            </Modal.Footer>

                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal show={isDupModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    {/* <Modal.Title as={'h6'}>{dupModalData.message}</Modal.Title> */}
                    <Modal.Title as={'h6'}>Do you wish to reuse this prospect?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Card>
                            <Card.Body>
                                <div>
                                    <h7>{dupModalData.hq_prospect_name}</h7>
                                </div>
                                <div>
                                    <h7>{dupModalData.cf_id_num}</h7>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success" onClick={() => {replaceProspect(dupModalData.hq_prospect_id)}}>Yes</button>
                    <button type="button" className="btn btn-danger" onClick={() => {handleUseExisting(dupModalData)}}>No</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditPersonGl;