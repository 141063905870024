import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Stack, Modal, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const AvanserCallSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [userList, setUserList] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInit, setEditInit] = useState({});

    const prefixList = [
        {label: "Malaysia", value: "+60"},
        {label: "Singapore", value: "+65"},
        {label: "Australia", value: "+61"},
        {label: "New Zealand", value: "+64"}
    ];

    const extList = [
        {label: "0", value: "0"},
        {label: "+65-", value: "+65-"},
        {label: "+61-", value: "+61-"}
    ];

    const getAllUser = () => {
        
        axios.get("ws/ws_user.php",{
            params:{
                task: "4a",
                user_tag: "",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var userData = data.record.map(data=>{
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingAvanserCall_headername_1'),
            field: 'user_name',
            resizable: true,
        },
        {
            headerName: t('SettingAvanserCall_headername_2'),
            field: "avanser_number",
            resizable: true,
        },
        {
            headerName: t('SettingAvanserCall_headername_3'),
            field: "avanser_token",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingAvanserCall_headername_4'),
            field: "avanser_from_extension",
            cellClass: 'center',
            resizable: true,
        },
        {
            headerName: t('SettingAvanserCall_headername_5'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            {session.user_email === 'support@nexcrm.my' && 
                                <>
                                    <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={()=>{handleDelete(params.data.avanser_number_id)}}/>
                                    <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={()=>{handleEdit(params.data)}}/>
                                </>
                            }
                            {session.user_email !== 'support@nexcrm.my' && <div>N/A</div>}
                        </div>
                    );
                }
            }
        }
    ]);

    const handleEdit= (data) => {
        
        var avanserNumberSplit = "";
        var prefix = "";
        var number = "";

        if (data.avanser_number.includes("-")) {
            avanserNumberSplit = data.avanser_number.split("-");
            prefix = avanserNumberSplit[0];
            number = avanserNumberSplit[1];
        }else{
            prefix = "";
            number = data.avanser_number;
        }
        setEditInit(
            {
                avanser_number_id: data.avanser_number_id,
                user_id: data.user_id,
                avanser_number_prefix: prefix,
                avanser_number: number,
                avanser_token: data.avanser_token,
                avanser_ext: data.avanser_from_extension
            }
        );
        setIsEdit(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t("SettingAvanserCall_alert_warning_title"),
            text: t('SettingAvanserCall_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingAvanserCall_alert_warning_confirm'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        }).then(result=>{
            if (result.isConfirmed) {

                axios.get('ws/ws_avanser.php',{
                    params:{
                        task: "deleteAvanserNumber",
						avanser_number_id: id,
						utoken: session.user_ac_token,
						ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingAvanserCall_alert_successTitle'),
                            text: t('SettingAvanserCall_alert_delete_success'),
                            timer: 1500
                        }).then(result => {
                            setIsLoading(true);
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingAvanserCall_alert_failed_title'),
                            text: t('SetttingAvanserCall_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
                
            }
        });
    }

    const getGridData = () => {
        
        axios.get('ws/ws_avanser.php',{
            params:{
                task: "getAvanserRecords",
                avanser_number_id: "",
                user_id: "",
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setRecordFound(Number(data.totalRecords));
                setRowData(data.record);
                setIsGrid(true);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
            }
            setIsLoading(false);
        }).catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);

            setIsLoading(false);
        });
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            getGridData();     
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        user_id: yup.string().required(t('SettingAvanserCall_required_field')),
        avanser_number: yup.string().required(t('SettingAvanserCall_required_field')),
        avanser_token: yup.string().required(t('SettingAvanserCall_required_field'))
    });

    const sendAdd = (values) => {

        var numbers = values.avanser_number_pref ? `${values.avanser_number_pref}-${values.avanser_number}`:values.avanser_number;

        axios.get("ws/ws_avanser.php",{
            params:{
                task: "addAvanserNumber",
                user_id: values.user_id,
                avanser_number: numbers,
                avanser_token: values.avanser_token,
                avanser_from_extension: values.avanser_from_ext,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingAvanserCall_alert_successTitle'),
                    text: t('SettingAvanserCall_alert_add_success'),
                    timer: 1500
                }).then(result=>{
                    setIsLoading(true);
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingAvanserCall_alert_failed_title'),
                    text: t('SettingAvanserCall_alert_failed_text')
                });
            }
        })
        .catch(error=>{
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        user_id: yup.string().required(t('SettingAvanserCall_required_field')),
        avanser_number: yup.string().required(t('SettingAvanserCall_required_field')),
        avanser_token: yup.string().required(t('SettingAvanserCall_required_field')),
    });

    const sendEdit = (values) => {
        
        axios.get('ws/ws_avanser.php',{
            params:{
                task: "updateAvanserNumber",
                user_id: values.user_id,
                avanser_number_id: values.avanser_number_id,
                avanser_number: values.avanser_number_prefix ? `${values.avanser_number_prefix}-${values.avanser_number}`:values.avanser_number,
                avanser_token: values.avanser_token,
                avanser_from_extension: values.avanser_ext,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingAvanserCall_alert_successTitle'),
                    text: t('SettingAvanserCall_alert_edit_success'),
                    timer: 1500
                }).then(result => {
                    setIsLoading(true);
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingAvanserCall_alert_failed_title'),
                    text: t('SettingAvanserCall_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    return(
        <div>
            { isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <div className="mt-3 mb-3">
                            <Stack className="d-flex justify-content-center align-items-center" direction="horizontal" gap={1}>
                                <button className="btn op-button op-primary-color text-light" disabled={session.user_email === 'support@nexcrm.my' ? false : true} onClick={setIsAdd}>{t('SettingAvanserCall_add_button')}</button>
                                <div className="px-2 ms-auto">{recordFound === 1 ? t('SettingAvanserCall_record_one', {count: recordFound}) : recordFound > 1 ? t('SettingAvanserCall_record_many', {count: recordFound}) : t('SettingAvanserCall_record_no')}</div>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={reportColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={80}
                                    pagination={false}
                                    // paginationPageSize={30}
                                    // paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd} size="lg">
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    user_id: "",
                                    avanser_number_pref: "",
                                    avanser_number: "",
                                    avanser_token: "",
                                    avanser_from_ext: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched })=>(
                                <Form noValidate onSubmit={handleSubmit}>
                                    
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingAvanserCall_modalHeader_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_title_1')}</Form.Label>
                                            <FormSelect
                                                placeholder="Select User Name"
                                                options={userList}
                                                isClearable={true}
                                                isSearchable={true}
                                                isInvalid={errors.user_id && touched.user_id}
                                                onChange={(e)=>{handleChange('user_id')(e ? e.value : "")}}
                                            />
                                            {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_title_2')}</Form.Label>
                                            <Stack direction="horizontal" gap={1}>
                                                <FormSelect
                                                    className="w-25"
                                                    placeholder="Select prefix"
                                                    options={prefixList}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e)=>{
                                                        handleChange('avanser_number_pref')(e ? e.value:"")
                                                    }}
                                                />
                                                <Form.Control
                                                    className="w-75"
                                                    type="text"
                                                    isInvalid={errors.avanser_number && touched.avanser_number}
                                                    onChange={handleChange('avanser_number')}
                                                />
                                            </Stack>
                                            {errors.avanser_number && touched.avanser_number && <div className="op-error-message">{errors.avanser_number}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_title_3')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.avanser_token && touched.avanser_token}
                                                onChange={handleChange("avanser_token")}
                                            />
                                            {errors.avanser_token && touched.avanser_token && <div className="op-error-message">{errors.avanser_token}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingAvanserCall_modalBody_title_4')}</Form.Label>
                                            <FormSelect
                                                placeholder="Select extension"
                                                options={extList}
                                                onChange={(e)=>{handleChange("avanser_from_ext")(e ? e.value:"")}}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingAvanserCall_modalFooter_submit')}</button>
                                    </Modal.Footer>

                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInit}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched })=>(
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingAvanserCall_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_edit_title_1')}</Form.Label>
                                            <FormSelect
                                                placeholder="Select User Name"
                                                options={userList}
                                                valueDefault={userList.filter(option => option.value === values.user_id)}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.user_id && touched.user_id}
                                                onChange={(e)=>{handleChange("user_id")(e ? e.value:"")}}
                                            />
                                            {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_edit_title_2')}</Form.Label>
                                            <Stack direction="horizontal" gap={1}>
                                                <FormSelect
                                                    className="w-25"
                                                    placeholder="Select prefix"
                                                    options={prefixList}
                                                    valueDefault={prefixList.filter(option => option.value === values.avanser_number_prefix)}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={(e)=>{handleChange("avanser_number_prefix")(e ? e.value:"")}}
                                                />
                                                <Form.Control
                                                    className="w-75"
                                                    type="text"
                                                    value={values.avanser_number}
                                                    isInvalid={errors.avanser_number && touched.avanser_number}
                                                    onChange={handleChange("avanser_number")}
                                                />
                                            </Stack>
                                            {errors.avanser_number && touched.avanser_number && <div className="op-error-message">{errors.avanser_number}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingAvanserCall_modalBody_edit_title_3')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.avanser_token}
                                                isInvalid={errors.avanser_token && touched.avanser_token}
                                                onChange={handleChange("avanser_token")}
                                            />
                                            {errors.avanser_token && touched.avanser_token && <div className="op-error-message">{errors.avanser_token}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingAvanserCall_modalBody_edit_title_4')}</Form.Label>
                                            <FormSelect
                                                placeholder="Select extension"
                                                options={extList}
                                                valueDefault={extList.filter(option => option.value === values.avanser_ext)}
                                                onChange={(e)=>{handleChange("avanser_ext")(e ? e.value:"")}}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingAvanserCall_modalFooter_submit')}</button>
                                    </Modal.Footer>

                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            ) }
        </div>
    );
}

export default AvanserCallSetting;