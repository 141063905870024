import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import * as formik from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SRMDashboard = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0); // Track total records

    // GET USER FUNCTION --------------------------------------------------------
    const getUser = async () => {
        try {
            const res = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_user.php`, {
                params: {
                    task: '4a',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    user_tag: ''
                }
            });
            const data = res.data;
            setUserData(data.record);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };


    // DATA SOURCE FOR GRID --------------------------------------------------------
    const gridDataSource = {
        getRows: async (params) => {
            const pageSize = params.endRow - params.startRow; // Dynamic page size
            const page = Math.floor(params.startRow / pageSize) + 1; // Calculate current page

            try {
                const glCompanyIds = [180, 190, 200, 203, 210, 212, 213];
                const task = glCompanyIds.includes(parseInt(session.company_id)) ? 'searchGLProspectsv3' : '8a';

                const res = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_prospect.php`, {
                    params: {
                        task: task,
                        company: session.company_id,
                        uid: session.user_id,
                        m: 'yes',
                        page: page, // Use calculated page
                        size: pageSize, // Use dynamic page size
                        q: '(ra)',
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        user: ''
                    }
                });

                const data = res.data;
                const rowsThisPage = data.record || [];
                const lastRow = data.totalCount ? parseInt(data.totalCount) : undefined;

                // If only 1 record exists, adjust the page size
                const pageSizeToUse = lastRow < 100 ? lastRow : 100;
                setTotalRecords(lastRow);

                // Update the grid with the rows
                params.successCallback(rowsThisPage, lastRow);
                gridRef.current.api.paginationSetPageSize(pageSizeToUse);  // Adjust page size dynamically
            } catch (error) {
                console.error(error);
                params.failCallback();
            }
        }
    };


    // URL LINK FUNCTION -----------------------------------------
    const setUrlLink = (params) => {
        const prospectToken = params?.data?.prospect_token;
        let baseUrl = '';
    
        if (session.hostUrlType === 'cloud') {
            if (session.company_id == 180) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/256f9d73284414974b3f25f5a8ffe9d1";
            } else if (session.company_id == 190) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/c5b2b1c3ff49c46a0864e337d2c0f6f8";
            } else if (session.company_id == 200) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/076848efda2eb8dfcb811b480f1d5367";
            } else if (session.company_id == 203) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/7803065a79610ec2e7940a063bf39604";
            } else if (session.company_id == 210) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/7e888df34ebae1af3a97ef8476cee4e6";
            } else if (session.company_id == 212) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/9a6e6fe6d5d64ba2061792dd0c33aba6";
            } else if (session.company_id == 213) {
                baseUrl = "https://app.outperformhq.io/form/s5/SRM-GL/2bd69ae253c2919e628fb67a0fc24ef9";
            } else {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/aaad9048ca436b453506cf3e064dd7e7";
            }
        } else if (session.hostUrlType === 'cloud_staging') {
            if (session.company_id == 180) {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/16b0d298210be843db394d743e43af81";
            } else if (session.company_id == 190) {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/5e073a07ccc66356aa267391e2ac3b39";
            } else if (session.company_id == 200) {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/58177269a23133725fe37540ea6f2597";
            } else if (session.company_id == 203) {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/0ac8be33209451488376c1f3384939a8";
            } else if (session.company_id == 210) {
                baseUrl = ""; // No URL for company_id 210 in cloud_staging
            } else if (session.company_id == 212) {
                baseUrl = "https://app.outperformhq.io/form/s3/SRM-GL/548fc7df370ad8e063f302433f0ab106";
            } else if (session.company_id == 213) {
                baseUrl = ""; // No URL for company_id 213 in cloud_staging
            }
        }
    
        const url = `${baseUrl}/0/${prospectToken}/0/`;
    
        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                Open SRM Form
            </a>
        );
    };
    

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center", cellStyle: { textAlign: "center" } },
        { headerName: "RA Name", field: "prospect_name", width: 300 },
        { headerName: "SRM Form Link", field: "", width: 450, cellRenderer: setUrlLink },
    ];

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getUser();
        }
    }, []);

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <div style={{ fontWeight: 'bold', fontSize: '22px' }}>
                        SRM Dashboard
                    </div>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        defaultColDef={{ flex: 1 }}
                                        rowModelType="infinite"
                                        datasource={gridDataSource}
                                        cacheBlockSize={100}
                                        cacheOverflowSize={2}
                                        maxConcurrentDatasourceRequests={2}
                                        maxBlocksInCache={2}
                                        rowHeight={100}
                                        paginationPageSize={100}
                                        pagination={true}
                                        animateRows={true}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Container>
    );
}

export default SRMDashboard;
