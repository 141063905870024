import * as React from "react";
import axios from "../../api/axios";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useEffect, useState, useRef } from "react";
import { faMicrophone, faPause, faPlay, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { faCircle, faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { LiveAudioVisualizer } from "react-audio-visualize";

const AudioNotesV2 = ({ session, deal_id, prospect_id, onSubmit }) => {
  const [isRecorded, setIsRecorded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const btnRef = useRef(null);
  const btnRef2 = useRef(null);
  const { startRecording, stopRecording, recordingBlob, isRecording, isPaused, togglePauseResume, mediaRecorder } = useAudioRecorder({
    audioTrackConstraints: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    },
    onNotAllowedOrFound: (error) => {
      console.error("Error accessing audio devices:", error);
    },
  });

  const send = async (blob) => {
    let data = new FormData();
    data.append("task", "getAINotes");
    data.append("ctoken", session.company_token);
    data.append("utoken", session.user_ac_token);
    data.append("deal_id", deal_id);
    data.append("prospect_id", prospect_id);
    data.append("blob", blob);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post("https://app.outperformhq.io/cloud_staging/ws/ws_ai_notes.php", data, config);
      onSubmit(response.data);
    } catch (error) {
      console.error("Error sending audio:", error);
    } finally {
      setLoading(false);
      setIsRecorded(false);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    console.log("recordingBlob", recordingBlob);
    if (firstLoad === true && recordingBlob === undefined) {
      startRecording(true);
      setTimeout(() => {
        setFirstLoad(false);
        stopRecording(false);
      }, 500);
    } else if (isRecorded === true) {
      send(recordingBlob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (mediaRecorder) {
      setAudioStream(mediaRecorder);
    }
  }, [mediaRecorder]);

  if (firstLoad === true) {
    return <Spinner animation="border" role="status" size="sm" />;
  }

  return (
    <div className="recorder-container">
      {isRecording ? (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{isPaused ? "Resume" : "Pause"}</Tooltip>}>
          <Button variant="" className="button-hover-white me-2" onClick={togglePauseResume}>
            <FontAwesomeIcon icon={isPaused ? faPlay : faPause} size="xl" />
          </Button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>AI Voice Note</Tooltip>}>
          <Button ref={btnRef} variant="" className="button-hover-white me-2" onClick={loading === false ? startRecording : null}>
            {loading ? <Spinner animation="border" role="status" size="sm" /> : <FontAwesomeIcon icon={faMicrophone} size="xl" />}
          </Button>
        </OverlayTrigger>
      )}

      {isRecording && firstLoad === false && (
        <>
          <FontAwesomeIcon icon={faCircle} color="red" beatFade className="me-2" size="xs" />
          {audioStream && <LiveAudioVisualizer mediaRecorder={audioStream} barColor="rgba(8, 14, 44, 0.1)" height={30} width={100} />}
          <Button ref={btnRef2} size="sm" variant="" className="ms-2 button-hover-white" onClick={() => stopRecording(false)}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </Button>
          <Button
            size="sm"
            variant=""
            className="op-primary-color text-light ms-2"
            disabled={buttonDisabled}
            onClick={() => {
              stopRecording(true);
              setButtonDisabled(true);
              if (isRecorded === false) {
                setLoading(true);
                setIsRecorded(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faPaperPlaneTop} />
          </Button>
        </>
      )}
    </div>
  );
};

export default AudioNotesV2;
