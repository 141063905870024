import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/pro-duotone-svg-icons";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const LeadRouteReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    // const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [userList, setUserList] = useState([]);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [outcome, setOutcome] = useState("");
    const [userID, setUserID] = useState(0);
    const [trigger, setTrigger] = useState(0);
    const [lrChartData, setLrChartData] = useState([]);
    const { t } = useTranslation();

    const leadOutcome = [
        {label: t('Report_leadRoute_LeaddOutcomeList_Accepted'), value: "accepted"},
        {label: t('Report_leadRoute_LeaddOutcomeList_Decline'), value: "declined"},
        {label: t('Report_leadRoute_LeaddOutcomeList_TimeOut'), value: "admin"},
    ];

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var userData = data.record.map((data)=>{
                    return {label: data.user_name, value: data.user_id}
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const getLeadRouteChart = useCallback(() => {
        axios.get('ws/ws_leadroute.php',{
            params:{
                task: "leadRouteReportV2",
                ds: startDate,
                de: endDate,
                user: userID,
                outcome: outcome,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var chartData = data.record.map((record)=>{
                    return {name: record.DATE, value: record.TOTAL_DEALS};
                });

                setTimeout(() => {
                    setLrChartData(chartData);
                }, 500);
            }else{
                setLrChartData([]);
            }
        });
    }, [outcome, startDate, endDate, userID, session]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div>{`Deal: ${payload[0].value}`}</div>
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [gridColumn] = useState([
        {
            headerName: t('Report_leadRoute_HeaderName1'),
            field: "reroute_date_time_create",
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return params.data.reroute_date_time_create;
                }else{
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            }
        },
        {
            headerName: t('Report_leadRoute_HeaderName2'),
            field: "user_name",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_leadRoute_HeaderName3'),
            field: "deal_title",
            resizable: true,
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    if (params.data.deal_title) {
                        window.open(`${session.origin}/deal/${params.data.deal_id}`, '_blank');
                    }
                }
            }
        },
        {
            headerName: t('Report_leadRoute_HeaderName4'),
            field: "ds_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_leadRoute_HeaderName5'),
            field: "user_tag_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_leadRoute_HeaderName6'),
            field: "prospect_name",
            resizable: true,
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    if (params.data.prospect_name) {
                        window.open(`${session.origin}/prospect/${params.data.prospect_id}`, '_blank');
                    }
                }
            },
        },
        {
            headerName: t('Report_leadRoute_HeaderName7'),
            field: "deal_accepted",
            resizable: true,
            width: 250,
            valueGetter: (params) => {
                if (params.data) {
                    var dealStatus = "";
                    if (params.data.deal_accepted === "accepted") {
                        dealStatus = `Deal accepted by ${params.data.user_name}`;
                    }else if (params.data.deal_accepted === "admin") {
                        dealStatus = "Timed out to manager";
                    }else{
                        dealStatus = "Lead still routing";
                    }

                    return dealStatus;
                }
            }
        },
        {
            headerName: t('Report_leadRoute_HeaderName8'),
            field: "firstCall",
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    
                    if (params.data.firstCall) {
                        if (params.data.firstCallListen) {
                            
                            return (
                                <a href={params.data.firstCallListen} target="_blank" rel="noreferrer">
                                  <FontAwesomeIcon icon={faCirclePlay} size="xl" className="me-1" />
                                  {params.data.firstCall}
                                </a>
                            );

                        }
                    }

                }
            }
        },
        {
            headerName: t('Report_leadRoute_HeaderName9'),
            resizable: true,
            width: 250,
            valueGetter: (params) => {
                if (params.data) {
                    var route ="";
                    var userRoute;
                    if (params.data.deal_accepted === "admin") {
                        if ((params.data.owner_id !== '9999') && (params.data.user_name !== params.data.CURR_DEAL_OWNER)) {
                            route = `Transfered to: ${params.data.CURR_DEAL_OWNER}`;
                        }else if (params.data.leadRecord) {
                            userRoute = params.data.leadRecord.map((data) => {
                                return data.user_name;
                            });
    
                            route = userRoute.toString().replaceAll(","," > ");
                        }
                    }else{
                        if (params.data.leadRecord) {
                            userRoute = params.data.leadRecord.map((data) => {
                                return data.user_name;
                            });
    
                            route = userRoute.toString().replaceAll(","," > ");
                        }
                    }

                    return route;
                }
            }
        },
    ]);

    const gridDataSource = useMemo(() => {
        return {
            rowCount: undefined,
            getRows: (params) => {
                
                axios.get('ws/ws_leadroute.php',{
                    params:{
                        task: "leadRouteData",
                        user: userID,
                        ds: startDate,
                        de: endDate,
                        startRow: params.startRow,
                        outcome: outcome,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {

                    let data = res.data;

                    if (data.status === 0) {
                        setIsGrid(true);
                        
                    }else{
                        setIsGrid(false);

                        Swal.fire({
                            icon: "error",
                            title: t('Report_alert_Title'),
                            text: t('Report_alert_Text')
                        });
                    }

                    setTimeout(() => {
                        var leadInfo = [];
                        var totalRecord = 0;

                        if (data.status === 0) {
                            leadInfo = data.record;
                            totalRecord = Number(data.totalRecord);
                            setRecordFound(totalRecord);
                        }else{
                            leadInfo = [];
                            totalRecord = 0;
                            setRecordFound(totalRecord);
                        }

                        var rowsThisPage = leadInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);

                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        }
    }, [trigger, session]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = () => {
        if (gridRef.current) {
            const params = {
                skipHeader: false,
                skipFooters: true,
                skipGroups: true,
                fileName: 'Lead-Route-dataExport.csv',
                columnGroups: true,
                processCellCallback: function (params) {
                    // Access the original value before rendering
                    if (params.column.getColDef().cellRendererFramework) {
                        // Use valueGetter method to get the original value
                        return params.valueGetter();
                    }
                    return params.value;
                },
            };
            gridRef.current.api.exportDataAsCsv(params);
        }
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            getLeadRouteChart();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {isLoading ? <Loader />:(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mb-3 mt-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control 
                                    type="date" 
                                    value={startDate} 
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group className="ms-auto" style={{width: '250px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllOutcome')}
                                    options={leadOutcome}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e)=>{setOutcome(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '250px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllUser')}
                                    options={userList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e)=>{setUserID(e ? e.value:"0")}}    
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light shadow" onClick={()=>{setTrigger(trigger + 1); getLeadRouteChart()}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-4">
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                            <ResponsiveContainer width="96%" height={300}>
                                <LineChart
                                height={300}
                                data={lrChartData}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip content={<CustomTooltip/>}/>
                                {/* <Legend /> */}
                                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <div>
                            <Stack className="d-flex" direction="horizontal" gap={1}>
                                <div className="p-2">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                                <button className="btn op-button op-primary-color text-light shadow ms-auto" onClick={()=>{window.open(`${session.origin}/report-deal-performance`)}}>{t('Report_leadRoute_Performance')}</button>
                                <button className="btn op-button op-primary-color text-light shadow" onClick={handleDownload}>{t('Report_Download')}</button>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={gridColumn}
                                    datasource={gridDataSource}
                                    defaultColDef={reportColDef}
                                    rowSelection={"multiple"}
                                    rowModelType={"infinite"}
                                    rowHeight={70}
                                    cacheBlockSize={30}
                                    cacheOverflowSize={2}
                                    maxConcurrentDatasourceRequests={1}
                                    infiniteInitialRowCount={1}
                                    maxBlocksInCache={50}
                                    pagination={true}
                                    paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                    suppressRowClickSelection={true}
                                    animateRows={true}
                                />
                            </div>
                        </div>
                        {!isGrid && <NoRecord message={t('Report_Grid_NoRecord_Message')} description={t('Report_Grid_NoRecord_Description')} width={300} />}
                    </div>
                </Container>
            )}
        </div>
    );
}

export default LeadRouteReport;