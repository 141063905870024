import { useState, useRef, useEffect } from "react";
import { Stack, Card, Form, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import Loader from "../includes/Loader";
import { faDollarCircle, faHandshake, faHandshakeSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCurrency } from "../lib/js/Function";
import DefaultLeadAnalytic from "./LeadAnalytic/DefaultLeadAnalytic";
// import WonLeadAnalytic from "./LeadAnalytic/WonLeadAnalytic";
// import LostLeadAnalytic from "./LeadAnalytic/LostLeadAnalytic";
// import DeletedLeadAnalytic from "./LeadAnalytic/DeletedLeadAnalytic";
import Swal from "sweetalert2/dist/sweetalert2";

const LeadsAnalytic = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    const [BUList, setBUList] = useState([]);
    const [dsCatList, setDsCatList] = useState([]);
    const [dsList, setDsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [lostReasonList, setLostReasonList] = useState([]);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'year').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [buId, setBuId] = useState("");
    const [dsCatId, setDsCatId] = useState("");
    const [dsId, setDsId] = useState("");
    const [userId, setUserId] = useState("");
    const [leadStat, setLeadStat] = useState("");
    const [lostReaID, setLostReaID] = useState("");

    const [chartData, setChartData] = useState([]);
    const [chartColor, setChartColor] = useState([]);
    const [isPieChart, setIsPieChart] = useState({
        dsCat: false,
        ds: false,
        bu: false,
        lostRea: false
    });

    const [leadStatList] = useState([
        {label: 'WON', value: 'won'},
        {label: 'LOST', value: 'lost'},
        {label: 'DELETED', value: 'deleted'}
    ]);

    const [isGrid, setIsGrid] = useState(false);
    const [rowData, setRowData] = useState([]);

    const [isDefault, setIsDefault] = useState(true);
    const [isLost, setIsLost] = useState(false);
    const [isLeadStat, setIsLeadStat] = useState(false);

    const [totalData, setTotalData] = useState({});

    const [dsCatPieData, setDsCatPieData] = useState([]);
    const [dsPieData, setdsPieData] = useState([]);
    const [buPieData, setbuPieData] = useState([]);
    const [lostReaPieData, setLostReaPieData] = useState([]);

    const getAllBU = () => {
        axios.get('ws/ws_setting.php', {
            params: {
                task: 'getAllBU',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                const dataList = data.record.map(rec => {
                    return {label: rec.unit_title, value: rec.business_unit_id};
                });

                setBUList(dataList);
            }else{
                setBUList([]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getAllSource = () => {
        axios.get('ws/ws_source.php', {
            params: {
                task: 2,
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                const dsList = data.record.map(rec => {
                    return {label: rec.ds_title, value: rec.ds_id, dsCatId: rec.source_category_id};
                });

                const dsCatList = data.source_cat.map(rec => {
                    return {label: rec.source_category_title, value: rec.source_category_id};
                });

                setDsList(dsList);
                setDsCatList(dsCatList);
            }else{
                setDsList([]);
                setDsCatList([]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getAllUser = () => {
        axios.get('ws/ws_user.php', {
            params: {
                task: '4a',
                user_tag: '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                const userList = data.record.map(rec => {
                    return {label: rec.user_name, value: rec.user_id};
                });

                setUserList(userList);
            }else{
                setUserList([]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getAllLostReason = () => {
        axios.get("ws/ws_deal_close.php", {
            params: {
                task: 'getLostReason',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map((rec) => {
                    return {label: rec.reason_title, value: rec.lost_reason_id};
                });

                setLostReasonList(dataList);
            }else{
                setLostReasonList([]);
            }
        })
        .catch(error => {
            console.log(error);
            setLostReasonList([]);
        });
    }

    const getAllData = () => {
        setIsDefault(false);
        setIsPieChart((prev) => ({
            ...prev,
            dsCat: dsCatId.length > 0 ? true : false,
            ds: dsId.length > 0 ? true : false,
            bu: buId.length > 0 ? true : false,
            lostRea: lostReaID.length > 0 ? true : false
        }));

        axios.get("ws/ws_lead_analytics.php", {
            params: {
                task: "leadAnalyticsListing",
                ds: startDate,
                de: endDate,
                bu: buId.toString(),
                source_cat: dsCatId.toString(),
                source: dsId.toString(),
                archieve: leadStat.toString(),
                user_id: userId.toString(),
                lost_reason_id: lostReaID.toString(),
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            var chartData = [];

            if (Number(data.status) === 0) {
                data.record.forEach(rec => {
                    chartData.push({name: rec.user_name, data1: rec.won_count, data2: rec.lost_count, data3: rec.deleted_count});
                });

                setIsDefault(true);

                if (leadStat.includes('lost')) {
                    setIsLost(true);
                }else{
                    setIsLost(false);
                }

                if (leadStat.length > 0) {
                    setIsLeadStat(true);
                }else{
                    setIsLeadStat(false);
                }

                var totalData = {};
                var totalWon = {count: 0, value: 0};
                var totalLost = {count: 0, value: 0};
                var totalDeleted = {count: 0};
                data.record.forEach(rec => {
                    totalWon.count = totalWon.count + Number(rec.won_count);
                    totalWon.value = totalWon.value + Number(rec.won_value);

                    totalLost.count = totalLost.count + Number(rec.lost_count);
                    totalLost.value = totalLost.value + Number(rec.lost_value);

                    totalDeleted.count = totalDeleted.count + Number(rec.deleted_count);
                });

                totalData = {
                    won: totalWon,
                    lost: totalLost,
                    deleted: totalDeleted
                };

                setTotalData(totalData);
                setChartData(chartData);
                setIsGrid(true);
                setRowData(data.record);
                setIsLoading(false);
            }else{
                setIsDefault(true);
                setIsLeadStat(false);
                setIsLost(false);
                var totalData = {};
                var totalWon = {count: 0, value: 0};
                var totalLost = {count: 0, value: 0};
                var totalDeleted = {count: 0};

                totalData = {
                    won: totalWon,
                    lost: totalLost,
                    deleted: totalDeleted
                };

                setTotalData(totalData);
                setChartData([]);
                setIsGrid(true);
                setRowData([]);
                setIsLoading(false);

                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "There aren't any data available for your chosen Date Range or Filters. Try tweaking your Date Range & Filters",
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const getDealSourceCategoryChart = () => {
        axios.get("ws/ws_lead_analytics.php", {
            params: {
                task: "getPiechartDataSourceCategory",
                ds: startDate,
                de: endDate,
                archieve: leadStat.toString(),
                source_cat: dsCatId.toString(),
                ds_id: dsId.toString(),
                bu_id: buId.toString(),
                lost_reason_id: lostReaID.toString(),
                user_id: userId.toString(),
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var leadStatVal = leadStat && leadStat.length > 0 ? leadStat.map(rec => {return rec+'_count'}) : [];

                var pieDSCatData = data.record.map(rec => {
                    var value = 
                        leadStatVal.length === 1 ? 
                        Number(rec[leadStatVal[0]]) : leadStatVal.length === 2 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) : leadStatVal.length === 3 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) + Number(rec[leadStatVal[2]]) : Number(rec.won_count) + Number(rec.lost_count) + Number(rec.deleted_count);

                    return {name: rec.source_category_title, value: value};
                });

                var filteredData = [];
                pieDSCatData.forEach(rec => {
                    if (Number(rec.value) > 0 && rec.name) {
                        filteredData.push(rec);
                    }
                });

                setDsCatPieData(filteredData);
            }else{
                setDsCatPieData([]);
            }
        })
        .catch(error => {
            console.log(error);
            setDsCatPieData([]);
        });
    }

    const getDealSourceChart = () => {
        axios.get("ws/ws_lead_analytics.php", {
            params: {
                task: 'getPiechartDataSource',
                ds: startDate,
                de: endDate,
                archieve: leadStat.toString(),
                ds_id: dsId.toString(),
                bu_id: buId.toString(),
                source_cat: dsCatId.toString(),
                lost_reason_id: lostReaID.toString(),
                user_id: userId.toString(),
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var leadStatVal = leadStat && leadStat.length > 0 ? leadStat.map(rec => {return rec+'_count'}) : [];

                var pieDSData = data.record.map(rec => {
                    var value = 
                        leadStatVal.length === 1 ? 
                        Number(rec[leadStatVal[0]]) : leadStatVal.length === 2 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) : leadStatVal.length === 3 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) + Number(rec[leadStatVal[2]]) : Number(rec.won_count) + Number(rec.lost_count) + Number(rec.deleted_count);

                    return {name: rec.ds_title, value: value};
                });

                var filteredData = [];

                pieDSData.forEach(rec => {
                    if (Number(rec.value) > 0 && rec.name) {
                        filteredData.push(rec);
                    }
                });

                setdsPieData(filteredData);
            }else{
                setdsPieData([]);
            }
        })
        .catch(error => {
            console.log(error);
            setdsPieData([]);
        });
    }

    const getBUChart = () => {
        axios.get("ws/ws_lead_analytics.php", {
            params: {
                task: "getPiechartDataBusinessUnit",
                ds: startDate,
                de: endDate,
                archieve: leadStat.toString(),
                source_cat: dsCatId.toString(),
                ds_id: dsId.toString(),
                bu_id: buId.toString(),
                lost_reason_id: lostReaID.toString(),
                user_id: userId.toString(),
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var leadStatVal = leadStat && leadStat.length > 0 ? leadStat.map(rec => {return rec+'_count'}) : [];

                var pieBUData = data.record.map(rec => {
                    var value = 
                        leadStatVal.length === 1 ? 
                        Number(rec[leadStatVal[0]]) : leadStatVal.length === 2 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) : leadStatVal.length === 3 ? 
                        Number(rec[leadStatVal[0]]) + Number(rec[leadStatVal[1]]) + Number(rec[leadStatVal[2]]) : Number(rec.won_count) + Number(rec.lost_count) + Number(rec.deleted_count);

                    return {name: rec.unit_title, value: value};
                });

                var filteredData = [];
                pieBUData.forEach(rec => {
                    if (Number(rec.value) > 0 && rec.name) {
                        filteredData.push(rec);
                    }
                });

                setbuPieData(filteredData);
            }else{
                setbuPieData([]);
            }
        })
        .catch(error => {
            console.log(error);
            setbuPieData([]);
        });
    }

    const getLostReasonChart = () => {
        axios.get("ws/ws_lead_analytics.php", {
            params: {
                task: 'getPiechartDataLostReason',
                ds: startDate,
                de: endDate,
                archieve: leadStat.toString(),
                source_cat: dsCatId.toString(),
                ds_id: dsId.toString(),
                bu_id: buId.toString(),
                lost_reason_id: lostReaID.toString(),
                user_id: userId.toString(),
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var lostReasonPieData = [];
                data.record.forEach((rec) => {
                    if (Number(rec.lost_count) > 0 && rec.reason_title) {
                        lostReasonPieData.push({name: rec.reason_title, value: Number(rec.lost_count)});
                    }
                });
                setLostReaPieData(lostReasonPieData);
            }else{
                setLostReaPieData([]);
            }
        })
        .catch(error => {
            console.log(error);
            setLostReaPieData([]);
        });
    }

    const getRandomColor = () =>{
        var color1 = [];

        for (var i = 0; i < 100; i++) {
            color1.push('#' + Math.floor(Math.random() * 16777215).toString(16));
        }

        setChartColor(color1);
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllBU();
            getAllSource();
            getAllUser();
            getAllData();
            getAllLostReason();
            getRandomColor();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
            {isLoading ? <Loader/> : 
                <div className="p-4">
                    <div className="mb-4">
                        <Stack direction="horizontal" gap={1}>
                            <Form.Group style={{width: '150px'}}>
                                <FormSelect
                                    options={BUList}
                                    placeholder="All Business Unit"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setBuId(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setBuId('')
                                    }}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '180px'}}>
                                <FormSelect
                                    options={dsCatList}
                                    placeholder="All Source Category"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setDsCatId(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setDsCatId('')
                                    }}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '150px'}}>
                                <FormSelect
                                    options={dsCatId && dsCatId.length > 0 ? dsList.filter(opt => dsCatId.includes(opt.dsCatId)) : dsList}
                                    placeholder="All Source"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setDsId(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setDsId('')
                                    }}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '150px'}}>
                                <FormSelect
                                    options={leadStatList}
                                    placeholder="All Lead Status"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setLeadStat(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setLeadStat('');
                                        setLostReaID('');
                                    }}
                                />
                            </Form.Group>
                            {leadStat.includes('lost') && <Form.Group style={{width: '150px'}}>
                                <FormSelect
                                    options={lostReasonList}
                                    placeholder="All Lost Reason"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setLostReaID(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setLostReaID('')
                                    }}
                                />
                            </Form.Group>}
                            <Form.Group style={{width: '150px'}}>
                                <FormSelect
                                    options={userList}
                                    placeholder="All User"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    onChange={(e) => {
                                        e ? setUserId(
                                          e.map((d) => {
                                            return d.value;
                                          })
                                        ) : setUserId('')
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="ms-auto">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {setStartDate(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {setEndDate(e.target.value)}}
                                />
                            </Form.Group>
                            <button 
                                type="button" 
                                className="btn op-button op-primary-color text-light" 
                                onClick={() => {
                                    getAllData();
                                    getDealSourceCategoryChart(); 
                                    getDealSourceChart();
                                    getBUChart();
                                    getLostReasonChart();
                                }}
                            >
                                Apply
                            </button>
                        </Stack>
                    </div>

                    <div className="mb-3">
                        <Row sm={12}>
                            <Col sm={4}>
                                <Card className="border-0 shadow-sm w-100">
                                    <Card.Header>
                                        <Card.Title as={'h6'}>Total Won Deal</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="py-2">
                                                <div style={{ fontSize: "18px" }}>
                                                    {totalData.won.count}
                                                </div>
                                                <div style={{ fontSize: "14px", fontWeight: "600" }}>{setCurrency(totalData.won.value)}</div>
                                            </div>
                                            <FontAwesomeIcon icon={faDollarCircle} size="3x" />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card className="border-0 shadow-sm w-100">
                                    <Card.Header>
                                        <Card.Title as={'h6'}>Total Lost Deal</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="py-2">
                                                <div style={{ fontSize: "18px" }}>
                                                    {totalData.lost.count}
                                                </div>
                                                <div style={{ fontSize: "14px", fontWeight: "600" }}>{setCurrency(totalData.lost.value)}</div>
                                            </div>
                                            <FontAwesomeIcon icon={faHandshake} size="3x" />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card className="border-0 shadow-sm w-100 m-0">
                                    <Card.Header>
                                        <Card.Title as={'h6'}>Total Deleted Deal</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="py-2">
                                                <div style={{ fontSize: "18px" }}>
                                                    {totalData.deleted.count}
                                                </div>
                                                <div style={{ fontSize: "14px", fontWeight: "600", color: 'white', cursor: 'default' }}>{setCurrency(0)}</div>
                                            </div>
                                            <FontAwesomeIcon icon={faHandshakeSlash} size="3x" />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    {isDefault && 
                        <DefaultLeadAnalytic 
                            chartData={chartData} 
                            gridData={rowData} 
                            isGrid={isGrid} 
                            leadStatData={leadStat} 
                            isDsCat={isPieChart.dsCat} 
                            isDs={isPieChart.ds} 
                            isBu={isPieChart.bu}
                            isLost={isLost}
                            isLeadStat={isLeadStat} 
                            dsCatPieData={dsCatPieData} 
                            dsPieData={dsPieData} 
                            buPieData={buPieData} 
                            LostReasonPieData={lostReaPieData} 
                            chartColor={chartColor}
                        />
                    }
                    {/* {isWon && <WonLeadAnalytic chartData={chartData} gridData={rowData} isGrid={isGrid} dsPieData={dsPieData} dsCatPieData={dsCatPieData} buPieData={buPieData} chartColor={chartColor}/>}
                    {isLost && <LostLeadAnalytic chartData={chartData} gridData={rowData} isGrid={isGrid} dsPieData={dsPieData} dsCatPieData={dsCatPieData} buPieData={buPieData} LostReasonPieData={lostReaPieData} chartColor={chartColor}/>}
                    {isDeleted && <DeletedLeadAnalytic chartData={chartData} gridData={rowData} isGrid={isGrid} dsPieData={dsPieData} dsCatPieData={dsCatPieData} buPieData={buPieData} chartColor={chartColor}/>} */}
                </div>
            }
        </div>
    );
}

export default LeadsAnalytic;