import React, { useEffect, useState, forwardRef, memo, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { faUserHair, faArrowRightFromBracket, faBell, faCircleQuestion, faGear, faRepeat, faUserLock, faArrowRightToArc, faHandHoldingCircleDollar, faPerson, faCalendarAlt, faHouseCircleExclamation, faObjectsColumn, faCircleEllipsis } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useParams, NavLink, Link } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import SearchBar from "./SearchBar";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";
import SwitchAccount from "../auth/SwitchAccount";
import { routeChild, routePath } from "../auth/RouteAccess";
import PersonalInformation from "../auth/PersonalInformation";
import Notification from "./Notification";
import ChangePassword from "../auth/ChangePassword";
import SalesCommission from "../components/SalesCommission";
import moment from "moment";
import "moment/locale/vi";

const Header = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const params = useParams();
  const { deal_id, prospect_id, rea_unit_id } = useParams();
  const [isSwitchAcc, setIsSwitchAcc] = useState(false);
  const { i18n, t } = useTranslation();
  const [notiInfo, setNotiInfo] = useState({});
  const [imgError, setImgError] = useState(false);
  const [isPersonalInfo, setIsPersonalInfo] = useState(false);
  const [cache] = useState(Math.random());
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isSalesCommission, setIsSalesCommission] = useState(false);

  // GET FUNCTION =============================================

  const getNotification = useCallback(async () => {
    try {
      const response = await axios.get("ws/ws_system.php", {
        params: {
          task: 3,
          user: session.user_id,
          type: session.role_id,
        },
      });

      const data = response.data;
      setNotiInfo(data);
    } catch (error) {
      console.error(error);
    }
  }, [session]);

  const getReleaseOnHold = useCallback(async () => {
    try {
      const result = await Swal.fire({
        title: "Close Booking Form?",
        text: "Closing the form will discard unsaved changes and make this unit available for others to book. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const bookingForm = sessionStorage.getItem("bookingForm");
        const bookingData = JSON.parse(bookingForm);
        const response = await axios.get("ws/ws_rea_hold_unit.php", {
          params: {
            task: "releaseHoldUnitForm",
            hold_unit_id: bookingData.holdid,
            rea_unit_id: bookingData.unitid,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          sessionStorage.removeItem("bookingForm");
          navigate(-1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [navigate, session, Swal]);

  // OPEN FUNCTION ============================================

  const openPersonalInfo = useCallback(() => {
    setIsPersonalInfo(true);
  }, []);

  // CLOSE FUNCTION ===========================================

  const closePersonalInfo = useCallback((result) => {
    setIsPersonalInfo(result);
  }, []);

  // SEND FUNCTION ============================================

  const sendNavigationBack = useCallback(() => {
    if (location.pathname === `/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`) {
      getReleaseOnHold();
    } else {
      navigate(-1);
    }
  }, [rea_unit_id, deal_id, prospect_id, location.pathname]);

  // SET FUNCTION =============================================

  const setInitial = (input) => {
    var str = "";
    if (/\s/.test(input)) {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    } else {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    }
    return str;
  };

  // RENDER FUNCTION ==========================================

  const RenderImage = useCallback(() => {
    if (imgError) {
      return (
        <div className="avatar-img">
          <span className="avatar-txt">{setInitial(session.user_name)}</span>
        </div>
      );
    } else {
      return (
        <div className="avatar-img2">
          <img className="d-block rounded-circle border" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${session.user_id}.png?c=${cache}`} width={30} height={30} onError={() => setImgError(true)} />
        </div>
      );
    }
  }, [imgError, session.hostUrl, session.hostUrlType, session.user_id, cache]);

  const RenderProfile = useCallback(
    ({ onClick }) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <div className="avatar align-items-center" onClick={openDropdown} style={{ gap: 8 }}>
          <RenderImage />
          <div className="m-0 mt-1">
            <Stack direction="vertical">
              <p className="op-root-header__profile_text text-dark m-0" style={{ lineHeight: 1 }}>
                {session.user_name}
              </p>
              <p className="op-root-header__profile_text text-muted m-0" style={{ fontSize: 10 }}>
                {session.company_title}
              </p>
            </Stack>
          </div>
        </div>
      );
    },
    [session, imgError]
  );

  // USEEFFECT ================================================

  useEffect(() => {
    if (location.pathname) {
      const titlePath = routePath(location.pathname, params, t);
      document.title = titlePath;
      moment.locale(session.default_lang);
      i18n.changeLanguage(session.default_lang);
      setPageTitle(titlePath);
      getNotification();
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header expand="lg" className="bg-body-tertiary shadow-sm op-root-header">
      <div className="op-root-header__left">
        <div className="d-flex align-items-center">
          {routeChild(location.pathname, params) && (
            <Button variant="light" className="me-2" onClick={sendNavigationBack}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Button>
          )}
          <h6 className="op-extrabold-font text-muted m-0" style={{ fontSize: 15 }}>
            {pageTitle}
          </h6>
        </div>
      </div>

      <div className="op-root-header__center">
        <SearchBar />
      </div>

      <div className="op-root-header__right">
        <div className="op-root-header__item">
          <Notification notiInfo={notiInfo} getNotification={getNotification} />
        </div>

        <div className="op-root-header__item">
          <a className="text-decoration-none" href="https://support.nexible.com.my/" target="_blank">
            <FontAwesomeIcon icon={faCircleQuestion} size="xl" style={{ color: "rgb(0, 0, 0)" }} />
          </a>
        </div>

        {Number(session.role_id) === 1 && (
          <div className="op-root-header__item">
            <Link to="/settings">
              <FontAwesomeIcon icon={faGear} size="xl" style={{ color: "#000" }} />
            </Link>
          </div>
        )}

        <div className="op-root-header__profile">
          <Dropdown size={100}>
            <Dropdown.Toggle as={RenderProfile} />
            <Dropdown.Menu className="profile-menu shadow-sm border-none animate slideIn" align="end" size={100}>
              <Dropdown.Header className="fw-semibold op-text-medium text-uppercase">{t("Container_my_account")}</Dropdown.Header>
              <Dropdown.Item className="d-flex align-items-center op-text-medium" onClick={openPersonalInfo}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                  <FontAwesomeIcon icon={faUserHair} className="me-3" size="lg" />
                </div>
                {t("Container_personal_preference")}
              </Dropdown.Item>
              <Dropdown.Item className="d-flex op-text-medium" onClick={() => navigate("/report-login")}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                  <FontAwesomeIcon icon={faArrowRightToArc} className="me-3" size="lg" />
                </div>
                {t("Container_login_report")}
              </Dropdown.Item>
              {[180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412].includes(Number(session.company_id)) && (
                <Dropdown.Item className="d-flex op-text-medium" onClick={() => setIsSalesCommission(true)}>
                  <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                    <FontAwesomeIcon icon={faHandHoldingCircleDollar} className="me-3" size="lg" />
                  </div>
                  {t("Container_sales_commission")}
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item className="d-flex op-text-medium" onClick={setIsChangePassword}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                  <FontAwesomeIcon icon={faUserLock} className="me-3" size="lg" />
                </div>
                {t("Container_change_password")}
              </Dropdown.Item>
              {Number(session.super_admin) === 1 && (
                <Dropdown.Item className="d-flex op-text-medium" onClick={setIsSwitchAcc}>
                  <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                    <FontAwesomeIcon icon={faRepeat} className="me-3" size="lg" />
                  </div>
                  Switch Account
                </Dropdown.Item>
              )}
              <Dropdown.Item className="d-flex op-text-medium" onClick={logoutUser}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: 35 }}>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-3" size="lg" />
                </div>
                {t("Container_logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <PersonalInformation open={isPersonalInfo} onClose={closePersonalInfo} />
      <ChangePassword open={isChangePassword} onClose={setIsChangePassword} />
      <SalesCommission open={isSalesCommission} onClose={setIsSalesCommission} />
      {isSwitchAcc && <SwitchAccount closeModal={setIsSwitchAcc} />}
    </header>
  );
};

export default memo(Header);
