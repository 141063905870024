import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Stack, Card } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil, faPlus } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReactSortable } from "react-sortablejs";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Label, Legend, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";

registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
);

const MySwal = withReactContent(Swal);

const Survey = () => {
    const { t } = useTranslation();
    const init = useRef(false);
    const filePondRef = useRef();
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [surveylist, setSurveyList] = useState([]);
    const [isAddSurvey, setisAddSurvey] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [dynamicFields, setDynamicFields] = useState([]);
    const [showCFModal, setShowCFModal] = useState(false);
    const [CFData, setCFData] = useState([]);
    const [bannerImg, setBannerImg] = useState("");
    const [isDarkerOverlay, setIsDarkerOverlay] = useState(false);
    const [isDarkerOverlayCF, setIsDarkerOverlayCF] = useState(false);
    const [selectedFieldType, setSelectedFieldType] = useState('');
    const [openEditParams, setOpenEditParams] = useState('');
    const [showEditCFModal, setShowEditCFModal] = useState(false);
    const [getCFData, setGetCFData] = useState('');
    const [cfArr, setCfArr] = useState([]);
    const [submissionData, setSubmissionData] = useState([]);
    const [showSubmissionModal, setShowSubmissionModal] = useState(false);
    const [customFieldInfoSubmission, setCustomFieldInfoSubmission] = useState([]);
    const [customFieldInfoSubmissionStat, setCustomFieldInfoSubmissionStat] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
    const [cfRecord, setCFRecord] = useState([]);


    // Validation Schema
    const validationSchema = yup.object().shape({
        title: yup.string()
            .trim()
            .required('Form title is required')
            .min(1, 'Form title cannot be empty or whitespace'),
        // .required(t("SurveySetting_title_is_required")),
    });

    const validationSchemaEdit = yup.object().shape({
        title: yup.string()
            .trim()
            .required('Form title is required')
            .min(1, 'Form title cannot be empty or whitespace'),
        // .required(t("SurveySetting_title_is_required")),
        slug: yup
            .string()
            .required(t("SurveySetting_slug_is_required")),
        banner_img: yup
            .string()
            .required("This field is required"),
    });

    const validationSchemaAddCf = yup.object().shape({
        field_name: yup
            .string()
            .required(t("SurveySetting_field_name_is_required")),
    });

    // GET FUNCTION --------------------------------------------------------
    const getSurveyList = () => {
        try {
            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_setting.php`, {
                    params: {
                        ctoken: session.company_token,
                        mode: 'Survey',
                        task: "getAllFormsV2",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    let data = res.data;
                    setSurveyList(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // SET FUNCTION -----------------------------------------
    const getPublishedResult = (params) => {
        const getNewPublishedValue = () => {
            return params.data.published === 'Yes' ? 'No' : 'Yes';
        };

        const handleClick = () => {
            const newPublished = getNewPublishedValue();

            const newData = surveylist.map(item => {
                if (item.reg_form_id === params.data.reg_form_id) {
                    return { ...item, published: newPublished };
                }
                return item;
            });

            setSurveyList(newData);
        };

        const handleButtonClick = () => {
            const newPublished = getNewPublishedValue();

            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_setting.php`, {
                    params: {
                        ctoken: session.company_token,
                        published: newPublished,  // Use the new value
                        reg_form_id: params.data.reg_form_id,
                        task: "setFormsStatus",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    if (res.data.status == '0') {
                        handleClick();
                    }
                })
                .catch(error => {
                    console.error("There was an error updating the form status:", error);
                });
        };

        const isPublished = params.data.published === 'Yes';
        const buttonLabel = isPublished ? 'Published' : 'Draft';
        const buttonVariant = isPublished ? 'success' : 'secondary';

        return (
            <Button
                variant={buttonVariant}
                onClick={handleButtonClick}
                style={{ padding: "0.2rem 0.5rem", fontSize: "0.8rem" }}
            >
                {buttonLabel}
            </Button>
        );
    };


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: t("SurveySetting_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("SurveySetting_title"),
            field: "form_title",
            width: 280,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' },
        },
        {
            headerName: t("SurveySetting_slug"),
            field: "url_title",
            width: 300,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2', 'cursor': 'pointer' },
            onCellClicked: function (params) {
                if (params.data) {
                    const slug = params.data.url_title;
                    if (!slug) {
                        Swal.fire({
                            icon: 'warning',
                            title: t("SurveySetting_missing_slug"),
                            text: t("SurveySetting_please_add_slug_in_edit_section"),
                            timer: 1500
                        });
                    } else {
                        const url = session.hostUrlType == 'cloud_staging'
                            ? `${session.hostUrl}/form/s3/${slug}/${params.data.form_hash}/0/0/0/`
                            : `${session.hostUrl}/form/s5/${slug}/${params.data.form_hash}/0/0/0/`;
                        window.open(url, '_blank');
                    }
                }
            }
        },
        { headerName: t("SurveySetting_view_count"), headerClass: "center", field: "view_count", width: 150, cellClass: "center" },
        {
            headerName: t("SurveySetting_submission"),
            field: "survey_submitted",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            onCellClicked: function (params) {
                if (params.data) {
                    var formId = params.data.reg_form_id;
                    getSubmissionData(formId);
                }
            }
        },
        {
            headerName: t("SurveySetting_published"),
            field: "published",
            width: 150,
            cellRenderer: getPublishedResult,
            pinned: "right",
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t("SurveySetting_action"),
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                const isPublished = params.data.published === 'Yes';

                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{t("SurveySetting_delete")}</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    data-action="trash"
                                    className={`fontAwesomeIcon ms-3 ${isPublished ? 'disabled' : ''}`}
                                    size="xl"
                                    icon={faTrash}
                                    style={{ pointerEvents: isPublished ? 'none' : 'auto', opacity: isPublished ? 0.5 : 1 }}
                                />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{t("SurveySetting_edit")}</Tooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    data-action="edit"
                                    className={`fontAwesomeIcon ms-3 ${isPublished ? 'disabled' : ''}`}
                                    size="xl"
                                    icon={faPencil}
                                    style={{ pointerEvents: isPublished ? 'none' : 'auto', opacity: isPublished ? 0.5 : 1 }}
                                />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];


    // Get Submission Data

    const createDynamicColumnsSubmission = (cfInfo) => {
        if (!cfInfo || !Array.isArray(cfInfo.cf_record)) {
            return [];
        }

        return cfInfo.cf_record.map(cf => ({
            headerName: cf.cf_label,
            field: cf.cf_id,
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
            valueGetter: (params) => {
                const record = params.data.cf_val.cf_record ? params.data.cf_val.cf_record.find(item => item.cf_id === cf.cf_id) : [];
                return record ? record.fd_value : '';
            }
        }));
    };

    const getSubmissionData = async (form_id) => {
        try {
            const res = await axios.get("ws/ws_survey.php", {
                params: {
                    ctoken: session.company_token,
                    reg_form_id: form_id,
                    task: "submittedList",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setSubmissionData(data.record);
                setCustomFieldInfoSubmission(data.cf_info);
                setCustomFieldInfoSubmissionStat(data.stat);
                setShowSubmissionModal(true);
            } else {
                setSubmissionData([]);
                setShowSubmissionModal(true);
            }
        } catch {

        }
    }

    const handleSubmissionCloseModal = () => {
        setShowSubmissionModal(false);
    }

    // Submission Grid

    const gridColumnSubmission = [
        { headerName: t("SurveySetting_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("SurveySetting_date_submission"),
            field: "survey_submission_date_time",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("SurveySetting_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("SurveySetting_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("SurveySetting_contact"),
            field: "contact1",
            width: 250,
        },
        ...(customFieldInfoSubmission ? createDynamicColumnsSubmission(customFieldInfoSubmission) : []),
    ]

    // Download Submission Data

    const handleDownloadSubmission = () => {
        var params = {
            fileName: "submission.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = async (params) => {
        try {
            setOpenEditParams(params);

            // Fetch banner image first
            await getBannerImg(params);

            const url = session.hostUrlType === 'cloud' ? `${session.hostUrl}/form/registration_api.php` : `${session.hostUrl}/form/registration_api_staging.php`;

            const response = await axios.get(url, {
                params: {
                    task: "getAllCF_survey",
                    token: params.form_hash,
                    urltitle: params.url_title
                }
            });

            if (response.data.status == '0') {
                setDynamicFields(response.data.record);
            } else {
                setDynamicFields([]);
            }

            setCurrentData(params);
            setShowModal(true);

        } catch (error) {
            console.error(error);
        }
    };

    const getBannerImg = async (params) => {
        try {
            const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/php/form_image_settings.php`, {
                params: {
                    task: "ReadImage",
                    banner_img: params.banner_img
                }
            });

            const data = response.data;

            if (data.status === 0 && data.base64) {
                setBannerImg(`data:${data.mime_type};base64,${data.base64}`)
            } else {
                setBannerImg('');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const openCFModal = () => {
        setShowCFModal(true);
        setIsDarkerOverlay(true);
    }

    const handleCloseCFModal = () => {
        setShowCFModal(false);
        setIsDarkerOverlay(false);
    };

    const handleAddCFList = async () => {
        openCFModal();

        const res = await axios.get("ws/ws_custom_field.php", {
            params: {
                task: "4"
            }
        })

        if (res.data.status === 0) {
            setCFData(res.data.record);
        }

    }

    const handleUpdate = async (values) => {
        try {
            const url = session.hostUrlType === 'cloud' ? `${session.hostUrlApi}/form/registration_api.php` : `${session.hostUrlApi}/form/registration_api_staging.php`;

            const response = await axios.get(url, {
                params: {
                    task: "updateTemplateV2",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    title: values.title,
                    slug: values.slug,
                    ds_id: values.ds_id,
                    user_id: values.user_id,
                    banner_img: values.banner_img,
                    form_id: values.form_id,
                    mode: values.mode,
                    pipeline_id: values.pipeline_id,
                    chat_instance_id: values.chat_instance_id,
                    published: values.published,
                    whatsapp_rsvp_message: values.whatsapp_rsvp_message,
                    whatsapp_qr_name: values.whatsapp_qr_name,
                    whatsapp_survey_message: values.whatsapp_survey_message,
                    associated_survey: values.associated_survey,
                    consent_needed: values.consent_needed,
                    consent_1_type: values.consent_1_type,
                    consent_1_text: values.consent_1_text,
                    event_date: values.event_date,
                    event_time: values.event_time,
                    event_location: values.event_location,
                    event_create_lead: values.event_create_lead,
                    event_user_tag: values.event_user_tag,
                    event_lead_assigned_source_token: values.event_lead_assigned_source_token,
                    form_description: values.description,
                }
            })

            const data = response.data;

            if (data.status === 0) {
                MySwal.fire({
                    icon: 'success',
                    title: t("SurveySetting_updated"),
                    text: t("SurveySetting_your_record_has_been_updated"),
                    timer: 1500
                });

                setShowModal(false);
                getSurveyList();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: t("SurveySetting_are_you_sure"),
            text: t("SurveySetting_you_wont_be_able_to_revert_it"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("SurveySetting_yes_delete_it"),
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.reg_form_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: t("SurveySetting_error"),
                        text: t("SurveySetting_invalid_data_for_deletion"),
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const url = session.hostUrlType === 'cloud' ? `${session.hostUrl}/form/registration_api.php` : `${session.hostUrl}/form/registration_api_staging.php`;

            const response = await axios.get(url, {
                params: {
                    ctoken: session.company_token,
                    form_id: params.reg_form_id,
                    task: "delForm",
                    utoken: session.user_ac_token,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: t("SurveySetting_deleted"),
                    text: t("SurveySetting_your_record_has_been_deleted"),
                    timer: 1500
                });
                getSurveyList();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: t("SurveySetting_error"),
                text: t("SurveySetting_error_happened_while_deleting_record"),
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const url = session.hostUrlType === 'cloud' ? `${session.hostUrl}/form/registration_api.php` : `${session.hostUrl}/form/registration_api_staging.php`;
            const response = await axios.get(url, {
                params: {
                    ctoken: session.company_token,
                    task: "addTemplateSurvey",
                    title: values.title,
                    utoken: session.user_ac_token,
                }
            });

            if (response.data.status == '0') {
                setisAddSurvey(false);
                getSurveyList();

                MySwal.fire({
                    icon: 'success',
                    title: t("SurveySetting_successfully_added"),
                    text: t("SurveySetting_record_has_been_added_successfully"),
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getSurveyList();

            const submitSurvey = sessionStorage.getItem('submitSurvey');
            const storedParams = submitSurvey ? JSON.parse(submitSurvey) : {};
            if (storedParams) {
                setisAddSurvey(storedParams.outcome)
                sessionStorage.removeItem('submitSurvey');
            }
        }
    }, []);

    const toggleAddSurveyModal = () => {
        setisAddSurvey(prevState => !prevState);
    };


    // Add CF

    const handleRefeshCustomFieldList = async () => {
        const url = session.hostUrlType === 'cloud' ? `${session.hostUrl}/form/registration_api.php` : `${session.hostUrl}/form/registration_api_staging.php`;
        const response = await axios.get(url, {
            params: {
                task: "getAllCF_survey",
                token: openEditParams.form_hash,
                urltitle: openEditParams.url_title
            }
        });

        if (response.data.status == '0') {
            setDynamicFields(response.data.record);
        } else {
            setDynamicFields([]);
        }
    }

    const handleAddCF = async (values) => {
        if (values.possible_values) {
            values.possible_values = values.possible_values.split('\n').join(',');
        }

        try {
            const res = await axios.get("ws/ws_custom_field.php", {
                params: {
                    add: "",
                    area: "survey",
                    cft: selectedFieldType,
                    company: session.company_id,
                    detail: "",
                    label: values.field_name,
                    reg_form_id: values.form_id,
                    task: "addSurveyCF",
                    value: values.possible_values
                }
            })

            const data = res.data;

            if (data.status === 0) {
                handleCloseCFModal();
                await handleRefeshCustomFieldList();

                Swal.fire({
                    icon: 'success',
                    title: t("SurveySetting_successfully_added"),
                    text: t("SurveySetting_new_custom_field_added"),
                    timer: 1500
                });

            }
        } catch {
            Swal.fire({
                icon: 'errror',
                title: t("SurveySetting_error"),
                text: t("SurveySetting_error_happened_when_adding_cutom_field"),
                timer: 1500
            });
        }
    };

    // Delete CF

    const handleDeleteCF = (params) => {
        MySwal.fire({
            title: t("SurveySetting_are_you_sure"),
            text: t("SurveySetting_you_wont_be_able_to_revert_it"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("SurveySetting_yes_delete_it"),
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.cf_id) {
                    sendDeleteCF(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: t("SurveySetting_error"),
                        text: t("SurveySetting_invalid_data_for_deletion"),
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDeleteCF = async (params) => {
        try {
            const response = await axios.get("ws/ws_custom_field.php", {
                params: {
                    cf: params.cf_id,
                    task: "7"
                }
            });

            if (response.data.status === 0) {
                MySwal.fire({
                    icon: 'success',
                    title: t("SurveySetting_deleted"),
                    text: t("SurveySetting_your_record_has_been_deleted"),
                    timer: 1500
                });
                handleRefeshCustomFieldList();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: t("SurveySetting_error"),
                text: t("SurveySetting_error_happened_while_deleting_record"),
                timer: 1500
            });
        }
    }

    // Edit CF

    const handleGetCFEditList = async (params) => {
        try {
            const res = await axios.get("ws/ws_custom_field.php", {
                params: {
                    cf: params.cf_id,
                    task: "9"
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setCfArr(data.record.cf_area_array);
            }
        } catch {

        }
    }

    const handleEditCFOpen = (params) => {
        setGetCFData(params);
        handleGetCFEditList(params);
        setShowEditCFModal(true);
        setIsDarkerOverlayCF(true);
    }

    const handleCloseEditCFModal = () => {
        setShowEditCFModal(false);
        setIsDarkerOverlayCF(false);
    };

    const handleEditCF = async (values) => {
        let arrValue = "";
        let newPossibleValue = "";

        if (values.possible_values) {
            arrValue = values.possible_values.split('\n').join(',');
        } else {
            arrValue = cfArr.join(', ');
        }

        if (values.new_possible_values) {
            newPossibleValue = values.new_possible_values.split('\n').join(',');
        }

        let combinedValue = "";
        if (arrValue && newPossibleValue) {
            combinedValue = arrValue + ',' + newPossibleValue;
        } else if (arrValue) {
            combinedValue = arrValue;
        } else if (newPossibleValue) {
            combinedValue = newPossibleValue;
        }

        try {
            const res = await axios.get("ws/ws_custom_field.php", {
                params: {
                    cf: getCFData.cf_id,
                    company: session.company_id,
                    ctoken: session.company_token,
                    fbfield: getCFData.fb_lead_ads_field_name,
                    fbmap: getCFData.fb_lead_ads_map,
                    grfield: getCFData.getresponse_field_name,
                    grmap: getCFData.getresponse_map,
                    // label: getCFData.cf_label,
                    label: values.field_name,
                    pipeid: getCFData.pipe_id,
                    reg_form_id: getCFData.reg_form_id,
                    task: "14",
                    utoken: session.user_ac_token,
                    value: combinedValue || ""
                }
            })

            const data = res.data;

            if (data.status === 0) {
                handleCloseEditCFModal();

                Swal.fire({
                    icon: 'success',
                    title: t("SurveySetting_updated"),
                    text: t("SurveySetting_values_updated"),
                    timer: 1200
                });
            }
        } catch {

        }
    };

    // render piechart

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const renderPieCharts = () => {
        if (!customFieldInfoSubmissionStat || !customFieldInfoSubmissionStat?.stat_record) {
            return null; // or handle loading state or error state
        }

        return (
            <div className="d-flex justify-content-center flex-wrap">
                {customFieldInfoSubmissionStat?.stat_record.length > 0 && customFieldInfoSubmissionStat?.stat_record.map((item, index) => {
                    // Extract entries from item object directly
                    const entries = Object.values(item).filter(entry => typeof entry === 'object');

                    // Map through entries to create pie chart data
                    const pieChartData = entries.map(entry => ({
                        name: entry.fd_value,
                        value: parseInt(entry.count)
                    }));

                    // Generate random colors for each cell
                    const colors = pieChartData.map(() => generateRandomColor());

                    return (
                        <div key={index} className="mb-4" style={{ width: '45%', margin: '0 auto' }}>
                            <Card className="border-0 shadow-sm">
                                <Card.Header>
                                    <div className="font h5 m-3" style={{ width: '100%' }}>{item.stat_label}</div>
                                </Card.Header>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Legend verticalAlign="top" />
                                        <Pie
                                            data={pieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"  // Adjust positioning as needed
                                            cy="50%"
                                            outerRadius={80}  // Adjust radius as needed
                                            innerRadius={30}
                                            label
                                        >
                                            {pieChartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <RechartsTooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Card>
                        </div>
                    );
                })}
            </div>
        );
    };

    useEffect(() => {
        const delay = 500;

        const delaySearch = setTimeout(() => {
            setDelayedSearchTerm(searchTerm);
        }, delay);

        return () => clearTimeout(delaySearch);
    }, [searchTerm]);

    const getQuickFilterText = (params) => {
        const textFields = ["form_title", "url_title"];
        const searchTermLowerCase = delayedSearchTerm.toLowerCase(); // Use delayed search term
        return textFields.map((field) => params.data[field]).join(' ').toLowerCase().includes(searchTermLowerCase);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
                        <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddSurveyModal} style={{ width: "90px" }}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("SurveySetting_survey")}
                        </button>
                        <div className="d-flex">
                            <Form.Control type="text" className="me-1 shadow-sm border-0" placeholder={t("SurveySetting_search")} style={{ width: 300 }} value={searchTerm || ''} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </Stack>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        rowData={loading ? [] : surveylist}
                                        rowHeight={100}
                                        pagination={true}
                                        onCellClicked={onCellClicked}
                                        quickFilterText={delayedSearchTerm}
                                        getQuickFilterText={getQuickFilterText}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddSurvey} onHide={toggleAddSurveyModal}>
                        <Formik
                            initialValues={{
                                title: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">{t("SurveySetting_add_survey")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_form_title")}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                isInvalid={touched.title && !!errors.title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            {t("SurveySetting_submit")}
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    {isDarkerOverlay && <div style={{ position: "fixed", top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '1061' }}></div>}

                    {/* CF Modal */}

                    <Modal show={showCFModal} onHide={handleCloseCFModal} size="lg" style={{ zIndex: '1071' }}>
                        <Formik
                            initialValues={{
                                field_name: "",
                                possible_values: "",
                                form_id: currentData ? currentData.reg_form_id : "",
                            }}
                            validationSchema={validationSchemaAddCf}
                            onSubmit={handleAddCF}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t("SurveySetting_add_custom_field")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_what_type_of_field_do_you_want_to_add")}
                                            </Form.Label>
                                            <Form.Control as="div">
                                                {CFData && CFData.map((field, index) => (
                                                    <Button
                                                        key={field.cft_id}
                                                        variant="outline-primary"
                                                        className="m-1"
                                                        onClick={() => setSelectedFieldType(field.cft_id)}
                                                        style={{
                                                            backgroundColor: selectedFieldType === field.cft_id ? 'lightblue' : 'transparent',
                                                            color: selectedFieldType === field.cft_id ? 'white' : 'black',
                                                            borderColor: selectedFieldType === field.cft_id ? 'lightblue' : '#007bff'
                                                        }}
                                                    >
                                                        {field.ctf_label}
                                                    </Button>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        {selectedFieldType === "3" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_possible_values")}</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={6}
                                                        name="possible_values"
                                                        onChange={handleChange}
                                                        isInvalid={touched.possible_values && !!errors.possible_values}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.possible_values}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="fw-bold">
                                                        {t("SurveySetting_enter_one_per_line")}
                                                    </Form.Label>
                                                    <div>{t("SurveySetting_consulting")}</div>
                                                    <div>{t("SurveySetting_training")}</div>
                                                    <div>{t("SurveySetting_speaking")}</div>
                                                </Form.Group>
                                            </>
                                        )}

                                        {selectedFieldType === "5" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                        )}

                                        {selectedFieldType === "2" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_possible_values")}</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={6}
                                                        name="possible_values"
                                                        onChange={handleChange}
                                                        isInvalid={touched.possible_values && !!errors.possible_values}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.possible_values}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="fw-bold">
                                                        {t("SurveySetting_enter_one_per_line")}
                                                    </Form.Label>
                                                    <div>{t("SurveySetting_consulting")}</div>
                                                    <div>{t("SurveySetting_training")}</div>
                                                    <div>{t("SurveySetting_speaking")}</div>
                                                </Form.Group>
                                            </>
                                        )}

                                        {selectedFieldType === "4" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_possible_values")}s</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={6}
                                                        name="possible_values"
                                                        onChange={handleChange}
                                                        isInvalid={touched.possible_values && !!errors.possible_values}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.possible_values}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="fw-bold">
                                                        {t("SurveySetting_enter_one_per_line")}
                                                    </Form.Label>
                                                    <div>{t("SurveySetting_consulting")}</div>
                                                    <div>{t("SurveySetting_training")}</div>
                                                    <div>{t("SurveySetting_speaking")}</div>
                                                </Form.Group>
                                            </>
                                        )}

                                        {selectedFieldType === "1" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                        )}

                                        {selectedFieldType === "7" && (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("SurveySetting_name_of_the_field")}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="field_name"
                                                        onChange={handleChange}
                                                        isInvalid={touched.field_name && !!errors.field_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.field_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" type="submit">
                                            {t("SurveySetting_add_custom_field")}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    {isDarkerOverlayCF && <div style={{ position: "fixed", top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '1061' }}></div>}

                    <Modal show={showEditCFModal} onHide={handleCloseEditCFModal} size="lg" style={{ zIndex: '1071' }}>
                        <Formik
                            initialValues={{
                                // field_name: cfRecord ? cfRecord.cf_label : "",
                                field_name: getCFData ? getCFData.cf_label : "",
                                possible_values: cfArr.map(item => item.trim()).join('\n'),
                                new_possible_values: ""
                            }}
                            // validationSchema={validationSchemaEdit}
                            onSubmit={handleEditCF}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t("SurveySetting_edit_custom_field")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_name_of_the_field")}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="field_name"
                                                value={values.field_name}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("SurveySetting_possible_values")}</Form.Label>
                                            {cfArr.map((item, index) => (
                                                <div className="mb-3" key={index}>
                                                    <div className="d-flex align-items-center">
                                                        <Form.Control
                                                            type="text"
                                                            value={item}
                                                            onChange={e => {
                                                                const updatedArr = [...cfArr];
                                                                updatedArr[index] = e.target.value;
                                                                setFieldValue('possible_values', updatedArr.join('\n'));
                                                                setCfArr(updatedArr);
                                                            }}
                                                        />
                                                        <Button
                                                            variant="light"
                                                            className="ms-2"
                                                            onClick={() => {
                                                                const updatedArr = [...cfArr];
                                                                updatedArr.splice(index, 1);
                                                                setFieldValue('possible_values', updatedArr.join('\n'));
                                                                setCfArr(updatedArr);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Form.Group>


                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                as="textarea"
                                                rows={6}
                                                name="new_possible_values"
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label className="fw-bold">
                                                {t("SurveySetting_enter_one_per_line")}
                                            </Form.Label>
                                            <div>{t("SurveySetting_consulting")}</div>
                                            <div>{t("SurveySetting_training")}</div>
                                            <div>{t("SurveySetting_speaking")}</div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            {t("SurveySetting_update")}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="md" style={{ zIndex: '1051' }}>
                        <Formik
                            initialValues={{
                                title: currentData ? currentData.form_title : "",
                                slug: currentData ? currentData.url_title : "",
                                ds_id: currentData ? currentData.ds_id : "",
                                user_id: currentData ? currentData.user_id : "",
                                banner_img: currentData ? currentData.banner_img : "",
                                form_id: currentData ? currentData.reg_form_id : "",
                                mode: currentData ? currentData.mode : "",
                                pipeline_id: currentData ? currentData.pipeline_id : "",
                                chat_instance_id: currentData ? currentData.chat_instance_id : "",
                                whatsapp_rsvp_message: currentData ? currentData.whatsapp_rsvp_message : "",
                                whatsapp_qr_name: currentData ? currentData.whatsapp_qr_name : "",
                                whatsapp_survey_message: currentData ? currentData.whatsapp_survey_message : "",
                                associated_survey: currentData ? currentData.associated_survey : "",
                                consent_needed: currentData ? currentData.consent_needed : "",
                                consent_1_type: currentData ? currentData.consent_1_type : "",
                                consent_1_text: currentData ? currentData.consent_1_text : "",
                                published: currentData ? currentData.published : "",
                                event_date: currentData ? currentData.event_date : "",
                                event_time: currentData ? currentData.event_time : "",
                                event_location: currentData ? currentData.event_location : "",
                                event_create_lead: currentData ? currentData.event_create_lead : "",
                                event_user_tag: currentData ? currentData.event_user_tag : "",
                                event_lead_assigned_source_token: currentData ? currentData.event_lead_assigned_source_token : "",
                                description: currentData ? currentData.form_description : "",
                                banner_img_upload: bannerImg ? [{ source: bannerImg }] : ""
                            }}
                            validationSchema={validationSchemaEdit}
                            onSubmit={handleUpdate}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t("SurveySetting_edit_survey")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_title")}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                isInvalid={touched.title && !!errors.title}
                                                placeholder={t("SurveySetting_form_title")}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_slug")}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="slug"
                                                value={values.slug}
                                                onChange={handleChange}
                                                isInvalid={touched.slug && !!errors.slug}
                                                placeholder={t("SurveySetting_slug")}
                                                onKeyPress={(e) => {
                                                    if (e.key === ' ') {
                                                        e.preventDefault(); // Prevent space from being entered
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.slug}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {t("SurveySetting_form_description")}
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                placeholder={t("SurveySetting_form_description")}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Card className="border-0 shadow-sm w-100">
                                                <Card.Header className="bg-light">
                                                    <div className="d-flex justify-content-between p-2">
                                                        <Card.Title as={"h8"} className="m-2">
                                                            {t("SurveySetting_survey_custom_field")}
                                                        </Card.Title>
                                                        <Button variant="success" onClick={() => handleAddCFList(currentData)}>
                                                            {t("SurveySetting_add_custom_field")}
                                                        </Button>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body style={{ height: '300px', overflowY: 'auto' }}>
                                                    <ReactSortable
                                                        list={dynamicFields}
                                                        setList={setDynamicFields}
                                                        animation={200}
                                                    >
                                                        {dynamicFields.map((field, index) => (
                                                            <Form.Group className="mb-3 d-flex" key={index}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`dynamicField_${index}`}
                                                                    value={field.cf_label}
                                                                    onChange={handleChange}
                                                                    disabled
                                                                />
                                                                <div className="d-flex m-auto">
                                                                    <Button variant="light" onClick={() => handleEditCFOpen(field)}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{t("SurveySetting_edit")}</Tooltip>}
                                                                        >
                                                                            <FontAwesomeIcon className="fontAwesomeIcon mx-auto" size="xl" icon={faPencil} />
                                                                        </OverlayTrigger>
                                                                    </Button>
                                                                    <Button variant="light" onClick={() => handleDeleteCF(field)}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{t("SurveySetting_delete")}</Tooltip>}
                                                                        >
                                                                            <FontAwesomeIcon className="fontAwesomeIcon mx-auto" size="xl" icon={faTrash} />
                                                                        </OverlayTrigger>
                                                                    </Button>
                                                                </div>
                                                            </Form.Group>
                                                        ))}
                                                    </ReactSortable>
                                                </Card.Body>
                                            </Card>
                                        </Form.Group>

                                        <Form.Group>
                                            {errors.banner_img && touched.banner_img && (
                                                <div className="op-error-message">{errors.banner_img}</div>
                                            )}
                                            <FilePond
                                                allowMultiple={false}
                                                name={`banner_img`}
                                                maxFiles={1}
                                                files={values.banner_img_upload}
                                                credits={false}
                                                onremovefile={() => {
                                                    setFieldValue("banner_img", "");
                                                    setFieldValue("banner_img_upload", []);
                                                }}
                                                onupdatefiles={(fileItems) => {
                                                    setFieldValue(
                                                        "banner_img_upload",
                                                        fileItems.map((fileItem) => fileItem.file)
                                                    );
                                                }}
                                                instantUpload={false}
                                                server={{
                                                    process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                        const formData = new FormData();
                                                        const reader = new FileReader();

                                                        const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

                                                        formData.append("task", "UploadImage");
                                                        formData.append("banner_img", `${Date.now()}_${Math.random()}`);

                                                        reader.onload = (e) => {
                                                            formData.append("data", e.target.result);
                                                            axios.post(`${session.hostUrl}/${session.hostUrlType}/php/form_image_settings.php`, formData).then((response) => {
                                                                const data = response.data;
                                                                if (Number(data.status) === 0) {
                                                                    load(data);
                                                                    setFieldValue("banner_img", data.file_name);
                                                                }
                                                            });
                                                        };
                                                        reader.readAsDataURL(file);
                                                    },
                                                }}
                                                isInvalid={touched.banner_img && !!errors.banner_img}
                                            />
                                        </Form.Group>

                                        <div className="mb-3">
                                            <div className="mb-2">{t("SurveySetting_publish")}</div>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="published"
                                                type="radio"
                                                value="Yes"
                                                checked={values.published === "Yes"}
                                                onChange={() => setFieldValue("published", "Yes")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="published"
                                                type="radio"
                                                value="No"
                                                checked={values.published === "No"}
                                                onChange={() => setFieldValue("published", "No")}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" type="submit">
                                            {t("SurveySetting_update")}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showSubmissionModal} onHide={handleSubmissionCloseModal} size="xl">
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between w-100">
                                <Modal.Title>{t("SurveySetting_submission")}</Modal.Title>
                                <Button variant="secondary" onClick={handleDownloadSubmission}>
                                    {t("SurveySetting_download")}
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {renderPieCharts()}
                            </div>

                            <div style={{ width: "100%", height: "80vh" }}>
                                <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumnSubmission} rowData={submissionData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </Container>
    );
}

export default Survey;
