import { useState, useEffect, useRef, forwardRef } from "react";
import { useAuth } from "../auth/AuthContext";
import { Container, Form, Stack, InputGroup, Dropdown, CloseButton, Card, OverlayTrigger, Tooltip, Modal, Spinner, ButtonGroup, Button, Image } from "react-bootstrap";
import Loader from "../includes/Loader";
import "../lib/scss/conversation.scss";
import { FormSelect } from "../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMagnifyingGlass, faRotateRight } from "@fortawesome/pro-light-svg-icons";
import { faCircleExclamation, faPaperPlaneTop, faBullhorn, faPaperclip, faXmark, faBarsFilter, faChevronDown, faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faEnvelope, faFile, faPeopleArrows, faPhone, faUser, faUserLock, faUserPlus, faUserTie, faUserUnlock, faImage, faLink } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import moment from "moment";
import NoRecord from "../includes/NoRecord";
import Swal from "sweetalert2/dist/sweetalert2";
// import { MapContainer, Marker, TileLayer } from "react-leaflet";
// import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FilePond } from "react-filepond";
import { io } from "socket.io-client";
import parse from "html-react-parser";
import { GoogleMap, Marker } from "@react-google-maps/api";

const Conversation = () => {
  const { session } = useAuth();
  const { chat_instance_id } = useParams();
  const init = useRef(false);
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [instanceList, setInstanceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [instanceData, setInstanceData] = useState({});
  const [prospectSearchData, setProspectSearchData] = useState("");
  const [prospectSearchData2, setProspectSearchData2] = useState({});
  const [prospectOwnerData, setProspectOwnerData] = useState("");
  const [prospectList, setProspectList] = useState([]);
  const [prospectData, setProspectData] = useState({});
  const [isProspect, setIsProspect] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isProspectFilter, setIsProspectFilter] = useState(0);
  const [messagesData, setMessagesData] = useState({});
  const [messagesList, setMessagesList] = useState([]);
  const [isSendFile, setIsSendFile] = useState(false);
  const [isSendFileLoading, setIsSendFileLoading] = useState(false);
  const [messageAction, setMessageAction] = useState({
    file: false,
    broadcast: false,
    input: false,
    inputPlaceholder: "Write a message",
    send: false,
  });
  const [blueprintAction, setBlueprintAction] = useState({
    addDealShow: true,
    addDealDisabled: false,
    qualifyLeadShow: true,
    qualifyLeadDisabled: false,
    dropLeadShow: true,
    dropLeadDisabled: false,
    transferAdminShow: true,
    transferAdminDisabled: false,
    transferRAShow: true,
    transferRADisabled: false,
  });
  const [isBroadcast, setIsBroadcast] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [isLoadingBroadCastUpload, setIsLoadingBroadCastUpload] = useState(false);
  const [inputData, setInputData] = useState("");
  const [lastConversationId, setLastConversationId] = useState([]);
  const [isAddDeal, setIsAddDeal] = useState(false);
  const [addDealUser, setAddDealUser] = useState([]);
  const [isQualifyLead, setIsQualifyLead] = useState(false);
  const [lostReasonList, setLostReasonList] = useState([]);
  const [isDropLead, setIsDropLead] = useState(false);

  const sendFileSchema = yup.object().shape({
    file: yup.array().min(1, "File is required"),
  });

  const addDealSchema = yup.object().shape({
    name: yup.string().required("Prospect name is required"),
    contact_num: yup.string().required("Phone number is required"),
    user_id: yup.string().required("Owner is required"),
  });

  const dropLeadSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
  });

  // GET FUNCTION ----------------------------------------------------------------

  // const getChatInstance = async () => {
  //   try {
  //     const response = await axios.get("ws/ws_ai_campaign.php", {
  //       params: {
  //         task: "getAudienceOptions",
  //         type: "chat_instance",
  //         utoken: session.user_ac_token,
  //         ctoken: session.company_token,
  //         company_id: session.company_id,
  //       },
  //     });

  //     const data = response.data;
  //     if (data.status === 0) {

  //       var session = null;
  //       const sessionRecord = sessionStorage.getItem("op-session_conversation");
  //       if (sessionRecord) {
  //         session = JSON.parse(sessionRecord);
  //         console.log(session)
  //         setProspectSearchData(session.user_contact);
  //       }

  //       const recordOptions = data.record.map((record) => ({
  //         label: record.instance_title + " - " + record.user_number,
  //         value: record.chat_instance_id,
  //         instance_id: record.instance_id,
  //         chat_contact_id: record.chat_contact_id,
  //         api_key: record["360D_api_key"],
  //         admin_transfer_button: Number(record.admin_transfer_button),
  //         cs_id: Number(record.cs_id),
  //         ds_token: record.ds_token,
  //       }));

  //       setInstanceData(session ? recordOptions.find((record) => record.value === session?.chat_instance_id) : chat_instance_id ? recordOptions.find((record) => record.value === chat_instance_id) : recordOptions[0]);
  //       setInstanceList(recordOptions);
  //       await getProspect("", "", recordOptions[0]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const getChatInstance = async () => {
    try {
      const { data } = await axios.get("ws/ws_ai_campaign.php", {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: session.company_id,
        },
      });

      if (data.status === 0) {
        let sessionData = null;
        var prospectSearch = "";
        const sessionRecord = sessionStorage.getItem("op-session_conversation");
        const sessionRecord2 = sessionStorage.getItem("op-session_chatbot_conversation");

        if (sessionRecord) {
          sessionData = JSON.parse(sessionRecord);
          setProspectSearchData(sessionData.prospect_name);
          prospectSearch = sessionData.prospect_name;
        }

        const recordOptions = data.record.map((record) => ({
          label: `${record.instance_title} - ${record.user_number}`,
          value: record.chat_instance_id,
          instance_id: record.instance_id,
          chat_contact_id: record.chat_contact_id,
          api_key: record["360D_api_key"],
          admin_transfer_button: Number(record.admin_transfer_button),
          cs_id: Number(record.cs_id),
          ds_token: record.ds_token,
        }));

        const selectedInstance = sessionData ? recordOptions.find((record) => record.value === sessionData?.chat_instance_id) : chat_instance_id ? recordOptions.find((record) => record.value === chat_instance_id) : recordOptions[0];

        setInstanceData(selectedInstance);
        setInstanceList(recordOptions);
        await getProspect(prospectSearch, "", selectedInstance || recordOptions[0], sessionRecord2);
      }
    } catch (error) {
      console.error("Failed to fetch chat instance data:", error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        const recordOptions2 = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        recordOptions2.unshift({
          label: "Select a sales rep",
          value: "",
        });

        setAddDealUser(recordOptions2);
        setUserList(recordOptions);
      }
    } catch (error) { }
  };

  const getProspect = async (searchStr, owner, instance, sessionRecord2) => {
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          searchStr: searchStr ? searchStr : "",
          startRow: 0,
          chat_instance_id: instance.value,
          instance_id: instance.instance_id,
          filter_user: owner ? owner : "",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record, index) => {
          record["index"] = index;
        });

        setProspectList(data.record);
        getMessages(data.record[0], 0, data.record, sessionRecord2);
        if (data.totalRecord > data.record.length) {
          setIsLoadMore(true);
        } else {
          setIsLoadMore(false);
        }
      } else {
        setProspectList([]);
        setProspectData({});
        setIsLoadMore(false);
        // getMessages({}, 0);
      }
    } catch {
      setProspectList([]);
      setProspectData({});
      setIsLoadMore(false);
      getMessages({}, 0);
    }
  };

  const getMoreProspect = () => {
    var startRow = prospectList.length + 1;
    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "getConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          searchStr: prospectSearchData ? prospectSearchData : "",
          startRow: startRow,
          chat_instance_id: instanceData.value,
          instance_id: instanceData.instance_id,
          filter_user: prospectOwnerData ? prospectOwnerData : "",
        },
      })
      .then((response) => {
        const data = response.data;

        if (data.status === 0) {
          setProspectList((prevState) => [...prevState, ...data.record]);
          if (prospectList.length + data.record.length + 1 >= data.totalRecord) {
            setIsLoadMore(false);
          }
        }
      });
  };

  const getMessages = (record, index, prospect, sessionRecord2) => {
    setIsProspect(index);
    setProspectData(record);

    let sessionData = null;

    if (sessionRecord2) {
      sessionData = JSON.parse(sessionRecord2);
      setProspectSearchData2(sessionData.prospect_name);
    }

    var chatId = record.chat_id;
    chatId = chatId.substring(1);
    chatId = chatId.replace("@c.us", "");

    if (init.current && prospect) {
      prospect[index].unread = 0;
      prospect[index].require_attention = 0;
      setProspectList(prospect);
    }

    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "getConversationMessagesV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: sessionData?.chat_instance_id || chatId,
          prospect_id: sessionData?.prospect_id || record.prospect_id,
          startRow: 0,
          chat_contact_id: sessionData?.chat_contact_id || record.chat_contact_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setMessagesData(data);

        if (data.status === 0) {
          let message = {};
          data.record = data.record.slice().reverse();
          data.record.forEach((input) => {
            var newDate = new Date(input.date_time_create);
            var momentDate = moment(newDate).format("YYYY-MM-DD");
            input.date = momentDate;

            if (message[input.date]) {
              message[input.date].push(input);
            } else {
              message[input.date] = [input];
            }
          });

          setMessagesList(message);
          setLastConversationId((prevIds) => [...prevIds, data.record[data.record.length - 1].chat_conversation_id]);
          onchangeMessageAction(data.record);
        } else {
          setMessagesList({});
          onchangeMessageAction([]);
        }

        const status = {
          addDealShow: true,
          addDealDisabled: false,
          qualifyLeadShow: true,
          qualifyLeadDisabled: false,
          dropLeadShow: true,
          dropLeadDisabled: false,
          transferAdminShow: true,
          transferAdminDisabled: false,
          transferRAShow: true,
          transferRADisabled: false,
          unlock_conversation: false,
        };

        if (data.blueprintStatus === 0) {
          if (data.blueprintRecord.status === "responded") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "qualified") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "dropped") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = instanceData.admin_transfer_button === 1 ? true : false;
            status.transferAdminDisabled = false;
            status.transferRAShow = true;
            status.transferRADisabled = false;
          } else if (data.blueprintRecord.status === "ended_transfered_admin") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended_transfered_salesrep") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "ended_qualified") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = true;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "active") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = false;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else if (data.blueprintRecord.status === "invalid_num") {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = true;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = true;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          } else {
            status.addDealShow = false;
            status.addDealDisabled = true;
            status.qualifyLeadShow = false;
            status.qualifyLeadDisabled = false;
            status.dropLeadShow = false;
            status.dropLeadDisabled = true;
            status.transferAdminShow = false;
            status.transferAdminDisabled = true;
            status.transferRAShow = false;
            status.transferRADisabled = true;
          }
        } else {
          status.addDealShow = true;
          status.addDealDisabled = false;
          status.qualifyLeadShow = false;
          status.qualifyLeadDisabled = true;
          status.dropLeadShow = false;
          status.dropLeadDisabled = true;
          status.transferAdminShow = false;
          status.transferAdminDisabled = true;
          status.transferRAShow = false;
          status.transferRADisabled = true;
        }

        setBlueprintAction(status);
      });

    sessionStorage.removeItem("conversationParams");
    sessionStorage.removeItem("op-session_chatbot_conversation");
  };

  const getRefreshMessages = (message) => {
    axios
      .get(`ws/ws_conversation.php`, {
        params: {
          task: "getWAMediaFile",
          company_id: session.company_id,
          chat_instance_id: instanceData.instance_id,
          media_id: message.media_id,
          conversation_id: message.chat_conversation_id,
          mime_type: message.media_mime_type,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getMessages(prospectData, isProspect, prospectList);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Cannot refresh it, Please try again!",
          });
        }
      });
  };

  const getTemplate = async () => {
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getWATemplateListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          record.label = record.whatsapp_template_name;
          record.value = record.whatsapp_template_name;
        });

        setTemplateList(data.record);
      } else {
        setTemplateList([]);
      }
    } catch {
      setTemplateList([]);
    }
  };

  const getLiveContact = async () => {
    let latestConversationId = setMax(lastConversationId);
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getLatestConversationInstances",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_conversation_id: latestConversationId,
          chat_contact_id: prospectData.chat_contact_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record) => {
          if (Number(record.chat_contact_id) === Number(prospectData.chat_contact_id)) {
            console.log("triggered", record, prospectData);
            getIncomingMessage();
          } else {
            console.log("not triggered", record, prospectData);
          }

          const contactIndex = prospectList.findIndex((val) => val.chat_contact_id === record.chat_contact_id);
          if (contactIndex !== -1) {
            const updatedProspectList = [...prospectList];
            updatedProspectList[contactIndex] = {
              ...updatedProspectList[contactIndex],
              last_update: record.last_update,
              last_message: record.last_message,
            };

            const [updatedContact] = updatedProspectList.splice(contactIndex, 1);
            setProspectList([updatedContact, ...updatedProspectList]);
          }
        });

        setProspectList((prevList) => prevList.map((record, index) => ({ ...record, index })));
      } else {
        getIncomingMessage(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIncomingMessage = async (prospectUpdate = false) => {
    console.log(prospectUpdate ? "auto" : "no auto", prospectData);
    try {
      const chat_id = prospectData.chat_id.substring(1).replace("@c.us", "");
      const lastDate = messagesData.record[messagesData.record.length - 1].date || "0000-00-00";

      const today = moment(new Date()).format("YYYY-MM-DD");
      const timeLastDate = new Date(lastDate);
      const timeTodayDate = new Date(today);
      var latestChatConversationID = null;

      if (timeLastDate.getTime() !== timeTodayDate.getTime()) {
        const lastIndex = messagesList[lastDate].length - 1;
        latestChatConversationID = messagesList[lastDate][lastIndex].chat_conversation_id;
        setLastConversationId((prevIds) => [...prevIds, latestChatConversationID]);
      } else {
        const todayIndex = messagesList[today].length - 1;
        if (todayIndex === -1 || !messagesList[today]) {
          setMessagesList((prev) => ({ ...prev, [today]: [] }));
        }
        latestChatConversationID = messagesList[today][todayIndex].chat_conversation_id;
        setLastConversationId((prevIds) => [...prevIds, latestChatConversationID]);
      }

      var latestChatConversationId = setMax(lastConversationId);
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "getConversationMessagesV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: chat_id,
          startRow: 0,
          chat_conversation_id: latestChatConversationId,
          chat_contact_id: prospectData.chat_contact_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record = data.record.slice().reverse();
        const newMessages = data.record.filter((record) => !messagesData.record.some((currentMessage) => currentMessage.chat_conversation_id === record.chat_conversation_id));
        if (newMessages.length > 0) {
          const updatedMessagesList = { ...messagesList };
          newMessages.forEach((message) => {
            var newDate = new Date(message.date_time_create);
            var momentDate = moment(newDate).format("YYYY-MM-DD");
            message.date = momentDate;
            if (updatedMessagesList[message.date]) {
              updatedMessagesList[message.date].push(message);
            } else {
              updatedMessagesList[message.date] = [message];
            }
          });
          setMessagesList(updatedMessagesList);
        }

        if (prospectUpdate) {
          const findContact = prospectList.find((val) => val.chat_contact_id === prospectData.chat_contact_id);
          const updatedProspect = [...prospectList];
          updatedProspect[findContact.index].last_update = data.record[0].date_time_create;
          updatedProspect[findContact.index].last_message = data.record[0].message_body;
          updatedProspect[findContact.index].unread = 1;
          updatedProspect[findContact.index].require_attention = 0;

          var findIndex = prospectList.findIndex((el) => el.chat_contact_id === prospectData.chat_contact_id);
          if (findIndex !== -1) {
            const updatedProspectList = [findContact, ...prospectList.slice(0, findIndex), ...prospectList.slice(findIndex + 1)];
            setProspectList(updatedProspectList);
          }

          const updateProspectIndex = [...prospectList];
          updateProspectIndex.forEach((record, index) => {
            record.index = index;
          });
          setProspectList(updateProspectIndex);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLostReason = async () => {
    const response = await axios.get("ws/ws_deal_close.php", {
      params: {
        task: "getLostReason",
        utoken: session.user_ac_token,
        ctoken: session.company_token,
      },
    });

    const data = response.data;
    if (data.status === 0) {
      const recordOptions = data.record.map((record) => ({
        label: record.reason_title,
        value: record.lost_reason_id,
      }));

      recordOptions.unshift({
        label: "Select a lost reason",
        value: "",
      });
      setLostReasonList(recordOptions);
    }
  };

  // ONCHANGE FUNCTION -----------------------------------------------------------

  const onchangeMessageAction = (record) => {
    let status = {
      file: false,
      broadcast: false,
      input: false,
      inputPlaceholder: "Write a message",
      send: false,
    };

    if (record.length) {
      let currLockStatus = Number(record[0].unlock_conversation);
      let currOutside24Hours = Number(record[0].outside_24h);

      if (currLockStatus === 0) {
        if (currOutside24Hours === 1) {
          status.input = true;
          status.inputPlaceholder = "Its outside 24 hours. The conversation has been closed.";
          status.file = true;
          status.broadcast = false;
          status.send = true;
        } else {
          status.input = true;
          status.inputPlaceholder = "The conversation has been closed.";
          status.file = true;
          status.broadcast = true;
          status.send = true;
        }
      } else {
        if (currOutside24Hours === 1) {
          status.input = true;
          status.inputPlaceholder = "Its outside 24 hours. The conversation has been closed.";
          status.file = true;
          status.broadcast = false;
          status.send = true;
        } else {
          status.input = false;
          status.inputPlaceholder = "Write a message";
          status.file = false;
          status.broadcast = false;
          status.send = false;
        }
      }
    }

    setMessageAction(status);
  };

  // SEND FUNCTION ---------------------------------------------------------------

  const sendFile = async (values) => {
    setIsSendFileLoading(true);

    const regex = /[^\\]*\.(\w+)$/;
    const filenameMatch = values.file[0].name.match(regex);

    if (!filenameMatch) {
      setIsSendFileLoading(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
      return;
    }

    let filename = filenameMatch[0].replace(/ /g, "_");
    const fileToUpload = await setBase64(values.file[0]);

    const formData = new FormData();
    const ctrl = new AbortController();

    filename = filename[0].replace(/ /g, "_");
    formData.append("task", "getMediaURL");
    formData.append("fileToUpload", fileToUpload);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("mime_type", values.file[0].type);
    formData.append("file_name", filename);

    setTimeout(() => ctrl.abort(), 20000);

    try {
      const response = await axios.post("ws/ws_conversation.php", formData, {
        signal: ctrl.signal,
      });

      const data = response.data;
      sendMedia(data.media_url, values.file[0].type, values.file[0].name);
    } catch {
      setIsSendFileLoading(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
    }
  };

  const sendMedia = (link, mime, filename) => {
    var mimeType = mime.split("/");
    if (mimeType[0] === "application") {
      mime = "document/" + mimeType[1];
    }

    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "sendFileV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          mime_type: mime,
          media_url: link,
          media_op_file_name: filename,
          contact_num: prospectData.contact_num,
          prospect_id: prospectData.prospect_id,
          chat_instance_id: instanceData.chat_instance_id,
          chat_contact_id: instanceData.chat_contact_id,
        },
      })
      .then((response) => {
        const data = response.data;

        if (Number(data.status) === 200) {
          getMessages(prospectData, isProspect, prospectList);
          const updatedProspect = [...prospectList];
          updatedProspect[isProspect].last_message = link;
          updatedProspect[isProspect].last_update = new Date();
          setProspectList(updatedProspect);
        }
      });
  };

  const sendBroadcastImage = async (file) => {
    setIsLoadingBroadCastUpload(true);

    if (!file.length) {
      setIsLoadingBroadCastUpload(false);
      return false;
    }

    const regex = /[^\\]*\.(\w+)$/;
    const filenameMatch = file[0].name.match(regex);

    if (!filenameMatch) {
      setIsLoadingBroadCastUpload(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });
      return false;
    }

    let filename = filenameMatch[0].replace(/ /g, "_");
    const fileToUpload = await setBase64(file[0]);

    const formData = new FormData();
    const ctrl = new AbortController();

    filename = filename[0].replace(/ /g, "_");
    formData.append("task", "getMediaURL");
    formData.append("fileToUpload", fileToUpload);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("mime_type", file[0].type);
    formData.append("file_name", filename);

    setTimeout(() => ctrl.abort(), 20000);

    try {
      const response = await axios.post("ws/ws_conversation.php", formData, {
        signal: ctrl.signal,
      });

      const data = response.data;
      return data.media_url;
    } catch {
      setIsLoadingBroadCastUpload(false);
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: "Something wrong with your entry, please try again",
      });

      return false;
    }
  };

  const sendBroadcast = (values) => {
    var message = values.whatsapp_template.message;
    if (values.whatsapp_params_input.length) {
      for (var i = 0; i < values.whatsapp_params_input.length; i++) {
        message = values.whatsapp_template.message.replace("<" + (i + 1) + ">", values.whatsapp_params_input[i]);
        message = values.whatsapp_template.message.replace("{{" + (i + 1) + "}}", values.whatsapp_params_input[i]);
      }
    }

    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "sendWATemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_instance_id: instanceData.value,
          chat_contact_id: instanceData.chat_contact_id,
          whatsapp_template_name: values.whatsapp_template.whatsapp_template_name,
          whatsapp_template_params: values.params.toString(),
          whatsapp_template_header_image: values.whatsapp_image ? values.whatsapp_image : values.whatsapp_link,
          media_op_file_name: "",
          media_mime_type: "",
          api_token: instanceData.api_key,
          msg: message,
          contact_num: "6" + prospectData.contact_num,
          prospect_id: prospectData.prospect_id || "",
        },
      })
      .then((response) => {
        getMessages(prospectData, isProspect, prospectList);
      });
  };

  const sendMessage = () => {
    if (inputData) {
      axios
        .get("ws/ws_conversation.php", {
          params: {
            task: "sendMessage",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            contact_num: prospectData.contact_num,
            message: inputData.toString().replace(/'/g, "\\'"),
            chat_instance_id: instanceData.value,
            instance_id: instanceData.instance_id,
            chat_contact_id: prospectData.chat_contact_id,
            prospect_id: prospectData.prospect_id || "",
          },
        })
        .then((response) => {
          const data = response.data;
          if (Number(data.status) === 200) {
            getMessages(prospectData, isProspect, prospectList);
            const updatedProspect = [...prospectList];
            updatedProspect[isProspect].last_message = inputData;
            updatedProspect[isProspect].last_update = new Date();
            setProspectList(updatedProspect);
            setLastConversationId((prevIds) => [...prevIds, data.chat_conversation_id]);
            setInputData("");
          }
        });
    }
  };

  const setMax = (input) => {
    if (toString.call(input) !== "[object Array]") return false;
    return Math.max.apply(null, input);
  };

  const sendOpenContact = () => {
    if (!prospectData.prospect_id) {
      Swal.fire({
        icon: "warning",
        title: "Opps...",
        text: "Sorry, this contact is not a prospect in NexCRM. Please add a new deal to make this contact as a prospect in NexCRM",
      });
    } else {
      if (messagesData.blueprintStatus === 0) {
        window.open(`${session.origin}/deal/${messagesData.blueprintRecord.deal_id}`);
      } else {
        window.open(`${session.origin}/prospect/${prospectData.prospect_id}`);
      }
    }
  };

  const sendCreateDeal = async (values) => {
    var csid = instanceData.cs_id > 0 ? instanceData.cs_id : "";

    try {
      const response = await axios.get("toolbox/api_addNewDeal.php", {
        params: {
          task: "addNewDeal",
          source_token: instanceData.ds_token,
          cs_id: csid,
          secret_key: session.company_token,
          outcome: "",
          name: values.name,
          contact: values.contact_num,
          email: values.email,
          content: values.notes,
          owner_id: values.user_id,
          inbound_deal: 1,
          send_notification: 0,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await sendAssociateConversationInstance(data);
      } else {
        setIsAddDeal(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendAssociateConversationInstance = async (record) => {
    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "associateConversationInstance",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: prospectData.chat_id,
          prospect_id: record.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getProspect(prospectSearchData, prospectOwnerData, instanceList);
        setIsAddDeal(false);
        Swal.fire({
          icon: "success",
          text: "Successfully create a new contact",
          timer: 1500,
        });
      } else {
        setIsAddDeal(false);
        Swal.fire({
          icon: "error",
          title: "Opps...",
          text: "Something wrong with your entry, please try again",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendQualifyLead = (values) => {
    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "qualifyBlueprintConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          prospect_id: prospectData.prospect_id,
          blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
          deal_id: messagesData.blueprintRecord.deal_id || 0,
          remark: values.remark,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getMessages(prospectData, isProspect, prospectList);

          setBlueprintAction((prevState) => ({
            ...prevState,
            qualifyLeadDisabled: true,
            dropLeadDisabled: true,
          }));

          Swal.fire({
            icon: "success",
            title: "Yeayyyy!!",
            text: "The lead has been qualified.",
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Opps...",
            text: "Something wrong to qualify the lead. Please try again.",
          });
        }
      });
  };

  const sendDropLead = (values) => {
    axios
      .get("ws/ws_conversation.php", {
        params: {
          task: "dropBlueprintConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          prospect_id: prospectData.prospect_id || 0,
          blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
          deal_id: messagesData.blueprintRecord.deal_id || 0,
          reasonid: values.reason,
          remark: values.remark,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getMessages(prospectData, isProspect, prospectList);

          setBlueprintAction((prevState) => ({
            ...prevState,
            qualifyLeadDisabled: false,
            dropLeadDisabled: true,
          }));

          Swal.fire({
            icon: "success",
            text: "The lead has been dropped.",
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Opps...",
            text: "Something wrong to drop the lead. Please try again.",
          });
        }
      });
  };

  const sendLeadAdmin = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Would you like to transfer the lead to the admin?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#eee",
      confirmButtonText: "Transfer",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_conversation.php", {
            params: {
              task: "transferBlueprintToAdmin",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              prospect_id: prospectData.prospect_id || 0,
              blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
              deal_id: messagesData.blueprintRecord.deal_id || 0,
            },
          })
          .then((response) => {
            const data = response.data;
            if (data.status === 0) {
              getMessages(prospectData, isProspect, prospectList);
              Swal.fire({
                icon: "success",
                text: "The lead has been transferred to the admin.",
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Opps...",
                text: "The lead has not transferred to the admin. Please try again.",
              });
            }
          });
      }
    });
  };

  const sendLeadRA = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Would you like to transfer the lead to the Sales rep?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#eee",
      confirmButtonText: "Transfer",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_conversation.php", {
            params: {
              task: "transferBlueprintToSalesrep",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              prospect_id: prospectData.prospect_id || 0,
              blueprint_deal_id: messagesData.blueprintRecord.blueprint_deal_id || 0,
              deal_id: messagesData.blueprintRecord.deal_id || 0,
            },
          })
          .then((response) => {
            const data = response.data;
            if (data.status === 0) {
              getMessages(prospectData, isProspect, prospectList);
              Swal.fire({
                icon: "success",
                text: "The lead has been transferred to the sales rep.",
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Opps...",
                text: "The lead has not transferred to the sales rep. Please try again.",
              });
            }
          });
      }
    });
  };

  const sendUnlockConversation = async () => {
    let status = messageAction.input ? 1 : 0;

    try {
      const response = await axios.get("ws/ws_conversation.php", {
        params: {
          task: "unlockConversation",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chat_id: prospectData.chat_id,
          mode: status,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getMessages(prospectData, isProspect, prospectList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------------------------------------------

  const setInitial = (input) => {
    var str = "";
    if (input) {
      if (/\s/.test(input)) {
        str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() + input.split(" ")[1].charAt(0).toUpperCase() : input;
      } else {
        str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() + input.split(" ")[0].charAt(1).toUpperCase() : input;
      }
    }
    return str;
  };

  const setShortMessage = (input) => {
    var str = "";
    var maxlength = 34;

    if (input.length >= maxlength) {
      str = input.substring(0, 31) + "...";
    }

    return str;
  };

  const setTimeStamp = (input) => {
    var today = new Date();
    var oriDate = new Date(input);
    var createdOn = new Date(input);
    var msInDay = 24 * 60 * 60 * 1000;

    createdOn.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    var diff = (+today - +createdOn) / msInDay;
    var str = "";

    if (diff === 0) {
      str = moment(oriDate).format("LT");
    } else if (diff === 1) {
      str = "Yesterday";
    } else if (diff < 6) {
      str = diff + " days ago";
    } else {
      str = moment(oriDate).format("ll");
    }
    return str;
  };

  // const setFormatMessages = ({ message_type, message_body, media_url, location_lat, location_long, contact_info }) => {
  //   const renderImage = (imageUrl) => (
  //     <div
  //       style={{
  //         width: "100%",
  //         height: 0,
  //         backgroundImage: `url(${imageUrl})`,
  //         backgroundSize: "cover",
  //         backgroundPosition: "center",
  //         backgroundRepeat: "no-repeat",
  //         paddingBottom: "75%",
  //         margin: "10px 0",
  //         cursor: "pointer",
  //       }}
  //       data-toggle="modal"
  //       href="#modalLightbox"
  //       onClick={() => renderLightbox(imageUrl, "broadcast")}
  //     ></div>
  //   );

  //   const renderAudio = (url) => (
  //     <audio controls controlsList="nodownload noplaybackrate" style={{ width: 400 }}>
  //       <source src={url} type="audio/ogg" />
  //     </audio>
  //   );

  //   const renderFile = (url, filename) => (
  //     <a class="file" href={url} target="_blank" rel="noreferrer">
  //       <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
  //       <div class="fileName">{filename}</div>
  //     </a>
  //   );

  //   const renderLightbox = (imageUrl, type) => {};

  //   const processText = (input) => {
  //     // Regular expressions for different patterns
  //     const regLink = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  //     const regLink2 = /(^|[^\/])(www\.[\S]+(\b|$))/gi;
  //     const regLink3 = /(^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+\S*$)/gim;
  //     const regEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  //     const regBreakLine = /(?:\r\n|\r|\n)/g;
  //     const regImage = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(?:jpg|jpeg|png|gif)\b)/gi;
  //     const regBold = /\*\*(.*?)\*\*/g; // Matches **bold text**
  //     const regItalic = /\*(.*?)\*/g; // Matches *italic text*
  //     const regUnderline = /__(.*?)__/g; // Matches __underline text__
  //     const regStrikethrough = /~~(.*?)~~/g; // Matches ~~strikethrough text~~

  //     // Initial processing
  //     let formattedText = input.toString().replace(/\\'/g, "'").replace(regBreakLine, "<br>");

  //     // Handle image URLs separately
  //     let imgPlaceholders = [];
  //     formattedText = formattedText.replace(regImage, (match) => {
  //       const placeholder = `{{img_placeholder_${imgPlaceholders.length}}}`;
  //       imgPlaceholders.push({ placeholder, url: match });
  //       return placeholder;
  //     });

  //     // Replace patterns with formatted tags
  //     formattedText = formattedText
  //       .replace(regBold, "<strong>$1</strong>")
  //       .replace(regItalic, "<em>$1</em>")
  //       .replace(regUnderline, "<u>$1</u>")
  //       .replace(regStrikethrough, "<del>$1</del>")
  //       .replace(regEmail, '<a href="mailto:$1" target="_blank">$1</a>')
  //       .replace(regLink, '<a href="$1" target="_blank">$1</a>')
  //       .replace(regLink2, '$1<a href="https://$2" target="_blank">$2</a>')
  //       .replace(regLink3, '<a href="https://www.$1" target="_blank">$1</a>');

  //     // Replace image placeholders with actual image tags
  //     imgPlaceholders.forEach(({ placeholder, url }) => {
  //       formattedText = formattedText.replace(placeholder, `<a href="${url}" target="_blank"><img src="${url}" alt="Image" style="width:150px; height:auto;" /></a>`);
  //     });

  //     return formattedText;
  //   };

  //   const processAudio = (text) => {
  //     let regAudio = /(https?:\/\/\S+(\.ogg|\.OGG|\.opus|\.OPUS))/g;
  //     let processedText = text.toString().replace(/\\'/g, "'");
  //     let parts = processedText.split(/(\s+)/);

  //     if (regAudio.test(parts)) {
  //       return renderAudio(parts);
  //     }

  //     return parts;
  //   };

  //   const processImage = (text) => {
  //     let regImage = /(https?:\/\/\S+(\.jpeg|\.png|\.jpg|\.gif))/g;
  //     let processedText = text.toString().replace(/\\'/g, "'");
  //     let parts = processedText.split(/(\s+)/);

  //     if (regImage.test(parts)) {
  //       return renderImage(parts);
  //     }

  //     return parts;
  //   };

  //   const processDocument = (text) => {
  //     let regFile = /(https?:\/\/\S+(\.docx|\.DOCX|\.doc|\.DOC|\.pdf|\.PDF|\.tiff|\.TIFF|\.tif|\.TIF|\.html|\.HTML|\.php|\.PHP|\.js|\.JS|\.xlsx|\.XLSX|\.xls|\.XLS|\.pptx|\.PPTX|\.ppt|\.PPT|\.zip|\.ZIP|\.csv|\.CSV|\.txt))/g;
  //     let regImage = /(https?:\/\/\S+(\.jpeg|\.png|\.jpg|\.gif))/g;
  //     let processedText = text.toString().replace(/\\'/g, "'");
  //     let parts = processedText.split(/(\s+)/);

  //     var partsarr = parts.map((part, index) => {
  //       if (regFile.test(part)) {
  //         let filename = part.split("/").pop().split("#")[0].split("?")[0];
  //         return <span key={index}>{renderFile(part, filename)}</span>;
  //       } else if (regImage.test(part)) {
  //         return <span key={index}>{renderImage(part)}</span>;
  //       }
  //       return <span key={index}>{part}</span>;
  //     });

  //     return partsarr;
  //   };

  //   // const processLocation = (lat, long) => {
  //   //   const position = [Number(lat), Number(long)];

  //   //   const markerIcon = divIcon({
  //   //     className: "my-custom-pin",
  //   //     html: renderToStaticMarkup(<FontAwesomeIcon icon={faLocationDot} size="3x" style={{ color: "var(--op-primary-color)" }} />),
  //   //     iconSize: [30, 48],
  //   //     iconAnchor: [15, 48],
  //   //   });

  //   //   const openGoogleMap = () => {
  //   //     window.open("https://www.google.com/maps?q=" + lat + "," + long, "_blank");
  //   //   };

  //   //   return (
  //   //     <div style={{ position: "relative", cursor: "pointer" }} onClick={openGoogleMap}>
  //   //       {/* <MapContainer style={{ height: 200, width: 300, position: "relative" }} center={position} zoom={15} dragging={false} doubleClickZoom={false} scrollWheelZoom={false} zoomControl={false}>
  //   //         <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
  //   //         <Marker position={position} icon={markerIcon} />
  //   //       </MapContainer> */}
  //   //     </div>
  //   //   );
  //   // };

  //   const processContact = (contact) => {
  //     if (contact) {
  //       return (
  //         <div class="file cursor-pointer">
  //           <FontAwesomeIcon icon={faUser} size="xl" className="me-2" />
  //           <div class="fileName">{contact[0].name.formatted_name}</div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div class="file cursor-pointer">
  //           <FontAwesomeIcon icon={faUser} size="xl" className="me-2" />
  //           <div class="fileName">No name</div>
  //         </div>
  //       );
  //     }
  //   };

  //   const renderFormattedMessage = () => {
  //     let formattedMessage = "";

  //     if (message_type === "text" || message_type === "chat") {
  //       formattedMessage = processText(message_body);
  //     } else if (message_type === "voice") {
  //       formattedMessage = processAudio(media_url);
  //     } else if (message_type === "image") {
  //       formattedMessage = processImage(media_url);
  //     } else if (message_type === "document") {
  //       formattedMessage = processDocument(media_url);
  //     } else if (message_type === "location") {
  //       // formattedMessage = processLocation(location_lat, location_long);
  //     } else if (message_type === "contacts") {
  //       formattedMessage = processContact(contact_info);
  //     } else {
  //       formattedMessage = processText(message_body);
  //     }

  //     console.log(formattedMessage);

  //     return formattedMessage;
  //   };

  //   return <div dangerouslySetInnerHTML={{ __html: renderFormattedMessage() }}></div>;
  // };

  const setFormatMessages = ({ message_type, message_body, media_url, location_lat, location_long, contact_info }) => {
    const renderLightbox = (imageUrl, type) => { };
    const renderImage = (imageUrl) => (
      <div
        style={{
          width: "100%",
          height: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: "75%",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => renderLightbox(imageUrl, "broadcast")}
      ></div>
    );

    const renderAudio = (url) => (
      <audio controls controlsList="nodownload noplaybackrate" style={{ width: 400 }}>
        <source src={url} type="audio/ogg" />
      </audio>
    );

    const renderFile = (url, filename) => (
      <a className="file" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        <div className="fileName">{filename}</div>
      </a>
    );

    const processText = (input) => {
      const regLink = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const regBreakLine = /(?:\r\n|\r|\n)/g;
      const regImage = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(?:jpg|jpeg|png|gif)\b)/gi;

      let formattedText = input.toString().replace(/\\'/g, "'").replace(regBreakLine, "<br>");

      // Handle images separately
      let imgPlaceholders = [];
      formattedText = formattedText.replace(regImage, (match) => {
        const placeholder = `{{img_placeholder_${imgPlaceholders.length}}}`;
        imgPlaceholders.push({ placeholder, url: match });
        return placeholder;
      });

      formattedText = formattedText.replace(regLink, '<a href="$1" target="_blank">$1</a>');

      imgPlaceholders.forEach(({ placeholder, url }) => {
        formattedText = formattedText.replace(placeholder, `<a href="${url}" target="_blank"><img src="${url}" alt="Image" style="width:150px; height:auto;" /></a>`);
      });

      return formattedText;
    };

    const processMedia = (text, type) => {
      const regFile = /(https?:\/\/\S+(\.docx|\.pdf|\.tiff|\.html|\.php|\.js|\.xlsx|\.pptx|\.zip|\.csv|\.txt))/g;
      const regImage = /(https?:\/\/\S+(\.jpeg|\.png|\.jpg|\.gif))/g;
      const regAudio = /(https?:\/\/\S+(\.ogg|\.opus))/g;

      let parts = text.toString().split(/\s+/);

      return parts.map((part, index) => {
        if (regFile.test(part)) {
          const filename = part.split("/").pop().split("#")[0].split("?")[0];
          return <span key={index}>{renderFile(part, filename)}</span>;
        } else if (regImage.test(part)) {
          return <span key={index}>{renderImage(part)}</span>;
        } else if (regAudio.test(part)) {
          return <span key={index}>{renderAudio(part)}</span>;
        }
        return <span key={index}>{part} </span>;
      });
    };

    const processContact = (contact) => {
      return (
        <div className="file cursor-pointer">
          <FontAwesomeIcon icon={faUser} size="xl" className="me-2" />
          <div className="fileName">{contact?.[0]?.name?.formatted_name || "No name"}</div>
        </div>
      );
    };

    const processLocation = (lat, long) => {
      const openGoogleMap = () => {
        window.open("https://www.google.com/maps?q=" + lat + "," + long, "_blank");
      };

      return (
        <div style={{ position: "relative", cursor: "pointer" }} onClick={openGoogleMap}>
          <GoogleMap center={{ lat: Number(lat), lng: Number(long) }} zoom={15} mapContainerStyle={{ width: "100%", height: "auto" }}>
            <Marker position={{ lat: Number(lat), lng: Number(long) }} />
          </GoogleMap>
        </div>
      );
    };

    const renderFormattedMessage = () => {
      if (message_type === "text" || message_type === "chat") {
        return <div dangerouslySetInnerHTML={{ __html: processText(message_body) }}></div>;
      } else if (message_type === "voice" || message_type === "audio") {
        return renderAudio(media_url);
      } else if (message_type === "image") {
        return renderImage(media_url);
      } else if (message_type === "document") {
        return <div>{processMedia(media_url, "document")}</div>;
      } else if (message_type === "location") {
        return <div>{processLocation(location_lat, location_long)}</div>
        //       // formattedMessage = processLocation(location_lat, location_long);
      } else if (message_type === "contacts") {
        return processContact(contact_info);
      } else {
        return <div dangerouslySetInnerHTML={{ __html: processText(message_body) }}></div>;
      }
    };

    return <div>{renderFormattedMessage()}</div>;
  };
  const setBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const setBlueprintStatus = (input) => {
    let str = "";

    if (input === "responded") {
      str = "Responded";
    } else if (input === "qualified") {
      str = "Qualified";
    } else if (input === "dropped") {
      str = "Dropped";
    } else if (input === "ended") {
      str = "Ended";
    } else if (input === "ended_transfered_admin") {
      str = "Transfered to SRM";
    } else if (input === "ended_transfered_salesrep") {
      str = "Transfered to SR";
    } else if (input === "ended_qualified") {
      str = "Tele Qualified";
    } else if (input === "active") {
      str = "In Progress";
    } else if (input === "invalid_num") {
      str = "Invalid Number";
    } else {
      str = input;
    }

    return str;
  };

  // KEYPRESS FUNCTION -----------------------------------------------------------

  const keypressSearchProspect = (event) => {
    if (event.key === "Enter") {
      setIsProspectFilter(Math.random());
    }
  };

  const keypressInput = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setInputData((prevInputData) => prevInputData + "\n");
    }
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderProspectFilter = ({ userOption, userInput }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const RenderToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
        handleShow();
      };

      return (
        <button ref={ref} className="btn op-button op-primary-color text-light ms-1" onClick={openDropdown}>
          <FontAwesomeIcon icon={faBarsFilter} size="lg" />
        </button>
      );
    });

    const RenderFilter = forwardRef(({ style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
          <Card>
            <Formik
              onSubmit={(values) => {
                setProspectOwnerData(values.user);
                setIsProspectFilter(Math.random());
                handleClose();
              }}
              initialValues={{
                user: userInput,
              }}
            >
              {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    Filter prospect
                    <Dropdown.Item as={CloseButton} onClick={handleClose} />
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Owner</Form.Label>
                      <FormSelect options={userOption} valueDefault={userInput ? userOption.find((record) => record.value === userInput) : null} placeholder="Select owner" onChange={(e) => handleChange("user")(e.value)} />
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-row-reverse">
                    <button type="submit" className="btn op-button op-primary-color text-light" style={{ zIndex: 0 }}>
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{ backgroundColor: "#eee" }}
                      onClick={() => {
                        setProspectOwnerData("");
                        setIsProspectFilter(Math.random());
                        handleClose();
                      }}
                    >
                      Clear
                    </button>
                  </Card.Footer>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      );
    });

    return (
      <Dropdown size={100} show={show}>
        <Dropdown.Toggle as={RenderToggle} />
        <Dropdown.Menu as={RenderFilter} />
      </Dropdown>
    );
  };

  const RenderMediaAction = forwardRef(({ onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <button ref={ref} type="button" className="btn op-button btn-circle btn-sm op-primary-color" onClick={openDropdown}>
        <FontAwesomeIcon icon={faPaperclip} size="lg" style={{ color: "#fff" }} />
      </button>
    );
  });

  // USEEFFECT FUNCTION ----------------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getChatInstance();
          await getUsers();
          await getTemplate();
          await getLostReason();

          setTimeout(() => {
            setLoading(false);
            init.current = true;
          }, 500);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      getProspect(prospectSearchData, prospectOwnerData, instanceData);
    }
  }, [isProspectFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=" + instanceData.instance_id,
      });

      socket.on("chat message", function (msg) {
        getLiveContact();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [instanceData, init.current, prospectData, messagesData]); // eslint-disable-line react-hooks/exhaustive-deps

  // const testbtn = () => {
  //   getLiveContact();
  // };

  return (
    <Container fluid style={{ padding: 0 }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-wrapper">
          {/* <button onClick={testbtn}>test</button> */}
          <section className="page-start">
            <div className="header">
              <Stack className="justify-content-center" direction="vertical" gap={2}>
                <FormSelect
                  options={instanceList}
                  valueDefault={instanceData.value ? instanceList.find((record) => record.value === instanceData.value) : chat_instance_id ? instanceList.find((record) => record.value === chat_instance_id) : instanceList[0]}
                  placeholder="Select an instance"
                  width={"100%"}
                  border={false}
                  shadow={true}
                  onChange={(e) => {
                    setInstanceData(e);
                    setIsProspectFilter(Math.random());
                  }}
                />
                <Form.Group className="d-flex">
                  <InputGroup>
                    {/* <Form.Control placeholder="Search prospect..." value={prospectSearchData ? prospectSearchData : prospectSearchData2} onKeyDown={keypressSearchProspect} onChange={(e) => setProspectSearchData(e.target.value)} /> */}
                    <Form.Control
                      placeholder="Search prospect..."
                      value={prospectSearchData || prospectSearchData2}
                      onKeyDown={keypressSearchProspect}
                      onChange={(e) => {
                        if (prospectSearchData2) {
                          setProspectSearchData2(e.target.value);
                        } else if (prospectSearchData) {
                          setProspectSearchData(e.target.value);
                        }
                      }}
                    />
                    <button
                      className="btn op-button op-primary-color text-light"
                      style={{ zIndex: 0 }}
                      onClick={() => {
                        setIsProspectFilter(Math.random());
                      }}
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </InputGroup>
                  <RenderProspectFilter userOption={userList} userInput={prospectOwnerData} />
                </Form.Group>
              </Stack>
            </div>
            <div className="content">
              {prospectList.length > 0 ? (
                prospectList.map((record, index) => (
                  <div key={index} className={`item ${isProspect === index ? "active" : null}`} onClick={() => getMessages(record, index, prospectList)}>
                    <div className="item-start">
                      <div className="initial-wrapper">
                        <div className="initial">{setInitial(record.prospect_name)}</div>
                      </div>
                      <div className="content-item">
                        {record.prospect_name && <h6>{record.prospect_name}</h6>}
                        {record.last_message && <p>{setShortMessage(record.last_message)}</p>}
                      </div>
                      <div className="content-time">
                        {record.last_update && <p>{setTimeStamp(record.last_update)}</p>}
                        {Number(record.unread) === 1 && <span className="new-message"></span>}
                        {Number(record.require_attention) === 1 && <span className="new-attention"></span>}
                      </div>
                    </div>
                    <div className="item-footer">{record.user_name && <span>{record.user_name}</span>}</div>
                  </div>
                ))
              ) : (
                <div className="px-4">
                  <NoRecord description="No matching prospect were found based on your criteria. Consider resetting your filters, or making adjustments to your current filter settings." iconSize={50} height={150} />
                </div>
              )}
              {isLoadMore && (
                <div className="item" onClick={getMoreProspect}>
                  <div className="item-start">
                    <div className="initial-wrapper">
                      <div className="initial">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </div>
                    <div className="content-item">
                      <h6>Load more prospect</h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <section className="page-content">
            <div className="content">
              <div className="content-wrapper">
                {messagesList &&
                  Object.entries(messagesList).map(([key, value]) => (
                    <div key={key} className="chat-wrapper">
                      <div className="chat-timestamp">
                        <span>{moment(new Date(key)).format("ll")}</span>
                      </div>

                      {value.map((record, index) => (
                        <div className="chat" key={index}>
                          {record.direction === "inbound" && (
                            <div className="inbound-wrapper">
                              <div className="inbound">
                                {record.message_body && (
                                  <div className="mb-2" style={{ lineHeight: 1.5 }}>
                                    {setFormatMessages(record)}
                                  </div>
                                )}
                                {!record.media_url && (record.message_type === "image" || record.message_type === "document" || record.message_type === "voice") && (
                                  <div className="d-flex align-items-center mb-3">
                                    <FontAwesomeIcon icon={faClock} className="me-2" />
                                    <p style={{ margin: 0 }}>Waiting for this message. This may take a while or you may refresh it</p>
                                  </div>
                                )}
                                <p className="inbound-extra">
                                  <span className="inbound-user me-2"> {messagesData.sender_name}</span>
                                  <span className="inbound-created">{moment(new Date(record.date_time_create)).format("LT")}</span>
                                </p>
                              </div>
                              {!record.media_url && (record.message_type === "image" || record.message_type === "document" || record.message_type === "voice") && (
                                <div className="inbound-refresh">
                                  <button className="btn btn-circle btn-sm" style={{ backgroundColor: "#eee" }} onClick={() => getRefreshMessages(record)}>
                                    <FontAwesomeIcon icon={faRotateRight} size="xl" />
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                          {record.direction === "outbound" && (
                            <div className="outbound-wrapper">
                              {Number(record.error) === 1 && (
                                <div className="outbound-error">
                                  <OverlayTrigger trigger={["hover", "hover"]} placement="left" overlay={(props) => <Tooltip {...props}>{record.error_message}</Tooltip>}>
                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                  </OverlayTrigger>
                                </div>
                              )}

                              <div className="outbound">
                                {record.message_body && (
                                  <div className="mb-2" style={{ lineHeight: 1.5 }}>
                                    {setFormatMessages(record)}
                                  </div>
                                )}
                                <p className="outbound-extra">
                                  <span className="outbound-user me-2">{record.user_name}</span>
                                  <span className="outbound-created">{moment(new Date(record.date_time_create)).format("LT")}</span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
            <div className="footer">
              <div className="input-tools">
                <Stack direction="horizontal" gap={2}>
                  <Dropdown drop="up">
                    <Dropdown.Toggle as={RenderMediaAction} />
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={setIsSendFile} disabled={messageAction.file}>
                        Send File
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button type="button" className="btn op-button btn-circle btn-sm op-primary-color" disabled={messageAction.broadcast} onClick={setIsBroadcast}>
                    <FontAwesomeIcon icon={faBullhorn} size="lg" style={{ color: "#fff" }} />
                  </button>
                </Stack>
              </div>

              <Form.Group className="w-100 ms-2">
                <Stack direction="horizontal" gap={2}>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={1}
                    onKeyDown={keypressInput}
                    className="h-100 input"
                    disabled={messageAction.input}
                    placeholder={messageAction.inputPlaceholder}
                    value={inputData}
                    style={{ resize: "none", borderRadius: "100rem" }}
                    onChange={(e) => setInputData(e.target.value)}
                  />
                  <button type="button" className="btn op-button btn-circle btn-sm op-primary-color" disabled={messageAction.send} onClick={sendMessage}>
                    <FontAwesomeIcon icon={faPaperPlaneTop} style={{ color: "#fff" }} />
                  </button>
                </Stack>
              </Form.Group>
            </div>
          </section>
          <section className="page-end">
            <div className="content">
              <div className="content-user">
                <Stack direction="horizontal" gap={2} className="cursor-pointer" onClick={sendOpenContact}>
                  <div className="initial-wrapper me-2">
                    <p className="initial">{setInitial(prospectData.prospect_name)}</p>
                  </div>
                  <Stack direction="vertical" className="justify-content-center">
                    <h6 className="op-text-medium m-0">{prospectData.prospect_name}</h6>
                    {messagesData && Number(messagesData.blueprintStatus) !== 1 && messagesData.blueprintRecord && messagesData.blueprintRecord.status && (
                      <p className="m-0" style={{ color: "#999", fontSize: 11 }}>
                        {messagesData.blueprintRecord.status ? setBlueprintStatus(messagesData.blueprintRecord.status) : ""}
                      </p>
                    )}
                  </Stack>
                </Stack>
                <Stack className="mt-2" direction="vertical" gap={3} style={{ padding: "10px 10px" }}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faUser} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {messagesData && messagesData.sender_name ? messagesData.sender_name : "No name"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faPhone} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.contact_num ? prospectData.contact_num : "No contact"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.email_title ? prospectData.email_title : "No email"}
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faUserTie} size="lg" />
                    <span className="ms-2" style={{ fontSize: 11 }}>
                      {prospectData.user_name ? prospectData.user_name : "No ownership"}
                    </span>
                  </div>
                </Stack>
              </div>

              <div className="footer">
                {blueprintAction.addDealShow && (
                  <div className={`item mt-2 ${blueprintAction.addDealDisabled ? "disabled" : ""}`} onClick={setIsAddDeal}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faUserPlus} size="lg" />
                      </div>
                      <span className="ms-2">Add New Deal</span>
                    </div>
                  </div>
                )}

                {blueprintAction.qualifyLeadShow && (
                  <div className={`item mt-2 ${blueprintAction.qualifyLeadDisabled ? "disabled" : ""}`} onClick={setIsQualifyLead}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                      </div>
                      <span className="ms-2">Qualify Lead</span>
                    </div>
                  </div>
                )}

                {blueprintAction.dropLeadShow && (
                  <div className={`item mt-2 ${blueprintAction.dropLeadDisabled ? "disabled" : ""}`} onClick={setIsDropLead}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faXmark} size="lg" />
                      </div>
                      <span className="ms-2">Drop Lead</span>
                    </div>
                  </div>
                )}

                {blueprintAction.transferAdminShow && (
                  <div className={`item mt-2 ${blueprintAction.transferAdminDisabled ? "disabled" : ""}`} onClick={sendLeadAdmin}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faPeopleArrows} size="lg" />
                      </div>
                      <span className="ms-2">Transfer to admin</span>
                    </div>
                  </div>
                )}

                {blueprintAction.transferRAShow && (
                  <div className={`item mt-2 ${blueprintAction.transferRADisabled ? "disabled" : ""}`} onClick={sendLeadRA}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-center" style={{ width: 20 }}>
                        <FontAwesomeIcon icon={faPeopleArrows} size="lg" />
                      </div>
                      <span className="ms-2">Transfer to sales rep</span>
                    </div>
                  </div>
                )}

                <div className="item mt-2" onClick={sendUnlockConversation}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center" style={{ width: 20 }}>
                      <FontAwesomeIcon icon={messageAction.input ? faUserUnlock : faUserLock} size="lg" />
                    </div>

                    <span className="ms-2"> {messageAction.input ? "Unlock conversation" : "Lock conversation"} </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <Modal show={isSendFile} onHide={setIsSendFile} size="lg" backdrop="static">
        <Formik
          validationSchema={sendFileSchema}
          onSubmit={sendFile}
          initialValues={{
            file: [],
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Attach a file</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group>
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    credits={false}
                    onupdatefiles={(fileItems) => {
                      setFieldValue(
                        "file",
                        fileItems.map((fileItem) => fileItem.file)
                      );
                    }}
                  />
                  {errors.file && touched.file && <div className="op-error-message">{errors.file}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSendFileLoading}>
                  {isSendFileLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send"}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isBroadcast} onHide={setIsBroadcast} backdrop="static">
        <Formik
          // validationSchema={sendFileSchema}
          onSubmit={sendBroadcast}
          initialValues={{
            whatsapp_template: {},
            whatsapp_content: false,
            whatsapp_params: false,
            whatsapp_params_input: [],
            whatsapp_template_header_image: false,
            whatsapp_link: "",
            whatsapp_link_show: false,
            whatsapp_image: "",
            whatsapp_image_show: false,
            file: [],
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>{!values.whatsapp_image_show && !values.whatsapp_link_show ? "Broadcast Message" : values.whatsapp_image_show ? "Upload your image" : "Insert your link image"}</Modal.Title>
              </Modal.Header>

              {!values.whatsapp_image_show && !values.whatsapp_link_show && (
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Whatsapp Template</Form.Label>
                    <FormSelect
                      options={templateList}
                      valueDefault={values.whatsapp_template ? templateList.find((record) => record.whatsapp_template_op_id === values.whatsapp_template.whatsapp_template_op_id) : null}
                      placeholder="Select Whatsapp template"
                      onChange={(e) => {
                        if (e) {
                          const templateParamsCount = Number(e.whatsapp_template_params);
                          const params = Array.from({ length: templateParamsCount }, () => "");
                          setFieldValue("whatsapp_params_input", params);
                          setFieldValue("whatsapp_params", templateParamsCount > 0 ? true : false);
                          setFieldValue("whatsapp_template", e);
                          setFieldValue("whatsapp_template_header_image", Number(e.whatsapp_template_header_image) === 1 ? true : false);
                          setFieldValue("whatsapp_link", "");
                          setFieldValue("whatsapp_image", "");
                          setFieldValue("whatsapp_content", true);
                        }
                      }}
                    />
                  </Form.Group>

                  {values.whatsapp_content && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Whatsapp Message</Form.Label>
                        <Form.Control as="textarea" rows={4} disabled value={values.whatsapp_template.message} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Whatsapp Params</Form.Label>
                        {values.whatsapp_params &&
                          values.whatsapp_params_input.map((record, index) => (
                            <Form.Control
                              type="text"
                              value={record}
                              placeholder={`params ${index + 1}`}
                              className="mb-2"
                              onChange={(e) => {
                                const updatedValue = [...values.whatsapp_params_input];
                                updatedValue[index] = e.target.value;
                                setFieldValue("whatsapp_params_input", updatedValue);
                              }}
                            />
                          ))}
                      </Form.Group>
                    </>
                  )}

                  {values.whatsapp_template_header_image && (
                    <Form.Group className="mb-3">
                      <Form.Label>Whatsapp Header Image</Form.Label> <br />
                      <ButtonGroup className="shadow-sm border">
                        <Button variant="light">
                          <FontAwesomeIcon icon={faImage} size="lg" onClick={() => setFieldValue("whatsapp_image_show", true)} />
                        </Button>
                        <Button variant="light">
                          <FontAwesomeIcon icon={faLink} size="lg" onClick={() => setFieldValue("whatsapp_link_show", true)} />
                        </Button>
                      </ButtonGroup>
                    </Form.Group>
                  )}

                  {values.whatsapp_template_header_image && (values.whatsapp_link || values.whatsapp_image) && (
                    <div className="position-relative w-100">
                      <Image src={values.whatsapp_image ? values.whatsapp_image : values.whatsapp_link} rounded className="w-100" />
                    </div>
                  )}
                </Modal.Body>
              )}

              {!values.whatsapp_image_show && !values.whatsapp_link_show && (
                <Modal.Footer>
                  <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSendFileLoading}>
                    {isSendFileLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Send"}
                  </button>
                </Modal.Footer>
              )}

              {values.whatsapp_image_show && (
                <Modal.Body>
                  <Form.Group>
                    <FilePond
                      allowMultiple={false}
                      acceptedFileTypes={["images/*"]}
                      maxFiles={1}
                      credits={false}
                      onupdatefiles={(fileItems) => {
                        setFieldValue(
                          "file",
                          fileItems.map((fileItem) => fileItem.file)
                        );
                      }}
                    />
                    {!values.file.length && <div className="op-error-message">File is required</div>}
                  </Form.Group>
                </Modal.Body>
              )}

              {values.whatsapp_image_show && (
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#eee" }}
                    onClick={() => {
                      setFieldValue("whatsapp_image", "");
                      setFieldValue("whatsapp_link", "");
                      setFieldValue("whatsapp_link_show", false);
                      setFieldValue("whatsapp_image_show", false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn op-button op-primary-color text-light"
                    disabled={isLoadingBroadCastUpload}
                    onClick={async () => {
                      const mediaUrl = await sendBroadcastImage(values.file);
                      if (mediaUrl) {
                        setFieldValue("whatsapp_image", mediaUrl);
                        setFieldValue("whatsapp_link", "");
                        setFieldValue("whatsapp_link_show", false);
                        setFieldValue("whatsapp_image_show", false);
                      }
                    }}
                  >
                    {isLoadingBroadCastUpload ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Upload"}
                  </button>
                </Modal.Footer>
              )}

              {values.whatsapp_link_show && (
                <Modal.Body>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Insert image link" onChange={(e) => handleChange("whatsapp_link")(e.target.value)} />
                    {!values.whatsapp_link_show && <div className="op-error-message">Link is required</div>}
                  </Form.Group>
                </Modal.Body>
              )}

              {values.whatsapp_link_show && (
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#eee" }}
                    onClick={() => {
                      setFieldValue("whatsapp_image", "");
                      setFieldValue("whatsapp_link", "");
                      setFieldValue("whatsapp_link_show", false);
                      setFieldValue("whatsapp_image_show", false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn op-button op-primary-color text-light"
                    disabled={isLoadingBroadCastUpload}
                    onClick={async () => {
                      if (!values.whatsapp_link_show) {
                        return;
                      }

                      setFieldValue("whatsapp_image", "");
                      setFieldValue("whatsapp_link_show", false);
                      setFieldValue("whatsapp_image_show", false);
                    }}
                  >
                    Insert
                  </button>
                </Modal.Footer>
              )}
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isAddDeal} onHide={setIsAddDeal} size="lg" backdrop="static">
        <Formik
          validationSchema={addDealSchema}
          onSubmit={sendCreateDeal}
          initialValues={{
            name: prospectData.prospect_name || "",
            contact_num: prospectData.contact_name || "",
            email: prospectData.email_title || "",
            notes: "",
            user_id: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add New Deal</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={values.name} onChange={(e) => handleChange("name")(e.target.value)} />
                  {errors.name && touched.name && <div className="op-error-message">{errors.name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" value={values.contact_num} onChange={(e) => handleChange("contact_num")(e.target.value)} />
                  {errors.contact_num && touched.contact_num && <div className="op-error-message">{errors.contact_num}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={values.email} onChange={(e) => handleChange("email")(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as={"textarea"} rows={3} value={values.notes} onChange={(e) => handleChange("notes")(e.target.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Ownership</Form.Label>
                  <FormSelect options={addDealUser} valueDefault={addDealUser[0]} onChange={(e) => handleChange("user_id")(e.value)} />
                  {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isQualifyLead} onHide={setIsQualifyLead} size="lg" backdrop="static">
        <Formik
          onSubmit={sendQualifyLead}
          initialValues={{
            remark: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add New Deal</Modal.Title>
              </Modal.Header>

              <Modal.Body className="note-notes">
                <Form.Group className="mb-3">
                  <Form.Label>Deal remark</Form.Label>
                  <Form.Control as="textarea" rows={3} value={values.remark} onChange={(e) => handleChange("remark")(e.target.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Qualify
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isDropLead} onHide={setIsDropLead} size="lg" backdrop="static">
        <Formik
          validationSchema={dropLeadSchema}
          onSubmit={sendDropLead}
          initialValues={{
            remark: "",
            reason: "",
          }}
        >
          {({ handleSubmit, values, handleChange, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add New Deal</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Reason</Form.Label>
                  <FormSelect options={lostReasonList} valueDefault={lostReasonList[0]} onChange={(e) => handleChange("reason")(e.value)} />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Deal remark</Form.Label>
                  <Form.Control as="textarea" rows={3} value={values.remark} onChange={(e) => handleChange("remark")(e.target.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Qualify
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default Conversation;
