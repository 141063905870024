import React, { useState, useEffect, useRef, createRef, useCallback } from "react";
import Loader from "../includes/Loader";
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Row, Stack } from "react-bootstrap";
import "../lib/scss/bookingForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faHouse, faUser, faClipboardCheck, faCircleCheck, faUserPlus, faUserMinus } from "@fortawesome/pro-solid-svg-icons";
import { faHouseBuilding, faHouseChimneyWindow, faHouse as faHouse2, faBadgePercent, faCircleDollar } from "@fortawesome/pro-duotone-svg-icons";
import { FormCustomField, FormDate2, FormSelect } from "../includes/FormCustom";
import { FilePond, registerPlugin } from "react-filepond";
import axios from "../api/axios";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import * as formik from "formik";
import * as yup from "yup";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { parsePhoneNumberFromString, parsePhoneNumber } from "libphonenumber-js";
import { MobilePrefix, financingList, firstTimeBuyerList, bumiputeraList, paymentMethodList, salutationList, identityTypeList, occupationList, genderList, raceList, nationalityList, maritalStatusList, relationList } from "../lib/js/Function";
import { bookingDefaultFormat, jointBuyerDefaultFormat, mainBuyerDefaultFormat } from "./booking/BookingFormat";

const bankList = [
  { label: "Affin Bank Berhad", value: "Affin Bank Berhad" },
  { label: "Affin Islamic Bank Berhad", value: "Affin Islamic Bank Berhad" },
  { label: "Alliance Bank Malaysia Berhad", value: "Alliance Bank Malaysia Berhad" },
  { label: "Alliance Islamic Bank Malaysia Berhad", value: "Alliance Islamic Bank Malaysia Berhad" },
  { label: "Al Rajhi Banking & Investment Corporation (Malaysia) Berhad", value: "Al Rajhi Banking & Investment Corporation (Malaysia) Berhad" },
  { label: "AmBank (M) Berhad", value: "AmBank (M) Berhad" },
  { label: "Bank Islam Malaysia Berhad", value: "Bank Islam Malaysia Berhad" },
  { label: "Bank Kerjasama Rakyat Malaysia Berhad", value: "Bank Kerjasama Rakyat Malaysia Berhad" },
  { label: "Bank Muamalat Malaysia Berhad", value: "Bank Muamalat Malaysia Berhad" },
  { label: "Bank of China (Malaysia) Berhad", value: "Bank of China (Malaysia) Berhad" },
  { label: "Bank Pertanian Malaysia Berhad (Agrobank)", value: "Bank Pertanian Malaysia Berhad (Agrobank)" },
  { label: "Bank Simpanan Nasional", value: "Bank Simpanan Nasional" },
  { label: "CIMB Bank Berhad", value: "CIMB Bank Berhad" },
  { label: "CIMB Islamic Bank Berhad", value: "CIMB Islamic Bank Berhad" },
  { label: "Citibank Berhad", value: "Citibank Berhad" },
  { label: "Hong Leong Bank Berhad", value: "Hong Leong Bank Berhad" },
  { label: "Hong Leong Islamic Bank Berhad", value: "Hong Leong Islamic Bank Berhad" },
  { label: "HSBC Amanah Malaysia Berhad", value: "HSBC Amanah Malaysia Berhad" },
  { label: "HSBC Bank Malaysia Berhad", value: "HSBC Bank Malaysia Berhad" },
  { label: "Industrial and Commercial Bank of China (Malaysia) Berhad", value: "Industrial and Commercial Bank of China (Malaysia) Berhad" },
  { label: "Kuwait Finance House", value: "Kuwait Finance House" },
  { label: "Malayan Banking Berhad", value: "Malayan Banking Berhad" },
  { label: "MBSB Bank Berhad", value: "MBSB Bank Berhad" },
  { label: "OCBC Bank (Malaysia) Berhad", value: "OCBC Bank (Malaysia) Berhad" },
  { label: "Public Bank Berhad", value: "Public Bank Berhad" },
  { label: "RHB Bank Berhad", value: "RHB Bank Berhad" },
  { label: "RHB Islamic Bank Berhad", value: "RHB Islamic Bank Berhad" },
  { label: "Standard Chartered Bank Malaysia Berhad", value: "Standard Chartered Bank Malaysia Berhad" },
  { label: "Standard Chartered Saadiq Berhad", value: "Standard Chartered Saadiq Berhad" },
  { label: "United Overseas Bank (Malaysia) Berhad", value: "United Overseas Bank (Malaysia) Berhad" },
];

const BookingForm = () => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);

  const { session, logoutUser } = useAuth();
  const navigate = useNavigate();
  const { rea_unit_id, deal_id, prospect_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [bookingInfo, setBookingInfo] = useState({});
  const [netPrice, setNetPrice] = useState(0);
  const [netPriceBackup, setNetPriceBackup] = useState("0.00");
  const init = useRef(false);
  const { Formik, useFormikContext } = formik;
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [customField, setCustomField] = useState([]);
  const [countdown, setCountdown] = useState(0);
  const [holdid, setHoldid] = useState(0);
  const [dealSource, setDealSource] = useState([]);
  const [bookingFormat, setBookingFormat] = useState(bookingDefaultFormat);
  const [mainFormat, setMainFormat] = useState(mainBuyerDefaultFormat);
  const [jointFormat, setJointFormat] = useState(jointBuyerDefaultFormat);
  const [userList, setUserList] = useState([]);

  const [bookingDetails, setBookingDetails] = useState({
    booking_fee: "",
    booking_fee_str: "RM 0.00",
    financing: "",
    first_time_buyer: "",
    bumiputera: "",
    payment_method: "",
    bank_name: "",
    payment_reference: "",
    payment_receipt: [],
    payment_receipt_name: "",
    source: "",
    source_title: "",
    user_id: session.user_id,
    custom_field: [],
  });

  const [buyerDetails, setBuyerDetails] = useState({
    buyer: [
      {
        role: "main",
        fullname: "",
        salutation: "",
        identification_type: "",
        identification_id: "",
        occupation: "",
        monthly_income: "",
        monthly_income_str: "RM 0.00",
        dob: "",
        gender: "",
        race: "",
        nationality: "",
        marital_status: "",
        email_address: "",
        mobile_number_region: "MY",
        mobile_number_prefix: "60",
        mobile_number: "",
        office_number_region: "MY",
        office_number_prefix: "60",
        office_number: "",
        fax_number: "",
        address1: "",
        address2: "",
        address3: "",
        postal_code: "",
        country: "",
        state: "",
        city: "",
        eme_full_name: "",
        eme_relation: "",
        eme_mobile_number_region: "MY",
        eme_mobile_number_prefix: "60",
        eme_mobile_number: "",
        eme_email: "",
        idFront: [],
        idFrontName: "",
        idBack: [],
        idBackName: "",
        sst_number: "",
        tax_number: "",
      },
    ],
  });

  const buyerRefs = buyerDetails.buyer.map(() => createRef());
  const buyerSchema = yup.object().shape({
    fullname: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.fullname_required) === 1 : Number(jointFormat.fullname_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    salutation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.salutation_required) === 1 : Number(jointFormat.salutation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    identification_type: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_type_required) === 1 : Number(jointFormat.identification_type_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    identification_id: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_number_required) === 1 : Number(jointFormat.identification_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    occupation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.occupation_required) === 1 : Number(jointFormat.occupation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    monthly_income: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.monthly_income_required) === 1 : Number(jointFormat.monthly_income_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    dob: yup.date().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.dob_required) === 1 : Number(jointFormat.dob_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    gender: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.gender_required) === 1 : Number(jointFormat.gender_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    race: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.race_required) === 1 : Number(jointFormat.race_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    nationality: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.nationality_required) === 1 : Number(jointFormat.nationality_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    marital_status: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.marital_status_required) === 1 : Number(jointFormat.marital_status_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    email_address: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.email_address_required) === 1 : Number(jointFormat.email_address_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    mobile_number: yup.string().test("mobile_number", "Invalid mobile number format", function (value) {
      const { mobile_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (role === "joint" && Number(jointFormat.mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (value && mobile_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, mobile_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid mobile number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    office_number: yup.string().test("office_number", "Invalid mobile number format", function (value) {
      const { office_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.office_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (role === "joint" && Number(jointFormat.office_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (value && office_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, office_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid office number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    fax_number: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.fax_number_required) === 1 : Number(jointFormat.fax_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address1: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address1_required) === 1 : Number(jointFormat.address1_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address2: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address2_required) === 1 : Number(jointFormat.address2_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address3: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address3_required) === 1 : Number(jointFormat.address3_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    postal_code: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.postcode_required) === 1 : Number(jointFormat.postcode_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    country: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.country_required) === 1 : Number(jointFormat.country_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    state: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.state_required) === 1 : Number(jointFormat.state_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    city: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.city_required) === 1 : Number(jointFormat.city_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_full_name: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_fullname_required) === 1 : Number(jointFormat.emergency_contact_fullname_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_relation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_relation_required) === 1 : Number(jointFormat.emergency_contact_relation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_mobile_number: yup.string().test("eme_mobile_number", "Invalid mobile number format", function (value) {
      const { eme_mobile_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.emergency_contact_mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (role === "joint" && Number(jointFormat.emergency_contact_mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (value && eme_mobile_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, eme_mobile_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid mobile number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    eme_email: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_email_required) === 1 : Number(jointFormat.emergency_contact_email_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    idFrontName: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_card_front_required) === 1 : Number(jointFormat.identification_card_front_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    idBackName: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_card_back_required) === 1 : Number(jointFormat.identification_card_back_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    sst_number: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.sst_number_required) === 1 : Number(jointFormat.sst_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    tax_number: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.tax_number_required) === 1 : Number(jointFormat.tax_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
  });

  const buyerDetailsSchema = yup.object().shape({
    buyer: yup.array().of(buyerSchema).required("At least one buyer is required"),
  });

  const customFieldSchema = yup.object().shape({
    fd_values: yup.string().when("required", {
      is: 1,
      then: () => yup.string().required("The field is required"),
    }),
  });

  const bookingDetailSchema = yup.object().shape({
    booking_fee: yup.string().when([], {
      is: () => Number(bookingFormat.booking_fee_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    financing: yup.string().when([], {
      is: () => Number(bookingFormat.financing_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    first_time_buyer: yup.string().when([], {
      is: () => Number(bookingFormat.first_time_buyer_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    bumiputera: yup.string().when([], {
      is: () => Number(bookingFormat.bumiputera_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_method: yup.string().when([], {
      is: () => Number(bookingFormat.payment_method_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    bank_name: yup.string().when([], {
      is: () => Number(bookingFormat.bank_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_reference: yup.string().when([], {
      is: () => Number(bookingFormat.payment_reference_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_receipt_name: yup.string().when([], {
      is: () => Number(bookingFormat.payment_receipt_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    source: yup.string().when([], {
      is: () => Number(bookingFormat.source_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    user_id: yup.string().when([], {
      is: () => Number(bookingFormat.user_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    custom_field: yup.array().of(customFieldSchema),
  });

  const [financingOptions, setFinancingOptions] = useState(financingList);
  const [prefixOptions, setPrefixOptions] = useState(MobilePrefix);
  const [raceOptions, setRaceOptions] = useState(raceList);
  const [firstTimeBuyerOptions, setFirstTimeBuyerOptions] = useState(firstTimeBuyerList);
  const [bumiputeraOptions, setBumiputeraOptions] = useState(bumiputeraList);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState(paymentMethodList);
  const [salutationOptions, setSalutationOptions] = useState(salutationList);
  const [identityTypeOptions, setIdentityTypeOptions] = useState(identityTypeList);
  const [occupationOptions, setOccupationOptions] = useState(occupationList);
  const [genderOptions, setGenderOptions] = useState(genderList);
  const [nationalityOptions, setNationalityOptions] = useState(nationalityList);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState(maritalStatusList);
  const [relationOptions, setRelationOptions] = useState(relationList);
  const [modules, setModules] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  // GET FUNCTION ----------------------------------------------

  const getViewBookingForm = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "ViewBookingForm",
          rea_unit_id: rea_unit_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.country.unshift({ label: "Select Country", value: "" });
        data.state.unshift({ label: "Select State", value: "" });
        data.city.unshift({ label: "Select City", value: "" });

        setHoldid(data.hold_unit_id);
        setCountdown(Number(data.record.on_hold_interval));

        sessionStorage.removeItem("bookingForm");
        sessionStorage.setItem("bookingForm", JSON.stringify({ holdid: data.hold_unit_id, unitid: rea_unit_id }));

        setCountrySetup(data.country);
        setStateSetup(data.state);
        setCitySetup(data.city);
        setDealSource(data.source);
        setBookingFormat(data.booking_format);
        setMainFormat(data.main_buyer_format);
        setJointFormat(data.joint_buyer_format);
        setBookingDetails((prevState) => ({ ...prevState, custom_field: data.custom_field.map((record) => ({ cf_id: record.cf_id, cf_label: record.cf_label, fd_values: "", required: Number(record.compulsory_column) })) }));
        setCustomField(data.custom_field);
        setModules(data.modules);
        setUserList(data.user);
        setNetPrice(Number(data.record.unit_selling_price));
        setNetPriceBackup(data.record.unit_selling_price);
        setBookingInfo(data.record);
        setSetupOptions();
      } else {
        logoutUser();
      }
    } catch {
      logoutUser();
    }
  };

  // SEND FUNCTION ---------------------------------------------

  const sendBookingDetail = useCallback((values) => {
    setBookingDetails(values);
    setStep(2);
  }, []);

  const sendBackBookingDetail = (values) => {
    setBuyerDetails(values);
    setStep(1);
  };

  const sendBuyerDetail = (values) => {
    setBuyerDetails(values);
    setStep(3);
  };

  const sendBookingForm = async () => {
    setDisableBtn(true);
    try {
      const bumiputeraArray = buyerDetails.buyer.map(() => bookingDetails.bumiputera) || [];
      var cf_id = [];
      var fd_value = [];
      var cf_label = [];

      if (customField.length > 0) {
        cf_id = bookingDetails.custom_field.map((record) => record.cf_id);
        cf_label = bookingDetails.custom_field.map((record) => record.cf_label);
        fd_value = bookingDetails.custom_field.map((record) => (Array.isArray(record.fd_values) ? record.fd_values.join(",") : record.fd_values));
      }

      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "CreateNewBooking",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_unit_id: rea_unit_id,
          rea_phase_id: bookingInfo.rea_phase_id,
          user_id: bookingDetails.user_id,
          deal_id: deal_id,
          prospect_id: prospect_id,
          selling_price: bookingInfo.unit_selling_price,
          nett_price: netPrice,
          source: bookingDetails.source,
          rebate_title: bookingInfo.rebate_title,
          rebate_value: bookingInfo.rebate_value,
          rebate_perc: bookingInfo.rebate_perc,
          bumi_package: bookingInfo.bumi_package,
          booking_deposit: bookingDetails.booking_fee,
          payment_method: bookingDetails.payment_method,
          payment_slip: bookingDetails.payment_receipt_name,
          payment_ref: bookingDetails.payment_reference,
          first_time: bookingDetails.first_time_buyer,
          financing: bookingDetails.financing,
          bank_name: bookingDetails.bank_name,
          buyer_role: buyerDetails.buyer.map((record) => record.role).join("[-0-]"),
          buyer_name: buyerDetails.buyer.map((record) => record.fullname).join("[-0-]"),
          buyer_salutation: buyerDetails.buyer.map((record) => record.salutation).join("[-0-]"),
          buyer_id_type: buyerDetails.buyer.map((record) => record.identification_type).join("[-0-]"),
          buyer_id_number: buyerDetails.buyer.map((record) => record.identification_id).join("[-0-]"),
          buyer_occupation: buyerDetails.buyer.map((record) => record.occupation).join("[-0-]"),
          buyer_income: buyerDetails.buyer.map((record) => record.monthly_income).join("[-0-]"),
          buyer_dob: buyerDetails.buyer.map((record) => record.dob).join("[-0-]"),
          buyer_gender: buyerDetails.buyer.map((record) => record.gender).join("[-0-]"),
          buyer_race: buyerDetails.buyer.map((record) => record.race).join("[-0-]"),
          buyer_nationality: buyerDetails.buyer.map((record) => record.nationality).join("[-0-]"),
          buyer_marital_status: buyerDetails.buyer.map((record) => record.marital_status).join("[-0-]"),
          buyer_bumiputera_status: bumiputeraArray.join("[-0-]"),
          buyer_email: buyerDetails.buyer.map((record) => record.email_address).join("[-0-]"),
          buyer_mobile_prefix: buyerDetails.buyer.map((record) => record.mobile_number_prefix).join("[-0-]"),
          buyer_mobile_number: buyerDetails.buyer.map((record) => record.mobile_number).join("[-0-]"),
          buyer_house_prefix: buyerDetails.buyer.map((record) => record.office_number_prefix).join("[-0-]"),
          buyer_house_number: buyerDetails.buyer.map((record) => record.office_number).join("[-0-]"),
          buyer_fax_number: buyerDetails.buyer.map((record) => record.fax_number).join("[-0-]"),
          buyer_address1: buyerDetails.buyer.map((record) => record.address1).join("[-0-]"),
          buyer_address2: buyerDetails.buyer.map((record) => record.address2).join("[-0-]"),
          buyer_address3: buyerDetails.buyer.map((record) => record.address3).join("[-0-]"),
          buyer_postcode: buyerDetails.buyer.map((record) => record.postal_code).join("[-0-]"),
          buyer_city: buyerDetails.buyer.map((record) => record.city).join("[-0-]"),
          buyer_state: buyerDetails.buyer.map((record) => record.state).join("[-0-]"),
          buyer_country: buyerDetails.buyer.map((record) => record.country).join("[-0-]"),
          buyer_id_front: buyerDetails.buyer.map((record) => record.idFrontName).join("[-0-]"),
          buyer_id_back: buyerDetails.buyer.map((record) => record.idBackName).join("[-0-]"),
          emergency_name: buyerDetails.buyer.map((record) => record.eme_full_name).join("[-0-]"),
          emergency_relation: buyerDetails.buyer.map((record) => record.eme_relation).join("[-0-]"),
          emergency_prefix: buyerDetails.buyer.map((record) => record.eme_mobile_number_prefix).join("[-0-]"),
          emergency_number: buyerDetails.buyer.map((record) => record.eme_mobile_number).join("[-0-]"),
          emergency_email: buyerDetails.buyer.map((record) => record.eme_email).join("[-0-]"),
          sst_number: buyerDetails.buyer.map((record) => record.sst_number).join("[-0-]"),
          tax_number: buyerDetails.buyer.map((record) => record.tax_number).join("[-0-]"),
          cf_id: cf_id.join("[-0-]"),
          cf_label: cf_label.join("[-0-]"),
          fd_value: fd_value.join("[-0-]"),
          hold_unit_id: holdid,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        navigate("/booking/list");
      } else {
        Swal.fire({
          icon: "error",
          title: "Opps...",
          text: "Failed to create a booking",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisableBtn(false);
    }
  };

  const sendReleasedUnitForm = async () => {
    try {
      const response = await axios.get("ws/ws_rea_hold_unit.php", {
        params: {
          task: "releaseHoldUnitForm",
          hold_unit_id: holdid,
          rea_unit_id: rea_unit_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        sessionStorage.removeItem("bookingForm");
        navigate(-1);
      } else {
        sessionStorage.removeItem("bookingForm");
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      sessionStorage.removeItem("bookingForm");
      navigate(-1);
    }
  };

  const sendElapsedTimeout = async () => {
    const result = await Swal.fire({
      title: "Your time to book unit has elapsed",
      text: "Please start the booking process again if you wish to book this unit.",
      icon: "warning",
      confirmButtonText: "OK",
    });

    if (result.isConfirmed) {
      await sendReleasedUnitForm();
    }
  };

  // SET FUNCTION ----------------------------------------------

  const setSetupOptions = async () => {
    const optionsMapping = {
      financingList: setFinancingOptions,
      raceList: setRaceOptions,
      prefixList: setPrefixOptions,
      firstTimeBuyerList: setFirstTimeBuyerOptions,
      paymentMethodList: setPaymentMethodOptions,
      salutationList: setSalutationOptions,
      identityTypeList: setIdentityTypeOptions,
      occupationList: setOccupationOptions,
      genderList: setGenderOptions,
      nationalityList: setNationalityOptions,
      maritalStatusList: setMaritalStatusOptions,
      relationList: setRelationOptions,
      bumiputeraList: setBumiputeraOptions,
    };

    try {
      const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: session.company_id,
          filename: "booking_modules",
        },
      });

      const data = response.data;

      if (Number(data.status) === 0) {
        for (const [key, setter] of Object.entries(optionsMapping)) {
          if (data.record[key] !== undefined) {
            setter(data.record[key]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setCountrySetup = (data) => {
    const options = data.map((record) => ({ label: record.country_title, value: record.country_title }));
    setCountry(options);
  };

  const setStateSetup = (data) => {
    const options = data.map((record) => ({ label: record.state_title, value: record.state_title, country: record.country_title }));
    setState(options);
  };

  const setCitySetup = (data) => {
    const options = data.map((record) => ({ label: record.city_title, value: record.city_title, state: record.state_title }));
    setCity(options);
  };

  const setPercentage = (input) => {
    if (input) {
      return Math.floor(input) + "%";
    }
    return "";
  };

  const setCurrency = (input) => {
    input = Number(input);

    const formattedAmount = input.toLocaleString("en-MY", {
      style: "currency",
      currency: "MYR",
    });

    return formattedAmount;
  };

  const setFinancing = (input) => {
    if (input === "bank") {
      return "Bank";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "government_loan") {
      return "Government Loan";
    } else if (input === "staff_loan") {
      return "Staff Loan";
    } else if (input === "other") {
      return "Rent To Own";
    }
  };

  const setFirstTimeBuyer = (input) => {
    if (input === "yes") {
      return "Yes";
    } else if (input === "no") {
      return "No";
    }
  };

  const setBumiputera = (input) => {
    if (input === "1") {
      return "Yes";
    } else if (input === "0") {
      return "No";
    }
  };

  const setPaymentMethod = (input) => {
    if (input === "bank_draft") {
      return "Online Banking";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "cc") {
      return "Credit Card";
    } else if (input === "cheque") {
      return "Cheque";
    } else if (input === "others") {
      return "Others";
    }
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const ScrollToFirstError2 = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        const findFirstError = (obj, keyPath = "") => {
          if (obj === undefined || obj === null) {
            return "";
          }
          return Object.keys(obj).reduce((acc, key) => {
            if (acc) return acc;
            const isArrayIndex = !isNaN(key);
            const newKeyPath = keyPath ? `${keyPath}${isArrayIndex ? `[${key}]` : `.${key}`}` : key;
            if (typeof obj[key] === "string") return newKeyPath;
            return findFirstError(obj[key], newKeyPath);
          }, "");
        };
        const firstErrorKey = findFirstError(errors);
        const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const setCountDown = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    if (minutes > 0 && seconds > 0) {
      return `${minutes}m ${seconds}s`;
    } else if (minutes > 0 && seconds === 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  const setShowSection = (conditions) => {
    return conditions.some((condition) => Number(condition));
  };

  const setFieldLabel = (label, required) => {
    return `${label} ${Number(required) === 1 ? "*" : ""}`;
  };

  const setBuyerLabelField = (index, column) => {
    if (index === 0) {
      return setFieldLabel(mainFormat[`${column}_label`], Number(step) === 3 ? 0 : mainFormat[`${column}_required`]);
    } else {
      return setFieldLabel(jointFormat[`${column}_label`], Number(step) === 3 ? 0 : jointFormat[`${column}_required`]);
    }
  };

  const setBuyerShowField = (index, column) => {
    if (index === 0) {
      return Number(mainFormat[`${column}_show`]) === 1;
    } else {
      return Number(jointFormat[`${column}_show`]) === 1;
    }
  };

  const setMobileNumber = (prefix, number) => {
    if (number && number !== "0") {
      const contactNumber = `+${prefix}${number}`;
      const phoneNumber = parsePhoneNumber(contactNumber);
      return phoneNumber.formatInternational();
    } else {
      return "";
    }
  };

  // USEEFFECT FUNCTION ----------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getViewBookingForm();
          setTimeout(() => {
            setLoading(false);
            init.current = true;
          }, 500);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rea_unit_id === "" || deal_id === "" || prospect_id === "") {
      logoutUser();
    }
  }, [rea_unit_id, deal_id, prospect_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0 && init.current) {
      sendElapsedTimeout();
    }
  }, [countdown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid style={{ padding: 0 }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-form">
          <section className="page-start">
            <Stack direction="vertical" gap={3}>
              <Card className="rounded">
                <Card.Body className="d-flex align-items-center">
                  <p className="m-0">
                    <FontAwesomeIcon icon={faClock} size="lg" className="me-2" /> Unit will be released in <b>{setCountDown(countdown)}</b>
                  </p>
                </Card.Body>
              </Card>

              <ListGroup as="ul">
                <ListGroup.Item as="li" className={`py-3 ${step === 1 ? "active" : ""}`}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="d-flex justify-content-center" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={faHouse} size="xl" />
                    </div>
                    <p style={{ lineHeight: 1.5, margin: 0 }}>
                      <span className="op-text-bigger fw-bold">Booking Detail</span> <br />
                      <span className="op-text-medium">Enter Booking Information</span>
                    </p>
                    {(step === 2 || step === 3) && <FontAwesomeIcon className="ms-auto" icon={faCircleCheck} size="2xl" />}
                  </Stack>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`py-3 ${step === 2 ? "active" : ""}`}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="d-flex justify-content-center" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={faUser} size="xl" />
                    </div>
                    <p style={{ lineHeight: 1.5, margin: 0 }}>
                      <span className="op-text-bigger fw-bold">Buyer Detail</span> <br />
                      <span className="op-text-medium">Enter Buyer Information</span>
                    </p>
                    {step === 3 && <FontAwesomeIcon className="ms-auto" icon={faCircleCheck} size="2xl" />}
                  </Stack>
                </ListGroup.Item>
                <ListGroup.Item as="li" className={`py-3 ${step === 3 ? "active" : ""}`}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="d-flex justify-content-center" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={faClipboardCheck} size="xl" />
                    </div>
                    <p style={{ lineHeight: 1.5, margin: 0 }}>
                      <span className="op-text-bigger fw-bold">Confirmation</span> <br />
                      <span className="op-text-medium">Confirm of the information</span>
                    </p>
                  </Stack>
                </ListGroup.Item>
              </ListGroup>

              {setShowSection([
                bookingFormat.project_show,
                bookingFormat.phase_show,
                bookingFormat.unit_type_show,
                bookingFormat.unit_type_show,
                bookingFormat.unit_show,
                bookingFormat.bumi_discount_show,
                bookingFormat.rebate_package_show,
                bookingFormat.selling_price_show,
                bookingFormat.net_price_show,
              ]) && (
                <Card>
                  <Card.Body>
                    <Stack direction="vertical" gap={3}>
                      {Number(bookingFormat.project_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faHouseBuilding} size="lg" className="me-2" /> {bookingFormat.project_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{bookingInfo.project_name || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.phase_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faHouseBuilding} size="lg" className="me-2" /> {bookingFormat.phase_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{bookingInfo.phase_name || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.unit_type_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faHouseChimneyWindow} size="lg" className="me-2" /> {bookingFormat.unit_type_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">
                            {bookingInfo.unit_title} {bookingInfo.unit_type}
                          </div>
                        </div>
                      )}

                      {Number(bookingFormat.unit_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faHouse2} size="lg" className="me-2" /> {bookingFormat.unit_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{bookingInfo.unit_number || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.bumi_discount_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faBadgePercent} size="lg" className="me-2" /> {bookingFormat.bumi_discount_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{setPercentage(bookingInfo.bumi_package) || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.rebate_package_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faBadgePercent} size="lg" className="me-2" /> {bookingFormat.rebate_package_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{bookingInfo.rebate_title || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.selling_price_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faCircleDollar} size="lg" className="me-2" /> {bookingFormat.selling_price_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{setCurrency(bookingInfo.unit_selling_price) || "Unavailable"}</div>
                        </div>
                      )}

                      {Number(bookingFormat.net_price_show) === 1 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="op-text-medium w-50 d-flex aling-items-center fw-bold">
                            <FontAwesomeIcon icon={faCircleDollar} size="lg" className="me-2" /> {bookingFormat.net_price_label}
                          </div>
                          <div className="op-text-medium w-50 text-end">{setCurrency(netPrice) || "Unavailable"}</div>
                        </div>
                      )}
                    </Stack>
                  </Card.Body>
                </Card>
              )}
            </Stack>
          </section>

          <section className="page-end">
            <section className="content">
              {step === 1 && (
                <Formik validationSchema={bookingDetailSchema} onSubmit={sendBookingDetail} initialValues={bookingDetails}>
                  {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <ScrollToFirstError />
                      <ScrollToFirstError2 />
                      <Card>
                        <Card.Body className="content">
                          {setShowSection([bookingFormat.booking_fee_show, bookingFormat.financing_show, bookingFormat.first_time_buyer_show, bookingFormat.bumiputera_show]) && (
                            <>
                              <Card.Header className="shadow-sm">Booking Information</Card.Header>
                              <Card.Body>
                                <Row>
                                  {Number(bookingFormat.booking_fee_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.booking_fee_label, bookingFormat.booking_fee_required)}</Form.Label>
                                        <Form.Control
                                          className="text-uppercase"
                                          type="text"
                                          name="booking_fee"
                                          value={values.booking_fee_str}
                                          onChange={(e) => {
                                            let inputValue = e.target.value.replace(/\D/g, "");
                                            let currentPrice = netPriceBackup.toString().replace(/\D/g, "");

                                            if (inputValue.length > 0) {
                                              inputValue = (parseInt(inputValue, 10) / 100).toFixed(2);
                                              currentPrice = (parseInt(currentPrice, 10) / 100).toFixed(2);

                                              if (values.bumiputera && Number(values.bumiputera) === 1 && Number(modules.is_calc_bumi) === 1) {
                                                currentPrice = Number(currentPrice) - (Number(currentPrice) * Number(bookingInfo.bumi_package)) / 100;
                                              }

                                              if (Number(modules.is_calc_booking_fee) === 1) {
                                                currentPrice = Number(currentPrice) - Number(inputValue);
                                              }

                                              setFieldValue("booking_fee", inputValue);
                                              setNetPrice(currentPrice);
                                              console.log(currentPrice);
                                            } else {
                                              inputValue = "0.00";
                                              setNetPrice(netPriceBackup);
                                            }

                                            setFieldValue("booking_fee_str", setCurrency(inputValue));
                                          }}
                                        />
                                        {errors.booking_fee && touched.booking_fee && <div className="op-error-message">{errors.booking_fee}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.financing_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.financing_label, bookingFormat.financing_required)}</Form.Label>
                                        <FormSelect options={financingOptions} name="financing" valueDefault={financingOptions.find((record) => record.value === values.financing)} onChange={(e) => handleChange("financing")(e.value)} />
                                        {errors.financing && touched.financing && <div className="op-error-message">{errors.financing}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.first_time_buyer_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.first_time_buyer_label, bookingFormat.first_time_buyer_required)}</Form.Label>
                                        <FormSelect options={firstTimeBuyerOptions} name="first_time_buyer" valueDefault={firstTimeBuyerOptions.find((record) => record.value === values.first_time_buyer)} onChange={(e) => handleChange("first_time_buyer")(e.value)} />
                                        {errors.first_time_buyer && touched.first_time_buyer && <div className="op-error-message">{errors.first_time_buyer}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.bumiputera_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.bumiputera_label, bookingFormat.bumiputera_required)}</Form.Label>
                                        <FormSelect
                                          options={bumiputeraOptions}
                                          name="bumiputera"
                                          valueDefault={bumiputeraOptions.find((record) => record.value === values.bumiputera)}
                                          onChange={(e) => {

                                            let currentPrice = netPriceBackup.toString().replace(/\D/g, "");
                                            currentPrice = (parseInt(currentPrice, 10) / 100).toFixed(2);
                                            
                                            if (Number(e.value) === 1 && Number(modules.is_calc_bumi) === 1) {
                                              currentPrice = Number(currentPrice) - (Number(currentPrice) * bookingInfo.bumi_package) / 100;
                                            }

                                            if (values.booking_fee && Number(modules.is_calc_booking_fee) === 1) {
                                              currentPrice = Number(currentPrice) - Number(values.booking_fee);
                                            }

                                            setNetPrice(currentPrice);
                                            setFieldValue("bumiputera", e.value);
                                          }}
                                        />
                                        {errors.bumiputera && touched.bumiputera && <div className="op-error-message">{errors.bumiputera}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}
                                </Row>
                              </Card.Body>
                            </>
                          )}

                          {setShowSection([bookingFormat.payment_method_show, bookingFormat.payment_reference_show, bookingFormat.payment_receipt_show, bookingFormat.bank_show]) && (
                            <>
                              <Card.Header className="shadow-sm">Payment Details</Card.Header>
                              <Card.Body>
                                <Row>
                                  {Number(bookingFormat.payment_method_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.payment_method_label, bookingFormat.payment_method_required)}</Form.Label>
                                        <FormSelect options={paymentMethodOptions} name="payment_method" valueDefault={paymentMethodOptions.find((record) => record.value === values.payment_method)} onChange={(e) => handleChange("payment_method")(e.value)} isOptionDisabled={true} />
                                        {errors.payment_method && touched.payment_method && <div className="op-error-message">{errors.payment_method}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.bank_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.bank_label, bookingFormat.bank_required)}</Form.Label>
                                        <FormSelect options={bankList} name="bank" valueDefault={bankList.find((record) => record.value === values.bank_name)} onChange={(e) => handleChange("bank_name")(e.value)} isOptionDisabled={true} />
                                        {errors.bank_name && touched.bank_name && <div className="op-error-message">{errors.bank_name}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.payment_reference_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.payment_reference_label, bookingFormat.payment_reference_required)}</Form.Label>
                                        <Form.Control className="text-uppercase" type="text" name="payment_reference" value={values.payment_reference} onChange={(e) => handleChange("payment_reference")(e.target.value)} />
                                        {errors.payment_reference && touched.payment_reference && <div className="op-error-message">{errors.payment_reference}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.payment_receipt_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.payment_receipt_label, bookingFormat.payment_receipt_required)}</Form.Label>
                                        <FilePond
                                          allowMultiple={false}
                                          maxFiles={1}
                                          name="payment_receipt_name"
                                          files={values.payment_receipt}
                                          credits={false}
                                          acceptedFileTypes={["image/*", "application/pdf"]}
                                          allowProcess={values.payment_receipt_name ? false : true}
                                          onupdatefiles={(fileItems) => {
                                            setFieldValue(
                                              "payment_receipt",
                                              fileItems.map((fileItem) => fileItem.file)
                                            );
                                          }}
                                          onremovefile={() => {
                                            setFieldValue("payment_receipt", []);
                                            setFieldValue("payment_receipt_name", "");
                                          }}
                                          instantUpload={false}
                                          server={{
                                            process: (fieldName, file, metadata, load, error, progress, abort) => {
                                              const formData = new FormData();
                                              const reader = new FileReader();

                                              formData.append("folder_name", "payment_slip");
                                              formData.append("company_id", session.company_id);
                                              formData.append("file_type", file.type.split("/")[1]);
                                              formData.append("mime_type", file.type);
                                              formData.append("utoken", session.user_ac_token);
                                              formData.append("ctoken", session.company_token);

                                              reader.onload = (e) => {
                                                formData.append("data", e.target.result);
                                                axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_assets_bookingv2.php`, formData).then((response) => {
                                                  const data = response.data;
                                                  if (Number(data.status) === 0) {
                                                    load(data);
                                                    setFieldValue("payment_receipt_name", data.file_name);
                                                  }
                                                });
                                              };
                                              reader.readAsDataURL(file);
                                            },
                                          }}
                                        />
                                        {errors.payment_receipt_name && touched.payment_receipt_name && <div className="op-error-message">{errors.payment_receipt_name}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}
                                </Row>
                              </Card.Body>
                            </>
                          )}

                          {(setShowSection([bookingFormat.source_show, bookingFormat.user_show]) || customField.length > 0) && (
                            <>
                              <Card.Header className="shadow-sm">Additional Information</Card.Header>
                              <Card.Body>
                                <Row>
                                  {Number(bookingFormat.source_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.source_label, bookingFormat.source_required)}</Form.Label>
                                        <FormSelect
                                          options={dealSource}
                                          valueDefault={values.source ? dealSource.find((record) => record.value === values.source) : ""}
                                          name="source"
                                          onChange={(e) => {
                                            setFieldValue("source", e.value);
                                            setFieldValue("source_title", e.label);
                                          }}
                                        />
                                        {errors.source && touched.source && <div className="op-error-message">{errors.source}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {Number(bookingFormat.user_show) === 1 && (
                                    <Col xxl={6} className="mb-3">
                                      <Form.Group>
                                        <Form.Label>{setFieldLabel(bookingFormat.user_label, bookingFormat.user_required)}</Form.Label>
                                        <FormSelect
                                          options={userList}
                                          disabled={Number(session.role_id) !== 1}
                                          valueDefault={values.user_id ? userList.find((record) => Number(record.value) === Number(values.user_id)) : ""}
                                          name="source"
                                          onChange={(e) => {
                                            setFieldValue("user_id", e.value);
                                          }}
                                        />
                                        {errors.source && touched.source && <div className="op-error-message">{errors.source}</div>}
                                      </Form.Group>
                                    </Col>
                                  )}

                                  {customField.length > 0 && (
                                    <>
                                      {customField.map((record, index) => (
                                        <Col xxl={6} className="mb-3" key={record.cf_id}>
                                          <FormCustomField
                                            id={record.cf_id}
                                            name={`custom_field[${index}].fd_values`}
                                            label={setFieldLabel(record.cf_label, record.compulsory_column)}
                                            options={record.cf_value}
                                            type={record.ctf_title}
                                            fd_values={record.fd_values}
                                            error={errors.custom_field && errors.custom_field[index] && errors.custom_field[index].fd_values && touched.custom_field && touched.custom_field[index] && touched.custom_field[index].fd_values ? errors.custom_field[index].fd_values : ""}
                                            placeholder=""
                                            isClearable={true}
                                            firstOptions={false}
                                            value={values.custom_field[index].fd_values}
                                            onChange={(info) => {
                                              const updatedCustomFields = [...values.custom_field];
                                              if (record.ctf_title === "Select") {
                                                updatedCustomFields[index] = { cf_id: record.cf_id, cf_label: record.cf_label, fd_values: info.value, required: Number(record.compulsory_column) };
                                              } else if (record.ctf_title === "Radio") {
                                                updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info.target.checked ? info.target.value : "", cf_label: record.cf_label, required: Number(record.compulsory_column) };
                                              } else if (record.ctf_title === "Date") {
                                                updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info, cf_label: record.cf_label, required: Number(record.compulsory_column) };
                                              } else if (record.ctf_title === "Checkbox") {
                                                const { value, checked } = info.target;
                                                const currentValues = values.custom_field[index].fd_values;
                                                const updatedValues = currentValues === "" ? [] : [...currentValues.split("|")];
                                                if (checked) {
                                                  updatedValues.push(value);
                                                } else {
                                                  const indexToRemove = updatedValues.indexOf(value);
                                                  if (indexToRemove !== -1) {
                                                    updatedValues.splice(indexToRemove, 1);
                                                  }
                                                }
                                                updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: updatedValues.join("|"), cf_label: record.cf_label, required: Number(record.compulsory_column) };
                                              } else {
                                                updatedCustomFields[index] = { cf_id: record.cf_id, fd_values: info.target.value, cf_label: record.cf_label, required: Number(record.compulsory_column) };
                                              }

                                              setFieldValue("custom_field", updatedCustomFields);
                                            }}
                                          />
                                        </Col>
                                      ))}
                                    </>
                                  )}
                                </Row>
                              </Card.Body>
                            </>
                          )}
                        </Card.Body>
                        <Card.Footer className="footer">
                          <Button type="submit" variant="" className="op-button op-primary-color text-light float-end">
                            Next
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Form>
                  )}
                </Formik>
              )}

              {step === 2 && (
                <Formik validationSchema={buyerDetailsSchema} onSubmit={sendBuyerDetail} initialValues={buyerDetails}>
                  {({ handleSubmit, setValues, setFieldValue, handleChange, values, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <ScrollToFirstError2 />
                      <Card>
                        <Card.Body className="content d-flex">
                          <div style={{ width: "93%" }}>
                            {values.buyer.map((record, index) => (
                              <div key={index} ref={buyerRefs[index]}>
                                <Card.Header className="shadow-sm d-flex justify-content-between align-items-center">
                                  <span>Buyer {index + 1}</span>
                                  {index > 0 && (
                                    <FontAwesomeIcon
                                      icon={faUserMinus}
                                      size="lg"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        var recordUpdated = values.buyer.filter((record, i) => i !== index);
                                        setValues((prevValues) => ({
                                          ...prevValues,
                                          buyer: recordUpdated,
                                        }));
                                      }}
                                    />
                                  )}
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    {setBuyerShowField(index, "salutation") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "salutation")}</Form.Label>
                                          <FormSelect
                                            options={salutationOptions}
                                            name={`buyer[${index}].salutation`}
                                            isSearchable={true}
                                            valueDefault={salutationOptions.find((data) => data.value === record.salutation)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].salutation = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].salutation && touched.buyer && touched.buyer[index] && touched.buyer[index].salutation && <div className="op-error-message">{errors.buyer[index].salutation}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "fullname") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "fullname")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].fullname`}
                                            value={record.fullname}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].fullname = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].fullname && touched.buyer && touched.buyer[index] && touched.buyer[index].fullname && <div className="op-error-message">{errors.buyer[index].fullname}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "identification_type") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "identification_type")}</Form.Label>
                                          <FormSelect
                                            options={identityTypeOptions}
                                            name={`buyer[${index}].identification_type`}
                                            isSearchable={true}
                                            valueDefault={identityTypeOptions.find((data) => data.value === record.identification_type)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].identification_type = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].identification_type && touched.buyer && touched.buyer[index] && touched.buyer[index].identification_type && <div className="op-error-message">{errors.buyer[index].identification_type}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "identification_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "identification_number")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].identification_id`}
                                            value={record.identification_id}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].identification_id = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].identification_id && touched.buyer && touched.buyer[index] && touched.buyer[index].identification_id && <div className="op-error-message">{errors.buyer[index].identification_id}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "occupation") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "occupation")}</Form.Label>
                                          <FormSelect
                                            options={occupationOptions}
                                            name={`buyer[${index}].occupation`}
                                            isSearchable={true}
                                            valueDefault={occupationOptions.find((data) => data.value === record.occupation)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].occupation = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].occupation && touched.buyer && touched.buyer[index] && touched.buyer[index].occupation && <div className="op-error-message">{errors.buyer[index].occupation}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "monthly_income") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "monthly_income")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].monthly_income`}
                                            value={record.monthly_income_str}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];

                                              let inputValue = e.target.value.replace(/\D/g, "");
                                              if (inputValue.length > 0) {
                                                inputValue = (parseInt(inputValue, 10) / 100).toFixed(2);
                                                updatedBuyer[index].monthly_income = inputValue;
                                                inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                              } else {
                                                inputValue = "0.00";
                                                updatedBuyer[index].monthly_income = "0.00";
                                              }

                                              updatedBuyer[index].monthly_income_str = `RM ${inputValue}`;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].monthly_income && touched.buyer && touched.buyer[index] && touched.buyer[index].monthly_income && <div className="op-error-message">{errors.buyer[index].monthly_income}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "dob") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "dob")}</Form.Label>
                                          <FormDate2
                                            value={record.dob ? moment(new Date(record.dob)).format("YYYY-MM-DD") : ""}
                                            name={`buyer[${index}].dob`}
                                            fromYear={1800}
                                            toYear={2024}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].dob = e;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].dob && touched.buyer && touched.buyer[index] && touched.buyer[index].dob && <div className="op-error-message">{errors.buyer[index].dob}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "gender") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "gender")}</Form.Label>
                                          <FormSelect
                                            options={genderOptions}
                                            name={`buyer[${index}].gender`}
                                            isSearchable={true}
                                            valueDefault={genderOptions.find((data) => data.value === record.gender)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].gender = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].gender && touched.buyer && touched.buyer[index] && touched.buyer[index].gender && <div className="op-error-message">{errors.buyer[index].gender}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "race") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "race")}</Form.Label>
                                          <FormSelect
                                            options={raceOptions}
                                            name={`buyer[${index}].race`}
                                            isSearchable={true}
                                            valueDefault={raceList.find((data) => data.value === record.race)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].race = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].race && touched.buyer && touched.buyer[index] && touched.buyer[index].race && <div className="op-error-message">{errors.buyer[index].race}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "nationality") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "nationality")}</Form.Label>
                                          <FormSelect
                                            options={nationalityOptions}
                                            name={`buyer[${index}].nationality`}
                                            isSearchable={true}
                                            valueDefault={nationalityOptions.find((data) => data.value === record.nationality)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].nationality = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].nationality && touched.buyer && touched.buyer[index] && touched.buyer[index].nationality && <div className="op-error-message">{errors.buyer[index].nationality}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "marital_status") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "marital_status")}</Form.Label>
                                          <FormSelect
                                            options={maritalStatusOptions}
                                            name={`buyer[${index}].marital_status`}
                                            isSearchable={true}
                                            valueDefault={maritalStatusOptions.find((data) => data.value === record.marital_status)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].marital_status = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].marital_status && touched.buyer && touched.buyer[index] && touched.buyer[index].marital_status && <div className="op-error-message">{errors.buyer[index].marital_status}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "email_address") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "email_address")}</Form.Label>
                                          <Form.Control
                                            type="email"
                                            name={`buyer[${index}].email_address`}
                                            value={record.email_address}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].email_address = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].email_address && touched.buyer && touched.buyer[index] && touched.buyer[index].email_address && <div className="op-error-message">{errors.buyer[index].email_address}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "mobile_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "mobile_number")}</Form.Label>
                                          <InputGroup>
                                            <FormSelect
                                              options={prefixOptions}
                                              isSearchable={true}
                                              valueDefault={prefixOptions.find((data) => data.value === record.mobile_number_prefix)}
                                              onChange={(e) => {
                                                const updatedBuyer = [...values.buyer];
                                                updatedBuyer[index].mobile_number_region = e.regionCode;
                                                updatedBuyer[index].mobile_number_prefix = e.value;
                                                setFieldValue("buyer", updatedBuyer);
                                              }}
                                              width="80px"
                                              borderRight={true}
                                            />
                                            <Form.Control
                                              className="text-uppercase"
                                              type="text"
                                              name={`buyer[${index}].mobile_number`}
                                              value={record.mobile_number}
                                              onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === "" || re.test(e.target.value)) {
                                                  const updatedBuyer = [...values.buyer];
                                                  updatedBuyer[index].mobile_number = e.target.value;
                                                  setFieldValue("buyer", updatedBuyer);
                                                }
                                              }}
                                            />
                                          </InputGroup>
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].mobile_number && touched.buyer && touched.buyer[index] && touched.buyer[index].mobile_number && <div className="op-error-message">{errors.buyer[index].mobile_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "office_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "office_number")}</Form.Label>
                                          <InputGroup>
                                            <FormSelect
                                              options={prefixOptions}
                                              isSearchable={true}
                                              valueDefault={prefixOptions.find((data) => data.value === record.office_number_prefix)}
                                              onChange={(e) => {
                                                const updatedBuyer = [...values.buyer];
                                                updatedBuyer[index].office_number_region = e.regionCode;
                                                updatedBuyer[index].office_number_prefix = e.value;
                                                setFieldValue("buyer", updatedBuyer);
                                              }}
                                              width="80px"
                                              borderRight={true}
                                            />
                                            <Form.Control
                                              className="text-uppercase"
                                              type="text"
                                              value={record.office_number}
                                              name={`buyer[${index}].office_number`}
                                              onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === "" || re.test(e.target.value)) {
                                                  const updatedBuyer = [...values.buyer];
                                                  updatedBuyer[index].office_number = e.target.value;
                                                  setFieldValue("buyer", updatedBuyer);
                                                }
                                              }}
                                            />
                                          </InputGroup>
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].office_number && touched.buyer && touched.buyer[index] && touched.buyer[index].office_number && <div className="op-error-message">{errors.buyer[index].office_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "fax_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "fax_number")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].fax_number`}
                                            value={record.fax_number}
                                            onChange={(e) => {
                                              const re = /^[0-9\b]+$/;
                                              if (e.target.value === "" || re.test(e.target.value)) {
                                                const updatedBuyer = [...values.buyer];
                                                updatedBuyer[index].fax_number = e.target.value;
                                                setFieldValue("buyer", updatedBuyer);
                                              }
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].fax_number && touched.buyer && touched.buyer[index] && touched.buyer[index].fax_number && <div className="op-error-message">{errors.buyer[index].fax_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "address1") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "address1")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].address1`}
                                            value={record.address1}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].address1 = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].address1 && touched.buyer && touched.buyer[index] && touched.buyer[index].address1 && <div className="op-error-message">{errors.buyer[index].address1}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "address2") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "address2")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].address2`}
                                            value={record.address2}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].address2 = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].address2 && touched.buyer && touched.buyer[index] && touched.buyer[index].address2 && <div className="op-error-message">{errors.buyer[index].address2}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "address3") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "address3")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].address3`}
                                            value={record.address3}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].address3 = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                        </Form.Group>
                                        {errors.buyer && errors.buyer[index] && errors.buyer[index].address3 && touched.buyer && touched.buyer[index] && touched.buyer[index].address3 && <div className="op-error-message">{errors.buyer[index].address3}</div>}
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "postcode") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "postcode")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].postal_code`}
                                            value={record.postal_code}
                                            onChange={(e) => {
                                              const re = /^[0-9\b]+$/;
                                              if (e.target.value === "" || re.test(e.target.value)) {
                                                const updatedBuyer = [...values.buyer];
                                                updatedBuyer[index].postal_code = e.target.value;
                                                setFieldValue("buyer", updatedBuyer);
                                              }
                                            }}
                                          />

                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].postal_code && touched.buyer && touched.buyer[index] && touched.buyer[index].postal_code && <div className="op-error-message">{errors.buyer[index].postal_code}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "country") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "country")}</Form.Label>
                                          <FormSelect
                                            options={country}
                                            name={`buyer[${index}].country`}
                                            valueDefault={country.find((data) => data.value === record.country)}
                                            isSearchable={true}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].country = e.value;
                                              updatedBuyer[index].state = "";
                                              updatedBuyer[index].city = "";
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].country && touched.buyer && touched.buyer[index] && touched.buyer[index].country && <div className="op-error-message">{errors.buyer[index].country}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "state") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "state")}</Form.Label>
                                          <FormSelect
                                            name={`buyer[${index}].state`}
                                            options={state.filter((data) => data.country === record.country)}
                                            valueDefault={state.find((data) => data.value === record.state)}
                                            isSearchable={true}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].state = e.value;
                                              updatedBuyer[index].city = "";
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].state && touched.buyer && touched.buyer[index] && touched.buyer[index].state && <div className="op-error-message">{errors.buyer[index].state}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "city") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "city")}</Form.Label>
                                          <FormSelect
                                            name={`buyer[${index}].city`}
                                            options={city.filter((data) => data.state === record.state)}
                                            valueDefault={city.find((data) => data.value === record.city)}
                                            isSearchable={true}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].city = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].city && touched.buyer && touched.buyer[index] && touched.buyer[index].city && <div className="op-error-message">{errors.buyer[index].city}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "sst_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "sst_number")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].sst_number`}
                                            value={record.sst_number}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].sst_number = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].sst_number && touched.buyer && touched.buyer[index] && touched.buyer[index].sst_number && <div className="op-error-message">{errors.buyer[index].sst_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "tax_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "tax_number")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].tax_number`}
                                            value={record.tax_number}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].tax_number = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].tax_number && touched.buyer && touched.buyer[index] && touched.buyer[index].tax_number && <div className="op-error-message">{errors.buyer[index].tax_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row>
                                    {setBuyerShowField(index, "emergency_contact_fullname") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "emergency_contact_fullname")}</Form.Label>
                                          <Form.Control
                                            className="text-uppercase"
                                            type="text"
                                            name={`buyer[${index}].eme_full_name`}
                                            value={record.eme_full_name}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].eme_full_name = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].eme_full_name && touched.buyer && touched.buyer[index] && touched.buyer[index].eme_full_name && <div className="op-error-message">{errors.buyer[index].emergency_contact_fullname}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "emergency_contact_relation") && (
                                      <Col xxl={6}>
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "emergency_contact_relation")}</Form.Label>
                                          <FormSelect
                                            options={relationOptions}
                                            name={`buyer[${index}].eme_relation`}
                                            isSearchable={true}
                                            valueDefault={relationOptions.find((data) => data.value === record.eme_relation)}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].eme_relation = e.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].eme_relation && touched.buyer && touched.buyer[index] && touched.buyer[index].eme_relation && <div className="op-error-message">{errors.buyer[index].eme_relation}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "emergency_contact_mobile_number") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "emergency_contact_mobile_number")}</Form.Label>
                                          <InputGroup>
                                            <FormSelect
                                              options={prefixOptions}
                                              isSearchable={true}
                                              valueDefault={prefixOptions.find((data) => data.value === record.eme_mobile_number_prefix)}
                                              onChange={(e) => {
                                                const updatedBuyer = [...values.buyer];
                                                updatedBuyer[index].eme_mobile_number_region = e.regionCode;
                                                updatedBuyer[index].eme_mobile_number_prefix = e.value;
                                                setFieldValue("buyer", updatedBuyer);
                                              }}
                                              width="80px"
                                              borderRight={true}
                                            />
                                            <Form.Control
                                              className="text-uppercase"
                                              type="text"
                                              value={record.eme_mobile_number}
                                              name={`buyer[${index}].eme_mobile_number`}
                                              onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === "" || re.test(e.target.value)) {
                                                  const updatedBuyer = [...values.buyer];
                                                  updatedBuyer[index].eme_mobile_number = e.target.value;
                                                  setFieldValue("buyer", updatedBuyer);
                                                }
                                              }}
                                            />
                                          </InputGroup>
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].eme_mobile_number && touched.buyer && touched.buyer[index] && touched.buyer[index].eme_mobile_number && <div className="op-error-message">{errors.buyer[index].eme_mobile_number}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "emergency_contact_email") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group>
                                          <Form.Label>{setBuyerLabelField(index, "emergency_contact_email")}</Form.Label>
                                          <Form.Control
                                            type="email"
                                            value={record.eme_email}
                                            name={`buyer[${index}].eme_email`}
                                            onChange={(e) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].eme_email = e.target.value;
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].eme_email && touched.buyer && touched.buyer[index] && touched.buyer[index].eme_email && <div className="op-error-message">{errors.buyer[index].eme_email}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row>
                                    {setBuyerShowField(index, "identification_card_front") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group className="w-100">
                                          <Form.Label>{setBuyerLabelField(index, "identification_card_front")}</Form.Label>
                                          <FilePond
                                            allowMultiple={false}
                                            name={`buyer[${index}].idFrontName`}
                                            maxFiles={1}
                                            files={record.idFront}
                                            allowProcess={record.idFrontName ? false : true}
                                            credits={false}
                                            onupdatefiles={(fileItems) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].idFront = fileItems.map((fileItem) => fileItem.file);
                                            }}
                                            onremovefile={() => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].idFrontName = "";
                                              updatedBuyer[index].idFront = [];
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                            instantUpload={false}
                                            server={{
                                              process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                const formData = new FormData();
                                                const reader = new FileReader();

                                                formData.append("folder_name", "ic");
                                                formData.append("company_id", session.company_id);
                                                formData.append("file_type", file.type.split("/")[1]);
                                                formData.append("mime_type", file.type);
                                                formData.append("utoken", session.user_ac_token);
                                                formData.append("ctoken", session.company_token);

                                                reader.onload = (e) => {
                                                  formData.append("data", e.target.result);
                                                  axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_assets_bookingv2.php`, formData).then((response) => {
                                                    const data = response.data;
                                                    if (Number(data.status) === 0) {
                                                      load(data);
                                                      const updatedBuyer = [...values.buyer];
                                                      updatedBuyer[index].idFrontName = data.file_name;
                                                      setFieldValue("buyer", updatedBuyer);
                                                    }
                                                  });
                                                };
                                                reader.readAsDataURL(file);
                                              },
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].idFrontName && touched.buyer && touched.buyer[index] && touched.buyer[index].idFrontName && <div className="op-error-message">{errors.buyer[index].idFrontName}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {setBuyerShowField(index, "identification_card_back") && (
                                      <Col xxl={6} className="mb-3">
                                        <Form.Group className="w-100">
                                          <Form.Label>{setBuyerLabelField(index, "identification_card_back")}</Form.Label>
                                          <FilePond
                                            allowMultiple={false}
                                            name={`buyer[${index}].idBack`}
                                            maxFiles={1}
                                            files={record.idBack}
                                            allowProcess={record.idBackName ? false : true}
                                            credits={false}
                                            onupdatefiles={(fileItems) => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].idBack = fileItems.map((fileItem) => fileItem.file);
                                            }}
                                            onremovefile={() => {
                                              const updatedBuyer = [...values.buyer];
                                              updatedBuyer[index].idBackName = "";
                                              updatedBuyer[index].idBack = [];
                                              setFieldValue("buyer", updatedBuyer);
                                            }}
                                            instantUpload={false}
                                            server={{
                                              process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                const formData = new FormData();
                                                const reader = new FileReader();

                                                formData.append("folder_name", "ic");
                                                formData.append("company_id", session.company_id);
                                                formData.append("file_type", file.type.split("/")[1]);
                                                formData.append("mime_type", file.type);
                                                formData.append("utoken", session.user_ac_token);
                                                formData.append("ctoken", session.company_token);

                                                reader.onload = (e) => {
                                                  formData.append("data", e.target.result);
                                                  axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_assets_bookingv2.php`, formData).then((response) => {
                                                    const data = response.data;
                                                    if (Number(data.status) === 0) {
                                                      load(data);
                                                      const updatedBuyer = [...values.buyer];
                                                      updatedBuyer[index].idBackName = data.file_name;
                                                      setFieldValue("buyer", updatedBuyer);
                                                    }
                                                  });
                                                };
                                                reader.readAsDataURL(file);
                                              },
                                            }}
                                          />
                                          {errors.buyer && errors.buyer[index] && errors.buyer[index].idBackName && touched.buyer && touched.buyer[index] && touched.buyer[index].idBackName && <div className="op-error-message">{errors.buyer[index].idBackName}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}
                                  </Row>
                                </Card.Body>
                              </div>
                            ))}
                          </div>

                          <div className="position-relative">
                            <ListGroup className="position-fixed" style={{ top: "15%", right: "1%" }}>
                              <ListGroup.Item
                                className="d-flex justify-content-center align-items-center py-3 active cursor-pointer"
                                onClick={() => {
                                  if (values.buyer.length === Number(bookingInfo.max_buyer)) {
                                    Swal.fire({
                                      icon: "warning",
                                      title: "opps...",
                                      text: "You've reached a limit to add more buyer",
                                      timer: 2000,
                                    });
                                    return;
                                  }

                                  const record = {
                                    role: "joint",
                                    fullname: "",
                                    salutation: "",
                                    identification_type: "",
                                    identification_id: "",
                                    occupation: "",
                                    monthly_income: "",
                                    dob: "",
                                    gender: "",
                                    race: "",
                                    nationality: "",
                                    marital_status: "",
                                    email_address: "",
                                    mobile_number_region: "MY",
                                    mobile_number_prefix: "60",
                                    mobile_number: "",
                                    office_number_region: "MY",
                                    office_number_prefix: "60",
                                    office_number: "",
                                    fax_number: "",
                                    address1: "",
                                    address2: "",
                                    address3: "",
                                    postal_code: "",
                                    country: "",
                                    state: "",
                                    city: "",
                                    eme_full_name: "",
                                    eme_relation: "",
                                    eme_mobile_number_region: "MY",
                                    eme_mobile_number_prefix: "60",
                                    eme_mobile_number: "",
                                    eme_email: "",
                                    idFront: [],
                                    idBack: [],
                                    idFrontName: "",
                                    idBackName: "",
                                    sst_number: "",
                                    tax_number: "",
                                  };

                                  setValues((prevValues) => ({
                                    ...prevValues,
                                    buyer: [...prevValues.buyer, record],
                                  }));

                                  setBuyerDetails((prevValues) => ({
                                    buyer: [...prevValues.buyer, record],
                                  }));
                                }}
                              >
                                <FontAwesomeIcon icon={faUserPlus} size="lg" />
                              </ListGroup.Item>
                              {values.buyer.map((record, index) => (
                                <ListGroup.Item
                                  key={index}
                                  className="d-flex justify-content-center align-items-center py-3 cursor-pointer"
                                  onClick={() => {
                                    buyerRefs[index].current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "start",
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faUser} size="lg" className="me-1" /> <span style={{ fontSize: 10 }}>{index + 1}</span>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                        </Card.Body>

                        <Card.Footer className="footer">
                          <Button type="button" variant="" className="op-button op-primary-color text-light" onClick={() => sendBackBookingDetail(values)}>
                            Previous
                          </Button>
                          <Button type="submit" variant="" className="op-button op-primary-color text-light float-end">
                            Next
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Form>
                  )}
                </Formik>
              )}

              {step === 3 && (
                <Form>
                  <Card>
                    <Card.Body className="content">
                      {setShowSection([bookingFormat.booking_fee_show, bookingFormat.financing_show, bookingFormat.first_time_buyer_show, bookingFormat.bumiputera_show]) && (
                        <>
                          <Card.Header className="shadow-sm">Booking Information</Card.Header>
                          <Card.Body>
                            <Row>
                              {Number(bookingFormat.booking_fee_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.booking_fee_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.booking_fee ? setCurrency(bookingDetails.booking_fee) : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {Number(bookingFormat.financing_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.financing_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.financing ? setFinancing(bookingDetails.financing) : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {Number(bookingFormat.first_time_buyer_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.first_time_buyer_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.first_time_buyer ? setFirstTimeBuyer(bookingDetails.first_time_buyer) : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {Number(bookingFormat.bumiputera_show) === 1 && (
                                <Col xxl={4}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.bumiputera_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.bumiputera ? setBumiputera(bookingDetails.bumiputera) : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}
                            </Row>
                          </Card.Body>
                        </>
                      )}

                      {setShowSection([bookingFormat.payment_method_show, bookingFormat.payment_reference_show, bookingFormat.payment_receipt_show, bookingFormat.bank_show]) && (
                        <>
                          <Card.Header className="shadow-sm">Payment Details</Card.Header>
                          <Card.Body>
                            <Row>
                              {Number(bookingFormat.payment_method_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.payment_method_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.payment_method ? setPaymentMethod(bookingDetails.payment_method) : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {bookingDetails.payment_method === "bank_draft" && Number(bookingFormat.bank_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.bank_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.bank_name ? bookingDetails.bank_name : "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {Number(bookingFormat.payment_reference_show) === 1 && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.payment_reference_label}</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.payment_reference || "-"}</span>
                                  </Form.Label>
                                </Col>
                              )}
                            </Row>
                            {Number(bookingFormat.payment_receipt_show) === 1 && (
                              <Row className="file-display">
                                <Col xxl={6} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{bookingFormat.payment_receipt_label}</span> <br />
                                  </Form.Label>
                                  {bookingDetails.payment_receipt.length > 0 && <FilePond className="preview" allowMultiple={false} allowProcess={false} allowReplace={false} allowRevert={false} maxFiles={1} files={bookingDetails.payment_receipt} credits={false} />}
                                </Col>
                              </Row>
                            )}
                          </Card.Body>
                        </>
                      )}

                      {(setShowSection([bookingFormat.source_show]) || customField.length > 0) && (
                        <>
                          <Card.Header className="shadow-sm">Additional Information</Card.Header>
                          <Card.Body>
                            <Row>
                              {Number(bookingFormat.source_show) === 1 && (
                                <Col xxl={4}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">Source</span> <br />
                                    <span className="text-muted text-uppercase">{bookingDetails.source_title}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {customField.length > 0 && (
                                <>
                                  {bookingDetails.custom_field.map((record, index) => (
                                    <Col xxl={4} key={index} className="mb-3">
                                      <Form.Label>
                                        <span className="fw-bold text-uppercase">{record.cf_label}</span> <br />
                                        <span className="text-muted text-uppercase">{record.fd_values}</span>
                                      </Form.Label>
                                    </Col>
                                  ))}
                                </>
                              )}
                            </Row>
                          </Card.Body>
                        </>
                      )}

                      {buyerDetails.buyer.map((record, index) => (
                        <div key={index}>
                          <Card.Header className="shadow-sm">Buyer {index + 1}</Card.Header>
                          <Card.Body>
                            <Row>
                              {setBuyerShowField(index, "fullname") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "fullname")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.fullname || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "salutation") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "salutation")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.salutation || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "identification_type") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "identification_type")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.identification_type || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "identification_number") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "identification_number")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.identification_id || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "occupation") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "occupation")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.occupation || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "monthly_income") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "monthly_income")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.monthly_income ? setCurrency(record.monthly_income) : "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "dob") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "dob")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.dob ? moment(new Date(record.dob)).format("ll") : "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "gender") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "gender")}</span> <br />
                                    <span className="text-muted text-capitalize">{record.gender || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "race") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "race")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.race || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "nationality") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "nationality")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.nationality || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "marital_status") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "marital_status")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.marital_status || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "email_address") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "email_address")}</span> <br />
                                    <span className="text-muted">{record.email_address || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "mobile_number") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "mobile_number")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.mobile_number ? setMobileNumber(record.mobile_number_prefix, record.mobile_number) : "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "office_number") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "office_number")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.office_number ? setMobileNumber(record.office_number_prefix, record.office_number) : "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "fax_number") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "fax_number")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.fax_number || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "address1") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "address1")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.address1 || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "address2") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "address2")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.address2 || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "address3") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "address3")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.address3 || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "postcode") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "postcode")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.postal_code || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "country") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "country")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.country || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "state") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "state")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.state || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "city") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "city")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.city || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "emergency_contact_fullname") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "emergency_contact_fullname")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.eme_full_name || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "emergency_contact_relation") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "emergency_contact_relation")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.eme_relation || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "emergency_contact_mobile_number") && (
                                <Col xxl={4} className="mb-3">
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "emergency_contact_mobile_number")}</span> <br />
                                    <span className="text-muted text-uppercase">{record.eme_mobile_number ? setMobileNumber(record.eme_mobile_number_prefix, record.eme_mobile_number) : "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "emergency_contact_email") && (
                                <Col xxl={4}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "emergency_contact_email")}</span> <br />
                                    <span className="text-muted">{record.eme_email || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "sst_number") && (
                                <Col xxl={4}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "sst_number")}</span> <br />
                                    <span className="text-muted">{record.sst_number || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}

                              {setBuyerShowField(index, "tax_number") && (
                                <Col xxl={4}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "tax_number")}</span> <br />
                                    <span className="text-muted">{record.tax_number || "Unspecified"}</span>
                                  </Form.Label>
                                </Col>
                              )}
                            </Row>

                            <Row className="file-display">
                              {setBuyerShowField(index, "identification_card_front") && (
                                <Col xxl={6}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "identification_card_front")}</span> <br />
                                  </Form.Label>
                                  {record.idFront.length > 0 && <FilePond className="preview" allowMultiple={false} allowProcess={false} allowReplace={false} allowRevert={false} maxFiles={1} files={record.idFront} credits={false} />}
                                </Col>
                              )}

                              {setBuyerShowField(index, "identification_card_back") && (
                                <Col xxl={6}>
                                  <Form.Label>
                                    <span className="fw-bold text-uppercase">{setBuyerLabelField(index, "identification_card_back")}</span> <br />
                                  </Form.Label>
                                  {record.idBack.length > 0 && <FilePond className="preview" allowMultiple={false} allowProcess={false} allowReplace={false} allowRevert={false} maxFiles={1} files={record.idBack} credits={false} />}
                                </Col>
                              )}
                            </Row>
                          </Card.Body>
                        </div>
                      ))}
                    </Card.Body>
                    <Card.Footer className="footer">
                      <Button type="button" variant="" className="op-button op-primary-color text-light" onClick={() => setStep(2)}>
                        Previous
                      </Button>
                      <Button type="button" variant="" className="op-button op-primary-color text-light float-end" disabled={disableBtn} onClick={() => sendBookingForm()}>
                        Next
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </section>
          </section>
        </div>
      )}
    </Container>
  );
};

export default BookingForm;
