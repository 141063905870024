import { useState, useRef, useMemo } from "react";
import { Card, Row, Col, Stack } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { setCurrency } from "../../lib/js/Function";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-duotone-svg-icons";

const DefaultLeadAnalytic = ({ chartData, gridData, isGrid, isBu, isLost, isLeadStat, isDsCat, isDs, leadStatData, dsCatPieData, dsPieData, buPieData, LostReasonPieData, chartColor}) => {
    const gridRef = useRef(null);

    const [visibleBar, setVisibleBar] = useState({
        data1: false,
        data2: false,
        data3: false
    });

    const [fullSize, setFullSize] = useState({
        chart1: false,
        chart2: false,
        chart3: false,
        chart4: false
    });

    const containerStyle = useMemo(() => ({ width: "100%", height: "40vh", paddingTop: 8 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: 'Owner Name',
            field: 'user_name',
            resizable: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: 'Overall Leads',
            field: 'total_deal',
            resizable: true,
            width: 250,
            hide: leadStatData.length > 0 ? true : false
        },
        {
            headerName: 'Won',
            field: 'won_count',
            resizable: true,
            width: 250,
            hide: (leadStatData.includes('won') || leadStatData.length === 0) ? false : true
        },
        {
            headerName: 'Won Value',
            field: 'won_value',
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return setCurrency(params.data.won_value);
                }
            },
            hide: (leadStatData.includes('won') || leadStatData.length === 0) ? false : true
        },
        {
            headerName: 'Lost',
            field: 'lost_count',
            resizable: true,
            width: 250,
            hide: (leadStatData.includes('lost') || leadStatData.length === 0) ? false : true
        },
        {
            headerName: 'Lost Value',
            field: 'lost_value',
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return setCurrency(params.data.lost_value);
                }
            },
            hide: (leadStatData.includes('lost') || leadStatData.length === 0) ? false : true
        },
        {
            headerName: 'Deleted',
            field: 'deleted_count',
            resizable: true,
            width: 250,
            hide: (leadStatData.includes('deleted') || leadStatData.length === 0) ? false : true
        }
    ]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        {payload.map((rec, i) => (
                            <div style={{color: rec.color}}>{`${rec.name}: ${rec.value}`}</div>
                        ))}
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    const CustomTooltip2 = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        {payload.map((rec, i) => (
                            <div style={{color: rec.color}}>{`Total deals: ${rec.value}`}</div>
                        ))}
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    const handleLegendClick = (item) => {
        setVisibleBar((prev) => ({
            ...prev,
            [item.dataKey]: !prev[item.dataKey]
        }));
    }

    const handleResize = (name) => {
        setFullSize((prev) => ({
            ...prev,
            [name]: !prev[name]
        }));
    }

    const handleDownload = () => {
        var params = {
            fileName : 'leads-analytic-dataExport.csv',
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <>
            <div className="mb-2">
                <Card className="border-0 shadow-sm">
                    <Card.Body>
                        <ResponsiveContainer width="98%" height={530}>
                            <BarChart
                                width={500}
                                height={300}
                                layout="vertical"
                                data={chartData}
                                margin={{
                                    top: 15,
                                    right: 10,
                                    left: 40,
                                    bottom: 5,
                                    }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number"/>
                                <YAxis dataKey="name" type="category" />
                                <Tooltip content={<CustomTooltip/>}/>
                                <Legend onClick={handleLegendClick}/>
                                <Bar dataKey="data1" stackId="a" fill="#0089cb" name={'Won'} hide={visibleBar.data1}/>
                                <Bar dataKey="data2" stackId="a" fill="#FE5F55" name={'Lost'} hide={visibleBar.data2}/>
                                <Bar dataKey="data3" stackId="a" fill="#00bf9d" name={'Deleted'} hide={visibleBar.data3}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Card.Body>
                </Card>
            </div>
            <div className="my-4">
                <Row sm={12}>
                    {(isDsCat || isDs || isBu || isLeadStat) && <Col sm={fullSize.chart1 ? 12 : 6}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Stack direction="horizontal" gap={2}>
                                    <h6>Source Category {leadStatData.length > 0 ? `(${leadStatData.toString().replaceAll(',', ' ').toUpperCase()})` : ''}</h6>
                                    <FontAwesomeIcon icon={faExpand} className="ms-auto" style={{cursor: 'pointer'}} onClick={() => {handleResize('chart1')}} size="lg"/>
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={dsCatPieData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                            }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey={'value'} type="number"/>
                                        <YAxis dataKey={'name'} type="category"/>
                                        <Tooltip content={<CustomTooltip2/>}/>
                                        <Legend
                                            payload={
                                                dsCatPieData.map(
                                                  (item, index) => ({
                                                    id: item.name,
                                                    type: "square",
                                                    value: `${item.name}`,
                                                    color: '#0089cb'
                                                  })
                                                )
                                            }
                                        />
                                        <Bar dataKey={'value'} name="name" fill="#0089cb"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>}
                    {(isDsCat || isDs || isBu || isLeadStat) && <Col sm={fullSize.chart2 ? 12 : 6}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Stack direction="horizontal" gap={2}>
                                    <h6>Source {leadStatData.length > 0 ? `(${leadStatData.toString().replaceAll(',', ' ').toUpperCase()})` : ''}</h6>
                                    <FontAwesomeIcon icon={faExpand} className="ms-auto" style={{cursor: 'pointer'}} onClick={() => {handleResize('chart2')}} size="lg"/>
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={400}>       
                                    <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={dsPieData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                            }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey={'value'} type="number"/>
                                        <YAxis dataKey={'name'} type="category"/>
                                        <Tooltip content={<CustomTooltip2/>}/>
                                        <Legend
                                            payload={
                                                dsPieData.map(
                                                  (item, index) => ({
                                                    id: item.name,
                                                    type: "square",
                                                    value: `${item.name}`,
                                                    color: '#0089cb'
                                                  })
                                                )
                                            }
                                        />
                                        <Bar dataKey={'value'} name="name" fill="#0089cb"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>}
                </Row>
                <Row sm={12} className="mb-2">
                    {(isDsCat || isDs || isBu || isLeadStat) && <Col sm={fullSize.chart3 ? 12 : 6}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Stack direction="horizontal" gap={2}>
                                    <h6>Business Unit {leadStatData.length > 0 ? `(${leadStatData.toString().replaceAll(',', ' ').toUpperCase()})` : ''}</h6>
                                    <FontAwesomeIcon icon={faExpand} className="ms-auto" style={{cursor: 'pointer'}} onClick={() => {handleResize('chart3')}} size="lg"/>
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={buPieData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                            }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey={'value'} type="number"/>
                                        <YAxis dataKey={'name'} type="category"/>
                                        <Tooltip content={<CustomTooltip2/>}/>
                                        <Legend
                                            payload={
                                                buPieData.map(
                                                  (item, index) => ({
                                                    id: item.name,
                                                    type: "square",
                                                    value: `${item.name}`,
                                                    color: '#0089cb'
                                                  })
                                                )
                                            }
                                        />
                                        <Bar dataKey={'value'} name="name" fill="#0089cb"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>}
                    {isLost && <Col sm={fullSize.chart4 ? 12 : 6}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Stack direction="horizontal" gap={2}>
                                    <h6>Lost Reason {leadStatData.length > 0 ? `(${leadStatData.toString().replaceAll(',', ' ').toUpperCase()})` : ''}</h6>
                                    <FontAwesomeIcon icon={faExpand} className="ms-auto" style={{cursor: 'pointer'}} onClick={() => {handleResize('chart4')}} size="lg"/>
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={400}>       
                                    <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={LostReasonPieData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                            }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey={'value'} type="number"/>
                                        <YAxis dataKey={'name'} type="category"/>
                                        <Tooltip content={<CustomTooltip2/>}/>
                                        <Legend
                                            payload={
                                                LostReasonPieData.map(
                                                  (item, index) => ({
                                                    id: item.name,
                                                    type: "square",
                                                    value: `${item.name}`,
                                                    color: '#0089cb'
                                                  })
                                                )
                                            }
                                        />
                                        <Bar dataKey={'value'} name="name" fill="#0089cb"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>}
                </Row>
            </div>
            <div>
                <button type="button" className="btn op-button op-primary-color text-light" onClick={() => {handleDownload()}}>Download</button>
            </div>
            <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={reportColumn}
                        rowData={gridData}
                        defaultColDef={reportColDef}
                        rowHeight={50}
                        // pagination={false}
                        // paginationPageSize={30}
                        paginationPageSizeSelector={false}
                    />
                </div>
            </div>
        </>
    );
}

export default DefaultLeadAnalytic;