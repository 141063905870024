import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Col, Container, Row, Stack, Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect } from "../includes/FormCustom";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import { useTranslation } from "react-i18next";
import "../lib/css/users.css"

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const Users = () => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const cToken = session.company_token;
  const uToken = session.user_ac_token;
  const companyId = session.company_id;
  const { Formik } = formik;
  const initialized = useRef(false);
  const [isLoading, setisLoading] = useState(false);
  const [isAddUser, setisAddUser] = useState(false);
  const [role, setrole] = useState([]);
  const [user, setuser] = useState([]);
  const [team, setteam] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showEditUser, setShowEditUser] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [permission, setPermission] = useState([]);
  const [pipeline, setPipeline] = useState([]);
  const [selectedUserrole, setSelectedUserrole] = useState(null);
  const [selectedUserPermission, setSelectedUserPermission] = useState(null);
  const [selectedReadWrite, setSelectedReadWrite] = useState(null);
  const [selectedWebLead, setSelectedWebLead] = useState(null);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [selectedAvanser, setSelectedAvanser] = useState(null);
  const [selectedReaAgent, setSelectedReaAgent] = useState(null);
  const [selectedHideAcc, setSelectedHideAcc] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const [displayUserImage, setDisplayUserImage] = useState(true);
  const [updatedPermission, setUpdatedPermission] = useState([]);
  const [updatedWebLead, setUpdatedWebLead] = useState([]);
  const [gridCount, setGridCount] = useState(0);
  const [emailError, setEmailError] = useState('');

  const [imageSrc, setImageSrc] = useState(localStorage.getItem('imageSrc'));
  const [bannerImg, setBannerImg] = useState("");
  const filePondRef = useRef();

  const addUserSchema = yup.object().shape({
    user_name: yup.string().required(t("Users_name_is_required")),
    user_email: yup.string().email('Invalid email address').required(t("Users_email_is_required")),
    user_role: yup.object().nullable().required(t("Users_user_role_is_required")),
  });

  const editUserSchema = yup.object().shape({
    user_name: yup.string().required(t("Users_name_is_required")),
    default_pipeline: yup.string().required(t("Users_default_pipeline_is_required")),
  });

  // GET FUNCTION --------------------------------------------------

  const getAllPipeline = () => {
    axios
      .get(`ws/ws_setting.php`, {
        params: {
          company: companyId,
          task: 'getAllPipeline'
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.pipeline_title;
            record.value = record.pipeline_id;
          });
          setPipeline(data.record);
        }
      });
  };

  const handlePipelineChange = (selectedOption) => {
    setSelectedPipeline(selectedOption);
  }

  const getPermission = () => {
    axios
      .get(`ws/ws_setting.php`, {
        params: {
          task: 18,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.permission_title;
            record.value = record.readwrite_id;
          });
          setPermission(data.record);
        }
      });
  };

  const handleReadWritePermission = (selectedOption) => {
    setSelectedReadWrite(selectedOption);
  }

  const getUserRole = () => {
    axios
      .get(`ws/ws_setting.php`, {
        params: {
          task: 3,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.role_title;
            record.value = record.role_id;
          });
          setrole(data.record);
        }
      });
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedUserrole(selectedOption);

    setUserDetails((prev) => ({ ...prev, role_id: selectedOption }));
  };

  const getUser = () => {
    const userTag = selectedTeam ? selectedTeam.value : "";
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user_tag: userTag,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setuser(data.record);
          setGridCount(data.record.length);
        } else {
          setGridCount(0);
          setuser([]);
        }
      });
  };

  useEffect(() => {
    if (selectedTeam !== null) {
      getUser();
    }
  }, [selectedTeam]);

  const updateTeamSelection = (tagIds) => {
    setteam((prevTeam) => {
      return prevTeam.map((teamItem) => {
        return {
          ...teamItem,
          isSelected: tagIds.includes(teamItem.value),
        };
      });
    });
  };

  const getTeam = (loginData) => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "getUserTags",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          const teamOptions = data.record.map((record) => ({
            label: record.user_tag_title,
            value: record.user_tag_id,
          }));

          // Add 'Select All Team' option
          const selectAllOption = {
            label: 'Select All Team',
            value: '',
          };

          setteam([selectAllOption, ...teamOptions]);
        }
      });
  };

  const getUserImageData = async (params) => {
    try {
      const avatarUrl = params.data.user_avatar_url;
      const lastSegment = avatarUrl.substring(avatarUrl.lastIndexOf('/') + 1);
      let userIdImage = `${params.data.user_id}.png`;

      // Check if lastSegment is "genericavatar.jpg"
      if (lastSegment === "genericavatar.jpg") {
        userIdImage = `${params.data.user_id}.png`;
      }

      await axios
        .get(`${session.hostUrl}/${session.hostUrlType}/php/user_image_settings.php`, {
          params: {
            task: "ReadImage",
            user_image: userIdImage || lastSegment
          }
        })
        .then(res => {
          let data = res.data;

          if (data.status === 0) {
            setBannerImg(`data:${data.mime_type};base64,${data.base64}`);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };


  const openEditUser = async (params) => {

    await getUserImageData(params);
    setUserImage(null);
    setDisplayUserImage(true);

    // User image
    const getUserImage = `${session.hostUrl}/${session.hostUrlApiType}/${params.data.user_avatar_url}`
    setUserImage(getUserImage);

    // Default image
    // const defaultImageUrl = `${session.hostUrl}/${session.hostUrlApiType}/assets/account_avatar/genericavatar.jpg`;
    // setDefaultImage(defaultImageUrl);

    axios
      .get("ws/ws_user.php", {
        params: {
          task: "3",
          user: params.data.user_id,
          utoken: uToken,
          ctoken: cToken,
        },
      })
      .then((response) => {
        var responseData = response.data;
        if (responseData) {
          const { avanser, hide_account, rea_agent } = responseData.record;

          setSelectedAvanser({ label: avanser, value: avanser });
          setSelectedHideAcc({ label: hide_account, value: hide_account });
          setSelectedReaAgent({ label: rea_agent, value: rea_agent });

          const tagIds = responseData.record.tag_ids.split(',').map(id => id.trim());
          updateTeamSelection(tagIds);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

    // Set the default role based on params.data.role_id
    const defaultUserRole = {
      label: params.data.role_title,
      value: params.data.role_id,
    };
    setSelectedUserrole(defaultUserRole);

    // Permission active/inactive
    const defaultPermission = {
      value: params.data.permission_id,
    }
    setSelectedUserPermission(defaultPermission);

    // Web lead
    const defaultWebLead = {
      value: params.data.web_lead,
    }
    setSelectedWebLead(defaultWebLead);

    // Read, write, delete
    const defaultReadWrite = {
      value: params.data.readwrite_permission,
    }
    const matchingPermission = permission.find(
      (item) => item.value === defaultReadWrite.value
    );
    setSelectedReadWrite(matchingPermission);

    // Default pipeline
    const defaultPipeline = {
      // value: params.data.default_pipeline,
      value: params.data.default_pipeline === 0 ? '' : params.data.default_pipeline,
    }
    const matchingPipeline = pipeline.find(
      (item) => item.value === defaultPipeline.value
    );
    setSelectedPipeline(matchingPipeline);

    // Set user details and open the modal
    setUserDetails(params.data);
    setShowEditUser(true);
  };

  const closeEditModal = () => {
    setShowEditUser(false);
  };

  const handleTeamChange = (selectedTeam) => {
    setteam((prevTeam) =>
      prevTeam.map((teamItem) =>
        teamItem.value === selectedTeam.value
          ? { ...teamItem, isSelected: !teamItem.isSelected }
          : teamItem
      )
    );
  };

  const handleUpdate = async (values) => {
    try {
      const companyId = userDetails.company_id;
      const userId = userDetails.user_id;
      const username = values.user_name;
      const pipeline = values.default_pipeline;
      const permission = selectedUserPermission.value;
      const readwritePermission = selectedReadWrite.value;
      const roleId = userDetails.role_id;
      const newRoleId = userDetails.role_id.role_id;
      const getroleid = newRoleId ? newRoleId : roleId;

      const contact = values.user_contact;
      const webLead = selectedWebLead.value;
      const notiMedium = userDetails.notification_medium;
      const tagIDs = team.filter((teamItem) => teamItem.isSelected).map((teamItem) => teamItem.value).join(',');

      const response = await axios.get(`ws/ws_user.php`, {
        params: {
          admin_report_enabled: 0,
          avanser: selectedAvanser.value,
          bcc_email_address: "",
          company: companyId,
          contact: contact,
          ctoken: cToken,
          default_pipe: pipeline,
          hide_account: selectedHideAcc.value,
          mobile_conversation_screen: 0,
          no_messages_after_hours: 1,
          noti_medium: notiMedium,
          permission: permission,
          rea_agent: selectedReaAgent.value,
          readwrite: readwritePermission,
          restrict_download: 0,
          role: getroleid,
          send_bulk_email: 0,
          tagIDs: tagIDs,
          task: "updateUser",
          user: userId,
          user_name: username,
          utoken: uToken,
          weblead: webLead
        },
      })

      if (response.data.status === 0) {

        closeEditModal();
        window.location.reload();
      } else {
        console.error('Update failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  // PERMISSION -------------------------------------------------------

  const handlePermissionChange = async (user) => {
    try {
      const newPermissionId = user.permission_id == "1" ? "2" : "1";

      setuser((prevUsers) =>
        prevUsers.map((prevUser) =>
          prevUser.user_id === user.user_id ? { ...prevUser, permission_id: newPermissionId } : prevUser
        )
      );

      const response = await axios.get(`ws/ws_user.php`, {
        params: {
          task: 'updatePermission',
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          permission: Number(newPermissionId),
          user: user.user_id,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setUpdatedPermission(newPermissionId)
      }
    } catch (error) {
      console.error('An unexpected error occurred', error);
    }
  };

  const handleWebLeadChange = async (user) => {
    try {
      setuser((prevUsers) =>
        prevUsers.map((prevUser) =>
          prevUser.user_id === user.user_id ? { ...prevUser, web_lead: 1 - user.web_lead } : prevUser
        )
      );

      const response = await axios.get(`ws/ws_user.php`, {
        params: {
          task: 'updateWebLead',
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          weblead: 1 - user.web_lead, // Use the updated value
          user: user.user_id,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setUpdatedWebLead(1 - user.web_lead);
      }
    } catch (error) {
      console.error('An unexpected error occurred', error);
    }
  };



  // GRID SETUP ------------------------------------------------------

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: t("Users_name"),
      field: "user_name",
      cellRenderer: function (params) {
        if (params.data !== undefined) {
          var initials = params.data.user_name.split("")[0];

          return (
            <div className="d-flex">
              <div className="avatar me-2">
                <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                  <div className="avatar-txt text-uppercase">{initials}</div>
                </div>
              </div>
              <span>{params.data.user_name}</span>
            </div>
          );
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
      // filter: 'agTextColumnFilter',
    },
    {
      headerName: t("Users_email"),
      field: "user_email",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        if (params.data) {
          return (
            <span style={{ cursor: 'text' }}>
              {params.data.user_email}
            </span>
          );
        }
      }
    },
    { headerName: t("Users_contact_number"), field: "user_contact", headerClass: "center", cellClass: "center", filter: 'agTextColumnFilter', },
    { headerName: t("Users_role"), field: "role_title", headerClass: "center", cellClass: "center", filter: 'agTextColumnFilter', },
    {
      headerName: t("Users_permission"),
      field: "permission_id",
      headerClass: "center",
      cellClass: "center",
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          const isChecked = Number(params.data.permission_id) === 1;

          return (
            <Form.Check
              type="switch"
              name="permission"
              checked={isChecked}
              onChange={() => handlePermissionChange(params.data)}
              className="d-flex align-items-center justify-content-center"
            />
          );
        }
      },
    },
    {
      headerName: t("Users_web_lead"),
      field: "web_lead",
      headerClass: "center",
      cellClass: "center",
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          const isChecked = Number(params.data.web_lead);

          return (
            <Form.Check
              type="switch"
              name="webLead"
              checked={isChecked}
              onChange={() => handleWebLeadChange(params.data)}
              className="d-flex align-items-center justify-content-center"
            />
          );
        }
      },
    },
    {
      headerName: "",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          const isChecked = Number(params.data.permission_id) === 1;

          return (
            <button type="button" className="btn btn-light" onClick={() => openEditUser(params)}>
              <FontAwesomeIcon icon={faPencil} size="lg" />
            </button>
          );
        }
      },
    },
  ];

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.user_id.toString();
  }, []);

  // USEEFFECT FUNCTION ----------------------------------------------

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getUserRole(session);
      getTeam(session);
      getPermission(session);
      getAllPipeline(session);
      getUser(session);
    }
  }, [session]);

  // SEARCH TERM -----------------------------------------------------

  useEffect(() => {
    const delay = 500;

    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(searchTerm);
    }, delay);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const getQuickFilterText = (params) => {
    const textFields = ["user_name", "user_email", "user_contact", "role_title"];
    const searchTermLowerCase = delayedSearchTerm.toLowerCase(); // Use delayed search term
    return textFields.map((field) => params.data[field]).join(' ').toLowerCase().includes(searchTermLowerCase);
  };

  // ADD USER ------------------------------------------------------
  const handleSubmitForm = async (values) => {
    try {
      if (!emailError) {
        const formData = new FormData();
        formData.append("name", values.user_name);
        formData.append("email", values.user_email);
        formData.append("contact", values.user_contact || "");
        formData.append("txtRole", values.user_role.value);
        formData.append("permission", values.user_permission);
        formData.append("company_id", session.company_id);
        // formData.append("user_avanser", values.user_avanser);

        await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/add_userV2.php`, formData);

        Swal.fire({
          icon: 'success',
          title: t("Users_success"),
          text: t("Users_user_added_successfully"),
          timer: 1500
        });

        setisAddUser(false);
        getUser();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: 'error',
        title: t("Users_error"),
        text: t("Users_an_error_occured_while_adding_the_user"),
      });
    }
  };


  // CHECK EMAIL -------------------------------------------------

  const checkEmailAvailability = async (email) => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: 9,
          email: email
        }
      });

      if (response.data.status === 1) {
        setEmailError(t("Users_email_in_use"));
      } else {
        setEmailError('');
      }
    } catch {

    }
  };


  return (
    <Container fluid className="p-4">
      {isLoading ? (
        "Loading"
      ) : (
        <Row>
          <Col xxl={12}>
            <Stack direction="horizontal" gap={3}>
              <button
                type="button"
                className="btn op-button op-primary-color text-light"
                onClick={setisAddUser}
                disabled={
                  !(
                    session.user_email === 'support@nexcrm.my' ||
                    session.user_email === 'demob2c@nexible.com.my' ||
                    session.user_email === 'demob2b@nexible.com.my'
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("Users_user")}
              </button>
              <h6 className="ms-auto op-text-medium mt-2 ms-auto"> {gridCount > 1 ? gridCount + " Users" : gridCount === 1 ? gridCount + " User" : "No user"}</h6>
              {Number(session.role_id) > 0 &&
                <FormSelect options={team} value={selectedTeam} onChange={(selectedOption) => setSelectedTeam(selectedOption)} placeholder={t("Users_select_team")} width="250px" border={false} shadow={true} />
              }
              <div className="d-flex">
                <Form.Control type="text" className="me-1 shadow-sm border-0" placeholder={t("Users_search")} style={{ width: 250 }} value={searchTerm || ''} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </Stack>
          </Col>
          <Col xxl={12}>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={gridColumn}
                  rowData={user}
                  defaultColDef={gridColDef}
                  rowHeight={70}
                  getRowId={gridRowId}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={false}
                  animateRows={true}
                  quickFilterText={delayedSearchTerm}
                  getQuickFilterText={getQuickFilterText}
                  enableCellTextSelection={true}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Modal show={isAddUser} onHide={setisAddUser}>
        <Formik
          validationSchema={addUserSchema}
          onSubmit={handleSubmitForm}
          initialValues={{
            user_name: "",
            user_email: "",
            user_contact: "",
            user_role: null,
            user_permission: "1",
            // user_avanser: "",
          }}
        >
          {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">{t("Users_add_user")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Users_name")}</Form.Label>
                  <Form.Control type="text" isInvalid={errors.user_name && touched.user_name} onChange={handleChange("user_name")} />
                  {errors.user_name && touched.user_name && <div className="op-error-message">{errors.user_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Users_email")}</Form.Label>
                  <Form.Control
                    type="email"
                    isInvalid={errors.user_email && touched.user_email}
                    onChange={handleChange("user_email")}
                    onBlur={(e) => checkEmailAvailability(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === ' ') {
                        event.preventDefault();
                      }
                    }}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  />
                  {((errors.user_email && touched.user_email) || emailError) && (
                    <div className="op-error-message">
                      {errors.user_email || emailError}
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Users_contact_number")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_contact"
                    pattern="\d*"
                    onChange={handleChange("user_contact")}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Users_role")}</Form.Label>
                  <FormSelect
                    options={role}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={t("Users_select_user_role")}
                    value={values.user_role}
                    onChange={(selectedOption) => {
                      setValues({
                        ...values,
                        user_role: selectedOption,
                      });
                    }}
                    isInvalid={errors.user_role && touched.user_role}
                  />
                  {errors.user_role && touched.user_role && (
                    <div className="op-error-message">{errors.user_role}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Users_permission")}</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Active"
                      name="user_permission"
                      type="radio"
                      value="1"
                      checked={values.user_permission == "1"}
                      onChange={handleChange("user_permission")}
                    />
                    <Form.Check
                      inline
                      label="Inactive"
                      name="user_permission"
                      type="radio"
                      value="2"
                      checked={values.user_permission == "2"}
                      onChange={handleChange("user_permission")}
                    />
                  </div>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("Users_submit")}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={showEditUser} onHide={closeEditModal}>
        <Formik
          initialValues={{
            user_id: userDetails ? userDetails.user_id : "",
            user_name: userDetails ? userDetails.user_name : "",
            user_email: userDetails ? userDetails.user_email : "",
            user_contact: userDetails ? userDetails.user_contact : "",
            default_pipeline: userDetails ? userDetails.default_pipeline : "",
            banner_img: userDetails ? userDetails.user_avatar_url : "",
            banner_img_upload: bannerImg ? [{ source: bannerImg }] : ""
          }}
          validationSchema={editUserSchema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t("Users_edit_user_detail")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="mb-3">
                    <div className="custom-filepond-container">
                      <Form.Group className="mb-3">
                        <div className="custom-filepond-container">
                          <FilePond
                            allowMultiple={false}
                            name={`banner_img`}
                            maxFiles={1}
                            files={values.banner_img_upload}
                            acceptedFileTypes={['image/*']}
                            credits={false}
                            imageCropAspectRatio='1.1'
                            imageResizeTargetWidth={150}
                            imageResizeTargetHeight={150}
                            stylePanelLayout='compact circle'
                            styleLoadIndicatorPosition='center'
                            styleProgressIndicatorPosition='right bottom'
                            styleButtonRemoveItemPosition='center bottom'
                            styleButtonProcessItemPosition='right bottom'
                            onremovefile={() => {
                              setFieldValue("banner_img", "");
                              setFieldValue("banner_img_upload", []);
                            }}
                            onupdatefiles={(fileItems) => {
                              setFieldValue(
                                "banner_img_upload",
                                fileItems.map((fileItem) => fileItem.file)
                              );
                            }}
                            instantUpload={false}
                            server={{
                              process: (fieldName, file, metadata, load, error, progress, abort) => {
                                const formData = new FormData();
                                const reader = new FileReader();

                                const urlSegments = userDetails.user_avatar_url.split('/');
                                const filename = urlSegments[urlSegments.length - 1];

                                formData.append("task", "UploadImage");
                                formData.append("user_id", userDetails.user_id);
                                formData.append("old_image", filename);


                                reader.onload = (e) => {
                                  formData.append("data", e.target.result);
                                  axios.post(`${session.hostUrl}/${session.hostUrlType}/php/user_image_settings.php`, formData).then((response) => {
                                    const data = response.data;
                                    if (Number(data.status) === 0) {
                                      load(data);
                                      setFieldValue("banner_img", data.file_name);
                                    }
                                  });
                                };
                                reader.readAsDataURL(file);
                              },
                            }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_name")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder={t("Users_name")}
                      value={values.user_name}
                      onChange={handleChange}
                      isInvalid={errors.user_name && touched.user_name}
                    />
                    {errors.user_name && touched.user_name && <div className="op-error-message">{errors.user_name}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name="user_email"
                      placeholder={t("Users_email")}
                      value={values.user_email}
                      disabled
                      style={{ cursor: 'not-allowed' }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_contact_number")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="user_contact"
                      placeholder={t("Users_contact_number")}
                      value={values.user_contact}
                      onChange={handleChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_role")}</Form.Label>
                    <FormSelect
                      options={role}
                      isSearchable={true}
                      isClearable={true}
                      placeholder={t("Users_select_user_role")}
                      valueDefault={selectedUserrole}
                      onChange={handleRoleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_contact_read_write_permission")}</Form.Label>
                    <FormSelect
                      options={permission}
                      isSearchable={true}
                      isClearable={true}
                      placeholder={t("Users_select_user_permission")}
                      valueDefault={selectedReadWrite}
                      onChange={handleReadWritePermission}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div>{t("Users_permission")}</div>
                    <Form.Check
                      inline
                      label="Active"
                      name="permission"
                      type="radio"
                      value="1"
                      checked={selectedUserPermission?.value == '1'}
                      onChange={() => setSelectedUserPermission({ label: 'Active', value: '1' })}
                    />
                    <Form.Check
                      inline
                      label="Inactive"
                      name="permission"
                      type="radio"
                      value="2"
                      checked={selectedUserPermission?.value == '2'}
                      onChange={() => setSelectedUserPermission({ label: 'Inactive', value: '2' })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <div>{t("Users_web_lead_active")}</div>
                    <Form.Check
                      inline
                      label="Active"
                      name="weblead"
                      type="radio"
                      value="1"
                      checked={selectedWebLead?.value == '1'}
                      onChange={() => setSelectedWebLead({ label: 'Active', value: '1' })}
                    />
                    <Form.Check
                      inline
                      label="Inactive"
                      name="weblead"
                      type="radio"
                      value="0"
                      checked={selectedWebLead?.value == '0'}
                      onChange={() => setSelectedWebLead({ label: 'Inactive', value: '0' })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_teams")}</Form.Label>
                    {team.map((team) => (
                      <Form.Check
                        key={team.value}
                        label={team.label}
                        type="checkbox"
                        checked={team.isSelected}
                        onChange={() => handleTeamChange(team)}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_notification_medium")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Users_notification")}
                      value='expo'
                      disabled
                      style={{ cursor: 'not-allowed' }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Users_default_pipeline")}</Form.Label>
                    <FormSelect
                      options={pipeline}
                      isSearchable={true}
                      isClearable={true}
                      name="default_pipeline"
                      placeholder={t("Users_select_pipeline")}
                      valueDefault={pipeline.find(option => option.value === (values.default_pipeline == '0' ? '' : values.default_pipeline))}
                      onChange={(selectedOption) => {
                        setFieldValue('default_pipeline', selectedOption ? selectedOption.value : '');
                      }}
                      isInvalid={errors.default_pipeline && touched.default_pipeline}
                    />
                    {errors.default_pipeline && touched.default_pipeline && <div className="op-error-message">{errors.default_pipeline}</div>}
                  </Form.Group>
                  {(session.user_email === 'support@nexcrm.my' || session.user_email === 'demob2c@nexible.com.my' || session.user_email === 'demob2b@nexible.com.my') && (
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Users_avanser")}</Form.Label>
                        <Form.Check
                          inline
                          label="Yes"
                          name="avanser"
                          type="radio"
                          value="1"
                          checked={selectedAvanser?.value == '1'}
                          onChange={() => setSelectedAvanser({ label: 'Yes', value: '1' })}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="avanser"
                          type="radio"
                          value="0"
                          checked={selectedAvanser?.value == '0'}
                          onChange={() => setSelectedAvanser({ label: 'No', value: '0' })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Users_rea_agent")}</Form.Label>
                        <Form.Check
                          inline
                          label="Yes"
                          name="agent"
                          type="radio"
                          value="1"
                          checked={selectedReaAgent?.value == '1'}
                          onChange={() => setSelectedReaAgent({ label: 'Yes', value: '1' })}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="agent"
                          type="radio"
                          value="0"
                          checked={selectedReaAgent?.value == '0'}
                          onChange={() => setSelectedReaAgent({ label: 'No', value: '0' })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Users_hide_account")}</Form.Label>
                        <Form.Check
                          inline
                          label="Yes"
                          name="hide"
                          type="radio"
                          value="1"
                          checked={selectedHideAcc?.value == '1'}
                          onChange={() => setSelectedHideAcc({ label: 'Yes', value: '1' })}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="hide"
                          type="radio"
                          value="0"
                          checked={selectedHideAcc?.value == '0'}
                          onChange={() => setSelectedHideAcc({ label: 'No', value: '0' })}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="submit">
                  {t("Users_update")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default Users;
