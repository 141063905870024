import { useState, useEffect, useRef, useMemo } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
// import { useTranslation } from "react-i18next";

const NotificationSettings = () => {
    // const { t } = useTranslation();
    const init = useRef(false);
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    const [notificationDataRecord, setNotificationDataRecord] = useState([]);
    const [notificationChannels, setNotificationChannels] = useState([]);

    const [checkedState, setCheckedState] = useState({});

    // GET FUNCTION
    const getNotificationData = async () => {
        try {
            const response = await axios.get("ws/ws_setting.php", {
                params: {
                    task: 'NotiSettings',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                },
            });

            const data = response.data;

            if (data.record) {
                setNotificationData(data)
                setNotificationDataRecord(data.record);

                const allSettings = data.record.flatMap(item => item.notification_setting);
                const uniqueActions = [...new Map(allSettings.map(setting => [setting.action_title, setting])).values()];

                const headers = uniqueActions.map(action => ({
                    headerName: action.action_title,
                    field: action.action_id,
                }));

                setNotificationChannels(headers);
            }
        } catch (error) {

        }
    }

    const updateNewChannelStatus = (event, ncId, actionId, userId) => {
        const { checked } = event.target;
        const status = checked ? 'on' : 'off';

        const key = `${userId}-${actionId}-${ncId}`;

        // Update the checked state
        setCheckedState(prevState => ({
            ...prevState,
            [key]: checked
        }));

        try {
            axios.get(`ws/ws_setting.php`, {
                params: {
                    action: actionId,
                    nc: ncId,
                    status: status,
                    task: 14,
                    user: userId
                },
            })
        } catch {

        }
    };


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "90vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    setLoading(true);
                    await getNotificationData();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderCheckboxes = (params, userId) => {
        const { data } = params;

        const getNoti = data.notification_setting.map(set => ({
            id: set.action_id,
            title: set.action_title,
            status: set.channel.map(state => ({
                ncId: state.nc_id,
                ncTitle: state.nc_name,
                state: state.ns_status,
            }))
        }));

        const matchingNotis = [];
        for (const item1 of notificationChannels) {
            const match = getNoti.find(item2 => item2.id === item1.field);
            if (match) {
                matchingNotis.push(match);
            } else {
                matchingNotis.push(null);
            }
        }

        const fieldIndex = notificationChannels.findIndex(channel => channel.field === params.colDef.field);

        if (fieldIndex !== -1 && matchingNotis[fieldIndex]) {
            const activeChannels = notificationData.crm_notification_channel_listing.filter(channel => channel.nc_status === 'active').length;
            return (
                <div className="lh-lg">
                    {matchingNotis[fieldIndex].status.slice(0, activeChannels).map((ch, index) => {
                        // Generate a unique key for the checkbox based on ncId, actionId, and userId
                        const key = `${userId}-${matchingNotis[fieldIndex].id}-${ch.ncId}`;
                        return (
                            <div key={index}>
                                <Form.Check
                                    type="checkbox"
                                    label={ch.ncTitle}
                                    // checked={checkedState[key] || ch.state === 'on'}
                                    checked={checkedState[key] === undefined ? ch.state === 'on' : checkedState[key]}
                                    onChange={(event) => updateNewChannelStatus(event, ch.ncId, matchingNotis[fieldIndex].id, userId)}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        }
        return null;
    };



    // Modify column definitions to render checkboxes
    const modifiedColumns = notificationChannels.map(channel => ({
        headerName: channel.headerName,
        field: channel.field,
        // cellRenderer: renderCheckboxes,
        cellRenderer: (params) => renderCheckboxes(params, params.data.user_id),
    }));

    modifiedColumns.unshift({
        headerName: "Username",
        field: "user_name",
    });

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact columnDefs={modifiedColumns} rowData={notificationDataRecord} rowHeight={120} pagination={true} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Container>
    )
}

export default NotificationSettings;
