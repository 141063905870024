import { useState, useRef, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import FormSelect from "../includes/FormSelect";
import * as formik from "formik";
import * as yup from "yup";
import { useAuth } from "../auth/AuthContext";

const SalesCommission = ({open, onClose }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    // const [openModal, setOpenModal] = useState(false);

    const [companyList] = useState([
        { "label": "BANDAR SERAI DEVELOPMENT SDN BHD", "value": "140" },
        { "label": "DINAMIK ATLANTIK SDN BHD", "value": "134" },
        { "label": "GAMUDA LAND (BOTANIC) SDN BHD", "value": "12" },
        { "label": "GAMUDA LAND (KEMUNING) SDN BHD", "value": "59" },
        { "label": "GAMUDA LAND (T12) SDN BHD", "value": "1155" },
        { "label": "HICOM-GAMUDA DEVELOPMENT SDN BHD", "value": "32" },
        { "label": "HIGHPARK DEVELOPMENT SDN BHD", "value": "139" },
        { "label": "HORIZON HILLS DEVELOPMENT SDN BHD", "value": "2" },
        { "label": "IDAMAN ROBERTSON SDN BHD", "value": "137" },
        { "label": "JADE HOMES SDN BHD", "value": "144" },
        { "label": "MADGE MANSIONS SDN BHD", "value": "138" },
        { "label": "VALENCIA DEVELOPMENT SDN. BHD.", "value": "141" }
    ]);

    // useEffect(() => {
    //     if (!init.current) {
    //         init.current = true;
    //         setOpenModal(true);
    //     }
    // }, []);

    const sendSchema = yup.object().shape({
        company_id: yup.string().required('This field is required')
    });

    const handleSend = (values) => {
        var text = session.user_email;
        var word = '@';
        var index = text.indexOf(word);
        var sendToLink = text.slice(0,index);
        if (session.hostUrlType === 'cloud_staging') {
            window.open(`rrRoadRunner://navigate?Start&code=MBSSC&username=${sendToLink}&accesscode=react@123&companyid=${values.company_id}`, '_blank');
        }else{
            window.open(`rrRoadRunner://navigate?Start&code=MBSSC&username=${sendToLink}&accesscode=Gamuda@React2023&companyid=${values.company_id}`, '_blank');
        }
        
        onClose(false);
    };

    return(
        <Modal show={open} onHide={onClose}>
            <Formik
                validationSchema={sendSchema}
                onSubmit={handleSend}
                initialValues={{
                    company_id: ''
                }}
            >
                {({ handleSubmit, setFieldValue, errors, touched }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title as={'h6'}>Sales Commission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Company Name</Form.Label>
                                <FormSelect
                                    options={companyList}
                                    placeholder="Select Company"
                                    isSearchable={true}
                                    isClearable={true}
                                    isInvalid={errors.company_id && touched.company_id}
                                    onChange={(e) => {setFieldValue('company_id', e ? e.value : '')}}
                                />
                                {errors.company_id && touched.company_id && <div className="op-error-message">{errors.company_id}</div>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default SalesCommission;