import { useState, memo } from "react";
import { useAuth } from "../../auth/AuthContext";
import { FormSelect } from "../../includes/FormCustom";
import axios from "../../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { Form, Modal } from "react-bootstrap";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { setCurrency } from "../../lib/js/Function";
const bankList = [
  { label: "Affin Bank Berhad", value: "Affin Bank Berhad" },
  { label: "Affin Islamic Bank Berhad", value: "Affin Islamic Bank Berhad" },
  { label: "Alliance Bank Malaysia Berhad", value: "Alliance Bank Malaysia Berhad" },
  { label: "Alliance Islamic Bank Malaysia Berhad", value: "Alliance Islamic Bank Malaysia Berhad" },
  { label: "Al Rajhi Banking & Investment Corporation (Malaysia) Berhad", value: "Al Rajhi Banking & Investment Corporation (Malaysia) Berhad" },
  { label: "AmBank (M) Berhad", value: "AmBank (M) Berhad" },
  { label: "Bank Islam Malaysia Berhad", value: "Bank Islam Malaysia Berhad" },
  { label: "Bank Kerjasama Rakyat Malaysia Berhad", value: "Bank Kerjasama Rakyat Malaysia Berhad" },
  { label: "Bank Muamalat Malaysia Berhad", value: "Bank Muamalat Malaysia Berhad" },
  { label: "Bank of China (Malaysia) Berhad", value: "Bank of China (Malaysia) Berhad" },
  { label: "Bank Pertanian Malaysia Berhad (Agrobank)", value: "Bank Pertanian Malaysia Berhad (Agrobank)" },
  { label: "Bank Simpanan Nasional", value: "Bank Simpanan Nasional" },
  { label: "CIMB Bank Berhad", value: "CIMB Bank Berhad" },
  { label: "CIMB Islamic Bank Berhad", value: "CIMB Islamic Bank Berhad" },
  { label: "Citibank Berhad", value: "Citibank Berhad" },
  { label: "Hong Leong Bank Berhad", value: "Hong Leong Bank Berhad" },
  { label: "Hong Leong Islamic Bank Berhad", value: "Hong Leong Islamic Bank Berhad" },
  { label: "HSBC Amanah Malaysia Berhad", value: "HSBC Amanah Malaysia Berhad" },
  { label: "HSBC Bank Malaysia Berhad", value: "HSBC Bank Malaysia Berhad" },
  { label: "Industrial and Commercial Bank of China (Malaysia) Berhad", value: "Industrial and Commercial Bank of China (Malaysia) Berhad" },
  { label: "Kuwait Finance House", value: "Kuwait Finance House" },
  { label: "Malayan Banking Berhad", value: "Malayan Banking Berhad" },
  { label: "MBSB Bank Berhad", value: "MBSB Bank Berhad" },
  { label: "OCBC Bank (Malaysia) Berhad", value: "OCBC Bank (Malaysia) Berhad" },
  { label: "Public Bank Berhad", value: "Public Bank Berhad" },
  { label: "RHB Bank Berhad", value: "RHB Bank Berhad" },
  { label: "RHB Islamic Bank Berhad", value: "RHB Islamic Bank Berhad" },
  { label: "Standard Chartered Bank Malaysia Berhad", value: "Standard Chartered Bank Malaysia Berhad" },
  { label: "Standard Chartered Saadiq Berhad", value: "Standard Chartered Saadiq Berhad" },
  { label: "United Overseas Bank (Malaysia) Berhad", value: "United Overseas Bank (Malaysia) Berhad" },
];

const EditPaymentDetails = ({ bookingData, bookingFormat, paymentMethodOptions, modules, bumiputera, onDone }) => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);

  const [paymentSlipInit, setPaymentSlipInit] = useState(true);
  const { session } = useAuth();
  const { Formik } = formik;

  const paymentSchema = yup.object().shape({
    booking_deposit: yup.string().when([], {
      is: () => Number(bookingFormat.booking_fee_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_method: yup.string().when([], {
      is: () => Number(bookingFormat.payment_method_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    bank_name: yup.string().when([], {
      is: () => Number(bookingFormat.bank_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_reference: yup.string().when([], {
      is: () => Number(bookingFormat.payment_reference_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    payment_receipt_name: yup.string().when([], {
      is: () => Number(bookingFormat.payment_receipt_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
  });

  const paymentInitialValues = {
    nett_price: bookingData.nett_price,
    nett_price_str: setCurrency(bookingData.nett_price),
    booking_deposit: bookingData.booking_deposit,
    booking_deposit_str: setCurrency(bookingData.booking_deposit),
    payment_method: bookingData.payment_method,
    bank_name: bookingData.bank_name,
    payment_reference: bookingData.payment_ref,
    payment_receipt_name: bookingData.payment_slip,
    payment_receipt: bookingData.payment_slip ? [{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/payment_slip/${bookingData.payment_slip}`, options: { type: "remote" } }] : [],
    allowProcess: bookingData.payment_slip ? false : true,
  };

  const sendPayment = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdatePaymentInfo",
          booking_deposit: values.booking_deposit,
          payment_method: values.payment_method,
          bank_name: values.bank_name,
          payment_date: values.payment_date,
          payment_ref: values.payment_reference,
          nett_price: values.nett_price,
          payment_slip: values.payment_receipt_name,
          rea_booking_id: bookingData.rea_booking_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          text: "Successfully updated the payment information",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          text: "Failed to update payment information, please try again or contact our developer",
        });
      }
    } catch (error) {
      onDone();
      Swal.fire({
        icon: "error",
        text: error,
      });
    }
  };

  const setFieldLabel = (label, required) => {
    return `${label} ${Number(required) === 1 ? "*" : ""}`;
  };

  return (
    <Formik onSubmit={sendPayment} validationSchema={paymentSchema} initialValues={paymentInitialValues}>
      {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title as={"h6"} className="text-uppercase">
              Edit Payment Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            {Number(modules.is_calc_booking_fee) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.net_price_label, 0)}</Form.Label>
                <Form.Control disabled value={values.nett_price_str} />
              </Form.Group>
            )}

            {Number(bookingFormat.booking_fee_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.booking_fee_label, bookingFormat.booking_fee_required)}</Form.Label>
                <Form.Control
                  value={values.booking_deposit_str}
                  onChange={(e) => {

                    let inputValue = e.target.value.replace(/\D/g, "");
                    let currentPrice = bookingData.unit_selling_price.replace(/\D/g, "");
     
                    if (inputValue.length > 0) {
                      inputValue = (parseInt(inputValue, 10) / 100).toFixed(2);
                      currentPrice = (parseInt(currentPrice, 10) / 100).toFixed(2);

                      if (Number(modules.is_calc_bumi) === 1 && Number(bumiputera) === 1) {
                        currentPrice = Number(currentPrice) - (Number(currentPrice) * Number(bookingData.bumi_package)) / 100;
                      }

                      if (Number(modules.is_calc_booking_fee) === 1) {
                        currentPrice = Number(currentPrice) - Number(inputValue);
                      }

                      setFieldValue("booking_deposit", inputValue);
                      setFieldValue("nett_price", currentPrice);
                    } else {
                      inputValue = "0.00";
                      setFieldValue("nett_price", bookingData.unit_selling_price);
                    }

                    setFieldValue("nett_price_str", setCurrency(currentPrice));
                    setFieldValue("booking_deposit_str", setCurrency(inputValue));

                  }}
                />
                {errors.booking_deposit && touched.booking_deposit && <div className="op-error-message">{errors.booking_deposit}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.payment_method_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.payment_method_label, bookingFormat.payment_method_required)}</Form.Label>
                <FormSelect options={paymentMethodOptions} valueDefault={paymentMethodOptions.find((record) => record.value === values.payment_method)} onChange={(e) => handleChange("payment_method")(e.value)} />
                {errors.payment_method && touched.payment_method && <div className="op-error-message">{errors.payment_method}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.bank_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.bank_label, bookingFormat.bank_required)}</Form.Label>
                <FormSelect options={bankList} valueDefault={bankList.find((record) => record.value === values.bank_name)} onChange={(e) => handleChange("bank_name")(e.value)} />
                {errors.bank_name && touched.bank_name && <div className="op-error-message">{errors.bank_name}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.payment_reference_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.payment_reference_label, bookingFormat.payment_reference_required)}</Form.Label>
                <Form.Control type="text" className="text-uppercase" value={values.payment_reference} onChange={(e) => handleChange("payment_reference")(e.target.value)} />
                {errors.payment_reference && touched.payment_reference && <div className="op-error-message">{errors.payment_reference}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.payment_receipt_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.payment_receipt_label, bookingFormat.payment_receipt_required)}</Form.Label>
                {paymentSlipInit && (
                  <FilePond
                    allowMultiple={false}
                    allowProcess={values.payment_receipt_name ? false : true}
                    maxFiles={1}
                    name="payment_receipt_name"
                    files={values.payment_receipt}
                    acceptedFileTypes={["image/*", "application/pdf"]}
                    credits={false}
                    onupdatefiles={(fileItems) => {
                      setFieldValue("payment_receipt", fileItems.map((fileItem) => fileItem.file));
                    }}
                    onremovefile={() => {
                      if (!values.allowProcess) {
                        setFieldValue("allowProcess", true);
                        setPaymentSlipInit(false);
                        setTimeout(() => setPaymentSlipInit(true), 1);
                      }

                      setFieldValue("payment_receipt", []);
                      setFieldValue("payment_receipt_name", "");
                    }}
                    instantUpload={false}
                    server={{
                      fetch: async (url, load, error) => {
                        try {
                          const decodedUrl = decodeURIComponent(url);
                          const response = await fetch(decodedUrl);
                          const blob = await response.blob();
                          load(blob);
                        } catch (err) {
                          error(err.message);
                        }
                      },
                      process: (fieldName, file, metadata, load) => {
                        const formData = new FormData();
                        const reader = new FileReader();

                        formData.append("folder_name", "payment_slip");
                        formData.append("utoken", session.user_ac_token);
                        formData.append("ctoken", session.company_token);
                        formData.append("company_id", session.company_id);
                        formData.append("file_type", file.type.split("/")[1]);
                        formData.append("mime_type", file.type);
                        formData.append("old_file", bookingData.payment_slip);

                        reader.onload = (e) => {
                          formData.append("data", e.target.result);
                          axios.post(`${session.hostUrl}/${session.hostUrlType}/php/update_assets_bookingv2.php`, formData).then((response) => {
                            const data = response.data;
                            if (Number(data.status) === 0) {
                              load(data);
                              setFieldValue("payment_receipt_name", data.file_name);
                            }
                          });
                        };
                        reader.readAsDataURL(file);
                      },
                    }}
                  />
                )}
                {errors.payment_receipt_name && touched.payment_receipt_name && <div className="op-error-message">{errors.payment_receipt_name}</div>}
              </Form.Group>
            )}

          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
              Save
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default memo(EditPaymentDetails);
