import { useState, useEffect, useMemo, useRef} from "react";
import { Stack, Form, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import "../lib/scss/op-report-style.scss";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const ReportSMSCampaign = () => {
    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    const gridRefSMSLog = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [isReport, setIsReport] = useState(false);
    const [recordFound, setRecordFound] = useState(0);

    const [rowDataSMSLog, setRowDataSMSLog] = useState([]);
    const [isSMSLog, setIsSMSLog] = useState(false);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'year').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const { t } = useTranslation();

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          resizable: true
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('Report_SMSCampaign_HeaderName1'),
            field: 'date_time_create',
            width: 200
        },
        {
            headerName: t('Report_SMSCampaign_HeaderName2'),
            field: 'campaign_title',
            flex: 1,
            minWidth: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    getSMSLog(params.data);
                }
            }
        },
        {
            headerName: t('Report_SMSCampaign_HeaderName3'),
            field: 'TOTAL_SENT',
            width: 200,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_SMSCampaign_HeaderName4'),
            field: 'TOTAL_DELIVERED',
            width: 200,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_SMSCampaign_HeaderName5'),
            field: 'TOTAL_SENT',
            width: 200,
            headerClass: "center",
            cellClass: "center"
        }
    ]);

    const containerStyleSMSLog = useMemo(() => ({ width: "100%", height: "50vh", paddingTop: 20 }), []);
    const gridStyleSMSLog = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [reportColumnSMSLog] = useState([
        {
            headerName: 'Date',
            field: 'date_time_create',
            width: 200
        },
        {
            headerName: 'Sent to',
            field: 'send_to',
            width: 180
        },
        {
            headerName: 'Message',
            field: 'message',
            flex: 1,
            autoHeight: true,
            wrapText: true,
            minWidth: 200
        },
        {
            headerName: 'Delivery Status',
            field: 'provider_delivery_status',
            width: 200
        }
    ]);

    const getGridData = () => {
        
        axios.get("ws/ws_sms_campaign.php", {
            params: {
                task: "getSMSCampaign",
                ds: startDate,
                de: endDate,
                prospectname: '',
                user: '',
                source: '',
                startRow: '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setIsReport(true);
                setIsLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsReport(false);
                setIsLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: t('Report_alert_Title'),
                    text: t('Report_alert_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });

            setRowData([]);
            setRecordFound(0);
            setIsReport(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []);

    const getSMSLog = (data) => {
        axios.get('ws/ws_sms_campaign.php', {
            params: {
                task: "getSMSLog",
                campaign_id: data.campaign_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowDataSMSLog(data.record);
                setIsSMSLog(true);
            }else{
                setRowDataSMSLog([]);
                setIsSMSLog(false);
            }
        })
        .catch(error => {
            console.log(error);
            setRowDataSMSLog([]);
            setIsSMSLog(false);
        });
    }

    return(
        <div>
            {isLoading ? <Loader/> : 
                <div className="report-main">
                    
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t('Report_FilterCriteria')}</div>
                        </section>
                        <section className="content">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {setStartDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {setEndDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                        </section>
                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button 
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={()=>{getGridData()}}
                                >
                                    {t('Report_ViewReport')}
                                </button>
                                <button className="btn btn-dark">{t('Report_Download')}</button>
                            </div>
                        </section>
                    </div>

                    <div className="report-content">
                        <section className="w-100">
                            <Stack direction="horizontal">
                                {isReport && <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>}
                            </Stack>
                        </section>
                        <section className="w-100">
                            <div className="mb-3" style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        rowData={rowData}
                                        defaultColDef={reportColDef}
                                        rowHeight={70}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>

                    <Modal show={isSMSLog} onHide={setIsSMSLog} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title as={'h6'}>SMS Log</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3" style={containerStyleSMSLog}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleSMSLog }}>
                                    <AgGridReact
                                        ref={gridRefSMSLog}
                                        columnDefs={reportColumnSMSLog}
                                        rowData={rowDataSMSLog}
                                        defaultColDef={reportColDef}
                                        rowHeight={70}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>}
        </div>
    );
}

export default ReportSMSCampaign;