import { useState, useRef, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons";

const AddDealDuplicate = ({ isOpen, dataSet, pipelineData, stageDefaultData, onSuccess }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState(false);
    const [prosData, setProsData] = useState([]);
    const [prosID, setProsID] = useState("");

    const getAllProspect = () => {
        axios.get("ext/gamuda/api_tasks.php", {
            params: {
                task: 'getProspectIDByPhone',
                contact1: dataSet.contact1,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                const dataList = data.record.map(rec => ({
                    label: `${rec.prospect_name} (${rec.contact1.slice(-4)})`,
                    value: rec.prospect_id,
                    idNo: rec.id_num,
                    contactNo: rec.contact1,
                    icon: rec.debtorID ? true : false
                }));

                if (dataList.length === 1) {
                    setProsID(dataList[0]);
                }

                setProsData(dataList);
                setOpenModal(true);
            } else {
                setProsData([]);
                setOpenModal(false);
                isOpen(false);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllProspect();
        }
    }, []);

    const createNewContact = () => {
        if (prosID.idNo) {
            triggerAddDeal("createNew");
        } else if (prosID === "") {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please choose a prospect'
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Unable to create deal'
            });
        }
    }

    const useExistingContact = () => {
        if (prosID) {
            triggerAddDeal("useExisting");
        } else if (prosID === "") {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please choose a prospect'
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Unable to create deal'
            });
        }
    }

    const triggerAddDeal = async (type) => {
        // console.log(prosID);
        // console.log(dataSet);
        try {
            var customField = dataSet.cf_deals.concat(dataSet.cf_person);
      
            var cf_id = customField.map((record) => {
              return record.name;
            });
      
            var fd_values = customField.map((record) => {
              if (Array.isArray(record.fd_values)) {
                return record.fd_values.join("|");
              } else {
                return record.fd_values;
              }
            });
      
            const response = await axios.get("ws/ws_deal.php", {
              params: {
                task: "addDealWithCfv3",
                title: dataSet.deal_title,
                value: dataSet.deal_value,
                source: dataSet.deal_source,
                pipeid: pipelineData.value === "All" ? 0 : pipelineData.value,
                close_date: dataSet.expected_close_date ? moment(dataSet.expected_close_date).format("YYYY-MM-DD") : "",
                prospect: type === "createNew" ?  dataSet.prospect_id : prosID.value,
                prospect_name: type === "createNew" ? dataSet.prospect_name : prosID.label,
                organization: "",
                organization_name: "",
                contact: dataSet.contact1,
                contact_id: dataSet.contact_id,
                user: session.user_id,
                cs: stageDefaultData,
                cfid_arr: cf_id.join("[-0-]"),
                cfval_arr: fd_values.join("[-0-]"),
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                company: session.company_id,
                create_new_hq_prospect: type === "createNew" ? 1 : 0
              },
            });
      
            const data = response.data;
            if (Number(data.status) === 0) {
              Swal.fire({
                icon: "success",
                title: t("DealList_successful"),
                text: t("DealList_success_deal_created"),
                timer: 1500,
              })
              .then(() => {
                isOpen(false);
                setOpenModal(false);
                onSuccess();
              });
            } else {
              Swal.fire({
                icon: "error",
                text: t("DealDetails_error_api_message"),
                timer: 1500,
              });
            }
          } catch (error) {
            console.error(error);
          }
    }

    return (
        <>
            <Modal show={openModal} onHide={isOpen}>
                <Modal.Header closeButton>
                    <Modal.Title as={'h6'}>Duplicate detected</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <FormSelect
                            options={prosData}
                            valueDefault={prosData.length === 1 ? prosData.filter(opt => opt.value === prosData[0].value) : ""}
                            placeholder="Select prospect"
                            Component={true}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(e) => { setProsID(e ? e : '') }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success" onClick={createNewContact}>Create new contact</button>
                    <button type="button" className="btn btn-danger" onClick={useExistingContact}>Use this contact</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddDealDuplicate;
