import { useState, useRef, useEffect, memo, useCallback } from "react";
import { Modal, Stack, Form, Spinner } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import * as formik from "formik";
import * as yup from "yup";
import iziToast from "izitoast";
import { immediateToast } from "izitoast-react";
import Cookies from "js-cookie";

const PersonalInformation = ({ open, onClose }) => {
  const { session } = useAuth();
  const { Formik } = formik;
  const fileInputRef = useRef(null);
  const [imgError, setImgError] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [cache, setCache] = useState(Math.random());
  const schema = yup.object().shape({
    user_name: yup.string().required("This field is required"),
  });

  const handleFileChange = useCallback(
    (event) => {
      setImgError(false);
      setImgLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      const reader = new FileReader();

      formData.append("task", "UploadImage");
      formData.append("user_id", session.user_id);
      formData.append("old_image", `${session.user_id}.png`);

      reader.onload = (e) => {
        formData.append("data", e.target.result);
        axios.post(`${session.hostUrl}/${session.hostUrlType}/php/user_image_settings.php`, formData).then((response) => {
          const data = response.data;
          if (Number(data.status) === 0) {
            setImgLoading(false);
            setCache(Math.random());
          } else {
            setImgLoading(false);
          }
        });
      };
      reader.readAsDataURL(file);
    },
    [session]
  );

  const removeImage = useCallback(async () => {
    try {
      iziToast.show({
        message: "Are you sure you want to remove your profile picture? Your profile won't have a picture until you upload a new one.",
        position: "center",
        timeout: false,
        buttons: [
          [
            "<button>Yes</button>",
            async function (instance, toast) {
              setImgLoading(true);
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/php/user_image_settings.php`, {
                params: {
                  task: "RemoveImage",
                  user_id: session.user_id,
                },
              });

              const data = response.data;
              if (data.status === 0) {
                setImgLoading(false);
                setCache(Math.random());
              } else {
                setImgLoading(false);
                setCache(Math.random());
              }
            },
            true,
          ],

          [
            "<button>No</button>",
            function (instance, toast) {
              setImgLoading(false);
              instance.hide({ transitionOut: "fadeOut" }, toast, "button");
            },
          ],
        ],
      });
    } catch (error) {
      setImgLoading(false);
      console.error(error);
    }
  }, [session]);

  const openFilePicker = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const saveProfile = useCallback(
    async (values) => {
      try {
        const response = await axios.get("ws/ws_user.php", {
          params: {
            task: 2,
            user: session.user_id,
            user_name: values.user_name,
            avatar_url: `${session.user_id}.png`,
            company: session.company_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const accountSession = session;
          accountSession.user_name = values.user_name;
          let expirationDate = new Date();
          if (accountSession.remember) {
            expirationDate.setTime(expirationDate.getTime() + 7 * 24 * 60 * 60 * 1000);
          } else {
            expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
          }

          Cookies.set("user_record", JSON.stringify(accountSession), { expires: expirationDate });
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to update",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        onClose(false);
      }
    },
    [session, onClose]
  );

  useEffect(() => {
    setImgError(false);
    setImgLoading(false);
    setCache(Math.random());
  }, [open]);

  return (
    <Modal show={open} onHide={onClose}>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
      <Formik validationSchema={schema} onSubmit={saveProfile} initialValues={{ user_name: session.user_name }}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Personal Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Stack direction="horizontal" gap={2}>
                  <div style={{ width: 80 }} />
                  {imgLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      {imgError ? (
                        <div className="avatar">
                          <div className="avatar-img op-primary-color" style={{ width: 40, height: 40, backgroundColor: "#eee" }}>
                            <div className="avatar-txt text-uppercase fs-5 text-light">{session.user_name.charAt(0)}</div>
                          </div>
                        </div>
                      ) : (
                        <img className="d-block rounded-circle border me-2" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${session.user_id}.png?c=${cache}`} width={50} height={50} onError={() => setImgError(true)} />
                      )}
                    </>
                  )}

                  <div>
                    <label className="mb-0 text-decoration-none ">
                      <span className="text-primary cursor-pointer" onClick={openFilePicker}>
                        Choose picture
                      </span>
                      {!imgError && <span className="text-dark"> · </span>}
                      {!imgError && (
                        <span className="text-danger cursor-pointer" onClick={removeImage}>
                          Remove
                        </span>
                      )}
                    </label>
                    <p className="mb-0" style={{ fontSize: 10 }}>
                      Max size 2 MB. Recommended Resolution: 500x500
                    </p>
                  </div>
                </Stack>
              </Form.Group>
              <Form.Group>
                <Stack direction="horizontal" gap={2}>
                  <Form.Label style={{ width: 100 }} className="mb-0">
                    Your Name
                  </Form.Label>
                  <Form.Control type="text" className="border" value={values.user_name} onChange={handleChange("user_name")} />
                </Stack>
                <Stack direction="horizontal" gap={2}>
                  <div style={{ width: 100 }} />
                  {errors.user_name && touched.user_name && <div className="op-error-message">{errors.user_name}</div>}
                </Stack>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn op-button op-primary-color text-light shadow">
                Save
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(PersonalInformation);
