import { useState, useRef, useEffect, useCallback, forwardRef, useLayoutEffect, useMemo, memo } from "react";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button, OverlayTrigger, Tooltip, ListGroup, ToggleButtonGroup, Image, Placeholder } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faStar, faChevronDown, faTriangleExclamation, faCircleXmark, faBrowser, faRobot, faPhoneHangup } from "@fortawesome/pro-solid-svg-icons";
import { faBuildings, faCircle as faCircleLight, faPlus, faExpand, faRotateRight, faChevronRight, faCircleDollar, faStarExclamation, faStar as faStar2, faSparkles } from "@fortawesome/pro-light-svg-icons";
import {
  faGlobePointer,
  faBriefcase,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faPenToSquare,
  faPhoneVolume,
  faRightLeft,
  faStopwatch,
  faUser,
  faUsers,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faPaperclipVertical,
  faEnvelope,
  faCalendarDay,
  faBarsStaggered,
  faBuilding,
  faShieldExclamation,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faFloppyDisk,
  faHouseBuilding,
  faHouseChimneyWindow,
  faHouse,
  faLocationDot,
  faBox,
  faMicrophone,
  faIndustryWindows,
  faBrowser as faBrowser2,
  faBullhorn,
  faClipboard,
  faList,
  faWandMagicSparkles,
} from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { useNavigate, useParams } from "react-router-dom";
import { FilePond } from "react-filepond";
import { prefixList, setCurrency, setDateStr, setDateTimeStr } from "../lib/js/Function";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import "../lib/scss/op-deal-details.scss";
import "../lib/scss/op-step.scss";
import "../lib/scss/op-rating.scss";
import "react-vertical-timeline-component/style.min.css";
import "../lib/css/op-label.css";
import ReactHtmlParser from "html-react-parser";
import { FormSelectCreatable, FormCustomField, FormSelect, FormDate } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { debounce } from "lodash";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import ProductComponent from "./deal_action/ProductComponent";
import { Fab, Action } from "react-tiny-fab";
import AudioNotesV2 from "./ai_action/AudioNotesV2";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";
import MeetupLog from "./timeline_component/meetupLog";
import { io } from "socket.io-client";
import ReferralComponent from "./deal_action/ReferralComponent";
import LogoOP from "../assets/images/outperform-logo.png";
import LogoOP2 from "../assets/images/outperform_logo_menu2.svg";
import Draggable from "react-draggable";
import { Device } from "@twilio/voice-sdk";
import { parsePhoneNumber } from "libphonenumber-js";

const newDate = new Date();
const latestDate = moment(newDate).format("YYYY-MM-DD");

const durationList = [
  { label: "5 Mins", value: "5" },
  { label: "10 Mins", value: "10" },
  { label: "15 Mins", value: "15" },
  { label: "20 Mins", value: "20" },
  { label: "30 Mins", value: "30" },
  { label: "35 Mins", value: "35" },
  { label: "40 Mins", value: "40" },
  { label: "45 Mins", value: "45" },
  { label: "50 Mins", value: "50" },
  { label: "55 Mins", value: "55" },
  { label: "1 Hour", value: "60" },
  { label: "1 Hour 30 Mins", value: "90" },
  { label: "2 Hours", value: "120" },
  { label: "2 Hours 30 Mins", value: "150" },
  { label: "3 Hours", value: "180" },
  { label: "3 Hours 30 Mins", value: "210" },
  { label: "4 Hours", value: "240" },
];

const timings = [
  { label: "8:00", value: "8:00" },
  { label: "8:15", value: "8:15" },
  { label: "8:30", value: "8:30" },
  { label: "8:45", value: "8:45" },
  { label: "9:00", value: "9:00" },
  { label: "9:15", value: "9:15" },
  { label: "9:30", value: "9:30" },
  { label: "9:45", value: "9:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];

const DealDetails = () => {
  const [meetingDurationList, setMeetingDurationList] = useState([]);
  const [meetingTimingsList, setMeetingTimingsList] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { deal_id } = useParams();
  const { Formik } = formik;
  const editorRef = useRef();
  const initialized = useRef(false);
  const containerRef = useRef(null);
  const [loading2, setLoading2] = useState(true);
  const [activeTab, setActiveTab] = useState("notes");
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isContributor, setIsContributor] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isWon, setIsWon] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [isChangeProspect, setIsChangeProspect] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const { session, logoutUser } = useAuth();
  const [deal, setDeal] = useState(null);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const [booking, setBooking] = useState([]);
  const [lostReason, setLostReason] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [callAnalytics, setCallAnalytics] = useState([]);
  const [rating, setRating] = useState(null);
  const [hoverRating, setHoverRating] = useState(null);
  const [pipeBtn, setPipeBtn] = useState({ won: "", lost: "" });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [dealSource, setDealSource] = useState([]);
  const [isEditDeal, setIsEditDeal] = useState(false);
  const [isAddContributor, setIsAddContributor] = useState(false);
  const [isAddForm, setisAddForm] = useState(false);
  const [form, setForm] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [userActivity, setUserActivity] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [whatsappTemplate, setWhatsappTemplate] = useState([]);
  const [isEditActivity, setIsEditActivity] = useState(false);
  const [initEditActivity, setInitEditActivity] = useState(null);
  const [initEditMeeting, setInitEditMeeting] = useState(null);
  const [isTransferOwner, setIsTransferOwner] = useState(false);
  const [isEditNote, setisEditNote] = useState(false);
  const [initEditNotes, setInitEditNotes] = useState(null);
  const [isDeleteDeal, setIsDeleteDeal] = useState();
  const [width, setWidth] = useState("100%");
  const [leadScoreList, setLeadScoreList] = useState([]);
  const [isLeadScore, setIsLeadScore] = useState(false);
  const [isEditDealInfo, setIsEditDealInfo] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [isArchievedDeal, setIsArchievedDeal] = useState(false);
  const [dealProduct, setDealProduct] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isAddProduct, setisAddProduct] = useState(false);
  const [isCallLead, setIsCallLead] = useState(false);
  const [isOrganizationAllowEditContact, setIsOrganizationAllowEditContact] = useState(true);
  const [isProspectAllowEditContact, setIsProspectAllowEditContact] = useState(true);
  const [isFindOrganization, setIsFindOrganization] = useState(false);
  const [findOrganizationData, setFindOrganizationData] = useState([]);
  const [isEditOrganization, setIsEditOrganization] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [countryOrganizationList, setCountryOrganizationList] = useState([]);
  const [organizationUser, setOrganizationUser] = useState([]);
  const [isAddOrganization, setIsAddOrganization] = useState(false);
  const [meetupLink, setMeetupLink] = useState({});
  const [meetingData, setMeetingData] = useState({});
  const [isCancelMeetup, setIsCancelMeetup] = useState(false);
  const [cancelMeetupReasonList, setCancelMeetupReasonList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [isTimeline, setIsTimeline] = useState(true);
  const [dealProductV2, setDealProductV2] = useState([]);
  const [aiFollowUpData, setAiFollowUpData] = useState({ agentNotes: "", audioFilename: "", whatsappMessage: "" });
  const [modalLoading, setModalLoading] = useState(true);
  const [isCall, setIsCall] = useState(false);
  const [callPosition, setCallPosition] = useState({ x: 0, y: 0 });
  const [callMode, setCallMode] = useState(false);
  const [callStatus, setCallStatus] = useState("ringing");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isEditSource, setIsEditSource] = useState(false);
  const [isAiTimelineLoading, setIsAiTimelineLoading] = useState(false);
  const [aiTimelineSummaryData, setAiTimelineSummaryData] = useState({ extraNotes: "", timelineSummary: "" });
  const [isEditMeeting, setIsEditMeeting] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [smartLinkList, setSmartLinkList] = useState([]);
  const [leadScore, setLeadScore] = useState(0);
  const [leadScoreUpdate, setLeadScoreUpdate] = useState(false);

  const [firstTimeline, setFirstTimeline] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(false);

  const transferDealSchema = yup.object().shape({
    user: yup.string().required("User is required"),
  });

  const aiFollowUpSchema = yup.object().shape({
    remark: yup.string().required("Must have something to send to the person"),
  });

  const changeProspectSchema = yup.object().shape({
    prospect: yup.string().required(t("DealDetails_error_message")),
  });

  const editProspectSchema = yup.object().shape({
    prospect_name: yup.string().required(t("DealDetails_error_message")),
    owner_id: yup.string().required(t("DealDetails_error_message")),
    contact1Prefix: yup.string().required(t("DealDetails_error_message")),
    contact1: yup
      .string()
      .required(t("DealDetails_error_message"))
      .test("checkDuplicateContact1", t("DealDetails_error_message_contact_number_already_exist"), async (value) => {
        if (deal.contact1 !== value) {
          const result = await debounceDuplicateContact(value, 1);
          return result;
        }
        return true;
      }),
    contact2: yup.string().test("checkDuplicateContact2", t("DealDetails_error_message_contact_number_already_exist"), async (value) => {
      if (deal.contact2 !== value) {
        return await debounceDuplicateContact(value, 2);
      }
      return true;
    }),
    contact3: yup.string().test("checkDuplicateContact3", t("DealDetails_error_message_contact_number_already_exist"), async (value) => {
      if (deal.contact3 !== value) {
        return await debounceDuplicateContact(value, 3);
      }
      return true;
    }),
    contact4: yup.string().test("checkDuplicateContact4", t("DealDetails_error_message_contact_number_already_exist"), async (value) => {
      if (deal.contact4 !== value) {
        return await debounceDuplicateContact(value, 4);
      }
      return true;
    }),
  });

  const editSourceSchema = yup.object().shape({
    ds_id: yup.string().required(t("DealDetails_error_message")),
  });

  const addContributorSchema = yup.object().shape({
    user_id: yup.string().required(t("DealDetails_error_message")),
  });

  const addFormSchema = yup.object().shape({
    form_id: yup.string().required(t("DealDetails_error_message")),
  });

  const notesSchema = yup.object().shape({
    notes: yup.string().required(t("DealDetails_error_message")),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("DealDetails_error_message")),
    activity_user: yup.string().required(t("DealDetails_error_message")),
    activity_duration: yup.string().when("activity_type", {
      is: (val) => Number(val) === 2,
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required(t("DealDetails_error_message")),
    activity_user: yup.string().required(t("DealDetails_error_message")),
  });

  const fileUploadSchema = yup.object().shape({
    file: yup.array().min(1, t("DealDetails_error_message")),
    title: yup.string().required(t("DealDetails_error_message")),
  });

  const addMessageschema = yup.object().shape({
    email_content: yup.string().required(t("DealDetails_error_message")),
    email_template: yup.string().when("message_type", {
      is: "email",
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
    whatsapp_template: yup.string().when("message_type", {
      is: "whatsapp",
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
    email_subject: yup.string().when(["message_type", "email_template"], {
      is: (messageType, emailTemplate) => messageType === "email" && emailTemplate,
      then: () => yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editNotesSchema = yup.object().shape({
    notes: yup.string().required(t("DealDetails_error_message")),
  });

  const editDealInfoSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealDetails_error_message")),
    deal_added: yup.string().required(t("DealDetails_error_message")),
    deal_close: yup.string().when("deal_archieve", {
      is: (value) => value === "won" || value === "lost",
      then: yup.string().required(t("DealDetails_error_message")),
    }),
  });

  const editOrganizationSchema = yup.object().shape({
    organization_title: yup.string().required(t("DealDetails_error_message")),
  });

  const cancelMeetupSchema = yup.object().shape({
    reason: yup.string().required(t("DealDetails_error_message")),
  });

  // TWILIO SETUP ----------------------------------------------------------------

  const [twilioDevice, setTwilioDevice] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [twilioConn, setTwilioConn] = useState(null);
  const [callSID, setCallSID] = useState("");
  const [callTimer, setCallTimer] = useState("00:00");
  const [callStart, setCallStart] = useState("connect");
  const timeRef = useRef(0);
  const timerRef = useRef(null);

  const getTwilioToken = async (account_sid, api_key_sid, api_key_secret, outgoing_app_sid) => {
    const response = await axios.get("https://app.outperformhq.io:50303/api/twilio/token", {
      params: {
        identity: "userid_" + session.user_id,
        userid: session.user_id,
        account_sid: account_sid,
        api_key_sid: api_key_sid,
        api_key_secret: api_key_secret,
        outgoing_app_sid: outgoing_app_sid,
      },
    });

    const data = await response.data;
    return data.token;
  };

  const initializeTwilioDevice = async (account_sid, api_key_sid, api_key_secret, outgoing_app_sid) => {
    try {
      if (twilioDevice === null) {
        const token = await getTwilioToken(account_sid, api_key_sid, api_key_secret, outgoing_app_sid);
        const device = new Device(token);
        console.log(device);
        setTwilioDevice(device);

        // Listen for registered event
        device.on("registered", () => {
          console.log("Twilio Device registered");
        });

        // Listen for error event
        device.on("error", (error) => {
          console.error("Twilio Device error:", error);
        });

        // Listen for incoming calls
        device.on("incoming", (call) => {
          console.log("Incoming call:", call);
        });

        // Catch if the device fails to initialize
        device.on("ready", () => {
          console.log("Twilio Device ready for use");
        });

        // Add a .catch for potential promise failures during initialization
        device
          .register()
          .then(() => console.log("Device registration successful"))
          .catch((error) => console.error("Device registration failed:", error));
      }
    } catch (error) {
      console.error("Error initializing Twilio Device:", error);
    }
  };

  const getTwilioCred = async () => {
    //call api to get twilio cred
    var account_sid = "";
    var api_key_sid = "";
    var api_key_secret = "";
    var outgoing_app_sid = "";

    try {
      const response = await axios.get("ws/ws_twilio.php", {
        params: {
          task: "getTwilioSettings",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        account_sid = response.data.record[0].account_sid;
        api_key_sid = response.data.record[0].api_key_sid;
        api_key_secret = response.data.record[0].api_key_secret;
        outgoing_app_sid = response.data.record[0].outgoing_app_sid;
        setTrackingNumber(response.data.record[0].twilio_number);
        initializeTwilioDevice(account_sid, api_key_sid, api_key_secret, outgoing_app_sid);
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const sendTwilioCallLead = async () => {
    let contact_person = deal.contact1;
    let country_code = deal.contact1_prefix === "" || deal.contact1_prefix === "60" ? "60" : "";

    if (contact_person === "") {
      return Swal.fire({
        icon: "warning",
        text: "The provided phone number is not valid and cannot be used to make a call.",
      });
    }

    if (country_code === "") {
      return Swal.fire({
        icon: "warning",
        text: "The prospect country code is not allowed to use this call method.",
      });
    }

    let num_to_call = "";
    let parsed_number = parsePhoneNumber(contact_person, "MY");
    if (parsed_number.isValid()) {
      num_to_call = `+${country_code}${parsed_number.nationalNumber}`;
    } else {
      return Swal.fire({
        icon: "warning",
        text: "The provided phone number is not valid and cannot be used to make a call.",
      });
    }

    setCallStatus("connecting");
    setCallStart("connect");
    timeRef.current = 0;
    timerRef.current = null;
    setIsCall(true);

    const params = { To: num_to_call };
    const connection_t = await twilioDevice.connect({ params });
    setTwilioConn(connection_t);

    connection_t.on("accept", (connection) => {
      console.log("accept");
      const callSid = connection.parameters.CallSid;
      setCallSID(callSid);
      axios.get("ws/ws_twilio.php", {
        params: {
          task: "setTwilioCall",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          call_sid: callSid,
          prospect_id: deal.prospect_id,
          call_type: "outbound",
          caller_number: trackingNumber,
          answer_number: deal.contact1,
          tracking_number: trackingNumber,
        },
      });
    });

    connection_t.on("ringing", () => {
      console.log("ringing");
      setCallStatus("ringing");
    });

    connection_t.on("disconnect", () => {
      console.log("disconnect");
      setCallStatus("disconnect");
      setCallStart("stop");
      setCallMode(false);
      setIsCall(false);
      setTimeout(() => {
        getTimeline();
      }, 10000);
    });

    connection_t.on("cancel", () => {
      console.log("cancel");
      setCallStatus("cancel");
    });
  };

  const sendHangUpCall = () => {
    setIsCall(false);
    setCallMode(false);
    if (twilioConn) {
      twilioConn.disconnect(); // Disconnect the call
      setTwilioConn(null); // Clear the connection
    }
  };

  const getCallStatus = (status) => {
    if (status === "connecting") {
      return "Connecting...";
    } else if (status === "ringing") {
      return "Ringing...";
    } else if (status === "disconnected") {
      return "Disconnected";
    } else if (status === "error") {
      return "Unavailable";
    } else if (status === "reject") {
      return "Unavailable";
    } else if (status === "accept") {
      return "Answered";
    }

    return "Connecting...";
  };

  // GET FUNCTION ----------------------------------------------------------------

  const getLeadScore = async (prospect) => {
    try {
      const response = await axios.get("ws/ws_lead_score.php", {
        params: {
          task: "getLeadScore",
          deal_id: deal_id,
          prospect_id: prospect,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const { scoreMatrixDetails } = response.data;
      let leadScoreCount = 0;

      if (scoreMatrixDetails && scoreMatrixDetails.length > 0) {
        for (let i = 0; i < scoreMatrixDetails.length; i++) {
          if (Number(scoreMatrixDetails[i].score) > 0 && Number(scoreMatrixDetails[i].score) <= Number(scoreMatrixDetails[i].maxScore)) {
            leadScoreCount += Number(scoreMatrixDetails[i].score);
          }
        }
      }

      setLeadScore(leadScoreCount);
    } catch (error) {
      console.error(error);
    }
  }

  const getDeals = async () => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "5a",
          type: "view",
          deal: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setDeal(data.record);
        setRating(Number(data.record.deal_priority));

        const contributor = Number(data.record.contributor_count) > 0 && data.record.contributor.find((record) => record.user_id === data.record.owner_id);
        setIsContributor(Number(data.record.contributor_count) > 0 && !contributor);
        if (Number(session.contributor_no_edit_contact) === 1) {
          if (Number(data.record.prospect_owner) !== Number(session.user_id)) {
            setIsProspectAllowEditContact(false);
          }

          if (Number(data.record.organization_owner) !== Number(session.user_id)) {
            setIsOrganizationAllowEditContact(false);
          }
        }
        setIsOwner(true);
        getAnalytics(data.record);
        getLeadScore(data.record.prospect_id);
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    } finally {
      setImgError(false);
    }
  };

  const getUser = async () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.user_name + " (" + record.role_title + ")";
            record.value = record.user_id;
          });

          var userNotes = data.record.map((record) => ({
            label: record.label,
            value: record.value,
          }));

          userNotes.unshift({
            label: t("DealDetails_none"),
            value: "",
          });

          var userActivityRecord = data.record.map((record) => ({
            label: record.label,
            value: record.value,
          }));

          userActivityRecord.unshift({
            label: t("DealDetails_select_user"),
            value: "",
          });

          var organizationUserRecord = data.record.map((record) => ({
            label: record.label,
            value: record.value,
          }));

          setOrganizationUser(organizationUserRecord);
          setUserActivity(userActivityRecord);
          setUserNotes(userNotes);
          setUser(data.record);
        }
      });
  };

  const getCrmStatus = async () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: session.company_id,
          dealid: deal_id,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.record) {
          setPipeBtn((prevState) => ({
            ...prevState,
            won: data.pipe_naming_won,
            lost: data.pipe_naming_lost,
          }));
          setStatus(data.record);
        }
      });
  };

  const getLostReason = async () => {
    axios
      .get("ws/ws_deal_close.php", {
        params: {
          task: "getLostReason",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.reason_title;
            record.value = record.lost_reason_id;
          });
          setLostReason(data.record);
        }
      });
  };

  const getBooking = async () => {
    axios
      .get("ws/ws_rea.php", {
        params: {
          task: "BookingDeal",
          deal_id: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setBooking(data.record);
        }
      });
  };

  const getAnalytics = async (deals) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "prospectHistory",
          prospect: deals.prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      setAnalytics(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTimeline = async (socket = false) => {

    if (firstTimeline) {
      setFirstTimeline(false);
    } else {
      setLoadingTimeline(true);
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 13,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        if (data.record) {
          data.record.forEach((record) => {
            record.meetup_record_description = "";
          });

          const processedRecords = await Promise.all(
            data.record.map(async (activity, index) => {
              if (activity.record_type === "Activity" && Number(activity.record_activity_type_id) === 2) {
                const [meetupCancel, meetupReschedule] = await Promise.all([getMeetupCancel(activity.record_activity_id), getMeetupReschedule(activity.record_activity_id)]);

                if (meetupCancel.status === 0 && Number(meetupCancel.record.cancel) === 1) {
                  let desc = `Cancelled by ${meetupCancel.record.cancel_user_name} on ${moment(new Date(meetupCancel.record.cancel_date_time)).format("LLL")}`;
                  if (meetupCancel.record.cancel_reason) {
                    desc += `\nReason: ${meetupCancel.record.cancel_reason}`;
                  }
                  if (meetupCancel.record.cancel_remark) {
                    desc += `\nRemark: ${meetupCancel.record.cancel_remark}`;
                  }
                  activity.meetup_record_description = desc;
                  activity.mad_show = false;
                  activity.meeting_scheduler = true;
                } else if (meetupReschedule.status === 0) {
                  if (meetupReschedule.record.length > 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    const previousMeetupRecord = meetupReschedule.record[1];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name} \nPrevious schedule on ${moment(new Date(previousMeetupRecord.chosen_date + " " + previousMeetupRecord.chosen_time)).format("LLL")}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    }
                  } else if (meetupReschedule.record.length === 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    } else {
                      activity.meetup_record_description = "";
                    }
                  } else {
                    activity.meetup_record_description = "";
                  }
                  if (index === data.record.length - 1) {
                    activity.mad_show = true;
                  } else {
                    activity.mad_show = false;
                  }

                  activity.meeting_scheduler = true;
                } else {
                  activity.mad_show = true;
                  activity.meetup_record_description = "";
                  activity.meeting_scheduler = false;
                }

                activity.meetup_cancel = meetupCancel;
                activity.meetup_reschedule = meetupReschedule;
              } else {
                activity.meetup_record_description = "";
                activity.meeting_scheduler = false;
              }

              if (!initialized.current || socket) {
                if ((activity.record_type === "Activity" || activity.record_type === "CustomActivity" || activity.record_type === "NoEditActivity") && activity.record_activity_type === "Call" && activity.record_file_url) {
                  try {
                    if (socket) setCallAnalytics([]);
                    const sentimentAnalysis = await getSentimentAnalysis(activity);
                    if (Number(sentimentAnalysis.call_insight_status) === 1) {
                      const newCallInsight = {
                        record_activity_id: activity.record_activity_id,
                        analytics_param_1: sentimentAnalysis.analytics_param_1 || "",
                        analytics_param_2: sentimentAnalysis.analytics_param_2 || "",
                        analytics_param_3: sentimentAnalysis.analytics_param_3 || "",
                        analytics_param_4: sentimentAnalysis.analytics_param_4 || "",
                        analytics_param_5: sentimentAnalysis.analytics_param_5 || "",
                        analytics_param_6: sentimentAnalysis.analytics_param_6 || "",
                        analytics_param_7: sentimentAnalysis.analytics_param_7 || "",
                        analytics_param_8: sentimentAnalysis.analytics_param_8 || "",
                        analytics_param_9: sentimentAnalysis.analytics_param_9 || "",
                        analytics_param_10: sentimentAnalysis.analytics_param_10 || "",
                        analytics_field_1: sentimentAnalysis.analytics_field_1 || "",
                        analytics_field_2: sentimentAnalysis.analytics_field_2 || "",
                        analytics_field_3: sentimentAnalysis.analytics_field_3 || "",
                        analytics_field_4: sentimentAnalysis.analytics_field_4 || "",
                        analytics_field_5: sentimentAnalysis.analytics_field_5 || "",
                        analytics_field_6: sentimentAnalysis.analytics_field_6 || "",
                        analytics_field_7: sentimentAnalysis.analytics_field_7 || "",
                        analytics_field_8: sentimentAnalysis.analytics_field_8 || "",
                        analytics_field_9: sentimentAnalysis.analytics_field_9 || "",
                        analytics_field_10: sentimentAnalysis.analytics_field_10 || "",
                        ai_summary: sentimentAnalysis.ai_summary || "",
                        loading: false,
                      };
                      setCallAnalytics((prevCallAnalytics) => [...prevCallAnalytics, { ...newCallInsight }]);
                    }
                  } catch (error) {
                    console.error("Error fetching sentiment analysis:", error);
                  }
                }
              }

              activity.mad_show = false;
              return activity;
            })
          );

          const meetingRecord = processedRecords.filter((record) => Number(record.record_activity_type_id) === 2);
          if (meetingRecord && meetingRecord.length > 0) {
            const meetingRecordLatest = getLatestRecord(meetingRecord);
            processedRecords.forEach((record) => {
              if (record.record_activity_id === meetingRecordLatest.record_activity_id) {
                record.mad_show = true;
              }
            });
          }

          setTimeline(processedRecords);
        } else {
          setTimeline([]);
        }
      } else {
        setTimeline([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
      setIsTimeline(true);
      setLoadingTimeline(false);
    }
  };

  function getLatestRecord(records) {
    if (records.length === 0) return null;

    return records.reduce((latest, record) => {
      const latestDate = new Date(latest.record_date_time_created);
      const currentDate = new Date(record.record_date_time_created);
      return currentDate > latestDate ? record : latest;
    });
  }

  const getMeetupCancel = async (activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupInfo",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetupReschedule = async (activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupRescheduleLog",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getSentimentAnalysis = (activity) => {
    return new Promise((resolve, reject) => {
      axios
        .get("ws/ws_sentiment_analysis.php", {
          params: {
            task: "get_call_insight_timeline_v2",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            call_data_id: activity.record_activity_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getExistingProspect = async (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectsForListbox",
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = Object.values(data.record)
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
            }));

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getExistingDesignation = async (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: session.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getDuplicateContact = async (contact, index) => {
    let isDuplicateExists;

    const contactFields = [`contact1`, `contact2`, `contact3`, `contact4`];
    const fieldToCheck = contactFields[index - 1];

    if (index >= 1 && index <= 4 && deal[fieldToCheck] === contact) {
      return true;
    }

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          task: "getMobileExist",
          company: session.company_id,
          people: deal.prospect_id,
          user: session.user_id,
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) !== 1;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const getDealSource = async () => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: 2,
          disp: "show",
          company: session.company_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOptions = data.record.map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

          setDealSource(recordOptions);
        }
      });
  };

  const getForm = async () => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "getAllCustomForms",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOptions = data.custom_form.map((record) => ({
            label: record.form_title,
            value: record.custom_form_id,
          }));

          setForm(recordOptions);
        }
      });
  };

  const debounceDuplicateContact = debounce(getDuplicateContact, 1000);

  const getCountry = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.country_title,
          value: record.country_id,
        }));
        setCountry(options);
      });
  };

  const getState = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.state_title,
          value: record.state_id,
          country: record.country_id,
        }));
        setState(options);
      });
  };

  const getCity = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.city_title,
          value: record.city_id,
          state: record.state_id,
        }));
        setCity(options);
      });
  };

  const getEmailTemplate = async () => {
    axios
      .get("ws/ws_email.php", {
        params: {
          task: "getEmailTemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOption = data.record.map((record) => ({
            label: record.email_template_title,
            value: record.email_template_id,
          }));

          setEmailTemplate(recordOption);
        } else {
          setEmailTemplate([]);
        }
      });
  };

  const getWhatsappTemplate = async () => {
    axios
      .get("ws/ws_custom_interaction.php", {
        params: {
          task: "getWhatsappInteractions",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.interaction_title = record.interaction_title.replace("\n", "%0a");
          });

          const recordOption = data.record.map((record) => ({
            label: record.interaction_name,
            value: record.custom_interaction_id,
            template: record.interaction_title,
          }));

          setWhatsappTemplate(recordOption);
        } else {
          setWhatsappTemplate([]);
        }
      });
  };

  const getProduct = async () => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          task: "getDealProducts",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const updatedProducts = data.product.map((record) => ({
          deal_product_id: record.deal_product_id,
          deal_id: record.deal_id,
          product_id: record.product_id,
          total_qty: record.total_qty,
          product_title: record.product_title,
          discount: record.discount,
          remark: record.remark,
          final_price: record.final_price,
          serial_num: record.serial_num,
          product_price: (Number(record.final_price) + Number(record.discount)) / Number(record.total_qty),
        }));

        setCurrentUser(response);
        setProductCount(data.totalProduct);
        setDealProduct(updatedProducts);
      } else {
        setCurrentUser(response);
        setDealProduct([]);
        setProductCount(0);
      }
    } catch (error) {
      console.error(error);
      setDealProduct([]);
      setProductCount(0);
    }
  };

  const getProductV2 = async () => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          task: "getDealProductsV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const updatedProducts = data.product.map((record) => ({
          deal_product_id: record.deal_product_id,
          deal_id: record.deal_id,
          product_id: record.product_id,
          total_qty: record.total_qty,
          product_title: record.product_title,
          discount: record.discount,
          remark: record.remark,
          final_price: record.final_price,
          serial_num: record.serial_num,
          product_price: (Number(record.final_price) + Number(record.discount)) / Number(record.total_qty),
          commission: record.commission,
          default_price: record.default_price,
        }));

        setDealProductV2(updatedProducts);
      } else {
        setDealProductV2([]);
      }
    } catch (error) {
      console.error(error);
      setDealProductV2([]);
    }
  };

  const getAllProduct = async () => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          bu_id: 0,
          ctoken: session.company_token,
          task: "getAllProducts",
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setProductList(data.prod_cat);
      }
    } catch {
      setProductCount(0);
    }
  };

  const getOrganizationData = async () => {
    try {
      const response = await axios.get("ws/ws_gplaces.php", {
        params: {
          task: "getPlaces",
          placeTitle: encodeURIComponent(deal.organization_title),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        setFindOrganizationData(data.record);
        setIsFindOrganization(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIndustry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "getOrganizations",
          company_id: session.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.in_title,
          value: record.in_id,
        }));

        setIndustryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationCountry = async () => {
    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 10,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.country_nicename,
          value: record.country_id,
        }));
        setCountryOrganizationList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetup = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupLink",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
        },
      });

      const data = response.data;
      setMeetupLink(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCancelMeetupReason = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupCancelReason",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOption = data.record.map((record) => ({
          label: record.cancel_reason,
          value: record.cancel_reason_id,
        }));

        setCancelMeetupReasonList(recordOption);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingAvailability = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkMeetingAvailability",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          duration: values.activity_duration,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const findRecord = data.record.find((record) => record.activity_mark_as_done === "no");
        if (findRecord) {
          const newDate = new Date(findRecord.activity_dated + " " + findRecord.activity_timed);
          const momentDate = moment(newDate).format("LLL");
          Swal.fire({
            icon: "warning",
            text: `You have an existing meeting scheduled with ${findRecord.prospect_name} on ${momentDate}. Kindly arrange an alternative date and time for this meeting.`,
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingDealStatus = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkActiveMeeting",
          deal_id: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        return true;
      } else {
        Swal.fire({
          icon: "warning",
          text: `You have an active meeting scheduled in this deal.`,
        });
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAiFollowUp = async () => {
    setModalLoading(true);
    try {
      const formData = new FormData();
      formData.append("task", "getAIFollowUp");
      formData.append("deal_id", deal_id);
      formData.append("prospect_id", deal.prospect_id);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/ws/ws_ai_notes.php`, formData);
      const data = response.data;
      if (Number(data.status) === 0) {
        data.whatsappMessage = ReactHtmlParser(data.whatsappMessage);
        setAiFollowUpData(data);
      } else {
        setAiFollowUpData({
          agentNotes: "",
          audioFilename: "",
          whatsappMessage: "",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const getAiTimelineSummary = async () => {
    setIsAiTimelineLoading(true);
    try {
      const formData = new FormData();
      formData.append("deal_id", deal_id);
      formData.append("prospect_id", deal.prospect_id);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("task", "getAITimelineSummary");

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/ws/ws_ai_notes.php`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "PHPSESSID=bnvfehdifa5b15mk4bdq4vteg6",
        },
      });

      const data = response.data;

      if (data.status === 0) {
        setAiTimelineSummaryData(data);
      } else {
        setAiTimelineSummaryData({ extraNotes: "Please contact our support team for assistance.", timelineSummary: "Something went wrong" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (firstTime) {
        setFirstTime(false);
        setTimeout(() => {
          setIsAiTimelineLoading(false);
        }, 500);
      } else {
        setIsAiTimelineLoading(false);
      }
    }
  };

  const getSmartLink = async () => {
    try {
      const response = await axios.get("docs/smart_link_api.php", {
        params: {
          task: "getLinks",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setSmartLinkList(data.record.map((record) => ({ label: record.smart_link_title, value: record.smart_link_template_id, ...record })));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION -----------------------------------------------------------

  const onchangeActionTabs = (key) => {
    setDisabledBtn(false);
    setIsSubmitting(false);
    setActiveTab(key);

    if (key === "ai_follow_up") {
      setIsActiveTab(true);
      getAiFollowUp();
    }

    // setIsActiveTab(false);
  };

  const onchangePriority = (priority) => {
    if ((deal.deal_archieve === "" && Number(deal.owner_id) === Number(session.user_id)) || Number(session.role_id) === 1) {
      setRating(priority);
      axios.get("ws/ws_deal.php", {
        params: {
          task: 34,
          priority: priority,
          deal: deal_id,
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_you_dont_have_permission_to_do_this_action"),
      });
    }
  };

  const onchangeStage = async (stage) => {

    const currentStageIndex = deal.cs_index;
    setDeal((prev) => ({ ...prev, cs_index: stage.cs_index }));

    try {
      const response = await axios.get("ws/ws_stage.php", {
        params: {
          task: "checkStageEnabled",
          csid: stage.cs_id,
          dealid: deal_id,
          prospectid: deal.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await sendMoveStage(stage, currentStageIndex);
      } else {
        setDeal((prev) => ({ ...prev, cs_index: currentStageIndex }));
        var element = data.record.map((record, index) => (
          <h6 key={index} className="d-flex">
            <FontAwesomeIcon icon={faShieldExclamation} className="me-2" size="lg" /> {record.title}
          </h6>
        ));

        Swal.fire({
          icon: "warning",
          text: t("DealDetails_stage_locked_please_fulfill_these_conditions_to_proceed"),
          content: {
            element: "div",
            attributes: {
              innerHTML: element,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ---------------------------------------------------------------

  const sendMoveStage = async (stage, currentStageIndex) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 7,
          user: session.user_id,
          deal: deal_id,
          status: stage.cs_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });
  
      const data = response.data;
      if (data.status === 0) {
        await getTimeline();
      } else {
        setDeal((prev) => ({ ...prev, cs_index: currentStageIndex }));
      }

    } catch (error) {
      console.error(error);
    }

  };

  const sendTransferDeal = async (values) => {
    setDeal((prev) => ({ ...prev, owner_id: values.user, prospect_owner: values.user }));
    setIsTransferOwner(false);

    try {
      await axios.get("ws/ws_deal.php", {
        params: {
          task: "transferOwnership",
          deal: deal_id,
          user: values.user,
          transferoption: 2,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      getTimeline();
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_deal_has_been_transferred"),
        timer: 2000,
      });

      if (Number(session.user_id) !== Number(values.user)) {
        await sendTransferDealInfo(values);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const sendTransferDealInfo = async (values) => {
    try {
      await axios.get("ws/ws_system.php", {
        params: {
          task: 5,
          action: 1,
          type: "deal",
          type_id: deal_id,
          sender: session.user_id,
          sender_type: session.role_id,
          receiver: values.user,
          receiver_type: values.role_id,
          company: session.company_id,
          prefix: "transfer",
        },
      });

      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_deal_has_been_transferred"),
        timer: 2000,
      });

      setIsTransferOwner(false);
      getDeals();
      getTimeline();
    } catch (error) {
      console.error(error);
    }
  };

  const sendDealWon = async (values) => {
    let productCount = 0;
    if (Number(deal.final_conversion) === 1) {
      productCount = productCount;
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: session.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: session.company_id,
          lostReasonID: "",
          productsAdded: productCount,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals();
        getAnalytics();
        getTimeline();
        setIsWon(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_has_been_marked_as_won"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDealLost = async (values) => {
    let productCount = 0;
    if (Number(deal.final_conversion) === 1) {
      productCount = productCount;
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: session.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: session.company_id,
          lostReasonID: values.lostReasonID,
          productsAdded: productCount,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals();
        getAnalytics();
        getTimeline();
        setIsLost(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_has_been_marked_as_lost"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteDeal = async (values) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: session.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: session.company_id,
          lostReasonID: "",
          productsAdded: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsDeleteDeal(false);
        getDeals();
        getAnalytics();
        getTimeline();
        Swal.fire({
          icon: "success",
          text: t("DealDetails_the_deal_has_been_deleted"),
          timer: 1500,
        });
      } else {
        setIsDeleteDeal(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendReopenDeal = async () => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "reopenDeal",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user: session.user_id,
          deal: deal_id,
          productsAdded: productCount,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getAnalytics();
        getDeals();
        getTimeline();
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_the_deal_successfully_reopen"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendChangeProspect = async (values) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "changeDealProspect",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal_id,
          prospectid: values.prospect,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals(session);
        Swal.fire({
          icon: "success",
          text: t("DealDetails_prospect_updated_successfully_changes_have_been_applied"),
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: t("DealDetails_the_prospect_chosen_has_not_been_changed"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsChangeProspect(false);
    }
  };

  const sendEditProspect = async (values) => {
    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var prospect_id = Array(customField.length).fill(deal.prospect_id);
    var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      })
      .filter((value) => value !== null);

    try {
      await sendEditProspectData(values);
      if (customField.length > 0) {
        await sendEditProspectCF(values, cf_id, cf_label, prospect_id, fd_values);
      }

      const response_prospect_extra = await sendEditProspectExtra(values);
      const status = response_prospect_extra ? 0 : 1;

      if (status === 0) {
        getDeals();
        setIsEditProspect(false);
        return Swal.fire({
          icon: "success",
          text: t("DealDetails_the_prospect_has_been_updated_successfully"),
          timer: 1500,
        });
      } else {
        getDeals();
        setIsEditProspect(false);
        return Swal.fire({
          icon: "error",
          text: "Prospect updated successfuly but we are unable to update prospect contact details, we are detecting duplicate contact details",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectData = async (values) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: values.designation_title,
          des: values.designation_title,
          name: values.prospect_name,
          organization: "",
          prospect_address: "",
          prospect: deal.prospect_id,
          owner_id: values.owner_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectExtra = async (values) => {
    try {
      const response = await axios.get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: deal.prospect_id,
          contact1_prefix: values.contact1Prefix,
          contact1: values.contact1,
          contact1_old: "",
          contact2_prefix: values.contact2Prefix,
          contact2: values.contact2,
          contact3_prefix: values.contact3Prefix,
          contact3: values.contact3,
          contact4_prefix: values.contact4Prefix,
          contact4: values.contact4,
          email1: values.email1,
          email2: values.email2,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.state_id,
          city_id: values.city_id,
          postcode: values.postcode,
          country_id: values.country_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectCF = async (values, cf_id, cf_label, prospect_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCF",
          record: prospect_id.toString(),
          hq_prospect_id: "",
          cf_label: cf_label.toString().replaceAll(" ", "_"),
          cf: cf_id.toString(),
          value: fd_values.toString(),
          user_id: session.user_id,
          company_id: session.company_id,
          secret_key: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditDeal = async (values) => {
    var customField = values.cf_deal;
    var cf_id = customField.map((record) => record.name);
    var deal_id = Array(customField.length).fill(deal.deal_id);
    var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      })
      .filter((value) => value !== null);

    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCF",
          secret_key: session.company_id,
          value: fd_values.toString(),
          cf: cf_id.toString(),
          record: deal_id.toString(),
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getDeals();
        Swal.fire({
          icon: "success",
          text: t("DealDetails_the_prospect_has_been_updated_successfully"),
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_there_is_some_of_information_has_been_updated_but_there_is_something_wrong_with_your_entry_please_try_again"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsEditDeal(false);
    }
  };

  const sendAddContributor = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "addDealContributor",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal.deal_id,
          userid: values.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            text: t("DealDetails_the_contributer_addition_is_success"),
            timer: 1500,
          });
          getDeals();
          setIsAddContributor(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_the_user_chosen_has_already_been_added_to_this_deal"),
          });
          getDeals();
          setIsAddContributor(false);
        }
      });
  };

  const sendAddForm = (values) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "addCustomFormV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal.deal_id,
          custom_form_id: values.form_id,
          custom_form_title: values.form_title,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            text: t("DealDetails_the_form_addition_is_success"),
            timer: 1500,
          });
          getDeals();
          setisAddForm(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_the_form_chosen_has_already_been_added_to_this_deal"),
          });
          getDeals();
          setisAddForm(false);
        }
      });
  };

  const sendNewNotes = async (values) => {
    setDisabledBtn(true);
    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.notes = values.notes.replace(images[index], newImgTag);
      });
    }

    const formData = new FormData();
    formData.append("task", "postNote");
    formData.append("region", session.country);
    formData.append("content", values.notes);
    formData.append("notify_user", values.user);
    formData.append("deal", deal_id);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_notes"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddActivity = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addNewActivity",
          region: session.country,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: "",
          assign_user: values.activity_user,
          deal: deal_id,
          people: deal.prospect_id,
          organization: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (values.google_calendar) {
          const date = moment(values.activity_date, "DD-MM-YYYY");
          const newDateStart = moment(date.format("YYYY-MM-DD") + " " + values.activity_type, "YYYY-MM-DD HH:mm");
          const dateStart = newDateStart.toISOString();
          const formattedDateStart = dateStart.substring(0, 19) + "Z";
          const duration = values.activity_duration ? parseInt(values.activity_duration.match(/\d+/)[0]) : 60;
          const newDateEnd = newDateStart.clone().add(duration, "minutes");
          const dateEnd = newDateEnd.toISOString();
          const formattedDateEnd = dateEnd.substring(0, 19) + "Z";
          const title = values.activity_title + " with " + deal.prospect_name;
          const googleCalendarLink = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(title) + "&details=" + encodeURIComponent(values.activity_notes) + "&location=&dates=" + formattedDateStart + "/" + formattedDateEnd;
          window.open(googleCalendarLink);
        }
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
      setIsActiveTab(false);
      setIsSubmitting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddMeetingScheduler = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const checkDealMeeting = await getMeetingDealStatus();
      if (!checkDealMeeting) {
        setIsSubmitting(false);
        return;
      }

      const checkMeetingAvailability = await getMeetingAvailability(values);
      if (!checkMeetingAvailability) {
        setIsSubmitting(false);
        return;
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addNewActivity",
          region: session.country,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: "",
          assign_user: values.activity_user,
          deal: deal_id,
          people: deal.prospect_id,
          organization: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (values.google_calendar) {
          const date = moment(values.activity_date, "DD-MM-YYYY");
          const newDateStart = moment(date.format("YYYY-MM-DD") + " " + values.activity_type, "YYYY-MM-DD HH:mm");
          const dateStart = newDateStart.toISOString();
          const formattedDateStart = dateStart.substring(0, 19) + "Z";
          const duration = values.activity_duration ? parseInt(values.activity_duration.match(/\d+/)[0]) : 60;
          const newDateEnd = newDateStart.clone().add(duration, "minutes");
          const dateEnd = newDateEnd.toISOString();
          const formattedDateEnd = dateEnd.substring(0, 19) + "Z";
          const title = values.activity_title + " with " + deal.prospect_name;
          const googleCalendarLink = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(title) + "&details=" + encodeURIComponent(values.activity_notes) + "&location=&dates=" + formattedDateStart + "/" + formattedDateEnd;
          window.open(googleCalendarLink);
        }

        setLeadScoreUpdate(true);
        sendCreateMeetup(values, data.record);
      } else {
        setIsActiveTab(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
      setIsActiveTab(false);
      setIsSubmitting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendAddFile = async (values) => {
    setDisabledBtn(true);
    const formData = new FormData();
    formData.append("deal_id", deal_id);
    formData.append("user_id", session.user_id);
    formData.append("file_title", values.title);
    formData.append("file", values.file[0]);

    try {
      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_file.php`, formData);
      const data = response.data;

      if (data.status === 0) {
        setLeadScoreUpdate(true);
        setIsActiveTab(false);
        getTimeline(false);

        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_upload_the_file"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);

        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
    }
  };

  const sendAddMessages = (values) => {
    setDisabledBtn(true);
    if (values.message_type === "email") {
      sendEmail(values);
    } else {
      if (values.smartlink !== "" || values.smartlink !== null || values.smartlink !== undefined || values.smartlink !== "0") {
        sendSmartLink(values);
      } else {
        sendWhatsapp(values);
      }
    }
  };

  const sendEmail = async (values) => {
    try {
      let receiverEmail = "";
      if (deal.email1) {
        receiverEmail = deal.email1;
      } else if (deal.email2) {
        receiverEmail = deal.email2;
      } else if (deal.prospect_email_info && deal.prospect_email_info.length > 0) {
        receiverEmail = deal.prospect_email_info[0].email_title;
      }

      let modifiedEmailContent = values.email_content.split("../textarea_upload/").join(`${session.hostUrl}/${session.hostUrlType}/textarea_upload/`).split("../../ext/").join(`${session.hostURL}/${session.hostUrlType}/ext/`).split("../").join(`${session.hostURL}/${session.hostUrlType}`);

      const formData = new FormData();
      formData.append("task", "sendEmail");
      formData.append("emailContent", modifiedEmailContent);
      formData.append("deal", deal_id);
      formData.append("receiverEmail", receiverEmail);
      formData.append("receiverName", deal.prospect_name);
      formData.append("subject", values.email_subject);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      await axios.post("ws/ws_email_post.php", formData);
      setLeadScoreUpdate(true);
      setIsActiveTab(false);
      getTimeline();
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_the_email_has_been_scheduled"),
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendWhatsapp = async (values, smartLinkHash = "") => {
    let numToContact = "";
    if (deal.contact1) {
      numToContact = deal.contact1;
    } else if (deal.contact2) {
      numToContact = deal.contact2;
    } else if (deal.prospect_contact_count > 0) {
      numToContact = deal.prospect_contact_info[0].contact_num;
    } else {
      Swal.fire({
        icon: "warning",
        text: "There is no contact number for this prospect",
        timer: 20000,
      });
    }

    if (numToContact.charAt(0) === "0") {
      numToContact = "6" + numToContact;
    }

    numToContact = numToContact.replace(/-/g, "");
    numToContact = numToContact.replace(/ /g, "");

    var message = values.email_content;
    if (smartLinkHash !== "") {
      message = message + "\n" + `${session.hostURL}/docs/i/${smartLinkHash}`;
    }

    window.open(`https://web.whatsapp.com/send?phone=${numToContact}&text=${message}`);

    try {
      await axios.get("ws/ws_custom_interaction.php", {
        params: {
          task: "setInteraction",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          interaction_id: values.whatsapp_template,
          source: "deal",
          type_id: deal_id,
          prospect_id: deal.prospect_id,
          message: message,
          hashid: smartLinkHash,
        },
      });
      setLeadScoreUpdate(true);
      setIsActiveTab(false);
      getTimeline();
      Swal.fire({
        icon: "success",
        title: t("DealDetails_success"),
        text: t("DealDetails_the_whatsapp_message_has_been_setup"),
        timer: 1500,
      });
    } catch {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendSmartLink = async (values) => {
    try {
      const response = await axios.get("docs/smart_link_api.php", {
        params: {
          task: "setLink",
          deal_id: deal_id,
          template_id: values.smartlink,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      sendWhatsapp(values, data.record);
    } catch (error) {
      console.error(error);
    }
  };

  const sendMarkAsDone = async (record) => {
    try {
      var okToMark = setCheckOkToEdit(record.record_create_user_id, record.record_assign_user_id);
      if (okToMark) {
        var newValue = record.record_activity_mad === "yes" ? "no" : "yes";
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 16,
            activity: record.record_activity_id,
            status: newValue,
            user: session.user_id,
            deal_id: deal_id,
          },
        });

        setLeadScoreUpdate(true);
        getTimeline();
      } else {
        Swal.fire({
          icon: "warning",
          title: t("DealDetails_failed"),
          text: t("DealDetails_sorry_you_cant_edit_this_task"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditActivity = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 12,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          ca_id: values.ca_id,
          assign_user: values.activity_user,
          aid: values.record_activity_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 12,
            create_user: session.user_id,
            dated: values.activity_date,
            timed: values.activity_time,
            duration: values.activity_duration,
            title: values.activity_title,
            content: values.activity_notes,
            mad: values.mad ? "yes" : "no",
            dat: values.activity_type,
            deal: deal_id,
            ca_id: values.ca_id,
            assign_user: values.activity_user,
            aid: values.record_activity_id,
          },
        });

        setIsEditActivity(false);
        setIsSubmitting(false);
        getTimeline();
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_update_the_activity"),
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        setIsEditActivity(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      setIsEditActivity(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendEditMeeting = async (values) => {
    setIsSubmitting(true);

    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.activity_notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.activity_notes = values.activity_notes.replace(images[index], newImgTag);
      });
    }

    try {
      const checkMeetingAvailability = await getMeetingAvailability(values);
      if (!checkMeetingAvailability) {
        setIsSubmitting(false);
        return;
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 12,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          ca_id: values.ca_id,
          assign_user: values.activity_user,
          aid: values.record_activity_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        await axios.get("ws/ws_deal.php", {
          params: {
            task: 12,
            create_user: session.user_id,
            dated: values.activity_date,
            timed: values.activity_time,
            duration: values.activity_duration,
            title: values.activity_title,
            content: values.activity_notes,
            mad: values.mad ? "yes" : "no",
            dat: values.activity_type,
            deal: deal_id,
            ca_id: values.ca_id,
            assign_user: values.activity_user,
            aid: values.record_activity_id,
          },
        });

        if (Number(values.record_activity_type_id) === 2 && meetingData.meetup_cancel.status === 0 && Number(meetingData.meetup_cancel.record.cancel) === 0) {
          sendRescheduleMeetup(values);
        } else {
          setIsEditMeeting(false);
          setIsSubmitting(false);
          getTimeline();
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_update_the_activity"),
            timer: 1500,
          });
        }
      } else {
        setIsActiveTab(false);
        setIsEditMeeting(false);
        setIsSubmitting(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      setIsEditMeeting(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendDeleteActivity = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: `${t("DealDetails_please_confirm_that_you_would_like_to_remove")} ${record.activity_title}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.get("ws/ws_deal.php", {
            params: {
              task: "deleteActivityV2",
              activity: record.record_activity_id,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          });

          getTimeline();
          Swal.fire({
            icon: t("DealDetails_success"),
            title: t("DealDetails_the_activity_has_been_removed"),
            timer: 1500,
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const sendEditNotes = async (values) => {
    const base64Pattern = /<img src="data:image\/([a-zA-Z]+);base64,([^"]*)"/g;
    let matches;
    let images = [];

    while ((matches = base64Pattern.exec(values.notes)) !== null) {
      images.push(matches[0]);
    }

    if (images.length > 0) {
      const updateImagePromises = images.map((img) => {
        const base64Data = img.match(/data:image\/([a-zA-Z]+);base64,([^"]*)/)[2];
        const formData = new FormData();
        formData.append("image", base64Data);
        return axios.post(`${session.hostUrl}/${session.hostUrlType}/php/note_editor_upload.php`, formData);
      });

      const responses = await Promise.all(updateImagePromises);
      responses.forEach((response, index) => {
        const imageUrl = `${session.hostUrl}/${session.hostUrlType}${response.data.image}`;
        const newImgTag = images[index].replace(/src="data:image\/([a-zA-Z]+);base64,([^"]*)"/, `src="${imageUrl}"`);
        values.notes = values.notes.replace(images[index], newImgTag);
      });
    }

    const formData = new FormData();
    formData.append("task", "editNotes");
    formData.append("content", values.notes);
    formData.append("note", values.record_activity_id);
    formData.append("deal", deal_id);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setisEditNote(false);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_updated_the_notes"),
          timer: 1500,
        });
      } else {
        setisEditNote(false);
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_error_api_message"),
      });
    }
  };

  const sendDeleteNotes = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: t("Please confirm that you would like to remove this note"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "24",
              note: record.record_activity_id,
              prospectNote: "",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t("DealDetails_the_activity_has_been_removed"),
              timer: 1500,
            });
          });
      }
    });
  };

  const sendDeleteFiles = (record) => {
    Swal.fire({
      title: t("DealDetails_are_you_sure"),
      text: t("DealDetails_please_confirm_that_you_would_like_to_remove_this_file"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "25",
              file: record.record_activity_id,
            },
          })
          .then((response) => {
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t("DealDetails_the_activity_has_been_removed"),
              timer: 1500,
            });
          });
      }
    });
  };

  const sendEditDealInfo = (values) => {
    const backup_data = { ...deal };
    var updateCloseDate = 0;
    if (deal.deal_archieve === "won" || deal.deal_archieve === "lost") {
      updateCloseDate = 1;
    }

    setDeal((prev) => ({ 
      ...prev, 
      deal_title: values.deal_title, 
      deal_value: values.deal_price, 
      deal_expected_close_date: values.deal_expected_close, 
      deal_date_time_create: values.deal_added, 
      deal_archieve: values.deal_archieve, 
      deal_archieve_won_log: prev.deal_archieve === "won" ? values.deal_close : "0000-00-00 00:00:00",
      deal_archieve_lost_log: prev.deal_archieve === "lost" ? values.deal_close : "0000-00-00 00:00:00",
      deal_archieve_deleted_log: prev.deal_archieve === "deleted" ? values.deal_close : "0000-00-00 00:00:00",
    }));

    setIsEditDealInfo(false);

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 4,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          title: values.deal_title,
          value: values.deal_price,
          close: values.deal_expected_close,
          added: values.deal_added,
          archieve: values.deal_archieve,
          dealclosed: values.deal_close,
          deal: deal.deal_id,
          updateAddedDate: 1,
          updateExpireDate: 1,
          updateCloseDate: updateCloseDate,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_the_deal_has_been_updated"),
            timer: 2000,
          });
        } else {
          setDeal(backup_data);
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_error_api_message"),
          });
        }
      });
  };

  const sendDeleteContributor = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "delContributor",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal_id,
          userid: record.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_error_api_message"),
          });
        } else {
          getDeals();
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_removed_the_contributor"),
            timer: 2000,
          });
        }
      });
  };

  const sendDeleteForm = (record) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "removeCustomForm",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
          custom_form_deal_id: record.custom_form_deal_id,
          custom_form_id: record.custom_form_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_error_api_message"),
          });
        } else {
          getDeals();
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_removed_the_form"),
            timer: 2000,
          });
        }
      });
  };

  const sendDeleteProduct = (record) => {
    axios
      .get("ws/ws_product.php", {
        params: {
          task: "delDealProduct",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
          deal: deal.deal_id,
          product: record.product_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getProduct();
          getAllProduct();
          getTimeline();
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_removed_the_product"),
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_successfully_removed_the_form"),
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_successfully_removed_the_form"),
        });
      });
  };

  const sendChooseOrganization = async (record) => {
    try {
      const dealRecord = {
        ...deal,
        organization_title: record.name,
        organization_address: record.address,
        organization_web_url: record.website,
        organization_contact: record.phone,
      };
      setDeal(dealRecord);

      await axios.get("ws/ws_organization.php", {
        params: {
          task: "updateOrgData",
          organization: deal.organization_id,
          title: encodeURIComponent(record.name),
          address: record.address,
          weburl: record.website,
          industry: deal.in_id,
          contact: encodeURIComponent(record.phone),
          country: deal.organization_country_id,
          latitude: record.lat,
          longitude: record.lon,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      setIsFindOrganization(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sendCallLead = async () => {
    setIsCallLead(true);

    let contactNumber = "";
    const prospectContactInfo = deal.prospect_contact_info;

    for (let i = 1; i <= 5; i++) {
      const contact = deal[`contact${i}`];
      if (contact) {
        contactNumber = contact;
        break;
      } else if (prospectContactInfo && prospectContactInfo.length > 0) {
        contactNumber = prospectContactInfo[0].contact_num;
      }
    }

    if (!contactNumber) {
      setIsCallLead(false);
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_there_arent_any_contact_numbers"),
      });
      return;
    }

    try {
      await axios.get("ws/ws_avanser.php", {
        params: {
          task: 1,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          contact: contactNumber,
        },
      });

      setTimeout(() => setIsCallLead(false), 10000);
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditOrganization = async (values) => {
    try {
      var customField = values.cf_organization;
      var cf_id = customField.map((record) => record.name);
      var organization_id = Array(customField.length).fill(deal.organization_id);
      var fd_values = customField
        .map((record) => {
          if (Array.isArray(record.fd_values)) {
            var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
            return filteredValues.length > 0 ? filteredValues.join("|") : null;
          } else {
            return record.fd_values;
          }
        })
        .filter((value) => value !== null);

      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: 2,
          organization: deal.organization_id,
          company: session.company_id,
          title: values.organization_title,
          address: values.organization_address,
          weburl: values.organization_web_url,
          industry: values.in_id,
          contact: values.organization_contact,
          country: values.organization_country_id,
          owner: values.owner_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          const response2 = await axios.get("ws/ws_custom_field.php", {
            params: {
              task: "updateOrAddCF",
              secret_key: session.company_id,
              value: fd_values.toString(),
              cf: cf_id.toString(),
              record: organization_id.toString(),
            },
          });

          const data2 = response2.data;
          if (data2.status === 0) {
            Swal.fire({
              icon: "success",
              text: "Successfully updated the organization",
              timer: 2000,
            });
            getDeals();
            setIsEditOrganization(false);
          } else {
            Swal.fire({
              icon: "success",
              text: "There is some of information has been updated but there is something wrong with your entry, please try again to update or contact our support IT",
              timer: 2000,
            });
            getDeals();
            setIsEditOrganization(false);
          }
        } else {
          Swal.fire({
            icon: "success",
            text: "Successfully updated the organization",
            timer: 2000,
          });
          getDeals();
          setIsEditOrganization(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNewOrganization = async (values) => {
    try {
      var customField = values.cf_organization;
      var cf_id = customField.map((record) => record.name);
      var organization_id = Array(customField.length).fill(deal.organization_id);
      var fd_values = customField
        .map((record) => {
          if (Array.isArray(record.fd_values)) {
            var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
            return filteredValues.length > 0 ? filteredValues.join("|") : null;
          } else {
            return record.fd_values;
          }
        })
        .filter((value) => value !== null);

      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "addOrgAndConnectProspect",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          title: values.organization_title,
          address: values.organization_address,
          weburl: values.organization_web_url,
          industry: values.in_id,
          contact: values.organization_contact,
          country: values.organization_country_id,
          prospect: deal.prospect_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (customField.length > 0) {
          const response2 = await axios.get("ws/ws_custom_field.php", {
            params: {
              task: "updateOrAddCF",
              secret_key: session.company_id,
              value: fd_values.toString(),
              cf: cf_id.toString(),
              record: organization_id.toString(),
            },
          });

          const data2 = response2.data;
          if (data2.status === 0) {
            Swal.fire({
              icon: "success",
              text: "Successfully added the organization",
              timer: 2000,
            });
            getDeals();
            setIsAddOrganization(false);
          } else {
            Swal.fire({
              icon: "success",
              text: "There is some of information has been added but there is something wrong with your entry, please try again to add or contact our support IT",
              timer: 2000,
            });
            getDeals();
            setIsAddOrganization(false);
          }
        } else {
          Swal.fire({
            icon: "success",
            text: "Successfully added the organization",
            timer: 2000,
          });
          getDeals();
          setIsAddOrganization(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Sorry, this organization name already exists!",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCancelMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "cancelMeetup",
          deal_id: deal_id,
          activity_id: meetingData.record_activity_id,
          cancel_reason: values.reason,
          cancel_remark: values.remark,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getTimeline();
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "success",
          text: "Successfully cancel the appointment",
          timer: 2000,
        });
      } else {
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRescheduleMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "rescheduleMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsEditActivity(false);
        setIsSubmitting(false);
        getTimeline();
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_update_the_activity"),
          timer: 1500,
        });
      } else {
        setIsEditActivity(false);
        setIsSubmitting(false);
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateMeetup = async (values, activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "createMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          activity_id: activity_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status == 0) {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      } else {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  };

  const sendAiFollowUp = async (values) => {
    try {
      if (deal.contact1 === "") {
        return Swal.fire({
          icon: "warning",
          text: "Thio prospect does not have contact number",
        });
      }

      if (deal.contact1_prefix === "") {
        return Swal.fire({
          icon: "warning",
          text: "Thio prospect does not have a country code attach to the contact number",
        });
      }

      const whatsapp = `https://wa.me/${deal.contact1_prefix}${deal.contact1}?text=${values.remark}`;
      window.open(whatsapp, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditSource = async (values) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 35,
          deal: deal.deal_id,
          source: values.ds_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setDeal((prev) => ({ ...prev, ds_title: values.ds_title, ds_id: values.ds_id }));
        Swal.fire({
          icon: "success",
          text: "Successfully updated the source",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsEditSource(false);
    }
  };

  // SET CHECK OWNER -------------------------------------------------------------

  const setCheckOwner = () => {
    if (deal.deal_archieve === "" && (isOwner || Number(session.role_id) === 1)) {
      return true;
    } else {
      return false;
    }
  };

  const setCheckValidUser = () => {
    var valid = true;
    if (deal.deal_archieve === "" && (Number(deal.owner_id) === Number(session.user_id) || Number(session.role_id) === 1)) {
      valid = true;
    } else if (deal.deal_archieve === "" && isContributor) {
      valid = true;
    } else {
      valid = false;
    }

    if (Number(session.role_id) === 1) {
      valid = true;
    }
    return valid;
  };

  const setCheckValidAdminUser = () => {
    if (Number(session.role_id) === 1) {
      return true;
    } else {
      return false;
    }
  };

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note") {
      content = record.CREATE_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      content = record.ASSIGN_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && deal.organization_title !== "0") {
      content = deal.organization_title;
      icon = <FontAwesomeIcon icon={faBuilding} size="lg" className="me-2" />;
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      content = record.record_comment;
      icon = <FontAwesomeIcon icon={faEnvelopeOpen} size="lg" className="me-2" />;
    }

    return (
      <Stack direction="horizontal" gap={3}>
        {Number(record.record_activity_type_id) === 2 ? (
          <div className="d-flex align-items-center">
            {meetupLink.status === 0 && <MeetupLog dealID={deal_id} activityID={record.record_activity_id} />}
            <div className="ms-2">{setDateTimeStr(record.record_date_time)}</div>
          </div>
        ) : (
          <div>{setDateTimeStr(record.record_date_time)}</div>
        )}

        <div className="d-flex">
          {icon}
          {content}
        </div>
      </Stack>
    );
  };

  const setCheckOkToEdit = (uid, uid2) => {
    var valid = true;
    if (isContributor) {
      if (uid !== session.user_id) {
        valid = false;
      }

      if (uid2 !== null && uid2 !== session.user_id) {
        valid = false;
      }
    }
    return valid;
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else {
      return "";
    }
  };

  const setDealTitle = (input) => {
    const maxLength = 50;
    if (input.length > maxLength) {
      const truncatedInput = input.substring(0, maxLength) + "...";
      return (
        <OverlayTrigger placement="bottom" trigger={["hover", "hover"]} overlay={<Tooltip>{input}</Tooltip>}>
          <span>{truncatedInput}</span>
        </OverlayTrigger>
      );
    } else {
      return input;
    }
  };

  const setTimelineAction = (record) => {
    const hasReadWritePermission = Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1;
    if (hasReadWritePermission) {
      if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp") {
        if ((Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1) || record.record_activity_mad === "yes") {
          return false;
        } else {
          return true;
        }
      } else if (record.record_type === "Note") {
        return true;
      } else if (record.record_type === "File") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setWidthTimeline = (record) => {
    if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === true) {
      return "90%";
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === false) {
      return "95%";
    } else if ((record.record_type !== "Activity" || record.record_type !== "CustomActivity") && setTimelineAction(record) === true) {
      return "95%";
    } else {
      return "100%";
    }
  };

  const setGenerateDurationList = (increment) => {
    const durationList = [];
    const maxDuration = 4 * 60; // 4 hours in minutes

    const addDuration = (minutes) => {
      if (minutes < 60) {
        durationList.push({ label: `${minutes} Mins`, value: `${minutes}` });
      } else {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        if (mins === 0) {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""}`, value: `${minutes}` });
        } else {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""} ${mins} Mins`, value: `${minutes}` });
        }
      }
    };

    for (let i = increment; i <= maxDuration; i += increment) {
      addDuration(i);
    }

    return durationList;
  };

  const setGenerateTimings = (startHour, endHour, interval) => {
    const timings = [];
    let currentTime = new Date();
    currentTime.setHours(startHour, 0, 0, 0);
    while (currentTime.getHours() < endHour || (currentTime.getHours() === endHour && currentTime.getMinutes() === 0)) {
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timings.push({ label: timeString, value: timeString });
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }
    return timings;
  };

  const setTimelineMAD = (record) => {
    if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      if (Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const setCheckImg = () => {
    setImgError(true);
  };

  const setInitial = (input) => {
    var str = "";
    if (/\s/.test(input)) {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    } else {
      str = input.length > 0 ? input.split(" ")[0].charAt(0).toUpperCase() : input;
    }
    return str;
  };

  const setFormatMessage = (input) => {
    var regLink = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var regLink2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var regLink3 = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/gim;
    var regEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    var regBreakLine = /(?:\r\n|\r|\n)/g;
    var regImage = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*\.(?:jpg|jpeg|png|gif)\b)/gim;
    var regBold = /\*\*(.*?)\*\*/g; // Matches text wrapped in double asterisks for bold
    var regItalic = /_(.*?)_/g; // Matches text wrapped in underscores for italic

    var tag = input.toString().replace(/\\'/g, "'");

    // Replace line breaks with <br> tags
    tag = tag.replace(regBreakLine, "<br>");

    // Replace image URLs with placeholders
    let imgPlaceholders = [];
    tag = tag.replace(regImage, (match) => {
      let placeholder = `{{img_placeholder_${imgPlaceholders.length}}}`;
      imgPlaceholders.push({ placeholder, url: match });
      return placeholder;
    });

    // Replace email addresses with mailto: links
    tag = tag.replace(regEmail, '<a href="mailto:$1" target="_blank">$1</a>');

    // Replace full URLs with <a> tags
    tag = tag.replace(regLink, '<a href="$1" target="_blank">$1</a>');

    // Replace www links with <a> tags
    tag = tag.replace(regLink2, '$1<a href="https://$2" target="_blank">$2</a>');

    // Replace domain-like strings with <a> tags
    tag = tag.replace(regLink3, '<a href="https://www.$1" target="_blank">$1</a>');

    // Replace bold text with <strong> tags
    tag = tag.replace(regBold, "<strong>$1</strong>");

    // Replace italic text with <em> tags
    tag = tag.replace(regItalic, "<em>$1</em>");

    // Replace placeholders with <a> and <img> tags
    imgPlaceholders.forEach(({ placeholder, url }) => {
      tag = tag.replace(placeholder, `<a href="${url}" target="_blank"><img src="${url}" alt="Image" style="width:150px; height:auto;" /></a>`);
    });

    return tag;
  };

  const setPhoneNumber = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined) {
      return "-";
    } else if (prefix === "" || prefix === null || prefix === undefined) {
      return contact;
    } else {
      const contactNumber = `+${prefix}${contact}`;
      const phoneNumber = parsePhoneNumber(contactNumber);
      return phoneNumber.formatInternational();
    }
  };

  // MODAL TRIGGER ---------------------------------------------------------------

  const modalEditActivity = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: record.record_activity_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var activityTime = data.record.activity_timed.split(":");
          var activityTimeValue = activityTime[0] + ":" + activityTime[1];
          const editValue = {
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: activityTimeValue,
            activity_time_val: activityTimeValue,
            activity_duration: data.record.activity_duration,
            activity_notes: data.record.activity_content,
            activity_notes2: data.record.activity_content,
            activity_linked: "deal",
            activity_user: data.record.assign_user_id,
            deal_id: deal_id,
            prospect_id: deal.prospect_id,
            mad: data.record.activity_mark_as_done === "yes" ? true : false,
            ca_id: data.record.ca_id,
            record_activity_id: record.record_activity_id,
            record_activity_type_id: record.record_activity_type_id,
          };

          setInitEditActivity(editValue);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_activity_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  const modalEditMeeting = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: record.record_activity_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var activityTime = data.record.activity_timed.split(":");
          var activityTimeValue = activityTime[0] + ":" + activityTime[1];
          const editValue = {
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: activityTimeValue,
            activity_time_val: activityTimeValue,
            activity_duration: data.record.activity_duration,
            activity_notes: data.record.activity_content,
            activity_notes2: data.record.activity_content,
            activity_linked: "deal",
            activity_user: data.record.assign_user_id,
            deal_id: deal_id,
            prospect_id: deal.prospect_id,
            mad: data.record.activity_mark_as_done === "yes" ? true : false,
            ca_id: data.record.ca_id,
            record_activity_id: record.record_activity_id,
            record_activity_type_id: record.record_activity_type_id,
          };

          setInitEditMeeting(editValue);
          setMeetingData(record);
          setIsEditMeeting(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_activity_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  const modalEditNotes = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 19,
          type: "deal",
          note: record.record_activity_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const editValue = {
            notes: data.record.note_content,
            notes2: data.record.note_content,
            notes_id: data.record.note_id,
            user_id: data.record.user_id,
            record_activity_id: record.record_activity_id,
          };

          setInitEditNotes(editValue);
          setisEditNote(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t("DealDetails_failed"),
            text: t("DealDetails_sorry_cannot_edit_the_notes_at_the_moment_please_contact_our_developer_thank_you"),
          });
        }
      });
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderDealAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      };

      return (
        <Button ref={ref} variant="light" className="shadow-sm border" onClick={handleClick} aria-label="Toggle dropdown">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={setIsEditDealInfo}>{t("DealDetails_edit_deal")}</Dropdown.Item>
          <Dropdown.Item onClick={setIsDeleteDeal}>{t("DealDetails_delete_deal")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderTimelineAction = ({ record }) => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown} className="float-end border">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && record.meeting_scheduler === false && <Dropdown.Item onClick={() => modalEditActivity(record)}>{t("DealDetails_edit_activity")}</Dropdown.Item>}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && record.meeting_scheduler === true && <Dropdown.Item onClick={() => modalEditMeeting(record)}>Edit Meeting</Dropdown.Item>}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && record.meeting_scheduler === false && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item onClick={() => sendDeleteActivity(record)}>{t("DealDetails_delete_activity")}</Dropdown.Item>
          )}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && record.meeting_scheduler === true && Number(session.readwrite_permission) === 0 && (
            <Dropdown.Item onClick={() => sendDeleteActivity(record)}>Delete Meeting</Dropdown.Item>
          )}
          {record.record_type === "Note" && <Dropdown.Item onClick={() => modalEditNotes(record)}>Edit Note</Dropdown.Item>}
          {record.record_type === "Note" && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteNotes(record)}>{t("DealDetails_delete_note")}</Dropdown.Item>}
          {record.record_type === "File" && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteFiles(record)}>{t("DealDetails_delete_file")}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  // QUILL EDITOR -----------------------------------------------------------------

  const RenderActionTab = useCallback(
    ({ tab, active, userListNotes, activityUser, isSubmitting, disabled, followUpLoading, followUpData }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={notesSchema}
                onSubmit={sendNewNotes}
                initialValues={{
                  notes: "",
                  user: "",
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <NoteEditor value={values.notes} onChangeValue={(value) => handleChange("notes")(value)} />
                        {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FormSelect options={userListNotes} valueDefault={userListNotes[0]} placeholder={t("DealDetails_select_user")} onChange={(e) => handleChange("user")(e.value)} />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between align-items-center">
                      {Number(session.ai_note) === 1 && (
                        <AudioNotesV2
                          session={session}
                          deal_id={deal_id}
                          prospect_id={deal.prospect_id}
                          onSubmit={(response) => {
                            if (response.message) {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.message : response.message);
                            } else if (response.transcript) {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.transcript : response.transcript);
                            } else {
                              setFieldValue("notes", values.notes ? values.notes + "</br></br>" + response.error : response.error);
                            }
                          }}
                        />
                      )}
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={disabled}>
                        {disabled ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  setIsActiveTab(true);
                  setDisabledBtn(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {t("DealDetails_click_here_to_take_a_notes")}
              </Card.Body>
            );
          }
        case "activity":
          if (active) {
            return (
              <Formik
                validationSchema={addActivitySchema}
                onSubmit={sendAddActivity}
                initialValues={{
                  activity_title: "",
                  activity_type: "1",
                  activity_date: latestDate,
                  activity_time: setLatestTime(timings).value,
                  activity_time_show: true,
                  activity_time_val: setLatestTime(timings),
                  activity_duration: "",
                  activity_duration_show: true,
                  activity_notes: "",
                  activity_linked: "deal",
                  activity_user: session.user_id,
                  deal_id: "",
                  prospect_id: "",
                  mad: false,
                  google_calendar: false,
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body id="notes_collapse">
                      <div className="d-flex mb-3">
                        <div style={{ width: "5%" }}></div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <Form.Control type="text" placeholder={t("DealDetails_activity_title")} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                            {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div style={{ width: "5%" }}></div>
                        <Form.Group>
                          <ButtonGroup className="activity-tab">
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                              <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                              {t("DealDetails_call")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                              <FontAwesomeIcon icon={faUsers} className="me-1" />
                              {t("DealDetails_meeting")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                              <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                              {t("DealDetails_task")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                              <FontAwesomeIcon icon={faFlag} className="me-1" />
                              {t("DealDetails_deadline")}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                              <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                              {t("DealDetails_email")}
                            </ToggleButton>
                          </ButtonGroup>
                        </Form.Group>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect options={timings} valueDefault={setLatestTime(timings)} placeholder={t("DealDetails_select_time")} onChange={(e) => handleChange("activity_time")(e.value)} />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect options={durationList} placeholder={t("DealDetails_select_duration")} onChange={(e) => handleChange("activity_duration")(e.value)} />
                              {errors.activity_duration && touched.activity_duration && <div className="op-error-message">{errors.activity_duration}</div>}
                            </Form.Group>
                          </Row>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faCircleUser} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <FormSelect placeholder={t("DealDetails_select_user")} options={activityUser} valueDefault={activityUser.find((record) => record.value === session.user_id)} onChange={(e) => handleChange("activity_user")(e.value)} />
                            {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group className="activity-notes">
                            <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSubmitting}>
                        {isSubmitting ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                      <Form.Check
                        type="switch"
                        label={t("DealDetails_mark_as_done")}
                        className="d-flex justify-content-center align-items-center me-3"
                        onChange={(e) => {
                          handleChange({
                            target: { name: "mad", value: e.target.checked },
                          });
                        }}
                      />
                      <Form.Check
                        type="switch"
                        label={t("DealDetails_add_to_google_calendar")}
                        className="d-flex justify-content-center align-items-center me-3"
                        onChange={(e) => {
                          handleChange({
                            target: { name: "google_calendar", value: e.target.checked },
                          });
                        }}
                      />
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("DealDetails_click_here_to_add_an_activity")}
              </Card.Body>
            );
          }
        case "files":
          if (active) {
            return (
              <Formik
                validationSchema={fileUploadSchema}
                onSubmit={sendAddFile}
                initialValues={{
                  file: [],
                  title: "",
                }}
              >
                {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder={t("DealDetails_file_title")} onChange={handleChange("title")} />
                        {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FilePond
                          allowMultiple={false}
                          maxFiles={1}
                          branding={false}
                          onupdatefiles={(fileItems) => {
                            setFieldValue(
                              "file",
                              fileItems.map((fileItem) => fileItem.file)
                            );
                          }}
                        />
                        {errors.file && touched.file && <div className="op-error-message">{errors.file}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={disabled}>
                        {disabled ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("DealDetails_click_here_to_upload_files")}
              </Card.Body>
            );
          }
        case "messages":
          if (active) {
            return (
              <Formik
                validationSchema={addMessageschema}
                initialValues={{
                  message_type: "email",
                  email_template: "",
                  whatsapp_template: "",
                  email_subject: "",
                  email_content: "",
                  email_content_val: "",
                  smartlink: "",
                }}
                onSubmit={sendAddMessages}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body id="notes_collapse">
                      <Form.Group className="mb-3">
                        <ToggleButtonGroup
                          className="activity-tab"
                          defaultValue="email"
                          type="radio"
                          name="message_type"
                          onChange={(value) => {
                            setFieldValue("message_type", value);
                            setFieldValue("email_template", "");
                            setFieldValue("whatsapp_template", "");
                            setFieldValue("email_subject", "");
                            setFieldValue("email_content", "");
                            setFieldValue("email_content_val", "");
                          }}
                        >
                          <ToggleButton variant="" id="message_type_1" value="email">
                            <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-2" />
                            {t("DealDetails_email")}
                          </ToggleButton>
                          <ToggleButton variant="" id="message_type_2" value="whatsapp">
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-1" />
                            {t("DealDetails_whatsapp")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Form.Group>

                      {values.message_type === "email" && (
                        <Form.Group className="mb-3">
                          <FormSelect
                            options={emailTemplate}
                            placeholder={t("DealDetails_choose_template")}
                            onChange={(e) => {
                              setFieldValue("email_template", e.value);
                              axios
                                .get("ws/ws_email.php", {
                                  params: {
                                    task: "getTemplateData",
                                    utoken: session.user_ac_token,
                                    ctoken: session.company_token,
                                    template_id: e.value,
                                  },
                                })
                                .then((response) => {
                                  const data = response.data;
                                  if (data.status === 0) {
                                    setFieldValue("email_subject", data.record.email_subject);
                                    setFieldValue("email_content", data.record.email_message);
                                    setFieldValue("email_content_val", data.record.email_message);
                                  }
                                });
                            }}
                          />
                          {values.message_type === "email" && errors.email_template && touched.email_template && <div className="op-error-message">{errors.email_template}</div>}
                        </Form.Group>
                      )}

                      {values.message_type === "whatsapp" && (
                        <Form.Group className="mb-3">
                          <FormSelect
                            options={whatsappTemplate}
                            placeholder={t("DealDetails_choose_template")}
                            onChange={(e) => {
                              var userContact = "";
                              for (var i = 0; i < user.length; i++) {
                                if (user[i].user_id === deal.owner_id) {
                                  userContact = user[i].user_contact;
                                  break;
                                }
                              }
                              var link_pName = deal.prospect_name.replaceAll(" ", "_");
                              var link_dTitle = deal.deal_title.replaceAll(" ", "_");
                              var link_userName = deal.user_name.replaceAll(" ", "_");

                              if (deal.meetup_available === 1) {
                                var meetupStr = `https://app.outperformhq.io/meetup/${deal.meetup_info.hash.substring(0, 8)}/${deal.meetup_info.slug}/${deal.meetup_info.base10}`;
                                e.template = e.template.replace("<meetup_link>", meetupStr);
                              }

                              e.template = e.template.replace("<prospect_name>", deal?.prospect_name || "");
                              e.template = e.template.replace("<deal_title>", deal?.deal_title || "");
                              e.template = e.template.replace("<prospect_address>", deal?.prospect_address || "");
                              e.template = e.template.replace("<ds_title>", deal?.ds_title || "");
                              e.template = e.template.replace("<deal_hash>", deal?.hash || "");
                              e.template = e.template.replace("<organization_title>", deal?.organization_title || "");
                              e.template = e.template.replace("<organization_address>", deal?.organization_address || "");
                              e.template = e.template.replace("<user_name>", deal?.user_name || "");
                              e.template = e.template.replace("<user_contact>", userContact);
                              e.template = e.template.replace("<designation_title>", deal?.designation_title || "");
                              e.template = e.template.replace("<source_category_title>", deal?.source_category_title || "");
                              e.template = e.template.replace("<prospect_contact>", deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : "");

                              e.template = e.template.replace("<prospect_name_link>", link_pName);
                              e.template = e.template.replace("<deal_title_link>", link_dTitle);
                              e.template = e.template.replace("<user_name_link>", link_userName);
                              e.template = e.template.replace("%0a", "");

                              setFieldValue("whatsapp_template", e.value);
                              setFieldValue("email_content", e.template);
                              setFieldValue("email_content_val", e.template);
                            }}
                          />
                          {values.message_type === "whatsapp" && errors.whatsapp_template && touched.whatsapp_template && <div className="op-error-message">{errors.whatsapp_template}</div>}
                        </Form.Group>
                      )}

                      {values.message_type === "email" && (
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder={t("DealDetails_email_subject")} value={values.email_subject} onChange={handleChange("email_subject")} />
                          {errors.email_subject && touched.email_subject && <div className="op-error-message">{errors.email_subject}</div>}
                        </Form.Group>
                      )}

                      <Form.Group className="activity-notes">
                        <NoteEditor value={values.email_content} onChangeValue={(value) => handleChange("email_content")(value)} />
                        {errors.email_content && touched.email_content && <div className="op-error-message">{errors.email_content}</div>}
                      </Form.Group>

                      {values.message_type === "whatsapp" && (
                        <Form.Group className="my-3">
                          <FormSelect options={smartLinkList} placeholder={"Choose Smart Link"} onChange={(e) => setFieldValue("smartlink", e.value)} />
                        </Form.Group>
                      )}
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={disabled}>
                        {disabled ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t("DealDetails_click_here_to_send_messages")}
              </Card.Body>
            );
          }
        case "ai_follow_up":
          if (active) {
            if (followUpLoading) {
              return (
                <>
                  <Card.Body id="notes_collapse">
                    <Form.Label className="fw-bold">Insight</Form.Label>
                    <Placeholder as="p" className="mb-0" animation="glow">
                      <Placeholder xs={8} size="sm" bg="secondary" />
                    </Placeholder>
                    <Placeholder as="p" className="mb-0" animation="glow">
                      <Placeholder xs={6} size="sm" bg="secondary" />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={4} size="sm" bg="secondary" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={12} bg="secondary" style={{ height: 200 }} />
                    </Placeholder>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-row-reverse align-items-center">
                    <Spinner animation="border" size="sm" />
                  </Card.Footer>
                </>
              );
            } else {
              return (
                <Formik validationSchema={aiFollowUpSchema} initialValues={{ remark: followUpData?.whatsappMessage }} onSubmit={sendAiFollowUp}>
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">Insight</Form.Label> <br />
                          <Form.Label className="text-muted">{followUpData?.agentNotes}</Form.Label>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control as="textarea" value={values.remark} onChange={handleChange("remark")} style={{ height: 200, resize: "none" }} />
                          {errors.remark && touched.remark && <div className="op-error-message">{errors.remark}</div>}
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="icon-link icon-link-hover text-decoration-none" onClick={getAiFollowUp}>
                          Regenerate
                        </Button>

                        <Button variant="success" type="submit">
                          <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-2" />
                          Send to Whatsapp
                        </Button>
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              );
            }
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  getAiFollowUp();
                  setIsActiveTab(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Click here to send AI Follow Up...
              </Card.Body>
            );
          }
        case "meeting_scheduler":
          if (active) {
            return (
              <Formik
                validationSchema={addActivitySchema}
                onSubmit={sendAddMeetingScheduler}
                initialValues={{
                  activity_title: "",
                  activity_type: "2",
                  activity_date: latestDate,
                  activity_time: setLatestTime(timings).value,
                  activity_time_show: true,
                  activity_time_val: setLatestTime(meetingTimingsList).value,
                  activity_duration: "60",
                  activity_duration_show: true,
                  activity_notes: "",
                  activity_linked: "deal",
                  activity_user: session.user_id,
                  deal_id: "",
                  prospect_id: "",
                  mad: false,
                  google_calendar: false,
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body id="notes_collapse">
                      <div className="d-flex mb-3">
                        <div style={{ width: "5%" }}></div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <Form.Control type="text" placeholder={"Meeting title"} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                            {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect options={meetingTimingsList} valueDefault={setLatestTime(meetingTimingsList)} placeholder={t("DealDetails_select_time")} onChange={(e) => handleChange("activity_time")(e.value)} />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect options={[{ label: "1 Hour", value: "60" }]} valueDefault={{ label: "1 Hour", value: "60" }} disabled placeholder={t("DealDetails_select_duration")} onChange={(e) => handleChange("activity_duration")(e.value)} />
                              {errors.activity_duration && touched.activity_duration && <div className="op-error-message">{errors.activity_duration}</div>}
                            </Form.Group>
                            {/* <Form.Group as={Col}>
                              <FormSelect options={meetingDurationList} placeholder={t("DealDetails_select_duration")} onChange={(e) => handleChange("activity_duration")(e.value)} />
                              {errors.activity_duration && touched.activity_duration && <div className="op-error-message">{errors.activity_duration}</div>}
                            </Form.Group> */}
                          </Row>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faCircleUser} size="lg" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group>
                            <FormSelect placeholder={t("DealDetails_select_user")} options={activityUser} valueDefault={activityUser.find((record) => record.value === session.user_id)} onChange={(e) => handleChange("activity_user")(e.value)} />
                            {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex mb-3">
                        <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                          <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                        </div>
                        <div style={{ width: "95%" }}>
                          <Form.Group className="activity-notes">
                            <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                      <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSubmitting}>
                        {isSubmitting ? <Spinner animation="border" size="sm" /> : t("DealDetails_save")}
                      </button>
                      <Form.Check
                        type="switch"
                        label={t("DealDetails_mark_as_done")}
                        className="d-flex justify-content-center align-items-center me-3"
                        onChange={(e) => {
                          handleChange({
                            target: { name: "mad", value: e.target.checked },
                          });
                        }}
                      />
                      <Form.Check
                        type="switch"
                        label={t("DealDetails_add_to_google_calendar")}
                        className="d-flex justify-content-center align-items-center me-3"
                        onChange={(e) => {
                          handleChange({
                            target: { name: "google_calendar", value: e.target.checked },
                          });
                        }}
                      />
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body
                id="notes"
                onClick={() => {
                  getAiFollowUp();
                  setIsActiveTab(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Click here to set meeting with scheduler
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
        <Card>
          <Formik
            validationSchema={transferDealSchema}
            onSubmit={sendTransferDeal}
            initialValues={{
              user: "",
              role_id: "",
              transferOption: 2,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {t("DealDetails_transfer_ownership")}
                  <Button variant="" as={CloseButton} onClick={() => setIsTransferOwner(false)} />
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <FormSelect
                      name="user"
                      value={values.user}
                      onChange={(info) => {
                        handleChange("user")(info.value);
                        handleChange("role_id")(info.role_id);
                      }}
                      isInvalid={errors.user && touched.user}
                      options={user}
                      placeholder={t("DealDetails_select_user")}
                    />
                    {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Check type="radio" name="transferOption" value={2} onChange={handleChange("transferOption")} label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t("DealDetails_deal")} & ${t("DealDetails_prospect")}`} checked />
                    {session.layout_mode === "real_estate" && <Form.Check type="radio" name="transferOption" value={1} onChange={handleChange("transferOption")} label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t("DealDetails_deal")} ${t("DealDetails_only")}`} />}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t("DealDetails_save")}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faListCheck} />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else {
      return "";
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `${t("DealDetails_deal_transfer_to")} ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return <RenderFile url={`${session.hostUrl}/${session.hostUrlType}/${record.record_file_url}`} title={`${record.record_title}.${record.record_file_ext}`} />;
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== ",") {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" className="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note":
        element = record.record_title;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto", width: 700 }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
      case "Email":
        element = record.record_title;
        break;
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const refreshCallInsight = useCallback(
    async (index, record) => {
      console.log(index, record);
      const { record_file_url, record_activity_id } = record;

      const findLoading = callAnalytics.find((record) => record.loading === true);
      if (findLoading) {
        return Swal.fire({
          icon: "warning",
          text: "Sorry, there is a audio in the processing, please wait and try again later",
        });
      }

      const callAnalyticsUpdate = [...callAnalytics];
      console.log(callAnalyticsUpdate[index], callAnalyticsUpdate);
      callAnalyticsUpdate[index].loading = true;
      setCallAnalytics(callAnalyticsUpdate);

      try {
        await axios.get(`${session.hostUrl}/${session.hostUrlType}/ws/ws_sentiment_analysis.php`, {
          params: {
            task: "call_insight_v3",
            url: record_file_url,
            call_data_id: record_activity_id,
            contact_number: "",
            prospect_id: deal.prospect_id,
            company_id: session.company_id,
            user_id: deal.owner_id,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        const callAnalyticsUpdate = [...callAnalytics];
        callAnalyticsUpdate[index].loading = false;
        setCallAnalytics(callAnalyticsUpdate);
      }
    },
    [deal, session, callAnalytics]
  );

  const RenderTimelineDescription = useCallback(
    ({ record, insight }) => {
      const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;
      if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
        if (record_activity_type === "Call") {
          var isLabel = insight.find((record) => record.record_activity_id === record_activity_id);
          var isLabelIndex = insight.findIndex((record) => record.record_activity_id === record_activity_id);

          if (record_file_url) {
            return (
              <div style={{ padding: "10px 0" }}>
                <RenderAudio url={record_file_url} />
                {isLabel && (
                  <div className="mt-2">
                    {isLabel.analytics_field_1 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_1}: {isLabel.analytics_param_1}
                      </div>
                    )}

                    {isLabel.analytics_field_2 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_2}: {isLabel.analytics_param_2}
                      </div>
                    )}

                    {isLabel.analytics_field_3 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_3}: {isLabel.analytics_param_3}
                      </div>
                    )}

                    {isLabel.analytics_field_4 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_4}: {isLabel.analytics_param_4}
                      </div>
                    )}

                    {isLabel.analytics_field_5 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_5}: {isLabel.analytics_param_5}
                      </div>
                    )}

                    {isLabel.analytics_field_6 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_6}: {isLabel.analytics_param_6}
                      </div>
                    )}

                    {isLabel.analytics_field_7 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_7}: {isLabel.analytics_param_7}
                      </div>
                    )}

                    {isLabel.analytics_field_8 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_8}: {isLabel.analytics_param_8}
                      </div>
                    )}

                    {isLabel.analytics_field_9 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_9}: {isLabel.analytics_param_9}
                      </div>
                    )}

                    {isLabel.analytics_field_10 && (
                      <div className="op label mb-2">
                        {isLabel.analytics_field_10}: {isLabel.analytics_param_10}
                      </div>
                    )}

                    {isLabel.ai_summary && (
                      <div className="op label mb-2 w-100" style={{ lineHeight: 1.5 }}>
                        {isLabel.ai_summary}
                      </div>
                    )}

                    <div className="shadow-sm rounded-circle bg-light d-flex justify-content-center border cursor-pointer" style={{ position: "absolute", bottom: 10, right: 10, padding: 5 }}>
                      {isLabel.loading ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faRotateRight} size="lg" onClick={() => refreshCallInsight(isLabelIndex, record)} />}
                    </div>
                  </div>
                )}
              </div>
            );
          }
        } else {
          return (
            <Stack direction="vertical">
              {Number(record.record_activity_type_id) === 2 && <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record.meetup_record_description) }} />}
              <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />
            </Stack>
          );
        }
      } else if (record_type === "Archieve") {
        return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
      } else if (record_type === "Email") {
        return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
      } else {
        return "";
      }
    },
    [callAnalytics]
  );

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const RenderUTM = ({ record }) => {
    let utm = "";
    if (record.utm_campaign) {
      utm = record.utm_campaign;
    }

    if (record.utm_content) {
      utm = utm ? `${utm} - ${record.utm_content}` : record.utm_content;
    }

    if (utm !== "") {
      return (
        <div className="d-flex p-2 w-100">
          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
            <FontAwesomeIcon icon={faGlobePointer} size="xl" />
          </div>
          <div className="w-100">
            <div className="text-muted">UTM</div>
            <div className="fw-bold">{utm}</div>
          </div>
        </div>
      );
    }
  };

  // GRID SETUP -------------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const leadScoreColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Score Category",
      field: "scoreCat",
      flex: 1,
    },
    {
      headerName: "Score",
      field: "score",
      minWidth: 80,
      cellClass: "center",
      pinned: "right",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.data.score;
        }
      },
    },
  ];

  const openDealColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const wonDealColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const lostDealColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const archievedDealColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealDetails_title"),
      field: "deal_title",
    },
    {
      headerName: t("DealDetails_value"),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t("DealDetails_source"),
      field: "ds_title",
    },
    {
      headerName: t("DealDetails_remark"),
      field: "archieve_remark",
    },
    {
      headerName: t("DealDetails_expected_close_date"),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const rowId = useMemo(() => {
    return (params) => params.index;
  }, []);

  // OPEN FUNCTION ----------------------------------------------------------------

  const openLeadScore = async () => {
    try {
      const response = await axios.get("ws/ws_lead_score.php", {
        params: {
          task: "getLeadScore",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const { scoreMatrixDetails } = response.data;
      const leadScoreList = [];

      if (scoreMatrixDetails && scoreMatrixDetails.length > 0) {
        for (let i = 0; i < scoreMatrixDetails.length; i++) {
          if (Number(scoreMatrixDetails[i].score) > 0 && Number(scoreMatrixDetails[i].score) <= Number(scoreMatrixDetails[i].maxScore)) {
            leadScoreList.push(scoreMatrixDetails[i]);
          }
        }
      }

      setLeadScoreList(leadScoreList);
      setIsLeadScore(true);
      
    } catch (error) {
      console.error(error);
    }
  };

  const openForm = (record) => {
    if (record.form_url) {
      window.open(`${record.form_url}?utoken=${session.user_ac_token}&ctoken=${session.company_token}&deal=${deal_id}`, "_blank");
    } else {
      Swal.fire({
        icon: "error",
        title: t("DealDetails_failed"),
        text: t("DealDetails_form_url_isnt_defined"),
      });
    }
  };

  // USEEFFECT FUNCTION -----------------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        try {
          await getDeals();
          await getUser();
          await getCrmStatus();
          await getLostReason();
          await getBooking();
          await getTimeline();
          await getCountry();
          await getState();
          await getCity();
          await getDealSource();
          await getForm();
          await getEmailTemplate();
          await getWhatsappTemplate();
          await getAllProduct();
          await getIndustry();
          await getOrganizationCountry();
          await getMeetup();
          await getCancelMeetupReason();
          await getSmartLink();
          if (session.layout_mode === "real-estate" || Number(session.product_feature) === 1) {
            await getProduct();
          }
          setIsLoading(false);
          initialized.current = true;
        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const modalWidth = 400;
    const modalHeight = 300;
    const x = (windowWidth - modalWidth) / 2;
    const y = (windowHeight - modalHeight) / 2;

    setCallPosition({ x, y });
    initializeData();
    getTwilioCred();
  }, [deal_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth - 50);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, [containerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (session) {
      setMeetingDurationList(setGenerateDurationList(Number(session.activity_duration_setting)));
      setMeetingTimingsList(setGenerateTimings(8, 22, Number(session.activity_duration_setting)));
    }
  }, [session]);

  useEffect(() => {
    if (initialized.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=deal_" + deal_id,
      });

      socket.on("chat message", function (msg) {
        setIsTimeline(false);
        getDeals();
        getTimeline(true);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [initialized.current]);

  useEffect(() => {
    if (initialized.current) {
      getAiTimelineSummary();
    }
  }, [initialized.current]);

  useEffect(() => {
    if (initialized.current && Number(deal.prospect_id) > 0) {
      const prospect_socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=prospect_" + deal.prospect_id,
      });

      prospect_socket.on("chat message", function (msg) {
        setIsTimeline(false);
        getDeals();
        getTimeline(true);
      });

      return () => {
        prospect_socket.disconnect();
      };
    }
  }, [initialized.current, deal]);

  useEffect(() => {
    if (callSID !== "") {
      const twilioSocket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=callsid_" + callSID,
      });

      twilioSocket.on("chat message", function (msg) {
        setCallStatus("connected");
        setCallStart("start");

        if (msg === "disconnect") {
          sendHangUpCall();
        }
      });

      return () => {
        twilioSocket.disconnect();
      };
    }
  }, [callSID]);

  useEffect(() => {
    if (callStart === "start") {
      timerRef.current = setInterval(() => {
        timeRef.current += 1;
        const hours = String(Math.floor(timeRef.current / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((timeRef.current % 3600) / 60)).padStart(2, "0");
        const seconds = String(timeRef.current % 60).padStart(2, "0");
        if (hours !== "00") {
          setCallTimer(`${hours}:${minutes}:${seconds}`);
        } else {
          setCallTimer(`${minutes}:${seconds}`);
        }
      }, 1000);
    } else if (callStart === "stop") {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [callStart]);

  useEffect(() => {
    if (leadScoreUpdate) {
      getLeadScore(deal.prospect_id);
      setLeadScoreUpdate(false);
    }
  }, [leadScoreUpdate]);

  return (
    <Container fluid style={{ padding: 0 }}>
      {isLoading || loading2 ? (
        <Loader />
      ) : (
        <div className="main">
          <div className="section-header">
            <Row>
              <Col xxl={12} className="py-3 px-5">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="op-text-bigger fs-5 me-2">{setDealTitle(deal.deal_title)}</div>
                      <div
                        className={`op-label me-2 text-light d-flex align-items-center py-2 ${deal.deal_status === "new" ? "bg-danger" : deal.deal_status === "inactive" ? "bg-warning" : deal.deal_status === "active" ? "bg-success" : ""}`}
                        style={{ padding: "5px 10px", borderRadius: 5, position: "relative", fontSize: 10 }}
                      >
                        {deal.deal_status === "new" ? t("DealList_contact_lead") : deal.deal_status === "inactive" ? t("DealList_follow_up") : deal.deal_status === "active" ? t("DealList_progressing") : ""}
                      </div>
                      {session.subscription_tier && Number(session.subscription_tier) > 2 && (
                        <div className="op-label circular op-primary-color text-light cursor-pointer" onClick={openLeadScore}>
                          {leadScore}
                        </div>
                      )}
                      <div className="d-flex align-items-center ms-2">
                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1;
                          return (
                            <label key={index}>
                              <input className="d-none" type="radio" name="rating" value={currentRating} onClick={() => onchangePriority(currentRating)} />
                              <FontAwesomeIcon icon={faStar} style={{ color: currentRating <= (hoverRating || rating) ? "#ffd43b" : "#ddd", cursor: "pointer" }} onMouseEnter={() => setHoverRating(currentRating)} onMouseLeave={() => setHoverRating(null)} />
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="ms-auto" />

                  {(Number(session.avanser) === 1 || Number(session.twilio) === 1) && (
                    <Button
                      variant="light"
                      className="shadow-sm border rounded-circle me-3 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        if (Number(session.twilio) === 1) {
                          sendTwilioCallLead();
                        } else {
                          sendCallLead();
                        }
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-center" style={{ padding: "5px 0px" }}>
                        <FontAwesomeIcon icon={faPhone} size="lg" />
                      </div>
                    </Button>
                  )}

                  <div className="d-flex align-items-center justify-content-center">
                    {imgError ? (
                      <div className="avatar me-2">
                        <div className="avatar-img op-primary-color" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                          <div className="avatar-txt text-uppercase fs-5 text-light">{deal.user_name.split("")[0]}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="me-2">
                        <img className="rounded-circle" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${deal.owner_id}.png`} width={35} height={35} onError={setCheckImg} />
                      </div>
                    )}
                    <div className="h-100" style={{ lineHeight: 1.2 }}>
                      <span className="op-text-bigger fw-bold">{deal.user_name}</span> <br />
                      <span className="op-text-medium">{!isOwner ? t("DealDetails_contributor") : t("DealDetails_owner")}</span>
                    </div>
                    {(!isContributor || Number(session.role_id) === 1) && (
                      <>
                        <Dropdown show={isTransferOwner} align="end" className="ms-2">
                          <Dropdown.Toggle className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                          <Dropdown.Menu as={RenderTransferOwnership} />
                        </Dropdown>
                      </>
                    )}
                  </div>

                  {deal.deal_archieve === "" && (
                    <div className="ms-2">
                      <button
                        type="button"
                        className="btn btn-success me-2"
                        onClick={() => {
                          if (Number(deal.won_cs) === 0 || deal.won_cs === null) {
                            setIsWon(true);
                          } else if (Number(deal.won_cs) === Number(deal.cs_id)) {
                            setIsWon(true);
                          } else {
                            setIsWon(false);
                            const message = Number(session.naming_scheme) === 1 ? `Oops, you can't convert this ${session.top_menu_deal}` : `Oops, you can't convert this deal yet.`;
                            Swal.fire({ icon: "warning", title: message, text: deal.won_cs_message });
                          }
                        }}
                      >
                        {pipeBtn.won}
                      </button>
                      <button type="button" className="btn btn-danger me-2" onClick={setIsLost}>
                        {pipeBtn.lost}
                      </button>
                    </div>
                  )}

                  {deal.deal_archieve !== "" && (
                    <>
                      {(Number(deal.owner_id) === Number(session.user_id) || Number(session.role_id) === 1) && (
                        <button className="btn btn-info text-light" onClick={() => sendReopenDeal()}>
                          Reopen
                        </button>
                      )}
                      {deal.deal_archieve === "won" && <button className="btn btn-success">{t("DealDetails_won")}</button>}
                      {deal.deal_archieve === "lost" && <button className="btn btn-danger">{t("DealDetails_lost")}</button>}
                      {deal.deal_archieve === "deleted" && <button className="btn btn-danger">{t("DealDetails_deleted")}</button>}
                      {deal.deal_archieve === "won" && Number(deal.pipeline_id) === Number(session.deal_custom_action_pipe) && Number(session.role_id) === 1 && <button className="btn btn-info">{session.deal_custom_action}</button>}
                    </>
                  )}

                  <RenderDealAction />
                </Stack>
              </Col>
              <Col xxl={12} className="pb-3 px-5">
                <div className="op steps w-100">
                  {status &&
                    deal &&
                    status.map((record, index) => (
                      <button key={index} className={`link step ${record.cs_index <= deal.cs_index ? "active" : ""}`} onClick={() => onchangeStage(record)}>
                        {record.cs_index <= deal.cs_index && <FontAwesomeIcon className="me-3" icon={faCircleCheck} style={{ color: "#fff" }} size="xl" />} <br />
                        <div className="content">
                          <div className="title text-start">{record.cs_title}</div>
                          {Number(record.max_days) > 0 && Number(record.stageDuration) > Number(record.max_days) && (
                            <div className="description d-flex text-light">
                              <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" style={{ color: "#fff" }} size="sm" />
                              {t("DealDetails_overdue_by_count_days", { count: record.stageOverdue })}
                            </div>
                          )}
                        </div>
                      </button>
                    ))}
                </div>
              </Col>
            </Row>
          </div>

          <div className="section-body">
            <div className="section-sidebar">
              <Accordion defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} alwaysOpen>
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="9">Summary</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      {(setCheckValidUser() || setCheckValidAdminUser()) && isProspectAllowEditContact && (
                        <button type="button" className="btn btn-light border rounded" onClick={setIsEditDealInfo}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      )}
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="9">
                    <div className="accordion-body px-0">
                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faCircleDollar} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Deal Value</div>
                          <div className="fw-bold">{deal.deal_value ? setCurrency(deal.deal_value) : "RM 0.00"}</div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBarsStaggered} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Pipeline</div>
                          <div className="fw-bold">{deal.pipeline_title ? deal.pipeline_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faFlag} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Expected Close Date</div>
                          <div className="fw-bold">{deal.deal_expected_close_date ? setDateStr(deal.deal_expected_close_date) : t("DealDetails_no_date")}</div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>

                {(Number(deal.final_conversion) === 1 || Number(deal.real_estate_final) === 1) && Number(session.subscription_tier) > 2 && Number(session.booking_module) === 1 && session.layout_mode === "real-estate" && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("DealDetails_unit_booking")}</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        {booking.length > 0 ? (
                          <Button className="text-capitalize text-light" variant={setStatusColor(booking[booking.length - 1].booking_status)} style={{ fontSize: 12 }}>
                            {booking[booking.length - 1].booking_status}
                          </Button>
                        ) : (
                          <button type="button" className="btn btn-light border" onClick={() => navigate(`/booking/project/${deal_id}/${deal.prospect_id}`)}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        )}
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body px-0">
                        <div className="d-flex align-items-center p-2 w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faBuildings} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">Project</div>
                            <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].project_name : "-"}</div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center p-2 w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faHouseBuilding} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">Phase</div>
                            <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].phase_name : "-"}</div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center p-2 w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faHouseChimneyWindow} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">Unit Type</div>
                            <div className="fw-bold">{booking.length > 0 ? `${booking[booking.length - 1].unit_title} ${booking[booking.length - 1].unit_type}` : "-"}</div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center p-2 w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faHouse} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">Unit Number</div>
                            <div className="fw-bold">{booking.length > 0 ? booking[booking.length - 1].unit_number : "-"}</div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                {session.layout_mode === "standard" && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("DealDetails_product")}</RenderAccordionButton>
                      {Number(session.role_id) === 1 && (
                        <div className="btn-group shadow-sm">
                          <button type="button" className="btn btn-light border" onClick={setisAddProduct}>
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      )}
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body">
                        {Number(productCount) > 0 ? (
                          dealProduct.map((record, index) => (
                            <Stack className="mb-2" direction="horizontal" key={index}>
                              <FontAwesomeIcon icon={faBox} size="lg" className="me-2" />
                              {record.product_title} - {record.total_qty}x <br /> {setCurrency(record.discount)}({t("DealDetails_discount")}) {setCurrency(record.final_price)}({t("DealDetails_total_price")})
                              <FontAwesomeIcon className="ms-auto cursor-pointer" icon={faCircleXmark} size="lg" onClick={() => sendDeleteProduct(record)} />
                            </Stack>
                          ))
                        ) : (
                          <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t("DealDetails_prospect")}</RenderAccordionButton>
                    {(setCheckValidUser() || setCheckValidAdminUser()) && isProspectAllowEditContact && (
                      <div className="btn-group shadow-sm">
                        <button type="button" className="btn btn-light border" onClick={setIsChangeProspect}>
                          <FontAwesomeIcon icon={faRightLeft} />
                        </button>

                        <button type="button" className="btn btn-light border" onClick={setIsEditProspect}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </div>
                    )}
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body px-0">
                      <div
                        className="d-flex justify-content-between align-items-center p-2 w-100 cursor-pointer hover-bg-secondary rounded"
                        onClick={() => {
                          if (isProspectAllowEditContact) {
                            window.open(`${session.origin}/prospect/${deal.prospect_id}`, "_blank");
                          } else {
                            Swal.fire({
                              text: "You are not allowed to view this prospect",
                              icon: "warning",
                            });
                          }
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faUser} size="xl" />
                          </div>
                          <div className="w-100">
                            <div className="text-muted">{t("DealDetails_prospect_name")}</div>
                            <div className="fw-bold">{deal.prospect_name ? deal.prospect_name : "-"}</div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBriefcase} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Designation</div>
                          <div className="fw-bold">{deal.designation_title ? deal.designation_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_contact_number_1")}</div>
                          <div className="fw-bold">{setPhoneNumber(deal.contact1_prefix, deal.contact1 ? deal.contact1 : deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : "")}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_contact_number_2")}</div>
                          <div className="fw-bold">{setPhoneNumber(deal.contact2_prefix, deal.contact2 ? deal.contact2 : deal.prospect_contact_count > 1 ? deal.prospect_contact_info[1].contact_num : "")}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_contact_number_3")}</div>
                          <div className="fw-bold">{setPhoneNumber(deal.contact3_prefix, deal.contact3 ? deal.contact3 : deal.prospect_contact_count > 2 ? deal.prospect_contact_info[2].contact_num : "")}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faPhone} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_contact_number_4")}</div>
                          <div className="fw-bold">{setPhoneNumber(deal.contact4_prefix, deal.contact4 ? deal.contact4 : deal.prospect_contact_count > 3 ? deal.prospect_contact_info[3].contact_num : "")}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faEnvelope} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_email_1")}</div>
                          <div className="fw-bold">{deal.email1 ? deal.email1 : deal.prospect_email_count > 0 ? deal.prospect_email_info[0].email_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faEnvelope} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_email_2")}</div>
                          <div className="fw-bold">{deal.email2 ? deal.email2 : deal.prospect_email_count > 1 ? deal.prospect_email_info[1].email_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_address_line_1")}</div>
                          <div className="fw-bold">{deal.address_line1 ? deal.address_line1 : deal.prospect_address ? deal.prospect_address : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_address_line_2")}</div>
                          <div className="fw-bold">{deal.address_line2 ? deal.address_line2 : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_address_line_3")}</div>
                          <div className="fw-bold">{deal.address_line3 ? deal.address_line3 : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_postal_code")}</div>
                          <div className="fw-bold">{deal.postal_code ? deal.postal_code : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_city")}</div>
                          <div className="fw-bold">{deal.city_title ? deal.city_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_state")}</div>
                          <div className="fw-bold">{deal.state_title ? deal.state_title : "-"}</div>
                        </div>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faLocationDot} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted"> {t("DealDetails_country")}</div>
                          <div className="fw-bold">{deal.country_title ? deal.country_title : "-"}</div>
                        </div>
                      </div>

                      {deal &&
                        deal.custom_field_person > 0 &&
                        deal.custom_field_person_data.map((record, index) => (
                          <div className="d-flex p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted"> {record.cf_label}</div>
                              <div className="fw-bold">{record.fd_value ? record.fd_value : "-"}</div>
                            </div>
                          </div>
                        ))}

                      {Number(session.role_id) === 1 && (
                        <div className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer" onClick={() => window.open(`${session.origin}/settings/fields/person`, "_blank")}>
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Create additional field</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="10">Source</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button type="button" className="btn btn-light border rounded" onClick={setIsEditSource}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="10">
                    <div className="accordion-body px-0">
                      <div className="d-flex align-items-center p-2 w-100">
                        <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                          <FontAwesomeIcon icon={faBullhorn} size="xl" />
                        </div>
                        <div className="w-100">
                          <div className="text-muted">Deal Source</div>
                          <div className="fw-bold">{deal.ds_title ? deal.ds_title : "-"}</div>
                        </div>
                      </div>

                      <RenderUTM record={deal} />
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(session.company_type_id) === 0 && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="6">Organization</RenderAccordionButton>
                      {(setCheckValidUser() || setCheckValidAdminUser()) && isOrganizationAllowEditContact && (
                        <div className="btn-group shadow-sm">
                          {deal.organization_title && (
                            <button type="button" className="btn btn-light border" onClick={getOrganizationData}>
                              Find Info
                            </button>
                          )}

                          {deal.organization_title && (
                            <button type="button" className="btn btn-light border" onClick={setIsEditOrganization}>
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          )}

                          {!deal.organization_title && (
                            <button type="button" className="btn btn-light border" onClick={setIsAddOrganization}>
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <Accordion.Collapse eventKey="6">
                      <div className="accordion-body px-0">
                        {deal.organization_title ? (
                          <>
                            <div
                              className="d-flex justify-content-between p-2 align-items-center w-100 hover-bg-secondary rounded cursor-pointer"
                              onClick={() => {
                                if (isOrganizationAllowEditContact) {
                                  window.open(`${session.origin}/organizations/${deal.organization_id}`, "_blank");
                                } else {
                                  Swal.fire({
                                    text: "You are not allowed to view this organization",
                                    icon: "warning",
                                  });
                                }
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faBuildings} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">Organization Name</div>
                                  <div className="fw-bold">{deal.organization_title ? deal.organization_title : "-"}</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Address</div>
                                <div className="fw-bold">{deal.organization_address ? deal.organization_address : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faBrowser2} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Website</div>
                                <div className="fw-bold">{deal.organization_web_url ? deal.organization_web_url : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faPhone} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Contact</div>
                                <div className="fw-bold">{deal.organization_contact ? deal.organization_contact : "-"}</div>
                              </div>
                            </div>

                            <div className="d-flex p-2 w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faIndustryWindows} size="xl" />
                              </div>
                              <div className="w-100">
                                <div className="text-muted">Industry</div>
                                <div className="fw-bold">{deal.in_title ? deal.in_title : "-"}</div>
                              </div>
                            </div>

                            {deal &&
                              deal.custom_field_organization > 0 &&
                              deal.custom_field_organization_data.map((record, index) => (
                                <div className="d-flex p-2 w-100" key={index}>
                                  <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                    <FontAwesomeIcon icon={faPlus} size="xl" />
                                  </div>
                                  <div className="w-100">
                                    <div className="text-muted">{record.cf_label}</div>
                                    <div className="fw-bold">{record.fd_value ? record.fd_value : "-"}</div>
                                  </div>
                                </div>
                              ))}

                            {Number(session.role_id) === 1 && (
                              <div className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer" onClick={() => window.open(`${session.origin}/settings/fields/organization`, "_blank")}>
                                <div className="d-flex align-items-center w-100">
                                  <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                    <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                                  </div>
                                  <div className="w-100">
                                    <div className="link-primary">Create additional field</div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="2">Details</RenderAccordionButton>
                    {deal && deal.custom_field > 0 && (
                      <div className="btn-group shadow-sm">
                        <button type="button" className="btn btn-light border" onClick={setIsEditDeal}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </div>
                    )}
                  </div>

                  <Accordion.Collapse eventKey="2">
                    <div className="accordion-body px-0">
                      {deal &&
                        deal.custom_field > 0 &&
                        deal.custom_field_data.map((record, index) => (
                          <div key={index}>
                            {record.cf_view_detail === "yes" && (
                              <div className="d-flex p-2 w-100">
                                <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                  <FontAwesomeIcon icon={faPlus} size="xl" />
                                </div>
                                <div className="w-100">
                                  <div className="text-muted">{record.cf_label}</div>
                                  <div className="fw-bold">{record.fd_value ? record.fd_value : "-"}</div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      {Number(session.role_id) === 1 && (
                        <div className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer" onClick={() => window.open(`${session.origin}/settings/fields/deal`, "_blank")}>
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faPlus} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Create additional field</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(Number(session.subscription_tier) > 2) && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="3">{t("DealDetails_analytics")}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="3">
                      <div className="accordion-body px-0">
                        <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={setIsOpenDeal}>
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faChartLineUp} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_count_inbound_lead", { count: analytics.total_open })}</div>
                              <div className="fw-bold">{setCurrency(analytics.open_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={setIsWonDeal}>
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faChartLine} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_count_sales_final_conversion_pipeline", { count: analytics.total_won })}</div>
                              <div className="fw-bold">{setCurrency(analytics.won_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={setIsLostDeal}>
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faChartLineDown} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_dropped_deals", { count: analytics.total_lost })}</div>
                              <div className="fw-bold">{setCurrency(analytics.lost_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100 hover-bg-secondary rounded" onClick={setIsArchievedDeal}>
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faChartBar} size="xl" />
                            </div>
                            <div className="w-100">
                              <div className="text-muted">{t("DealDetails_count_archieved_deals", { count: analytics.total_deleted })}</div>
                              <div className="fw-bold">{setCurrency(analytics.deleted_amount)}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="4">{t("DealDetails_contributor")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button type="button" className="btn btn-light border" onClick={setIsAddContributor}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="4">
                    <div className="accordion-body px-0">
                      {Number(deal.contributor_count) > 0 ? (
                        deal.contributor.map((record, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between cursor-pointer p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faUserTie} size="xl" />
                              </div>
                              <div className="w-100">{record.user_name}</div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleXmark} size="lg" onClick={() => sendDeleteContributor(record)} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="5">{t("DealDetails_forms")}</RenderAccordionButton>
                    <div className="btn-group shadow-sm">
                      <button type="button" className="btn btn-light border" onClick={setisAddForm}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <Accordion.Collapse eventKey="5">
                    <div className="accordion-body px-0">
                      {Number(deal.totalFormsCreated) > 0 ? (
                        deal.custom_form_created.map((record, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between p-2 w-100">
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                                <FontAwesomeIcon icon={faBrowser} size="xl" />
                              </div>
                              <div className="w-100 cursor-pointer" onClick={() => openForm(record)}>
                                {record.form_deal_title}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faCircleXmark} size="lg" onClick={() => sendDeleteForm(record)} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(session.company_id) === 287 && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="7">Referral</RenderAccordionButton>
                      <div className="btn-group shadow-sm">
                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            if (session.hostUrlType === "cloud_staging") {
                              window.open(`${session.hostUrl}/form/rt_staging/${deal.prospect_token}`, "_blank");
                            } else {
                              window.open(`${session.hostUrl}/form/rt/${deal.prospect_token}`, "_blank");
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faList} size="lg" />
                        </button>

                        <button
                          type="button"
                          className="btn btn-light border"
                          onClick={() => {
                            var prospectType = "";
                            deal.custom_field_person_data.forEach((data) => {
                              if (Number(data.cf_id) === 6078 || Number(data.cf_id) === 6864) {
                                prospectType = data.fd_value;
                              }
                            });

                            if (prospectType.toLocaleLowerCase() === "customer") {
                              if (session.hostUrlType === "cloud_staging") {
                                window.open(`${session.hostUrl}/form/rc_staging/${deal.prospect_token}/${session.company_token}`, "_blank");
                              } else {
                                window.open(`${session.hostUrl}/form/rc/${deal.prospect_token}/${session.company_token}`, "_blank");
                              }
                            } else if (prospectType.toLocaleLowerCase() === "agent") {
                              if (session.hostUrlType === "cloud_staging") {
                                window.open(`${session.hostUrl}/form/ra_staging/${deal.prospect_token}/${session.company_token}`, "_blank");
                              } else {
                                window.open(`${session.hostUrl}/form/ra/${deal.prospect_token}/${session.company_token}`, "_blank");
                              }
                            } else {
                              Swal.fire({
                                icon: "warning",
                                title: "Alert",
                                text: "Please fill in prospect type",
                              });
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faClipboard} size="lg" />
                        </button>
                      </div>
                    </div>

                    <Accordion.Collapse eventKey="7">
                      <div className="accordion-body px-0">
                        <ReferralComponent prospectID={deal.prospect_id} dealID={deal_id} />
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="8">Meetup</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="8">
                    <div className="accordion-body px-0">
                      {meetupLink.status === 0 ? (
                        <div className="d-flex justify-content-between align-items-center p-2 w-100 hover-bg-secondary rounded cursor-pointer" onClick={() => window.open(meetupLink.meetup_link, "_blank")}>
                          <div className="d-flex align-items-center w-100">
                            <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30, height: 30 }}>
                              <FontAwesomeIcon icon={faLink} size="xl" color="RGBA(var(--bs-primary-rgb)" />
                            </div>
                            <div className="w-100">
                              <div className="link-primary">Link</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                            <FontAwesomeIcon icon={faChevronRight} color="RGBA(var(--bs-primary-rgb)" />
                          </div>
                        </div>
                      ) : (
                        <div className="px-3">{t("DealDetails_none_at_the_moment")}</div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className="section-content position-relative" ref={containerRef}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t("DealDetails_notes")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t("DealDetails_activity")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="meeting_scheduler">
                          <FontAwesomeIcon icon={faCalendarClock} className="me-2" size="lg" />
                          Meeting Scheduler
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="files">
                          <FontAwesomeIcon icon={faPaperclipVertical} className="me-2" size="lg" />
                          {t("DealDetails_files")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="messages">
                          <FontAwesomeIcon icon={faEnvelope} className="me-2" size="lg" />
                          {t("DealDetails_messages")}
                        </Nav.Link>
                      </Nav.Item>

                      {Number(session.ai_note) === 1 && (
                        <Nav.Item>
                          <Nav.Link eventKey="ai_follow_up">
                            <FontAwesomeIcon icon={faWandMagicSparkles} className="me-2" size="lg" />
                            AI Follow Up
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab tab={activeTab} active={isActiveTab} userListNotes={userNotes} activityUser={userActivity} isSubmitting={isSubmitting} disabled={disabledBtn} followUpLoading={modalLoading} followUpData={aiFollowUpData} />
                </Card>

                {Number(session.ai_note) === 1 && (
                  <Card className="mt-2 border-0">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex align-items-center mb-2">
                          <FontAwesomeIcon icon={faSparkles} size="xl" className="me-2" />
                          <div className="fw-bold" style={{ fontSize: 15 }}>
                            AI Timeline Summary
                          </div>
                        </div>
                        {isAiTimelineLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <Button variant="link" className="p-0 text-decoration-none" onClick={getAiTimelineSummary}>
                            Regenerate
                          </Button>
                        )}
                      </div>
                      <Row>
                        <Col sm={7}>
                          {isAiTimelineLoading ? (
                            <Card className="border-0">
                              <Card.Body>
                                <Placeholder as={Card.Text} animation="glow" className="mb-0">
                                  <Placeholder xs={7} bg="secondary" /> <Placeholder xs={4} bg="secondary" /> <Placeholder xs={4} bg="secondary" />
                                  <Placeholder xs={6} bg="secondary" /> <Placeholder xs={8} bg="secondary" />
                                </Placeholder>
                              </Card.Body>
                            </Card>
                          ) : (
                            <Card className="border-0">
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  <div dangerouslySetInnerHTML={{ __html: setFormatMessage(aiTimelineSummaryData.timelineSummary) }} />{" "}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          )}
                        </Col>

                        <Col sm={5}>
                          {isAiTimelineLoading ? (
                            <Card style={{ backgroundColor: "#eee" }}>
                              <Card.Body>
                                <Placeholder as={Card.Text} animation="glow" className="mb-0">
                                  <Placeholder xs={7} bg="secondary" /> <Placeholder xs={4} bg="secondary" /> <Placeholder xs={4} bg="secondary" />
                                  <Placeholder xs={6} bg="secondary" /> <Placeholder xs={8} bg="secondary" />
                                </Placeholder>
                              </Card.Body>
                            </Card>
                          ) : aiTimelineSummaryData.extraNotes ? (
                            <Card style={{ backgroundColor: "#eee" }}>
                              <Card.Body>
                                <Card.Text className="mb-0">
                                  <div dangerouslySetInnerHTML={{ __html: setFormatMessage(aiTimelineSummaryData.extraNotes) }} />{" "}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}

                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t("DealDetails_timeline")} </RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0" style={{ width: width }}>
                      <div className="accordion-body">
                        {loadingTimeline ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                              <Spinner animation="border" role="status" size="sm">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </div>
                        ) : (
                          <>
                          {isTimeline && (
                            <VerticalTimeline layout="1-column-left">
                              {timeline && timeline.length
                                ? timeline.map((record, index) => (
                                    <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                      <Stack direction="horizontal" gap={1} style={{ alignItems: "normal" }}>
                                        <div style={{ width: setWidthTimeline(record) }}>
                                          <div className={`op-text-bigger fw-bold`}>
                                            <RenderTimelineHeader record={record} />
                                          </div>
                                          <div style={{ fontSize: 11, maxWidth: "100%", wordWrap: "break-word" }}>
                                            <RenderTimelineDescription record={record} insight={callAnalytics} />
                                          </div>
                                          <RenderTimelineDocument record={record} />
                                        </div>
  
                                        {setTimelineMAD(record) ? (
                                          <div className="mt-1 me-1" style={{ width: "3%" }}>
                                            <div className="w-100 d-flex justify-content-center float-end" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                              {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                            </div>
                                          </div>
                                        ) : null}
  
                                        {record.record_type === "Activity" &&
                                        Number(record.record_activity_type_id) === 2 &&
                                        meetupLink.status === 0 &&
                                        record.meetup_cancel.status === 0 &&
                                        Number(record.meetup_cancel.record.cancel) === 0 &&
                                        record.record_activity_mad === "no" &&
                                        record.meeting_scheduler === true ? (
                                          <div className="mt-1 me-2" style={{ width: "3%" }}>
                                            <div
                                              className="w-100 d-flex justify-content-center"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setMeetingData(record);
                                                setIsCancelMeetup(true);
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#dc3545" }} />
                                            </div>
                                          </div>
                                        ) : null}
  
                                        {setTimelineAction(record) ? (
                                          <div style={{ width: "5%" }}>
                                            <div className="float-end">
                                              <RenderTimelineAction record={record} />
                                            </div>
                                          </div>
                                        ) : null}
                                      </Stack>
                                    </VerticalTimelineElement>
                                  ))
                                : null}
                              <VerticalTimelineElement date={moment(new Date(deal.deal_date_time_create)).format("lll")} iconStyle={{ background: "#fff" }} icon={<FontAwesomeIcon icon={faFloppyDisk} />}>
                                <div className="op-text-bigger fw-bold">{t("DealDetails_deal_created")}</div>
                              </VerticalTimelineElement>
                            </VerticalTimeline>
                          )}
                          </>
                        )}
                 
    
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Container>
            </div>
          </div>

          <Modal show={isWon} onHide={setIsWon} size="md">
            <Formik
              onSubmit={sendDealWon}
              initialValues={{
                remark: "",
                deal_archieve: "won",
              }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isLost} onHide={setIsLost} size="md">
            <Formik
              onSubmit={sendDealLost}
              initialValues={{
                remark: "",
                deal_archieve: "lost",
                lostReasonID: "",
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <FormSelect options={lostReason} placeholder={t("DealDetails_select_reason")} onChange={(info) => handleChange("lostReasonID")(info.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isDeleteDeal} onHide={setIsDeleteDeal} size="md">
            <Formik
              onSubmit={sendDeleteDeal}
              initialValues={{
                remark: "",
                deal_archieve: "deleted",
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_would_you_like_to_leave_remark")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t("DealDetails_remark")} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t("DealDetails_up_to_200_characters")}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isChangeProspect} onHide={setIsChangeProspect}>
            <Formik
              onSubmit={sendChangeProspect}
              validationSchema={changeProspectSchema}
              initialValues={{
                prospect: "",
              }}
            >
              {({ handleSubmit, handleChange, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_change_prospect")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <FormSelectCreatable
                        placeholder={t("DealDetails_find_prospect")}
                        loadOptions={getExistingProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect && touched.prospect}
                        width="100%"
                        onChange={(info) => {
                          handleChange({
                            target: { name: "prospect", value: info.value },
                          });
                        }}
                      />
                      {errors.prospect && touched.prospect && <div className="op-error-message">{errors.prospect}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditProspect} onHide={setIsEditProspect}>
            <Formik
              onSubmit={sendEditProspect}
              validationSchema={editProspectSchema}
              initialValues={{
                prospect_id: deal && deal.prospect_id ? deal.prospect_id : "",
                prospect_name: deal && deal.prospect_name ? deal.prospect_name : "",
                designation_title: deal && deal.designation_title ? deal.designation_title : "",
                designation_id: deal && deal.designation_id ? deal.designation_id : "",
                contact1Prefix: deal && deal.contact1_prefix ? deal.contact1_prefix : "",
                contact2Prefix: deal && deal.contact2_prefix ? deal.contact2_prefix : "",
                contact3Prefix: deal && deal.contact3_prefix ? deal.contact3_prefix : "",
                contact4Prefix: deal && deal.contact4_prefix ? deal.contact4_prefix : "",
                contact1PrefixVal: deal && deal.contact1_prefix ? prefixList().list.find((record) => record.value === deal.contact1_prefix) || prefixList().list[0] : prefixList().list[0],
                contact2PrefixVal: deal && deal.contact2_prefix ? prefixList().list.find((record) => record.value === deal.contact2_prefix) || prefixList().list[0] : prefixList().list[0],
                contact3PrefixVal: deal && deal.contact3_prefix ? prefixList().list.find((record) => record.value === deal.contact3_prefix) || prefixList().list[0] : prefixList().list[0],
                contact4PrefixVal: deal && deal.contact4_prefix ? prefixList().list.find((record) => record.value === deal.contact4_prefix) || prefixList().list[0] : prefixList().list[0],
                contact1: deal && deal.contact1 ? deal.contact1 : deal && deal.prospect_contact_count > 0 && deal.prospect_contact_info[0] && deal.prospect_contact_info[0].contact_num ? deal.prospect_contact_info[0].contact_num : "",
                contact2: deal && deal.contact2 ? deal.contact2 : deal && deal.prospect_contact_count > 0 && deal.prospect_contact_info[1] && deal.prospect_contact_info[1].contact_num ? deal.prospect_contact_info[1].contact_num : "",
                contact3: deal && deal.contact3 ? deal.contact3 : deal && deal.prospect_contact_count > 0 && deal.prospect_contact_info[2] && deal.prospect_contact_info[2].contact_num ? deal.prospect_contact_info[2].contact_num : "",
                contact4: deal && deal.contact4 ? deal.contact4 : deal && deal.prospect_contact_count > 0 && deal.prospect_contact_info[3] && deal.prospect_contact_info[3].contact_num ? deal.prospect_contact_info[3].contact_num : "",
                email1: deal && deal.email1 ? deal.email1 : deal && deal.prospect_email_count > 0 && deal.prospect_email_info[0] && deal.prospect_email_info[0].email_title ? deal.prospect_email_info[0].email_title : "",
                email2: deal && deal.email2 ? deal.email2 : deal && deal.prospect_email_count > 0 && deal.prospect_email_info[1] && deal.prospect_email_info[1].email_title ? deal.prospect_email_info[1].email_title : "",
                address1: deal && deal.address_line1 ? deal.address_line1 : deal && deal.prospect_address ? deal.prospect_address : "",
                address2: deal && deal.address_line2 ? deal.address_line2 : "",
                address3: deal && deal.address_line3 ? deal.address_line3 : "",
                postcode: deal && deal.postal_code ? deal.postal_code : "",
                countryVal: deal && Number(deal.country_id) > 0 ? { label: deal.country_title, value: deal.country_id } : "",
                country_id: deal && Number(deal.country_id) > 0 ? deal.country_id : 0,
                stateVal: deal && Number(deal.state_id) > 0 ? { label: deal.state_title, value: deal.state_id } : "",
                state_id: deal && Number(deal.state_id) > 0 ? deal.state_id : 0,
                cityVal: deal && Number(deal.city_id) > 0 ? { label: deal.city_title, value: deal.city_id } : "",
                city_id: deal && Number(deal.city_id) > 0 ? deal.city_id : 0,
                owner: deal && deal.prospect_owner ? user.find((record) => record.value === deal.prospect_owner) : "",
                owner_id: deal && deal.prospect_owner ? deal.prospect_owner : "",
                cf_person:
                  deal && deal.custom_field_person && deal.custom_field_person > 0
                    ? deal.custom_field_person_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_prospect")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_prospect_name")} *</Form.Label>
                      <Form.Control type="text" value={values.prospect_name} onChange={handleChange("prospect_name")} />
                      {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_designation")}</Form.Label>
                      <FormSelectCreatable
                        loadOptions={getExistingDesignation}
                        isLoading={isFindDesignation}
                        isInvalid={errors.designation_id && touched.designation_id}
                        placeholder={values.designation_title}
                        width="100%"
                        onChange={(info) => {
                          handleChange({
                            target: { name: "designation_title", value: info.label },
                          });

                          handleChange({
                            target: { name: "designation_id", value: info.value },
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_prospect_owner")} *</Form.Label>
                      <FormSelect
                        options={user}
                        valueDefault={values.owner}
                        onChange={(e) => {
                          setFieldValue("owner", e);
                          setFieldValue("owner_id", e.value);
                        }}
                      />
                      {errors.owner_id && touched.owner_id && <div className="op-error-message">{errors.owner_id}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_contact_number_1")} *</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <FormSelect
                          options={prefixList().list}
                          valueDefault={values.contact1PrefixVal}
                          width="100px"
                          onChange={(e) => {
                            handleChange("contact1Prefix")(e.value);
                          }}
                        />
                        <Form.Control type="text" value={values.contact1} onChange={handleChange("contact1")} />
                      </Stack>
                      {errors.contact1Prefix && touched.contact1Prefix && <div className="op-error-message">{errors.contact1Prefix}</div>}
                      {errors.contact1 && touched.contact1 && <div className="op-error-message">{errors.contact1}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_contact_number_2")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <FormSelect
                          options={prefixList().list}
                          valueDefault={values.contact2PrefixVal}
                          width="100px"
                          onChange={(e) => {
                            handleChange("contact2Prefix")(e.value);
                          }}
                        />
                        <Form.Control type="text" value={values.contact2} onChange={handleChange("contact2")} />
                      </Stack>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_contact_number_3")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <FormSelect
                          options={prefixList().list}
                          valueDefault={values.contact3PrefixVal}
                          width="100px"
                          onChange={(e) => {
                            handleChange("contact3Prefix")(e.value);
                          }}
                        />
                        <Form.Control type="text" value={values.contact3} onChange={handleChange("contact3")} />
                      </Stack>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_contact_number_4")}</Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <FormSelect
                          options={prefixList().list}
                          valueDefault={values.contact4PrefixVal}
                          width="100px"
                          onChange={(e) => {
                            handleChange("contact4Prefix")(e.value);
                          }}
                        />
                        <Form.Control type="text" value={values.contact4} onChange={handleChange("contact4")} />
                      </Stack>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_email_1")}</Form.Label>
                      <Form.Control type="email" value={values.email1} onChange={handleChange("email1")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_email_2")}</Form.Label>
                      <Form.Control type="email" value={values.email2} onChange={handleChange("email2")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_address_line_1")}</Form.Label>
                      <Form.Control type="text" value={values.address1} onChange={handleChange("address1")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_address_line_2")}</Form.Label>
                      <Form.Control type="text" value={values.address2} onChange={handleChange("address2")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_address_line_3")}</Form.Label>
                      <Form.Control type="text" value={values.address3} onChange={handleChange("address3")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_postal_code")}</Form.Label>
                      <Form.Control type="number" value={values.postcode} onChange={handleChange("postcode")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_country")}</Form.Label>
                      <FormSelect
                        options={country}
                        valueDefault={values.countryVal}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("country_id", e.value);
                          setFieldValue("stateVal", "");
                          setFieldValue("state_id", "");
                          setFieldValue("cityVal", "");
                          setFieldValue("city_id", "");
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_state")}</Form.Label>
                      <FormSelect
                        options={Number(values.country_id) > 0 ? state.filter((record) => record.country === values.country_id) : state}
                        valueDefault={values.stateVal}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("state_id", e.value);
                          setFieldValue("cityVal", "");
                          setFieldValue("city_id", "");
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_city")}</Form.Label>
                      <FormSelect
                        options={Number(values.state_id) > 0 ? city.filter((record) => record.state === values.state_id) : city}
                        valueDefault={values.cityVal}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("city_id", e.value);
                        }}
                      />
                    </Form.Group>

                    {deal.custom_field_person > 0 &&
                      deal.custom_field_person_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_person[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_person];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              const { value, checked } = info.target;
                              updatedCustomFields[index].fd_values = checked ? value : "";
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_person", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditDeal} onHide={setIsEditDeal}>
            <Formik
              onSubmit={sendEditDeal}
              initialValues={{
                cf_deal:
                  deal && deal.custom_field && deal.custom_field > 0
                    ? deal.custom_field_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    {deal &&
                      deal.custom_field > 0 &&
                      deal.custom_field_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_deal[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_deal];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              const { value, checked } = info.target;
                              updatedCustomFields[index].fd_values = checked ? value : "";
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_deal", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddContributor} onHide={setIsAddContributor}>
            <Formik
              onSubmit={sendAddContributor}
              validationSchema={addContributorSchema}
              initialValues={{
                user_id: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_add_contributor")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_user")} *</Form.Label>
                      <FormSelect options={user} onChange={(e) => handleChange("user_id")(e.value)} />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddForm} onHide={setisAddForm}>
            <Formik
              onSubmit={sendAddForm}
              validationSchema={addFormSchema}
              initialValues={{
                form_id: "",
                form_title: "",
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_add_form")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_form")} *</Form.Label>
                      <FormSelect
                        options={form}
                        onChange={(e) => {
                          setFieldValue("form_id", e ? e.value : "");
                          setFieldValue("form_title", e ? e.label : "");
                        }}
                      />
                      {errors.form_id && touched.form_id && <div className="op-error-message">{errors.form_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik validationSchema={editActivitySchema} initialValues={initEditActivity} onSubmit={sendEditActivity}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_activity")} </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("DealDetails_activity_title")} value={values.activity_title} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect options={timings} valueDefault={timings.find((record) => record.value === values.activity_time)} placeholder={t("DealDetails_select_time")} onChange={(e) => handleChange("activity_time")(e.value)} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect options={durationList} valueDefault={durationList.find((record) => record.value === values.activity_duration)} placeholder={t("DealDetails_select_duration")} onChange={(e) => handleChange("activity_duration")(e.value)} />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect placeholder={t("DealDetails_select_user")} options={userActivity} valueDefault={userActivity.find((record) => record.value === values.activity_user)} onChange={(e) => handleChange("activity_user")(e.value)} />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label={t("DealDetails_mark_as_done")}
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.mad}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "mad", value: e.target.checked },
                        });
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isSubmitting}>
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditMeeting} onHide={setIsEditMeeting} size="lg">
            <Formik validationSchema={editActivitySchema} initialValues={initEditMeeting} onSubmit={sendEditMeeting}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Meeting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("DealDetails_activity_title")} value={values.activity_title} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={Number(values.activity_type) === 2 ? meetingTimingsList : timings}
                              valueDefault={Number(values.activity_type) === 2 ? meetingTimingsList.find((record) => record.value === values.activity_time) : timings.find((record) => record.value === values.activity_time)}
                              placeholder={t("DealDetails_select_time")}
                              onChange={(e) => handleChange("activity_time")(e.value)}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect
                              options={Number(values.activity_type) === 2 ? meetingDurationList : durationList}
                              valueDefault={Number(values.activity_type) === 2 ? meetingDurationList.find((record) => record.value === values.activity_duration) : durationList.find((record) => record.value === values.activity_duration)}
                              placeholder={t("DealDetails_select_duration")}
                              onChange={(e) => handleChange("activity_duration")(e.value)}
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect placeholder={t("DealDetails_select_user")} options={userActivity} valueDefault={userActivity.find((record) => record.value === values.activity_user)} onChange={(e) => handleChange("activity_user")(e.value)} />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label={t("DealDetails_mark_as_done")}
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.mad}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "mad", value: e.target.checked },
                        });
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isSubmitting}>
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setisEditNote} size="lg">
            <Formik onSubmit={sendEditNotes} validationSchema={editNotesSchema} initialValues={initEditNotes}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_notes")} </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <NoteEditor value={values.notes} onChangeValue={(value) => handleChange("notes")(value)} />
                      {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isLeadScore} onHide={setIsLeadScore} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>
                {t("DealDetails_rubric_score")} ({leadScore})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {leadScoreList.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={leadScoreList} columnDefs={leadScoreColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isEditDealInfo} onHide={setIsEditDealInfo}>
            <Formik
              onSubmit={sendEditDealInfo}
              validationSchema={editDealInfoSchema}
              initialValues={{
                deal_title: deal.deal_title || "",
                deal_price: deal.deal_value || 0,
                deal_expected_close: deal && deal.deal_expected_close_date !== "0000-00-00" ? moment(new Date(deal.deal_expected_close_date)).format("YYYY-MM-DD") : "",
                deal_added: moment(new Date(deal.deal_date_time_create)).format("YYYY-MM-DD"),
                deal_close: deal.deal_archieve === "won" ? moment(new Date(deal.deal_archieve_won_log)).format("YYYY-MM-DD") : deal.deal_archieve === "lost" ? moment(new Date(deal.deal_archieve_lost_log)).format("YYYY-MM-DD") : "",
                deal_archieve: deal.deal_archieve,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal_information")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_title")} *</Form.Label>
                      <Form.Control type="text" value={values.deal_title} onChange={(e) => handleChange("deal_title")(e.target.value)} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_price")}</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.deal_price}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            handleChange("deal_price")(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_expected_close")} </Form.Label>
                      <FormDate value={values.deal_expected_close ? moment(new Date(values.deal_expected_close)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_expected_close")(date)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_deal_added")} *</Form.Label>
                      <FormDate value={values.deal_added ? moment(new Date(values.deal_added)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_added")(date)} />
                      {errors.deal_added && touched.deal_added && <div className="op-error-message">{errors.deal_added}</div>}
                    </Form.Group>

                    {(deal.deal_archieve === "won" || deal.deal_archieve === "lost") && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t("DealDetails_deal_closed")} *</Form.Label>
                        <FormDate value={values.deal_close ? moment(new Date(values.deal_close)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_close")(date)} />
                        {errors.deal_close && touched.deal_close && <div className="op-error-message">{errors.deal_close}</div>}
                      </Form.Group>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isOpenDeal} onHide={setIsOpenDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_open_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_open && analytics.record_open.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_open} columnDefs={openDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isWonDeal} onHide={setIsWonDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_won_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_won && analytics.record_won.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_won} columnDefs={wonDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isLostDeal} onHide={setIsLostDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_lost_deals")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_lost && analytics.record_lost.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_lost} columnDefs={lostDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isArchievedDeal} onHide={setIsArchievedDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t("DealDetails_deleted_deals")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_deleted && analytics.record_deleted.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_deleted} columnDefs={archievedDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isAddProduct} onHide={() => setisAddProduct(false)} className="product-modal" size="xl">
            <ProductComponent
              dealProductList={dealProduct}
              getDealProductV2={dealProductV2}
              productList={productList}
              getuser={currentUser}
              onSubmit={() => {
                getProduct();
                getProductV2();
                getAllProduct();
                getTimeline();
                setisAddProduct(false);
              }}
            />
          </Modal>

          <Modal show={isCallLead} onHide={setIsCallLead}>
            <Modal.Body>
              <div className="d-flex flex-column-reverse positon-relative w-100">
                <CloseButton className="ms-auto" onClick={() => setIsCallLead(false)} />
              </div>
              <div className="call-lead">
                <div className="call-pulse">
                  <FontAwesomeIcon icon={faPhone} beatFade size="3x" style={{ color: "#fff" }} />
                </div>
              </div>
              <h5 className="text-center call-text">
                {t("DealDetails_calling")} {deal.prospect_name}
              </h5>
            </Modal.Body>
          </Modal>

          <Modal show={isFindOrganization} onHide={setIsFindOrganization} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Find Organization Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {findOrganizationData.length > 0 ? (
                <ListGroup as="ol" numbered>
                  {findOrganizationData.map((record, index) => (
                    <ListGroup.Item as="li" key={index} className="d-flex align-items-start">
                      <div className="ms-3">
                        <div className="fw-bold">{record.name}</div>
                        <p className="op-text-small">{record.address}</p>
                        <Button variant="" className="op-primary-color text-light" onClick={() => sendChooseOrganization(record)}>
                          Use Data
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <NoRecord message={t("DealDetails_no_record_at_the_moment")} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isEditOrganization} onHide={setIsEditOrganization}>
            <Formik
              onSubmit={sendEditOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_title: deal && deal.organization_title ? deal.organization_title : "",
                in_id: deal && deal.in_id && Number(deal.in_id) > 0 ? deal.in_id : "",
                organization_web_url: deal && deal.organization_web_url ? deal.organization_web_url : "",
                organization_contact: deal && deal.organization_contact ? deal.organization_contact : "",
                organization_address: deal && deal.organization_address ? deal.organization_address : "",
                organization_country_id: deal && deal.organization_country_id && Number(deal.organization_country_id) > 0 ? deal.organization_country_id : "",
                owner_id: deal && deal.owner_id && Number(deal.owner_id) > 0 ? deal.owner_id : "",
                cf_organization:
                  deal && deal.custom_field_organization && deal.custom_field_organization > 0
                    ? deal.custom_field_organization_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Organization Name *</Form.Label>
                      <Form.Control value={values.organization_title} name="organization_title" onChange={handleChange("organization_title")} />
                      {errors.organization_title && touched.organization_title && <div className="op-error-message">{errors.organization_title}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Industry</Form.Label>
                      <FormSelect name="in_id" options={industryList} valueDefault={values.in_id ? industryList.find((record) => Number(record.value) === Number(values.in_id)) : ""} onChange={(e) => handleChange("in_id")(e.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Website URL</Form.Label>
                      <Form.Control value={values.organization_web_url} name="organization_web_url" onChange={handleChange("organization_web_url")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control value={values.organization_contact} name="organization_contact" onChange={handleChange("organization_contact")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Organization Address</Form.Label>
                      <Form.Control as="textarea" value={values.organization_address} name="organization_address" onChange={handleChange("organization_address")} style={{ height: 100, resize: "none" }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <FormSelect
                        name="organization_country_id"
                        options={countryOrganizationList}
                        valueDefault={values.organization_country_id ? countryOrganizationList.find((record) => Number(record.value) === Number(values.organization_country_id)) : ""}
                        onChange={(e) => handleChange("organization_country_id")(e.value)}
                      />
                    </Form.Group>

                    {Number(session.role_id) === 1 && (
                      <Form.Group className="mb-3">
                        <Form.Label>Prospect Owner</Form.Label>
                        <FormSelect name="owner_id" options={organizationUser} valueDefault={values.owner_id ? organizationUser.find((record) => Number(record.value) === Number(values.owner_id)) : ""} onChange={(e) => handleChange("owner_id")(e.value)} />
                      </Form.Group>
                    )}

                    {deal &&
                      deal.custom_field_organization > 0 &&
                      deal.custom_field_organization_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_organization[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_organization];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              const { value, checked } = info.target;
                              updatedCustomFields[index].fd_values = checked ? value : "";
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_organization", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddOrganization} onHide={setIsAddOrganization}>
            <Formik
              onSubmit={sendNewOrganization}
              validationSchema={editOrganizationSchema}
              initialValues={{
                organization_title: "",
                in_id: "",
                organization_web_url: "",
                organization_contact: "",
                organization_address: "",
                organization_country_id: "",
                cf_organization:
                  deal && deal.custom_field_organization && deal.custom_field_organization > 0
                    ? deal.custom_field_organization_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: "",
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Create Organization</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Organization Name *</Form.Label>
                      <Form.Control value={values.organization_title} name="organization_title" onChange={handleChange("organization_title")} />
                      {errors.organization_title && touched.organization_title && <div className="op-error-message">{errors.organization_title}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Industry</Form.Label>
                      <FormSelect name="in_id" options={industryList} valueDefault={values.in_id ? industryList.find((record) => Number(record.value) === Number(values.in_id)) : ""} onChange={(e) => handleChange("in_id")(e.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Website URL</Form.Label>
                      <Form.Control value={values.organization_web_url} name="organization_web_url" onChange={handleChange("organization_web_url")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control value={values.organization_contact} name="organization_contact" onChange={handleChange("organization_contact")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Organization Address</Form.Label>
                      <Form.Control as="textarea" value={values.organization_address} name="organization_address" onChange={handleChange("organization_address")} style={{ height: 100, resize: "none" }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <FormSelect
                        name="organization_country_id"
                        options={countryOrganizationList}
                        valueDefault={values.organization_country_id ? countryOrganizationList.find((record) => Number(record.value) === Number(values.organization_country_id)) : ""}
                        onChange={(e) => handleChange("organization_country_id")(e.value)}
                      />
                    </Form.Group>

                    {deal &&
                      deal.custom_field_organization > 0 &&
                      deal.custom_field_organization_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_organization[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_organization];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              const { value, checked } = info.target;
                              updatedCustomFields[index].fd_values = checked ? value : "";
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_organization", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isCancelMeetup} onHide={setIsCancelMeetup}>
            <Formik
              onSubmit={sendCancelMeetup}
              validationSchema={cancelMeetupSchema}
              initialValues={{
                remark: "",
                reason: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Appointment Cancellation Reason</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Cancel Reason</Form.Label>
                      <FormSelect name="reason" options={cancelMeetupReasonList} onChange={(e) => setFieldValue("reason", e.label)} />
                      {errors.reason && touched.reason && <div className="op-error-message">{errors.reason}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control as="textarea" value={values.organization_title} name="organization_title" onChange={handleChange("remark")} style={{ height: 100, resize: "none" }} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal
            show={isCall}
            onHide={setIsCall}
            centered={true}
            dialogAs={({ children, ...props }) => {
              if (callMode) {
                return (
                  <Draggable handle=".header-call2" bounds="body">
                    <div style={{ ...props.style, position: "absolute", width: 200, top: `56px`, left: `60px` }} defaultposition={callPosition}>
                      <Modal.Dialog style={{ marginTop: 0, marginBottom: 0 }}>{children}</Modal.Dialog>
                    </div>
                  </Draggable>
                );
              }

              return (
                <Draggable handle=".header-call" bounds="body">
                  <div style={{ ...props.style, position: "absolute", width: 500, top: `${callPosition.y}px`, left: `${callPosition.x}px` }} defaultposition={callPosition}>
                    <Modal.Dialog style={{ marginTop: 0, marginBottom: 0 }}>{children}</Modal.Dialog>
                  </div>
                </Draggable>
              );
            }}
            enforceFocus={false}
            backdrop={false}
          >
            {callMode ? (
              <Modal.Body className="p-0">
                <Modal.Header className="header-call2" style={{ padding: 10 }}>
                  <Image src={LogoOP2} height={15} width="auto" />
                  <Button variant="link" size="sm" className="text-dark" style={{ padding: 0 }} onClick={() => setCallMode(false)}>
                    <FontAwesomeIcon icon={faExpand} />
                  </Button>
                </Modal.Header>

                <div style={{ height: 50, width: "10%%", margin: "0 10px 0 10px", borderRadius: 8 }}>
                  <div className="d-flex align-items-center h-100">
                    <span>
                      <div className="d-flex align-items-center">
                        <div className="rounded-circle op-primary-color d-flex align-items-center justify-content-center text-light mb-0" style={{ width: 30, height: 30 }}>
                          {setInitial(deal.prospect_name)}
                        </div>
                        <div className="text-center fw-bold ms-2" style={{ fontSize: 13 }}>
                          {deal.prospect_name}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div style={{ padding: "0 10px 10px 10px" }} className="d-flex justify-content-between align-items-center">
                  <div className="text-center" style={{ fontSize: 12 }}>
                    {callStart === "connect" ? getCallStatus(callStatus) : callTimer}
                  </div>
                  <Button variant="danger" className="rounded-circle p-2" onClick={sendHangUpCall}>
                    <FontAwesomeIcon icon={faPhoneHangup} size="lg" color="#fff" />
                  </Button>
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body className="p-0">
                <Stack direction="horizontal" className="header-call" style={{ padding: 10 }}>
                  <Image src={LogoOP} height={15} width="auto" />
                  <div className="ms-auto d-flex align-items-center">
                    <CloseButton onClick={() => setCallMode(true)} />
                  </div>
                </Stack>
                <div style={{ height: 300, width: "10%%", margin: "0 10px 0 10px", backgroundColor: "#eee", borderRadius: 8 }}>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <span>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="rounded-circle op-primary-color d-flex align-items-center justify-content-center text-light fs-1 mb-2" style={{ width: 100, height: 100 }}>
                          {setInitial(deal.prospect_name)}
                        </div>
                      </div>
                      <div className="text-center fw-bold" style={{ fontSize: 20 }}>
                        {deal.prospect_name}
                      </div>
                      <div className="text-center" style={{ fontSize: 17 }}>
                        {callStart === "connect" ? getCallStatus(callStatus) : callTimer}
                      </div>
                    </span>
                  </div>
                </div>
                <div style={{ padding: 10 }} className="d-flex justify-content-center align-items-center">
                  {callStart !== "stop" && (
                    <Button variant="danger" className="rounded-circle p-3" onClick={sendHangUpCall}>
                      <FontAwesomeIcon icon={faPhoneHangup} size="xl" color="#fff" />
                    </Button>
                  )}
                </div>
              </Modal.Body>
            )}
          </Modal>

          <Modal show={isEditSource} onHide={setIsEditSource}>
            <Formik
              onSubmit={sendEditSource}
              validationSchema={editSourceSchema}
              initialValues={{
                dealSource: deal && Number(deal.ds_id) > 0 ? { label: deal.ds_title, value: deal.ds_id } : "",
                ds_id: deal && Number(deal.ds_id) > 0 ? deal.ds_id : "",
                ds_title: deal && Number(deal.ds_id) > 0 ? deal.ds_title : "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("DealDetails_edit_deal")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_source")} *</Form.Label>
                      <FormSelect
                        options={dealSource}
                        valueDefault={values.dealSource}
                        onChange={(e) => {
                          setFieldValue("ds_title", e.label);
                          setFieldValue("ds_id", e.value);
                        }}
                      />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </Container>
  );
};

export default DealDetails;
