import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil, faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const SettingsCampaign = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [campaignData, setCampaignData] = useState([]);
    const [businessUnit, setBusinessUnit] = useState([]);
    const [isAddCampaign, setisAddCampaign] = useState(false);
    const [dateRange, setdateRange] = useState({
        ds: "",
        de: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [selectedCampaignData, setSelectedCampaignData] = useState("");
    const [selectedCampaignModal, setSelectedCampaignModal] = useState(false);
    const [sourceListData, setSourceListData] = useState([]);
    const [sourceRecord, setSourceRecord] = useState([]);
    const [isAddSource, setisAddSource] = useState(false);

    const [selectedHqData, setSelectedHqData] = useState("");
    const [HqDataListing, setHqDataListing] = useState([]);
    const [isAddHq, setisAddHq] = useState(false);
    const [HqCompanyList, setHqCompanyList] = useState([]);
    const [GlHqCompanyList, setGlHqCompanyList] = useState([]);
    const [currentDataHq, setCurrentDataHq] = useState([]);
    const [showModalHq, setShowModalHq] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedProject, setSelectedProject] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [delayedSearchTerm, setDelayedSearchTerm] = useState('');



    // Validation Schema
    const validationSchema = yup.object().shape({
        // campaign_title: yup.string().required("Campaign title is required"),
        campaign_title: yup.string()
            .trim()
            .required('Campaign title is required')
            .min(1, 'Campaign title cannot be empty or whitespace'),
        start_date: yup.string().required("Start date is required"),
        end_date: yup.string().required("Start date is required")
    });

    // GET FUNCTION --------------------------------------------------------
    const getCampaignList = () => {
        const gettask = session.company_id == '251' ? "listv2" : "listFromProject";
        try {
            axios
                .get(`ws/ws_campaign.php`, {
                    params: {
                        ctoken: session.company_token,
                        task: gettask,
                        utoken: session.user_ac_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    if (data.record && data.record.length > 0) {
                        setCampaignData(data.record);
                    } else {
                        setCampaignData([]);
                    }
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getBusinessUnitList = () => {
        try {
            axios
                .get(`ws/ws_setting.php`, {
                    params: {
                        company: session.company_id,
                        task: "getAllBU"
                    }
                })
                .then(res => {
                    let data = res.data;
                    setBusinessUnit(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getCampaignSourceList = async (campaign) => {
        setSelectedCampaignData(campaign);
        let campaign_id = campaign?.marketing_campaign_id || selectedCampaignData?.marketing_campaign_id;

        try {
            const res = await axios.get(`ws/ws_campaign.php`, {
                params: {
                    ctoken: session.company_token,
                    marketing_campaign_id: campaign_id,
                    task: "list_campaign_sources",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setSourceListData(data.record);
            }

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getCampaignSource = async () => {
        try {
            const res = await axios.get(`ws/ws_source.php`, {
                params: {
                    company: session.company_id,
                    task: 2
                }
            })

            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    label: record.ds_title,
                    value: record.ds_id,
                    company_id: record.company_id
                }));
                setSourceRecord(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    // DATE RANGE FILTER --------------------------------------------------
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Campaign Title",
            field: "marketing_campaign_title",
            cellClass: "cursor-pointer",
            width: 250,
            valueFormatter: (params) => params.data.marketing_campaign_title ? params.data.marketing_campaign_title : "-",
            onCellClicked: function (params) {
                if (params.data) {
                    if (session.company_id == "251") {
                        setSelectedHqData(params.data);
                        getHqListing(params.data);
                        listHqCompany();
                        listGlHqCompany();
                        setSelectedCampaignModal(true);
                    } else {
                        setSourceListData([]);
                        getCampaignSourceList(params.data);
                        getCampaignSource();
                        setSelectedCampaignModal(true);
                    }
                }
            }
        },
        {
            headerName: "Business Unit",
            field: "unit_title",
            width: 250,
        },
        { headerName: "Start Date", field: "start_date", width: 250 },
        { headerName: "End Date", field: "end_date", width: 250 },
        { headerName: "Campaign Target Unit", field: "campaign_target_unit", width: 250 },
        { headerName: "Campaign Target Value", field: "campaign_target_value", width: 250 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            width: 250,
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    // Source Grid
    const gridColumnSource = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Deal Source Title",
            field: "ds_title",
            width: 250,
        },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            width: 250,
            cellRenderer: function (params) {
                const isDisabled = params.data.company_id !== session.company_id;

                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            {/* <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} /> */}
                            <span>
                                <FontAwesomeIcon
                                    data-action="trash"
                                    className="fontAwesomeIcon"
                                    size="xl"
                                    icon={faTrash}
                                    style={{ pointerEvents: isDisabled ? 'none' : 'auto', opacity: isDisabled ? 0.5 : 1 }}
                                />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    const gridColumnSourceGLHQ = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Deal Source Title",
            field: "ds_title",
            width: 250,
        },
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            let params = {};

            if (session.company_id === '251') {
                params = {
                    company_id: values.company_id,
                    ctoken: session.company_token,
                    end_date: values.end_date,
                    marketing_campaign_id: values.marketing_campaign_id,
                    start_date: values.start_date,
                    task: "updatev2",
                    title: values.campaign_title,
                    utoken: session.user_ac_token
                };
            } else {
                params = {
                    business_unit_id: values.business_unit,
                    campaign_target_unit: values.campaign_target_unit,
                    campaign_target_value: values.campaign_target_value,
                    company_id: values.company_id,
                    ctoken: session.company_token,
                    end_date: values.end_date,
                    marketing_campaign_id: values.marketing_campaign_id,
                    start_date: values.start_date,
                    task: "updateForProject",
                    title: values.campaign_title,
                    utoken: session.user_ac_token
                };
            }

            const res = await axios.get("ws/ws_campaign.php", { params });

            const data = res.data;

            if (data.status === 0) {
                setShowModal(false);
                getCampaignList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened when updating campaign!',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened when updating campaign!',
                timer: 1500
            });
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.marketing_campaign_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (paramsSend) => {
        try {
            let params = {};

            if (session.company_id === '251') {
                params = {
                    ctoken: session.company_token,
                    task: "deleteCampaign",
                    title: paramsSend.marketing_campaign_title,
                    utoken: session.user_ac_token,
                };
            } else {
                params = {
                    ctoken: session.company_token,
                    marketing_campaign_id: paramsSend.marketing_campaign_id,
                    task: "deleteAsProject",
                    utoken: session.user_ac_token,
                };
            }

            const res = await axios.get("ws/ws_campaign.php", { params });

            const data = res.data;

            if (data.status === 0) {
                getCampaignList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened while deleting record',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    // Source Delete
    const onCellClickedSource = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "trash":
                    handleTrashSourceClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    // Project Setting Edit (HQ)

    const onCellClickedHq = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "trash":
                    handleHqTrashClick(rowData);
                    break;
                case "edit":
                    handleHqEditClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleHqEditClick = (params) => {
        setCurrentDataHq(params);
        setShowModalHq(true);
    };

    const handleCloseModalHq = () => {
        setShowModalHq(false);
    };


    // --------------------------------------------------------------------------------

    const handleTrashSourceClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.campaign_sources_id) {
                    sendDeleteSource(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDeleteSource = async (params) => {
        try {
            const response = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: session.company_token,
                    campaign_sources_id: params.campaign_sources_id,
                    task: "delete_campaign_sources",
                    utoken: session.user_ac_token,
                }
            });

            const data = response.data;

            if (data.status === 0) {
                setSelectedCampaignModal(false);
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened while deleting record',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            let params = {};

            if (session.company_id === '251') {
                params = {
                    company_id: values.bu_company_id || session.company_id,
                    ctoken: session.company_token,
                    end_date: values.end_date,
                    hq_company_id: session.company_id,
                    start_date: values.start_date,
                    task: "addCampaignTitle",
                    title: values.campaign_title,
                    utoken: session.user_ac_token
                };
            } else {
                params = {
                    business_unit_id: values.business_unit,
                    campaign_target_unit: values.campaign_target_unit,
                    campaign_target_value: values.campaign_target_value,
                    company_id: values.bu_company_id || session.company_id,
                    ctoken: session.company_token,
                    end_date: values.end_date,
                    hq_company_id: session.company_id,
                    start_date: values.start_date,
                    task: "addForProject",
                    title: values.campaign_title,
                    utoken: session.user_ac_token
                }
            }

            const res = await axios.get("ws/ws_campaign.php", { params });

            const data = res.data;

            if (data.status === 0) {
                setisAddCampaign(false);
                getCampaignList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened when adding campaign!',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to add campaign! Campaifn already exist!',
                timer: 1500
            });
        }
    };

    const handleAddSource = async (values) => {
        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: session.company_token,
                    ds_id: values.source_record,
                    marketing_campaign_id: selectedCampaignData.marketing_campaign_id,
                    task: "add_campaign_sources",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setisAddSource(false);
                setSelectedCampaignModal(false)
                // getCampaignSource();
                // getCampaignSourceList();
                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Deal Source was unable to add because it was being used!',
                    timer: 2000
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'The deal source is currently being used!',
                timer: 2000
            });
        }
    };


    // GL HQ api ---------------------------------------------------

    const getHqListing = async (clickedData) => {
        setHqDataListing([]);
        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: session.company_token,
                    task: "listProjectCampaign",
                    title: selectedHqData.marketing_campaign_title || clickedData.marketing_campaign_title,
                    utoken: session.user_ac_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                setHqDataListing(data.record);
            } else {
                setHqDataListing([]);
            }
        } catch {
            setHqDataListing([]);
        }
    }

    const gridColumnHq = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Project Title",
            field: "project_title",
            cellClass: "cursor-pointer",
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    if (session.company_id == "251") {
                        setSourceListData([]);
                        getCampaignSourceList(params.data);
                        getCampaignSource();
                        setSelectedCampaignModal(true);
                        setSelectedProject(params.data);
                        setCurrentStep(2);
                    }
                }
            }
        },
        {
            headerName: "Business Unit",
            field: "business_unit_id",
            width: 250,
            valueFormatter: (params) => {
                if (params.value === "0") {
                    return "-";
                } else {
                    const company = HqCompanyList.find(company =>
                        company.business_unit && company.business_unit.some(unit => unit.business_unit_id === params.value)
                    );

                    if (company) {
                        const unit = company.business_unit.find(unit => unit.business_unit_id === params.value);
                        return unit ? unit.business_unit_title : "";
                    } else {
                        const fallbackUnit = businessUnit.find(unit => unit.business_unit_id === params.value);
                        return fallbackUnit ? fallbackUnit.unit_title : "";
                    }
                }
            }
        },
        { headerName: "Campaign Target Unit", field: "campaign_target_unit", width: 250 },
        { headerName: "Campaign Target Value", field: "campaign_target_value", width: 250 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            width: 250,
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    const listHqCompany = async () => {
        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: session.company_token,
                    task: "listCompanyBU",
                    utoken: session.user_ac_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                setHqCompanyList(data.record);
            }
        } catch {

        }
    }

    const listGlHqCompany = async () => {
        try {
            const res = await axios.get("ws/ws_setting.php", {
                params: {
                    company: session.company_id,
                    task: "getAllBU",
                }
            })
            const data = res.data;

            if (data.status === 0) {
                setGlHqCompanyList(data.record);
            }
        } catch {

        }
    }

    const handleAddHq = async (values) => {
        const projectIds = values.project.join('|');
        const businessUnitIds = Object.values(values.business_units).join('|');
        const targetUnit = Object.values(values.target_unit).join('|');
        const targetValue = Object.values(values.target_value).join('|');

        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    business_unit_id: businessUnitIds,
                    campaign_target_unit: targetUnit,
                    campaign_target_value: targetValue,
                    company_id: projectIds,
                    ctoken: session.company_token,
                    hq_company_id: session.company_id,
                    marketing_campaign_id: selectedHqData.marketing_campaign_id,
                    task: "addProjectCampaign",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setisAddHq(false);
                getHqListing();
                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened when adding project!',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened when adding campaign!',
                timer: 1500
            });
        }
    };

    const handleUpdateHq = async (values) => {
        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    business_unit_id: values.business_unit,
                    campaign_target_unit: values.target_unit,
                    campaign_target_value: values.target_value,
                    company_id: values.company_id,
                    ctoken: session.company_token,
                    hq_company_id: session.company_id,
                    marketing_campaign_id: values.marketing_campaign_id,
                    task: "updateProjectCampaign",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setShowModalHq(false);
                getHqListing();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened when updating project!',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened when updating project!',
                timer: 1500
            });
        }
    };

    const handleHqTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.marketing_campaign_id) {
                    sendDeleteHq(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDeleteHq = async (params) => {
        try {
            const res = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: session.company_token,
                    marketing_campaign_id: params.marketing_campaign_id,
                    task: "deleteProjectCampaign",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                getHqListing();

                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened while deleting record',
                    timer: 1500
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }


    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setLoading(true);
            getCampaignList();
            getBusinessUnitList();
        }
    }, []);

    const toggleAddCampaignModal = () => {
        setisAddCampaign(prevState => !prevState);
    };

    const toggleAddSourceModal = () => {
        setisAddSource(prevState => !prevState);
    };

    const toggleAddHqModal = () => {
        setisAddHq(prevState => !prevState);
    };

    useEffect(() => {
        const delay = 500;

        const delaySearch = setTimeout(() => {
            setDelayedSearchTerm(searchTerm);
        }, delay);

        return () => clearTimeout(delaySearch);
    }, [searchTerm]);

    const getQuickFilterText = (params) => {
        const textFields = ["campaign_title", "business_unit_id"];
        const searchTermLowerCase = delayedSearchTerm.toLowerCase(); // Use delayed search term
        return textFields.map((field) => params.data[field]).join(' ').toLowerCase().includes(searchTermLowerCase);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <div className="d-flex w-100">
                        <div style={{ width: '25%' }}>
                            <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddCampaignModal}>
                                <FontAwesomeIcon icon={faPlus} className="me-2" /> Campaign
                            </button>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className="d-flex justify-content-center">
                                <Form.Control type="text" className="me-1 shadow-sm border-0" placeholder="Search..." style={{ width: 300 }} value={searchTerm || ''} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <div style={{ width: '25%' }}></div>
                    </div>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        rowData={loading ? [] : campaignData}
                                        rowHeight={70}
                                        pagination={true}
                                        onCellClicked={onCellClicked}
                                        quickFilterText={delayedSearchTerm}
                                        getQuickFilterText={getQuickFilterText}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddCampaign} onHide={toggleAddCampaignModal} size="md">
                        <Formik
                            initialValues={{
                                campaign_title: "",
                                business_unit: "",
                                start_date: "",
                                end_date: "",
                                campaign_target_unit: "",
                                campaign_target_value: "",
                                bu_company_id: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Campaign</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {session.company_id && session.company_id == '251' ? (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_title"
                                                        onChange={handleChange}
                                                        isInvalid={touched.campaign_title && !!errors.campaign_title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.campaign_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="from"
                                                        className="border"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("ds", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.start_date && !!errors.start_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="from"
                                                        className="border"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("de", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.end_date && !!errors.end_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                        ) : (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_title"
                                                        onChange={handleChange}
                                                        isInvalid={touched.campaign_title && !!errors.campaign_title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.campaign_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <Form.Label>
                                                        Business Unit
                                                    </Form.Label>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={businessUnit.map(item => ({ value: item.business_unit_id, label: item.unit_title, company_id: item.company_id }))}
                                                        onChange={(e) => {
                                                            setFieldValue('business_unit', e ? e.value : "");
                                                            setFieldValue('bu_company_id', e ? e.company_id : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="from"
                                                        className="border"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("ds", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.start_date && !!errors.start_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="from"
                                                        className="border"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("de", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.end_date && !!errors.end_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Target Unit</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_target_unit"
                                                        onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Target Value</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_target_value"
                                                        onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Add Campaign
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                        <Formik
                            initialValues={{
                                campaign_title: currentData ? currentData.marketing_campaign_title : "",
                                business_unit: currentData ? (currentData.business_unit_id == '0' ? "" : currentData.business_unit_id) : "",
                                start_date: currentData ? currentData.start_date : "",
                                end_date: currentData ? currentData.end_date : "",
                                campaign_target_unit: currentData ? currentData.campaign_target_unit : "",
                                campaign_target_value: currentData ? currentData.campaign_target_value : "",
                                marketing_campaign_id: currentData ? currentData.marketing_campaign_id : "",
                                company_id: currentData ? currentData.company_id : ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {session.company_id && session.company_id == '251' ? (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_title"
                                                        value={values.campaign_title}
                                                        onChange={handleChange}
                                                        isInvalid={touched.campaign_title && !!errors.campaign_title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.campaign_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="from"
                                                        className="border"
                                                        value={values.start_date ? moment(values.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("ds", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.start_date && !!errors.start_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="from"
                                                        className="border"
                                                        value={values.end_date ? moment(values.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("de", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.end_date && !!errors.end_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>
                                        ) : (
                                            <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_title"
                                                        value={values.campaign_title}
                                                        onChange={handleChange}
                                                        isInvalid={touched.campaign_title && !!errors.campaign_title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.campaign_title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="w-100">
                                                    <Form.Label>
                                                        Business Unit
                                                    </Form.Label>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder="Select an option"
                                                        options={businessUnit.map(item => ({ value: item.business_unit_id, label: item.unit_title, company_id: item.company_id }))}
                                                        valueDefault={
                                                            values.business_unit !== null && values.business_unit !== undefined && values.business_unit !== ""
                                                                ? {
                                                                    label: businessUnit.find(item => item.business_unit_id === values.business_unit)?.unit_title || '',
                                                                    value: values.business_unit
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue('business_unit', e ? e.value : "");
                                                            setFieldValue('company_id', e ? e.company_id : "");
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="from"
                                                        className="border"
                                                        value={values.start_date ? moment(values.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("ds", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.start_date && !!errors.start_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="from"
                                                        className="border"
                                                        value={values.end_date ? moment(values.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : ""}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            onchangeDateRange("de", moment(e.target.value).format("DD/MM/YYYY"));
                                                        }}
                                                        isInvalid={touched.end_date && !!errors.end_date}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Target Unit</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_target_unit"
                                                        value={values.campaign_target_unit}
                                                        onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign Target Value</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="campaign_target_value"
                                                        value={values.campaign_target_value}
                                                        onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    {/* Campaign Add Source */}

                    {
                        session.company_id && session.company_id == '251' ? (
                            <>
                                <Offcanvas
                                    show={selectedCampaignModal}
                                    onHide={setSelectedCampaignModal}
                                    placement="bottom"
                                    style={{
                                        left: 57,
                                        height: '100vh',
                                    }}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title as={"h6"}>Campaign Project Setting</Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body style={{ backgroundColor: "rgb(243, 243, 243)" }}>
                                        {currentStep === 1 ? (
                                            <>
                                                <div>
                                                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddHqModal}>
                                                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Project Campaign
                                                    </button>
                                                </div>
                                                <Row>
                                                    <Col xxl={12}>
                                                        <div style={containerStyle}>
                                                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                                <AgGridReact
                                                                    ref={gridRef}
                                                                    columnDefs={gridColumnHq}
                                                                    defaultColDef={{ flex: 1 }}
                                                                    rowData={loading ? [] : HqDataListing}
                                                                    rowHeight={70}
                                                                    pagination={true}
                                                                    onCellClicked={onCellClickedHq}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex">
                                                    <div className="me-4">
                                                        <button type="button" className="btn op-button op-primary-color text-light" onClick={() => setCurrentStep(1)}>
                                                            <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back to Projects
                                                        </button>
                                                    </div>
                                                    {sourceListData.some(source => source.company_id === session.company_id) && (
                                                        <div>
                                                            <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddSourceModal}>
                                                                <FontAwesomeIcon icon={faPlus} className="me-2" /> Source
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <Row>
                                                    <Col xxl={12}>
                                                        <div style={containerStyle}>
                                                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                                <AgGridReact
                                                                    ref={gridRef}
                                                                    columnDefs={gridColumnSource}
                                                                    defaultColDef={{ flex: 1 }}
                                                                    rowData={loading ? [] : sourceListData} // Update with relevant data
                                                                    rowHeight={70}
                                                                    pagination={true}
                                                                    onCellClicked={onCellClickedSource}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Modal show={isAddSource} onHide={toggleAddSourceModal} size="md" style={{ zIndex: '1051' }}>
                                                    <Formik
                                                        initialValues={{
                                                            source_record: ""
                                                        }}
                                                        onSubmit={handleAddSource}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange,
                                                            values,
                                                            errors,
                                                            touched,
                                                            setFieldValue
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title className="op-text-bigger">Add Source</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <Form.Group className="w-100">
                                                                        <Form.Label>
                                                                            Deal Source
                                                                        </Form.Label>
                                                                        <FormSelect
                                                                            className="mb-3"
                                                                            placeholder="Select an option"
                                                                            options={sourceRecord}
                                                                            onChange={(e) => {
                                                                                setFieldValue('source_record', e ? e.value : "");
                                                                            }}
                                                                            isSearchable={true}
                                                                            isClearable={true}
                                                                        />
                                                                    </Form.Group>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                                                        Add Source
                                                                    </button>
                                                                </Modal.Footer>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Modal>
                                            </>
                                        )}
                                    </Offcanvas.Body>
                                </Offcanvas>

                                <Modal show={isAddHq} onHide={toggleAddHqModal} size="md" style={{ zIndex: '1051' }}>
                                    <Formik
                                        initialValues={{
                                            project: [],
                                            business_units: {},
                                            target_unit: "",
                                            target_value: ""
                                        }}
                                        onSubmit={(values) => {
                                            handleAddHq(values);
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            setFieldValue
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title className="op-text-bigger">Add Project</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {HqCompanyList.map((company, index) => (
                                                        <div key={index} className="mb-3">
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={company.company_title}
                                                                value={company.company_id}
                                                                checked={values.project.includes(company.company_id)}
                                                                onChange={(e) => {
                                                                    const updatedProjects = [...values.project];
                                                                    const updatedBusinessUnits = { ...values.business_units };

                                                                    if (e.target.checked) {
                                                                        updatedProjects.push(company.company_id);
                                                                        updatedBusinessUnits[company.company_id] = "";
                                                                    } else {
                                                                        const index = updatedProjects.indexOf(company.company_id);
                                                                        if (index > -1) {
                                                                            updatedProjects.splice(index, 1);
                                                                            delete updatedBusinessUnits[company.company_id];
                                                                        }
                                                                    }

                                                                    setFieldValue("project", updatedProjects);
                                                                    setFieldValue("business_units", updatedBusinessUnits);
                                                                }}
                                                            />
                                                            {values.project.includes(company.company_id) && (
                                                                <>
                                                                    <Form.Group className="mt-2">
                                                                        <Form.Label>Business Unit</Form.Label>
                                                                        <FormSelect
                                                                            value={values.business_units[company.company_id]}
                                                                            options={company.business_unit.map(item => ({ value: item.business_unit_id, label: item.business_unit_title }))}
                                                                            onChange={(e) => {
                                                                                const updatedBusinessUnits = {
                                                                                    ...values.business_units,
                                                                                    [company.company_id]: e.value,
                                                                                };
                                                                                setFieldValue("business_units", updatedBusinessUnits);
                                                                            }}
                                                                        />
                                                                    </Form.Group>

                                                                    <Form.Group className="mt-2">
                                                                        <Form.Label>Target Unit</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`target_unit.${company.company_id}`}
                                                                            value={values.target_unit[company.company_id]}
                                                                            onChange={(e) => {
                                                                                const updatedTargetUnits = {
                                                                                    ...values.target_unit,
                                                                                    [company.company_id]: e.target.value,
                                                                                };
                                                                                setFieldValue("target_unit", updatedTargetUnits);
                                                                            }}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Group>

                                                                    <Form.Group className="mt-2">
                                                                        <Form.Label>Target Value</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name={`target_value.${company.company_id}`}
                                                                            value={values.target_value[company.company_id]}
                                                                            onChange={(e) => {
                                                                                const updatedTargetValue = {
                                                                                    ...values.target_value,
                                                                                    [company.company_id]: e.target.value,
                                                                                };
                                                                                setFieldValue("target_value", updatedTargetValue);
                                                                            }}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button type="submit" className="op-button op-primary-color text-light shadow">
                                                        Add Project
                                                    </Button>
                                                </Modal.Footer>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal>

                                <Modal show={showModalHq} onHide={handleCloseModalHq} size="md" style={{ zIndex: '1051' }}>
                                    <Formik
                                        initialValues={{
                                            business_unit: currentDataHq ? currentDataHq.business_unit_id : "",
                                            target_unit: currentDataHq ? currentDataHq.campaign_target_unit : "",
                                            target_value: currentDataHq ? currentDataHq.campaign_target_value : "",
                                            company_id: currentDataHq ? currentDataHq.company_id : "",
                                            marketing_campaign_id: currentDataHq ? currentDataHq.marketing_campaign_id : ""
                                        }}
                                        onSubmit={handleUpdateHq}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Edit</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Business Unit</Form.Label>
                                                        <FormSelect
                                                            valueDefault={
                                                                values && values.company_id === '251'
                                                                    ? {
                                                                        value: GlHqCompanyList.find(item => item.business_unit_id === values.business_unit)?.business_unit_id,
                                                                        label: GlHqCompanyList.find(item => item.business_unit_id === values.business_unit)?.unit_title
                                                                    }
                                                                    : {
                                                                        value: HqCompanyList.find(company => company.company_id === values.company_id)
                                                                            ?.business_unit.find(item => item.business_unit_id === values.business_unit)?.business_unit_id,
                                                                        label: HqCompanyList.find(company => company.company_id === values.company_id)
                                                                            ?.business_unit.find(item => item.business_unit_id === values.business_unit)?.business_unit_title
                                                                    }
                                                            }
                                                            options={
                                                                currentDataHq && currentDataHq.company_id == '251'
                                                                    ? GlHqCompanyList.map(item => ({
                                                                        value: item.business_unit_id,
                                                                        label: item.unit_title
                                                                    }))
                                                                    : HqCompanyList.find(company => company.company_id === currentDataHq.company_id)
                                                                        ?.business_unit.map(item => ({
                                                                            value: item.business_unit_id,
                                                                            label: item.business_unit_title
                                                                        })) || []
                                                            }
                                                            placeholder="Select an option"
                                                            onChange={(e) => {
                                                                setFieldValue("business_unit", e.value);
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Target Unit</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="target_unit"
                                                            value={values.target_unit}
                                                            onChange={handleChange}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Target Value</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="target_value"
                                                            value={values.target_value}
                                                            onChange={handleChange}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" type="submit">
                                                        Update
                                                    </Button>
                                                </Modal.Footer>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal>
                            </>
                        ) : (
                            <>
                                <Offcanvas
                                    show={selectedCampaignModal}
                                    onHide={setSelectedCampaignModal}
                                    placement="bottom"
                                    style={{
                                        left: 57,
                                        height: '100vh',
                                    }}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title as={"h6"}>Campaign Source Setting</Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body style={{ backgroundColor: "rgb(243, 243, 243)" }}>
                                        <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddSourceModal}>
                                            <FontAwesomeIcon icon={faPlus} className="me-2" /> Source
                                        </button>
                                        <Row>
                                            <Col xxl={12}>
                                                <div style={containerStyle}>
                                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                        <AgGridReact ref={gridRef} columnDefs={gridColumnSource} defaultColDef={{ flex: 1 }} rowData={loading ? [] : sourceListData} rowHeight={70} pagination={true} onCellClicked={onCellClickedSource} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Offcanvas.Body>
                                </Offcanvas>

                                <Modal show={isAddSource} onHide={toggleAddSourceModal} size="md" style={{ zIndex: '1051' }}>
                                    <Formik
                                        initialValues={{
                                            source_record: ""
                                        }}
                                        onSubmit={handleAddSource}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title className="op-text-bigger">Add Source</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group className="w-100">
                                                        <Form.Label>
                                                            Deal Source
                                                        </Form.Label>
                                                        <FormSelect
                                                            className="mb-3"
                                                            placeholder="Select an option"
                                                            options={sourceRecord}
                                                            onChange={(e) => {
                                                                setFieldValue('source_record', e ? e.value : "");
                                                            }}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                        />
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                                        Add Source
                                                    </button>
                                                </Modal.Footer>
                                            </Form>
                                        )}
                                    </Formik>
                                </Modal>
                            </>
                        )
                    }

                </div >
            )}
        </Container >
    );
}

export default SettingsCampaign;
