import { useAuth } from "../../auth/AuthContext";
import { FormSelect } from "../../includes/FormCustom";
import axios from "../../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";
import { useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

const BookingLog = ({ logRecord, bookingFormat, buyerFormat }) => {
  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "63vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const setFinancing = (input) => {
    if (input === "bank") {
      return "Bank";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "government_loan") {
      return "Government Loan";
    } else if (input === "staff_loan") {
      return "Staff Loan";
    } else if (input === "other") {
      return "Rent To Own";
    }
  };

  const setFirstTimeBuyer = (input) => {
    if (input === "yes") {
      return "Yes";
    } else if (input === "no") {
      return "No";
    }
  };

  const setPaymentMethod = (input) => {
    if (input === "bank_draft") {
      return "Online Banking";
    } else if (input === "cash") {
      return "Cash";
    } else if (input === "cc") {
      return "Credit Card";
    } else if (input === "cheque") {
      return "Cheque";
    } else if (input === "others") {
      return "Others";
    }
  };

  const setCurrency = (input) => {
    if (input) {
      input = Number(input);

      const formattedAmount = input.toLocaleString("en-MY", {
        style: "currency",
        currency: "MYR",
      });

      return formattedAmount;
    }

    return "";
  };

  const setBumiputera = (input) => {
    if (Number(input) === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const setPercentage = (input) => {
    if (input) {
      return Math.floor(input) + "%";
    }
    return "";
  };

  const setBookingStatus = (record) => {
    const labels = {
      booked: "Reserved",
      booking_confirmed: "Booking Confirmed",
      spa_signed: "SPA Signed",
      spa_sold: "Sold",
      spa_stamped: "SPA Stamped",
      cancelled: "Cancelled",
    };

    return labels[record] || "";
  };

  const setCategoryLabel = (record) => {
    const labels = {
      buyer_bumiputera: bookingFormat.bumiputera_label,
      booking_status: "Booking Status",
      booking_deposit: bookingFormat.booking_fee_label,
      payment_method: bookingFormat.booking_fee_label,
      payment_ref: bookingFormat.payment_reference_label,
      payment_slip: bookingFormat.payment_receipt_label,
      payment_date: "Payment Date",
      selling_price: bookingFormat.selling_price_label,
      nett_price: bookingFormat.net_price_label,
      bumi_package: bookingFormat.bumi_discount_label,
      rebate_title: bookingFormat.rebate_package_label,
      first_time_buyer: bookingFormat.first_time_buyer_label,
      financing: bookingFormat.financing_label,
      buyer_salutation: buyerFormat.salutation_label,
      buyer_name: buyerFormat.fullname_label,
      buyer_id_type: buyerFormat.identification_type_label,
      buyer_id_number: buyerFormat.identification_number_label,
      buyer_occupation: buyerFormat.occupation_label,
      buyer_income: buyerFormat.monthly_income_label,
      buyer_dob: buyerFormat.dob_label,
      buyer_gender: buyerFormat.gender_label,
      buyer_race: buyerFormat.race_label,
      buyer_nationality: buyerFormat.nationality_label,
      buyer_marital: buyerFormat.marital_status_label,
      buyer_email: buyerFormat.email_address_label,
      buyer_mobile_prefix: buyerFormat.mobile_number_label + " Prefix",
      buyer_mobile_number: buyerFormat.mobile_number_label,
      buyer_house_prefix: buyerFormat.office_number_label + " Prefix",
      buyer_house_number: buyerFormat.office_number_label,
      buyer_fax_number: buyerFormat.fax_number_label,
      buyer_address1: buyerFormat.address1_label,
      buyer_address2: buyerFormat.address2_label,
      buyer_address3: buyerFormat.address3_label,
      buyer_postcode: buyerFormat.postcode_label,
      buyer_country: buyerFormat.country_label,
      buyer_state: buyerFormat.state_label,
      buyer_city: buyerFormat.city_label,
      emergency_name: buyerFormat.emergency_contact_fullname_label,
      emergency_relation: buyerFormat.emergency_contact_relation_label,
      emergency_prefix: buyerFormat.emergency_contact_mobile_number_label + " Prefix",
      emergency_number: buyerFormat.emergency_contact_mobile_number_label,
      emergency_email: buyerFormat.emergency_contact_email_label,
      buyer_id_front: buyerFormat.identification_card_front_label,
      buyer_id_back: buyerFormat.identification_card_back_label,
      spaRef: "SPA Reference",
      solicitorName: "Solicitor Name",
      solicitorContactNum: "Solicitor Contact",
      solicitorAddress: "Solicitor Address",
      solicitorPic: "Solicitor PIC",
      financierName: "Financier Name",
      financierBranch: "Financier Branch",
      financierPic: "Financier PIC",
      financierPicContact: "Financier PIC Contact",
      financierBranchAddress: "Financier Branch Address",
      bank_name: bookingFormat.bank_name_label,
      sst_number: buyerFormat.sst_number_label,
      tax_number: buyerFormat.tax_number_label,
    };

    return labels[record] || record;
  };

  const setValueLabel = (record, category) => {
    const labels = {
      buyer_bumiputera: setBumiputera(record),
      booking_deposit: setCurrency(record),
      payment_method: setPaymentMethod(record),
      selling_price: setCurrency(record),
      nett_price: setCurrency(record),
      bumi_package: setPercentage(record),
      first_time_buyer: setFirstTimeBuyer(record),
      financing: setFinancing(record),
      buyer_mobile_prefix: record ? "+" + record : "No values",
      buyer_house_prefix: record ? "+" + record : "No values",
      emergency_prefix: record ? "+" + record : "No values",
      booking_status: setBookingStatus(record),
    };

    return labels[category] || record;
  };

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      width: 70,
      maxWidth: 70,
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => params.rowIndex + 1,
    },
    {
      headerName: "Changed Date",
      field: "date_time_create",
      maxWidth: 150,
      cellRenderer: (params) => moment(new Date(params.data.date_time_create)).format("LLL"),
    },
    {
      headerName: "Changed By",
      field: "user_name",
      maxWidth: 150,
    },
    {
      headerName: "Title",
      field: "log_title",
      maxWidth: 300,
    },
    {
      headerName: "Changed Values",
      field: "log_record",
      autoHeight: true,
      cellRenderer: (params) => {
        if (params.data.log_type === "array") {
          return (
            <div className="py-2">
              {Object.entries(params.data.log_record).map(([category, values]) => (
                <div className="log-item" key={category}>
                  {values.old !== values.new && (
                    <p className="m-0" style={{ lineHeight: 1.5 }}>
                      <b>{setCategoryLabel(category)}</b> | {setValueLabel(values.old, category) || "No values"} <FontAwesomeIcon icon={faArrowRight} size="xs" /> {setValueLabel(values.new, category) || "No values"}
                    </p>
                  )}
                </div>
              ))}
            </div>
          );
        } else {
          return params.data.log_record;
        }
      },
    },
  ];

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback(function () {
    return Math.random();
  }, []);

  return (
    <div style={containerStyle}>
      <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
        <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={logRecord} defaultColDef={gridColDef} rowHeight={50} rowSelection={"single"} getRowId={gridRowId} autoHeight={true} animateRows={true}  />
      </div>
    </div>
  );
};

export default BookingLog;
